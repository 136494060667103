import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { Row, Table } from "reactstrap";

import { formatNumberWithCommas } from "helpers/format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDraggable } from "react-use-draggable-scroll";
import TooltipCustom from "./TooltipCustom";
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";

interface TableLottoProps {
  className?: any;
  summary?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string, order_by: string };
  handleChangeSorting?: (sortBy: { sort_by: string | undefined | null, order_by: string | undefined | null }) => void,
  manualSorting?: boolean,
  isLoading?: boolean,
  isShowPagination?: boolean,
  isShowLoadingBottom?: boolean,
  isShowColumn?: boolean,
  classBackgroundWhenActiveSort?: string,
  isScrollToTop?: boolean,
}

const TableLotto = ({
  summary,
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = '',
  isScrollToTop = true,
}: TableLottoProps) => {

  const { t } = useTranslation();

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);

  const thRefs = useRef<any>([]); // Fixed Header Table
  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementHeaderFixedRef = useRef<any>(null); // Fixed Header Table
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [rowsShow, setRowsShow] = useState<number[]>([]);

  const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false); // Fixed Header Table

  const { events } = useDraggable(warperTableRef);

  const generateSortingIndicator = (column: string = '') => {
    return (
      column ? (
        <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
          {(column === sorting?.sort_by && sorting?.order_by === 'ASC') && <i className={`ri-arrow-up-line fs-13 text-primary`} />}
          {(column === sorting?.sort_by && sorting?.order_by === 'DESC') && <i className={`ri-arrow-down-line fs-13 text-primary`} />}
        </span>
      ) : (
        ""
      )
    );
  };

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft)
    if (warperTableRef.current && elementFixedRef.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
        elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft; // Fixed Header Table
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }

      // Begin::Fixed Header Table
      const rect = warperTableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top;
      if (distanceFromTop <= 70) {
        elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft;
        setIsShowHeaderTableFixed((prev) => true);
      } else {
        setIsShowHeaderTableFixed((prev) => false);
      }
      // End::Fixed Header Table
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleShowMore = (num: number) => {
    if (rowsShow?.includes(num)) {
      setRowsShow((prev) => prev?.filter((item) => item !== num));
    } else {
      setRowsShow((prev) => [...prev, num]);
    }
  }

  const getColorNum = (num: number) => {
    if (num >= 50) {
      return '#b0d8';
    }
    if (num >= 40) {
      return '#b0d840';
    }
    if (num > 30) {
      return '#aaa';
    }
    if (num > 20) {
      return '#ff7272';
    }
    if (num > 10) {
      return '#69c8f2';
    }
    return '#f7b'
  };

  const formatDate = (date: string) => {
    const num = moment(date).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
    const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
    return (
      < >{date} ({textNote})</>
    )
  }

  return (
    <Fragment>
      <div className={`table-custom-pagination ${divClass}`} {...events} ref={warperTableRef} onScroll={handleScroll}>
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ minHeight: '70px' }} >
          <thead className={theadClass} ref={tableRef}>
            <tr className={trClass}>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '180px' }}
                ref={el => thRefs.current[0] = el} // Fixed Header Table
              >
                {t('Date')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '100px' }}
                ref={el => thRefs.current[1] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'session', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'session', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'session' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Session Lotto')}
                {generateSortingIndicator('session')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '200px' }}
                ref={el => thRefs.current[2] = el} // Fixed Header Table
              >

                {t('Title')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '200px' }}
                ref={el => thRefs.current[3] = el} // Fixed Header Table
              >
                {t('Number Win')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '160px' }}
                ref={el => thRefs.current[4] = el} // Fixed Header Table
              >
                {t('Number Bonus')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '220px' }}
                ref={el => thRefs.current[5] = el} // Fixed Header Table
              >
                {t('Payment Deadline')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '200px', }}
                ref={el => thRefs.current[6] = el} // Fixed Header Table

              >
                {t('Total Sale Amount')}
              </th>
              <th
                className={`text-end cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
                ref={el => thRefs.current[7] = el} // Fixed Header Table
              >
                {t('Created at')}
              </th>
              <th
                className={`text-end cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
                ref={el => thRefs.current[8] = el} // Fixed Header Table
              >
                {t('Updated at')}
              </th>
            </tr>
            <tr >
              <th colSpan={8} className="py-0 px-0" style={{ borderBottomColor: 'transparent' }}>
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((item: any, index: number) => (
              <>
                <tr key={item?.id}>
                  <td className="text-start" role="cell" rowSpan={rowsShow?.includes(item?.id) ? (item?.rank_bonus?.length + 2) : 1} style={{ borderRight: 'var(--vz-card-border-width) solid var(--vz-card-border-color)' }}>
                    <div className="d-flex justify-content-between align-item-center">
                      <div className="d-flex align-item-center m-0">
                        <div className="m-auto">{formatDate(item?.date)}</div>
                      </div>
                      <div className="p-2 cursor-pointer" onClick={() => handleShowMore(item?.id)} style={{ fontWeight: 'bold', fontSize: '22px' }}>
                        {rowsShow?.includes(item?.id) ? <i className="bx bx-minus"></i> : <i className="bx bx-plus"></i>}
                      </div>
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="d-flex justify-content-center align-item-center">
                      <div >{item?.session || ''}</div>
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{item?.title || ''}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="d-flex">{
                      item?.number_win?.map((num: number) => (
                        <div className="ball-number" style={{ backgroundColor: `${getColorNum(num)}` }}>{num}</div>
                      ))
                    }</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>
                      <div className="ball-number" style={{ backgroundColor: `#fbc400` }}>{item?.number_bonus}</div>
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{item?.payment_deadline || ''}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{formatNumberWithCommas(item?.total_sale_amount || 0)}</div>
                  </td>
                  <td className="text-end" role="cell">
                    <div>
                      <span>{String(item?.created_at || '').split(' ')?.[0] || ''}</span> <br /> <span className="text-secondary">{String(item?.created_at || '').split(' ')?.[1] || ''}</span>
                    </div>
                  </td>
                  <td className="text-end" role="cell">
                    <div>
                      <span>{String(item?.updated_at || '').split(' ')?.[0] || ''}</span> <br /> <span className="text-secondary">{String(item?.updated_at || '').split(' ')?.[1] || ''}</span>
                    </div>
                  </td>
                </tr>
                {(rowsShow?.includes(item?.id) && item?.rank_bonus?.length) && (
                  <tr style={{ backgroundColor: 'rgb(234, 234, 234)' }}>
                    <td className="text-center" role="cell" >
                      <div className="text-center d-flex justify-content-center align-item-center">
                        <div className="fs-12 fw-bold">{t('Rank')}</div>
                      </div>
                    </td>
                    <td className="text-center" role="cell">
                      <div className="fs-12 fw-bold">{t('Win Amount Per Game')}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div className="fs-12 fw-bold">{t('Number Of Win Game')}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div className="fs-12 fw-bold">{t('Total Of Win Amount')}</div>
                    </td>
                    <td className="text-center" role="cell" colSpan={2}>
                      <div className="fs-12 fw-bold">{t('Win Criteria')}</div>
                    </td>
                    <td className="text-center" role="cell" colSpan={2}>
                      <div className="fs-12 fw-bold">{t('Note')}</div>
                    </td>
                  </tr>)}
                {rowsShow?.includes(item?.id) && (<>{item?.rank_bonus?.map((rank: any, i: number) => (
                  <tr key={`${item?.id}-${i}`} style={{ backgroundColor: 'rgb(248, 248, 248)' }}>
                    <td className="text-center" role="cell">
                      <div className="text-center d-flex justify-content-center align-item-center">
                        <div className="fs-12">{rank?.rank}</div>
                      </div>
                    </td>
                    <td className="text-center" role="cell">
                      <div className="fs-12">{formatNumberWithCommas(rank?.win_amount_per_game || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div className="fs-12">{formatNumberWithCommas(rank?.number_of_win_game || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div className="fs-12">{formatNumberWithCommas(rank?.total_of_win_amount || 0)}</div>
                    </td>
                    <td className="text-center" role="cell" colSpan={2}>
                      <div className="fs-12">{rank?.win_criteria || '-'}</div>
                    </td>
                    <td className="text-center" role="cell" colSpan={2}>
                      <div className="fs-12">{rank?.note || '-'}</div>
                    </td>
                  </tr>
                ))}
                </>)}
              </>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Begin::Fixed Header Table */}
      <div
        className={`table-card overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
        style={{
          position: 'fixed',
          top: 70,
          zIndex: 9,
          left: elementWarperTable?.left,
          width: elementWarperTable?.width,
          right: 0,

        }}
        ref={elementHeaderFixedRef}
      >
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'} mb-0`} style={{ height: 'auto', tableLayout: 'fixed' }} >
          <thead className={theadClass} style={{ width: elementTable?.width }}>
            <tr className={trClass}>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[0]?.offsetWidth }}
              >
                {t('Date')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[1]?.offsetWidth }}
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'session', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'session', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'session' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Session Lotto')}
                {generateSortingIndicator('session')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[2]?.offsetWidth }}
              >

                {t('Title')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[3]?.offsetWidth }}
              >
                {t('Number Win')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[4]?.offsetWidth }}
              >
                {t('Number Bonus')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[5]?.offsetWidth }}
              >
                {t('Payment Deadline')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[6]?.offsetWidth }}

              >
                {t('Total Sale Amount')}
              </th>
              <th
                className={`text-end cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[7]?.offsetWidth }}
              >
                {t('Created at')}
              </th>
              <th
                className={`text-end cursor-pointer ${thClass || ''}`}
                {...{ width: thRefs.current[8]?.offsetWidth }}
              >
                {t('Updated at')}
              </th>
            </tr>
          </thead>
        </Table>
      </div>
      {/* End::Fixed Header Table */}

      <div
        className="overflow-x-auto color-scrollbar-os"
        style={{
          position: 'fixed',
          zIndex: 999,
          left: elementWarperTable?.left,
          bottom: 0,
          width: elementWarperTable?.width,
          right: 0,
          opacity: isScrollBottom ? 0 : 1,
        }}
        ref={elementFixedRef}
        onScroll={handleScroll}
      >
        <div style={{ width: elementTable?.width, height: '1px' }}></div>
      </div>


      {isShowPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {t('Total')}:
              {" "}
              <span className="fw-semibold ms-1">{formatNumberWithCommas(totalRecords)}</span>
              {" "}
              {t('Results')}
            </div>
          </div>
          <div className="col-sm-auto">
            <ReactPaginate
              nextLabel={`${t('next')} >`}
              onPageChange={(page) => {
                if (handleChangePage) {
                  isScrollToTop && handleScrollToTop();
                  handleChangePage(page.selected);
                }
              }}
              forcePage={+customPageIndex}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={customPageCount}
              previousLabel={`< ${t('previous')}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-separated"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>)}
    </Fragment>
  );
};

TableLotto.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableLotto);