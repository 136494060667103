import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import TableContainer from "../../../components/Common/TableContainer";
import { getRelatedKeywords as onGetRelatedKeywords } from "../../../store/thunks";

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

export interface Props {
  id?: number;
}

const formatQuery = (query: any) => {
  return {
    id: query?.id,
    page: query?.page_inner,
    limit: query?.limit_inner,
  };
};

const RelatedKeyword = ({ id }: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    id: withDefault(NumberParam, id),
    time_request_inner: withDefault(NumberParam, 0),
    page_inner: withDefault(NumberParam, 1),
    limit_inner: withDefault(NumberParam, 30),
    sort_by_inner: withDefault(StringParam, TYPE_SELECT_DEFAULT),
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.TrendIndex;

  const RelatedKeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      relatedKeywords: state.relatedKeywords,
      isRelatedKeywordsSuccess: state.isRelatedKeywordsSuccess,
      isRelatedKeywordsLoading: state.isRelatedKeywordsLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { relatedKeywords, error, isRelatedKeywordsLoading } = useSelector(RelatedKeywordProperties);

  useEffect(() => {
    if (!!query?.id) {
      dispatch(onGetRelatedKeywords(formatQuery(query)));
    }
  }, [dispatch, JSON.stringify(query)]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => {
          return (
            <>
              <div>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Depth"),
        accessor: "related_keywords",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div>
              {(cell.value || [])[0]?.depth}
            </div>
          );
        },
      },
      {
        Header: t('Search Monthly'),
        accessor: "search_monthly",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Search Monthly PC'),
        accessor: "search_monthly_pc",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Search Monthly Mobile'),
        accessor: "search_monthly_mobile",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },

      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: false,
        sortable: false,
        thWidth: 110,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </>
          )
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: false,
        sortable: false,
        thWidth: 110,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </>
          )
        },
      },
    ],
    [i18n?.language, query]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page_inner: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={relatedKeywords?.list?.length ? relatedKeywords?.list : []}
                        customPageSize={query.limit_inner}
                        customPageIndex={query.page_inner - 1}
                        totalRecords={relatedKeywords?.pagination?.total}
                        customPageCount={Math.ceil(Number(relatedKeywords?.pagination?.total) / Number(relatedKeywords?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by_inner, order_by: query.order_by_inner }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isRelatedKeywordsLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default RelatedKeyword;