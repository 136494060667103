import Tags from '@yaireo/tagify/dist/react.tagify';
import "@yaireo/tagify/dist/tagify.css";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface Option {
  label: string;
  value: string;
}

interface KeywordInputProps {
  name?: string,
  placeholder?: string,
  initialValue: Option[],
  onChangeTag?: (params: Option[]) => void;
  type?: 'keywords'
}

const KeywordInput = ({
  name = '',
  placeholder = '',
  type = 'keywords',
  initialValue = [],
  onChangeTag,
}: KeywordInputProps) => {
  const { i18n } = useTranslation();

  const onChange = useCallback((event: any) => {
    const valueArr = event.detail.tagify.value?.map((item: any) => ({ ...item, value: String(item?.value).trim(), label: item?.value }));
    onChangeTag && onChangeTag(valueArr);
  }, [onChangeTag]);

  return (
    <div className={`${i18n?.language === 'ko' ? 'input-tagify-lang-ko' : 'input-tagify-lang-en'} ${type}`}>
      <Tags
        name={name}
        value={initialValue}
        className="form-control form-custom-input-tagify"
        placeholder=''
        onChange={onChange}
        showDropdown={false}
        settings={{
          trim: true,
          editTags: 2,
          keepInvalidTags: false,
          // classNames: {
          //   tag: `tagify__tag`
          // }
        }}
      />
    </div>
  );
};

export default KeywordInput;
