import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import TableContainer from "../../../../components/Common/TableContainer";
import { getAdsAccountHistory as onGetAdsAccountHistory } from "../../../../store/thunks";

import { IAdsAccount, IAgency, IMedia } from 'api/types/_media';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import { useRole } from 'components/Hooks/UserHooks';

interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'id';

const History = ({ accountId = '' }: any) => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const [query, setQuery]: any = useQueryParams({
    account_id: withDefault(StringParam, accountId),
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    partner_id: withDefault(StringParam, ''),
    agency_id: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [listAgency, setListAgency] = useState<IAgency[]>([]);
  const [agencySearch, setAgencySearch] = useState<Option | null>(
    {
      label: listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.name || t('All Agency'),
      value: `${listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.id || ''}`
    }
  );

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.id || ''}`
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Media;

  const AdsAccountProperties = createSelector(
    selectLayoutState,
    (state) => ({
      adsAccount: state.adsAccountHistory,
      isAdsAccountSuccess: state.isAdsAccountHistorySuccess,
      isAdsAccountLoading: state.isAdsAccountHistoryLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { adsAccount, error, isAdsAccountLoading } = useSelector(AdsAccountProperties);

  const [systemRate, setSystemRate] = useState<string>('');
  const [partnerRate, setPartnerRate] = useState<string>('');
  const [initialValuesDefault, setInitialValuesDefault] = useState<IAdsAccount | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!!accountId) {
      dispatch(onGetAdsAccountHistory(query));
    }
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      partner_id: mediaSearch?.value || '',
      agency_id: agencySearch?.value ?? '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      partner_id: '',
      date_from: moment(new Date(new Date().setDate(new Date().getDate() - 2))).format("Y-MM-DD"),
      date_to: moment(new Date(new Date().setDate(new Date().getDate() - 2))).format("Y-MM-DD"),
      agency_id: '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };

    setQuery(queryNew, "push");
    setMediaSearch({ label: t('All Media'), value: '' });
    setAgencySearch({ label: t('All Agency'), value: '' });
  };

  const handleShowUpdate = async (item: IAdsAccount) => {
    setInitialValuesDefault(item);
    setSystemRate(`${item?.system_revenue_rate}`);
    setPartnerRate(`${item?.partner_revenue_rate}`);
  };

  const onCloseClick = async () => {
    setInitialValuesDefault(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('ID'),
        accessor: "id",
        filterable: false,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle',
        Cell: (cell: any) => (
          <>
            <div>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: false,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle',
        Cell: (cell: any) => (
          <>
            <div>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('System Revenue Rate'),
        accessor: "system_revenue_rate",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="w-100 text-center m-auto">
              {item?.system_revenue_rate}%
            </div>
          )
        }
      },
      {
        Header: t('Partner Revenue Rate'),
        accessor: "partner_revenue_rate",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="w-100 text-center m-auto">
              {item?.partner_revenue_rate}%
            </div>
          )
        }
      },
      // {
      //   Header: t('Effective Date'),
      //   accessor: "effective_to",
      //   filterable: false,
      //   sortable: true,
      //   thClass: "text-center",
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <div className="w-100 text-center m-auto">
      //         {item?.effective_from} {!!item?.effective_to ? ` > ` : '-'} {item?.effective_to}
      //       </div>
      //     )
      //   }
      // },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language, JSON.stringify(listMedia)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card id="leadsList">
          <CardBody className="pt-3">
            <div>
              <TableContainer
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="align-middle"
                theadClass="table-light"
                columns={columns}
                data={adsAccount?.list?.length ? adsAccount?.list : []}
                customPageSize={query.limit}
                customPageIndex={query.page - 1}
                totalRecords={adsAccount?.pagination?.total}
                customPageCount={Math.ceil(Number(adsAccount?.pagination?.total) / Number(adsAccount?.pagination?.limit))}
                handleChangePage={handleChangePage}
                manualSorting={true}
                sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                handleChangeSorting={handleChangeSorting}
                isLoading={isAdsAccountLoading}
                isShowPagination={true}
              />
            </div>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment >
  );
};

export default History;
