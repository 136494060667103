import { createAsyncThunk } from "@reduxjs/toolkit";
import lottoApi from 'api/lottoApi';
import { formatQueryParams } from "helpers/format";

export const getLottos = createAsyncThunk("Lottos" , async (params: any = {}) => {
  try{
    const response = await lottoApi.lottos(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});
