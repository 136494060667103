import { createSlice } from "@reduxjs/toolkit";
import { getAllBrands, getAllModels, getBrands, getCars, getCategoriesCar, getModels, importCars } from "./thunk";
import { IBrand, ICar, ICategoryCar, IModel } from "api/types/_car";
import { PaginationResponse, ResponseData } from "api/types/_public";

export interface IState {
  categories: ResponseData<ICategoryCar[]> & PaginationResponse | null,
  isCategoryLoading : boolean,
  isCategorySuccess : boolean,

  models: ResponseData<IModel[]> & PaginationResponse | null,
  isModelLoading: boolean,
  isModelSuccess: boolean,


  brands: ResponseData<IBrand[]> & PaginationResponse | null,
  isBrandLoading: boolean,
  isBrandSuccess: boolean,

  cars: ResponseData<ICar[]> & PaginationResponse | null,
  isCarLoading: boolean,
  isCarSuccess: boolean,

  importCars: ResponseData<ICar[]> & PaginationResponse | null,
  isImportCarLoading: boolean,
  isImportCarSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading : false,
  isCategorySuccess : false,

  models: null,
  isModelLoading: false,
  isModelSuccess: false,

  brands: null,
  isBrandLoading: false,
  isBrandSuccess: false,

  cars: null,
  isCarLoading: false,
  isCarSuccess: false,

  importCars: null,
  isImportCarLoading: false,
  isImportCarSuccess: false,

  error: {},
};

const carSlice = createSlice({
  name: "car",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get categories
    builder.addCase(getCategoriesCar.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategoriesCar.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategoriesCar.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });


    //get models
    builder.addCase(getModels.pending, (state: IState, action: any) => {
      state.isModelLoading = true
    });
    builder.addCase(getModels.fulfilled, (state: IState, action: any) => {
      state.models = action.payload.data;
      state.isModelSuccess = true;
      state.isModelLoading = false;
    });
    builder.addCase(getModels.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isModelSuccess = false;
      state.isModelLoading = false;
    });


    //get brands
    builder.addCase(getBrands.pending, (state: IState, action: any) => {
      state.isBrandLoading = true
    });
    builder.addCase(getBrands.fulfilled, (state: IState, action: any) => {
      state.brands = action.payload.data;
      state.isBrandSuccess = true;
      state.isBrandLoading = false;
    });
    builder.addCase(getBrands.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isBrandSuccess = false;
      state.isBrandLoading = false;
    });

    //get cars
    builder.addCase(getCars.pending, (state: IState, action: any) => {
      state.isCarLoading = true
    });
    builder.addCase(getCars.fulfilled, (state: IState, action: any) => {
      state.cars = action.payload.data;
      state.isCarSuccess = true;
      state.isCarLoading = false;
    });
    builder.addCase(getCars.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCarSuccess = false;
      state.isCarLoading = false;
    });

    //import cars
    builder.addCase(importCars.pending, (state: IState, action: any) => {
      state.isImportCarLoading = true
    });
    builder.addCase(importCars.fulfilled, (state: IState, action: any) => {
      state.importCars = action.payload.data;
      state.isImportCarSuccess = true;
      state.isImportCarLoading = false;
    });
    builder.addCase(importCars.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isImportCarSuccess = false;
      state.isImportCarLoading = false;
    });
  },
});

export default carSlice.reducer;