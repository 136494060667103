import { IMedia } from 'api/types/_media';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import DropdownOption from 'components/Common/DropdownOption';
import InputsRange from 'components/Common/InputsRange';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { SCHEDULE_CAMPAIGN_STATISTIC_OPTIONS, STATUS_RUNNING_CAMPAIGN_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { changeStatusCampaignsStatistics, getAllCategoriesType, getAllMedias, getCampaignStatisticsFromLab as onGetCampaignStatistics, putCampaignStatus } from "../../../store/thunks";
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import LabelWrapper from 'components/Common/LabelWrapper';
import CopyWrapper from 'components/Common/CopyWrapper';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'start_news_count': StringParam,
  'end_news_count': StringParam,
  'start_pv_per_news': StringParam,
  'end_pv_per_news': StringParam,
}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'pv_per_news';
const formatGetParamNumber = (str: String = '') => {
  const arr = String(str || "")?.split('-') || [];
  const from = String(arr[0] || "")?.split(':')[1] || '';
  const to = String(arr[1] || "")?.split(':')[1] || '';
  return ({ from, to });
};

const CampaignStatisticList = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();


  const STATUS_RUNNING_CAMPAIGN_OPTIONS_LANG = STATUS_RUNNING_CAMPAIGN_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const SCHEDULE_OPTIONS_LANG = SCHEDULE_CAMPAIGN_STATISTIC_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    domain: withDefault(StringParam, ''),
    name: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    category_type: withDefault(StringParam, ''),
    is_runing: withDefault(StringParam, ''),
    date_format: withDefault(StringParam, ''),
    // start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    // end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    ...typeQuery
  });

  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isStatusUpdate, setIsStatusUpdate] = useState<boolean>(false);
  const [idsUpdate, setIdsUpdate] = useState<number[] | string[]>([]);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);


  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);

  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [statusActionSearch, setStatusActionSearch] = useState<Option | null>(null);

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [scheduleTypeSearch, setScheduleTypeSearch] = useState<Option | null>(null);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const listCategoriesTypeLang = listCategoriesType?.map((item: any) => ({ ...item, label: t(`Category_Type_[${item?.value}]_For_Campaign`) }));

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  // News from to range
  const [newsFromRangeSearch, setNewsFromRangeSearch] = useState<string>(query?.start_news_count || "");
  const [newsToRangeSearch, setNewsToRangeSearch] = useState<string>(query?.end_news_count || "");

  // PV Per News from to range
  const [pvpnFromRangeSearch, setPVPNFromRangeSearch] = useState<string>(query?.start_pv_per_news || "");
  const [pvpnToRangeSearch, setPVPNToRangeSearch] = useState<string>(query?.end_pv_per_news || "");

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Lab;

  const CampaignStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      campaignStatistics: state.campaignStatistics,
      isCampaignStatisticSuccess: state.isCampaignStatisticSuccess,
      isCampaignStatisticLoading: state.isCampaignStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { campaignStatistics, error, isCampaignStatisticLoading } = useSelector(CampaignStatisticsProperties);

  useEffect(() => {
    dispatch(onGetCampaignStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      // title: titleSearch || "",
      name: keywordSearch || "",
      // date_format: scheduleTypeSearch?.value || '',
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      // start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      // end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      // category_type: categoryTypeSearch?.value || '',
      is_runing: statusActionSearch?.value ?? '',
      start_news_count: newsFromRangeSearch || '',
      end_news_count: newsToRangeSearch || '',
      start_pv_per_news: pvpnFromRangeSearch || '',
      end_pv_per_news: pvpnToRangeSearch || '',
      page: 1,
      // sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      domain: '',
      // title: '',
      name: '',
      // date_format: undefined,
      // start_date: moment(new Date()).format("Y-MM-DD"),
      // end_date: moment(new Date()).format("Y-MM-DD"),
      // category_type: undefined,
      is_runing: undefined,
      start_news_count: undefined,
      end_news_count: undefined,
      start_pv_per_news: undefined,
      end_pv_per_news: undefined,
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setCategoryTypeSearch(null);
    setStatusActionSearch(null);
    // setScheduleTypeSearch(null);
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);

    setNewsFromRangeSearch("");
    setNewsToRangeSearch("");
    setPVPNFromRangeSearch("");
    setPVPNToRangeSearch("");
  };

  const handleShowConfirmUpdateStatus = (status: boolean, ids: string[] | number[] | undefined = undefined) => {
    const items: string[] | number[] = Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])?.map((item: Option) => item?.value);
    setIsStatusUpdate((_prev) => status);
    setIdsUpdate((_prev) => (ids || items));
    setIsConfirm((_prev) => true);
  };

  const handleOnConfirmUpdate = async () => {
    try {
      setIsLoadingUpdate((_prev) => true);
      const data = {
        is_runing: Number(isStatusUpdate),
        ids: idsUpdate || []
      };
      const response: any = await putCampaignStatus(data);
      setIsLoadingUpdate((_prev) => false);
      if (response?.data) {
        setIsConfirm((_prev) => false);
        setOptionsSelected((_prev: any) => ({}));
        const newState: any = (campaignStatistics?.list || [])?.map((item: any) => {
          const val: number = Number(item?.id || '');
          return ((idsUpdate || []) as number[])?.includes(val) ? ({ ...item, is_runing: Number(isStatusUpdate) }) : item
        })
        dispatch(changeStatusCampaignsStatistics({
          ...campaignStatistics,
          list: newState
        }));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const onCloseConfirmClick = () => {
    setIsConfirm((_prev) => false);
  }

  // Begin:: handle select table
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (campaignStatistics?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.name }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === campaignStatistics?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);


  // End:: handle select table

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "id",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
              <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
            </div>
          </>
        ),
      },
      {
        Header: t('Campaign'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <CopyWrapper contentCopy={cell?.value} style={{ minWidth: '140px' }}>
              {cell?.value}
            </CopyWrapper>
          </>
        ),
      },
      {
        Header: t('Total News Count'),
        accessor: "total_news_count",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Description Total News'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: '100px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('PV per News'),
        accessor: "pv_per_news",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Description PV Per News'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end me-3" style={{ minWidth: '100px' }}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('PV Per News (Min)'),
        accessor: "min_pv_per_news",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description PV Per News (Min)'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '130px' }}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('PV Per News (Max)'),
        accessor: "max_pv_per_news",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description PV Per News (Max)'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '130px' }}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="ms-4 text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Medias'),
        accessor: "domains",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer" style={{ minWidth: '100px' }}>
              {cell?.value && (<> <span>
                {/* {cell?.value} */}
                {listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0]?.name || ''}
              </span>
                <br />
                <Link className='text-normal' to={(String(cell?.value).includes('https://') || String(cell?.value).includes('http://')) ? cell?.value : `https://${cell?.value}`} target="_blank">
                  {cell?.value}
                </Link>
              </>
              )}
            </div>
          </>)
        },
      },
      {
        Header: t('Activated'),
        accessor: "is_runing",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const active = value?.is_runing;
          return (
            <div className="form-check form-switch form-switch-sm text-center" dir="ltr" style={{ minWidth: '40px' }}>
              <input type="checkbox" className="form-check-input" name="is_runing" id="customSwitchsizelg" checked={Boolean(active)}
                onChange={(event: any) => {
                  if (isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_UPDATE, userPermissions)) {
                    handleShowConfirmUpdateStatus(event.target.checked, [value?.id]);
                  }
                }}
              />
            </div>
          );
        }
      },
    ],
    [i18n?.language, listCategoriesTypeLang, JSON.stringify(optionsSelected), query?.page, JSON.stringify(campaignStatistics?.list), userPermissions, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resMedia, resCategoriesType]: any = await Promise.all([getAllMedias(), getAllCategoriesType()]);

      const listCateType = resCategoriesType?.data?.map((item: any) => ({ value: item?.type, label: item?.name })) || []
      const valCateType = listCateType?.filter((item: any) => item?.value === query?.category_type)[0];
      setListCategoriesType((_prev) => listCateType);
      setCategoryTypeSearch((prev) => (valCateType ? { ...valCateType, label: t(`Category_Type_[${valCateType?.value}]_For_Campaign`) } : null));

      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }));
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    // if (scheduleTypeSearch?.value) {
    //   setScheduleTypeSearch((_prev: any) => SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === scheduleTypeSearch?.value)[0]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Campaign Statistics')} - ${t('Lab')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    setDurationResponses([
      {
        name: 'Campaign Statistics',
        time: campaignStatistics?.pagination?.duration || 0
      }
    ]);
  }, [campaignStatistics?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Campaign Statistics')} pageTitle={t('Lab')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST, userPermissions) && (
              <Col lg={12} >
                <Card id="leadsList" className="ribbon-box right ">
                  <div className="ribbon  round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per 10 mins')}</div>
                  <CardHeader className="border-0 pb-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-4">
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics total-3  mt-2">
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-0  bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-2">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isCampaignStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={campaignStatistics?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mt-0  bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Activated')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-2">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isCampaignStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={campaignStatistics?.total_actived || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mt-0 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('InActivated')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-2">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isCampaignStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={campaignStatistics?.total_unactive || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-0">
                          <Col sm={12} md={6} lg={6} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Campaign')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Campaign')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Status')} isShow={!!statusActionSearch?.value}>
                              <DropdownOption
                                name="status-active"
                                dataList={STATUS_RUNNING_CAMPAIGN_OPTIONS_LANG || []}
                                placeholder={`${t("Status Action")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={statusActionSearch || null}
                                onChangeSelect={(e: any) => setStatusActionSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('Status_Action_Running_ALL'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>

                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-0">
                          <Col sm={12} md={6} lg={6} className="mt-2 mt-md-0 mt-md-2">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={newsFromRangeSearch}
                              valueTo={newsToRangeSearch}
                              placeholderFrom={`${t('News From Range')}...`}
                              placeholderTo={`${t('News To Range')}...`}
                              labelFrom={`${t('News From Range')}`}
                              labelTo={`${t('News To Range')}`}
                              onChangeFrom={(val) => setNewsFromRangeSearch(val)}
                              onChangeTo={(val) => setNewsToRangeSearch(val)}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} md={6} lg={6} className="mt-3 mt-md-2">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={pvpnFromRangeSearch}
                              valueTo={pvpnToRangeSearch}
                              placeholderFrom={`${t('PV Per News From Range')}...`}
                              placeholderTo={`${t('PV Per News To Range')}...`}
                              labelFrom={`${t('PV Per News From Range')}`}
                              labelTo={`${t('PV Per News To Range')}`}
                              onChangeFrom={(val) => setPVPNFromRangeSearch(val)}
                              onChangeTo={(val) => setPVPNToRangeSearch(val)}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Row className="g-4 align-items-center mb-2 mt-0">
                      <Col sm={12} md={4} lg={4} className="mt-4 mt-md-2 order-1 order-md-0">
                        <div className="text-start text-md-start">
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={() => handleShowConfirmUpdateStatus(true)}
                            disabled={countRowSelected <= 0 || isLoadingUpdate}
                          >
                            {t('Button ON')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-light fs-14"
                            onClick={() => handleShowConfirmUpdateStatus(false)}
                            disabled={countRowSelected <= 0 || isLoadingUpdate}
                            style={{ borderColor: '#d4d4d4' }}
                          >
                            {t('Button OFF')}
                          </button>
                        </div>
                      </Col>
                      <Col sm={12} md={8} lg={8} className="mt-2 mt-md-2 order-0 order-md-1">
                        <div className="text-center text-md-end">
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isCampaignStatisticLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div>
                      <div className="pb-4 text-primary w-100 text-start">
                        <strong>{formatNumberWithCommas(countRowSelected)}</strong> {t('item selected')}.
                      </div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={campaignStatistics?.list?.length ? campaignStatistics?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={campaignStatistics?.pagination?.total}
                        customPageCount={Math.ceil(Number(campaignStatistics?.pagination?.total) / Number(campaignStatistics?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isCampaignStatisticLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <ModalConfirm
          textButtonConfirm={t('Button Update Status Campaign')}
          classButtonConfirm='btn-primary'
          classIconButtonConfirm='ri-send-plane-line'
          header={t('Button Update Status Campaign')}
          title={t('Are you sure you want to update this?')}
          content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
          isShowIcon={false}
          isOpen={isOpenConfirm}
          isLoading={isLoadingUpdate}
          onClose={onCloseConfirmClick}
          onConfirm={handleOnConfirmUpdate}
        />
      </div>
    </React.Fragment>
  );
};

export default CampaignStatisticList;
