import { createSlice } from "@reduxjs/toolkit";
import { getCategoriesSport, getGames, getSports } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICategorySport, IGame, ISportStatistics } from "api/types/_sport";

export interface IState {
  sports: ResponseData<ISportStatistics[]> & PaginationResponse | null,
  isSportsLoading: boolean,
  isSportsSuccess: boolean,


  games: ResponseData<IGame[]> & PaginationResponse | null,
  isGamesLoading: boolean,
  isGamesSuccess: boolean,

  categories: ResponseData<ICategorySport[]> & PaginationResponse | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  sports: null,
  isSportsLoading: false,
  isSportsSuccess: false,

  games: null,
  isGamesLoading: false,
  isGamesSuccess: false,

  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,

  error: {},
};

const SportSlice = createSlice({
  name: "Sport",
  initialState,
  reducers: {
    changeStatusActiveSportsAction(state: any, action : any) {
      state.sports = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get Sport
    builder.addCase(getSports.pending, (state: IState, action: any) => {
      state.isSportsLoading = true
    });
    builder.addCase(getSports.fulfilled, (state: IState, action: any) => {
      state.sports = action.payload.data;
      state.isSportsSuccess = true;
      state.isSportsLoading = false;
    });
    builder.addCase(getSports.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSportsSuccess = false;
      state.isSportsLoading = false;
    });


     //get Games
     builder.addCase(getGames.pending, (state: IState, action: any) => {
      state.isGamesLoading = true
    });
    builder.addCase(getGames.fulfilled, (state: IState, action: any) => {
      state.games = action.payload.data;
      state.isGamesSuccess = true;
      state.isGamesLoading = false;
    });
    builder.addCase(getGames.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isGamesSuccess = false;
      state.isGamesLoading = false;
    });

    // get categories
    builder.addCase(getCategoriesSport.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategoriesSport.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategoriesSport.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });
  },
});

export const {
  changeStatusActiveSportsAction,
} = SportSlice.actions;

export default SportSlice.reducer;