import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import statisticApi from 'api/statisticApi';
import { formatQueryParams } from "helpers/format";
import { changeStatusCampaignsAction, changeStatusFlowedKeywordAnalysisAction } from "./reducer";

export const getNewsStatistics = createAsyncThunk("Statistics/NewsLogs" , async (params: any = {}) => {
  try{
    const response = await statisticApi.newsStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getKeywordInflows = createAsyncThunk("Statistics/KeywordInflows" , async (params: any = {}) => {
  try{
    const response = await statisticApi.keywordInflows(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getKeywordStatistics = createAsyncThunk("Statistics/KeywordLogs" , async (params: any = {}) => {
  try{
    const response = await statisticApi.keywordStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getKeywordAnalysis = createAsyncThunk("Statistics/KeywordAnalysis" , async (params: any = {}) => {
  try{
    const response = await statisticApi.keywordAnalysis(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getKeywordAnalysisNormal = async (params: any = {}) => {
  try{
    const response = await statisticApi.keywordAnalysis(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};


export const getFlowedKeywordAnalysis = createAsyncThunk("Statistics/FlowedKeywordAnalysis" , async (params: any = {}) => {
  try{
    const response = await statisticApi.flowedKeywordAnalysis(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getFlowedKeywordAnalysisNormal =  async (params: any = {}) => {
  try{
    const response = await statisticApi.flowedKeywordAnalysis(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const getCampaignStatistics = createAsyncThunk("Statistics/Campaigns" , async (params: any = {}) => {
  try{
    const response = await statisticApi.campaignStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getCategoryStatistics = createAsyncThunk("Statistics/Categories" , async (params: any = {}) => {
  try{
    const response = await statisticApi.categoryStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getExportCategoryStatistics =  async (params: any = {}) => {
  try{
    const response = await statisticApi.categoryStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
};

export const getWebsiteStatistics = createAsyncThunk("Statistics/Websites" , async (params: any = {}) => {
  try{
    const response = await statisticApi.websiteStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getVisitorStatistics = createAsyncThunk("Statistics/Visitor" , async (params: any = {}) => {
  try{
    const response = await statisticApi.visitorStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getRevenueStatistics = createAsyncThunk("Statistics/Revenue" , async (params: any = {}) => {
  try{
    const response = await statisticApi.revenueStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getMediaRevenueStatistics = createAsyncThunk("Statistics/Media-Revenue" , async (params: any = {}) => {
  try{
    const response = await statisticApi.revenueStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getMediaRankingStatistics = createAsyncThunk("Statistics/Media-Ranking" , async (params: any = {}) => {
  try{
    const response = await statisticApi.mediaRankingStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getNewsLogsByNormal =  async (params: any = {}) => {
  try{
    const response = await statisticApi.newsLogs(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getWebsiteLogsByNormal =  async (params: any = {}) => {
  try{
    const response = await statisticApi.websiteLogs(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getWebsiteSummariesLogs =  async (params: any = {}) => {
  try{
    const response = await statisticApi.websiteSummariesLogs(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getDataChartForKeywords =  async (params: any = {}) => {
  try{
    const response = await statisticApi.dataChartForKeywords(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const changeStatusCampaignsStatistics = (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusCampaignsAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};



export const updateStatusFlowedKeywordAnalysis = async (data: any = {}) => {
  try{
    const response = await statisticApi.updateStatusFlowedKeywordAnalysis(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const changeStatusFlowedKeywordAnalysis = (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusFlowedKeywordAnalysisAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};