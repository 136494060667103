import { ICoin } from "api/types/_coin";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;

}

interface FilterCoinsProps {
  name?: string,
  dataList?: ICoin[],
  initialValue?: Option[] | Option | undefined | null;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
}

const FilterCoins = ({
  name = '',
  initialValue = [],
  dataList = [],
  onChangeSelect,
  isHasOptionAll = false,

}: FilterCoinsProps) => {
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };
  const optionAll: any = { label: '', code: t('All Coin Code'), value: '' };
  useEffect(() => {
    const resultOptions = dataList?.map((item) => ({ code: item?.code, label: `${i18n?.language === 'en' ? item?.english_name : item?.korean_name}`, value: String(item?.code), image_url: item?.image_url })) || [] as Option[];
    const result: any = isHasOptionAll ? [optionAll].concat(resultOptions) : resultOptions;
    setOptions((_prev: any) => result);
    if (initialValue) {
      const val = result?.filter((e: any) => e.value === (initialValue as any)?.value)[0];
      setOnChangeOption(val);
    } else {
      setOnChangeOption(isHasOptionAll ? result[0] : null);
    }
  }, [JSON.stringify(dataList), JSON.stringify(initialValue)]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={false}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{t('Select Coin Code')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (<div className="d-flex">
          {!!option?.image_url && <img src={option?.image_url} loading="lazy" alt={option?.label} style={{ width: '20px', height: '20px' }} className="me-2" />}
          {option?.code}
          <div className="op ms-2">{`${String(option?.label)}`}</div> </div>)}
        className="search-filter-currencies"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default FilterCoins;
