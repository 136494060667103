import { IMedia } from 'api/types/_media';
import { ITemplate } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import CopyWrapper from 'components/Common/CopyWrapper';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownOption from 'components/Common/DropdownOption';
import InputsRange from 'components/Common/InputsRange';
import LabelWrapper from 'components/Common/LabelWrapper';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { KEYWORD_DISPLAY_FLOWED_KEYWORD_OPTIONS, TYPE_KEYWORD_OPTIONS, TYPE_NEWS_FLOWED_KEYWORD_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import CampaignFormByFlowedKeywordAnalysis from 'pages/Campaign/CampaignForm/CampaignFormByFlowedKeywordAnalysis';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { changeStatusFlowedKeywordAnalysis, getAllMedias, getAllTemplates, getFlowedKeywordAnalysis as onGetFlowedKeywordAnalysis, updateStatusFlowedKeywordAnalysis } from "../../../store/thunks";
import ModalConfirm from 'components/Common/ModalConfirm';
import { formatNumberWithCommas, renderUrlSearchKeywordInNaver } from 'helpers/format';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'daily_search_volume';

const PERIOD_A: string = '';
const PERIOD_B: string = '';

const CampaignFlowedKeyword = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();

  const TYPE_KEYWORD_OPTIONS_LANG = TYPE_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_FLOWED_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const KEYWORD_DISPLAY_OPTIONS_LANG = KEYWORD_DISPLAY_FLOWED_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    rank: withDefault(StringParam, ''),
    sequence: withDefault(StringParam, ''),
    page_sequence: withDefault(StringParam, ''),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    domain: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    type_keyword: withDefault(StringParam, ''),
    date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    start_date_period_a: withDefault(StringParam, moment("2024-01-01", 'Y-MM-DD').format("Y-MM-DD")),
    end_date_period_a: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 7))).format("Y-MM-DD")),
    start_date_period_b: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 2))).format("Y-MM-DD")),
    end_date_period_b: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")),
    pv_period_a_from: withDefault(StringParam, ''),
    pv_period_a_to: withDefault(StringParam, ''),
    pv_period_b_from: withDefault(StringParam, ''),
    pv_period_b_to: withDefault(StringParam, ''),
    page_sequence_from: withDefault(StringParam, ''),
    page_sequence_to: withDefault(StringParam, ''),
    sequence_from: withDefault(StringParam, ''),
    sequence_to: withDefault(StringParam, ''),
    type: withDefault(StringParam, '2'),
    is_active: withDefault(StringParam, '1'),
  });

  const [dateSearch, setDateSearch] = useState<string>(query?.date || moment(new Date()).format("Y-MM-DD"));

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [impressionSessionSearch, setImpressionSessionSearch] = useState<string | number>(query?.page_sequence ?? '');

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.type))[0]);

  const [keywordDisplaySearch, setKeywordDisplaySearch] = useState<Option | null>(KEYWORD_DISPLAY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);

  // News from to range
  const [dateASearch, setDateASearch] = useState<any[]>([moment(query?.start_date_period_a || "", 'Y-MM-DD').toDate(), moment(query?.end_date_period_a || "", 'Y-MM-DD').toDate()]);
  const [startDateA, endDateA] = dateASearch;

  // PV Per News from to range
  const [dateBSearch, setDateBSearch] = useState<any[]>([moment(query?.start_date_period_b || "", 'Y-MM-DD').toDate(), moment(query?.end_date_period_b || "", 'Y-MM-DD').toDate()]);
  const [startDateB, endDateB] = dateBSearch;

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [sectionFromRangeSearch, setSectionFromRangeSearch] = useState<any>(query?.page_sequence_from || '');
  const [sectionToRangeSearch, setSectionToRangeSearch] = useState<any>(query?.page_sequence_to || '');

  const [PVAFromRangeSearch, setPVAFromRangeSearch] = useState<any>(query?.pv_period_a_from || '');
  const [PVAToRangeSearch, setPVAToRangeSearch] = useState<any>(query?.pv_period_a_to || '');

  const [PVBFromRangeSearch, setPVBFromRangeSearch] = useState<any>(query?.pv_period_b_from || '');
  const [PVBToRangeSearch, setPVBToRangeSearch] = useState<any>(query?.pv_period_b_to || '');

  const [sequenceFromRangeSearch, setSequenceFromRangeSearch] = useState<any>(query?.sequence_from || '');
  const [sequenceToRangeSearch, setSequenceToRangeSearch] = useState<any>(query?.sequence_to || '');

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [isOpenCreateCampaign, setIsOpenCreateCampaign] = useState<boolean>(false);

  const [idKeywordLoading, setIdKeywordLoading] = useState<number | string | null>(null);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const FlowedKeywordAnalysisProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywordAnalysis: state.flowedKeywordAnalysis,
      isKeywordAnalysisSuccess: state.isFlowedKeywordAnalysisSuccess,
      isKeywordAnalysisLoading: state.isFlowedKeywordAnalysisLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywordAnalysis, error, isKeywordAnalysisLoading } = useSelector(FlowedKeywordAnalysisProperties);

  useEffect(() => {
    dispatch(onGetFlowedKeywordAnalysis(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || "",
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      date: dateSearch || '',
      start_date_period_a: startDateA ? moment(new Date(startDateA)).format("Y-MM-DD") : '',
      end_date_period_a: endDateA ? moment(new Date(endDateA)).format("Y-MM-DD") : '',
      start_date_period_b: startDateB ? moment(new Date(startDateB)).format("Y-MM-DD") : '',
      end_date_period_b: endDateB ? moment(new Date(endDateB)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date(),
      page_sequence: impressionSessionSearch ?? '',
      page_sequence_from: sectionFromRangeSearch || '',
      page_sequence_to: sectionToRangeSearch || '',
      pv_period_a_from: PVAFromRangeSearch || '',
      pv_period_a_to: PVAToRangeSearch || '',
      pv_period_b_from: PVBFromRangeSearch || '',
      pv_period_b_to: PVBToRangeSearch || '',
      sequence_from: sequenceFromRangeSearch || '',
      sequence_to: sequenceToRangeSearch || '',
      type: typeNewsSearch?.value || '',
      is_active: keywordDisplaySearch?.value || '',
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      domain: '',
      keyword: '',
      date: moment(new Date()).format("Y-MM-DD"),
      start_date_period_a: moment("2024-01-01", "YYYY-MM-DD").format("YYYY-MM-DD"),
      end_date_period_a: moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD"),
      start_date_period_b: moment(new Date()).subtract(2, 'days').format("YYYY-MM-DD"),
      end_date_period_b: moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date(),
      page_sequence: '',
      page_sequence_from: '',
      page_sequence_to: '',
      pv_period_a_from: '',
      pv_period_a_to: '',
      pv_period_b_from: '',
      pv_period_b_to: '',
      sequence_from: '',
      sequence_to: '',
      order_by: 'DESC',
      type: TYPE_NEWS_OPTIONS_LANG[0]?.value,
      is_active: KEYWORD_DISPLAY_OPTIONS_LANG[0]?.value,
    };

    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch(moment(new Date()).format("Y-MM-DD"));
    setImpressionSessionSearch('');
    setSectionFromRangeSearch('');
    setSectionToRangeSearch('');
    setSequenceFromRangeSearch('');
    setSequenceToRangeSearch('');
    setPVAFromRangeSearch('');
    setPVAToRangeSearch('');
    setPVBFromRangeSearch('');
    setPVBToRangeSearch('');
    setTypeNewsSearch(TYPE_NEWS_OPTIONS_LANG[0]);
    setKeywordDisplaySearch(KEYWORD_DISPLAY_OPTIONS_LANG[0]);
    setDateASearch([moment(queryNew?.start_date_period_a || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date_period_a || "", 'Y-MM-DD').toDate()]);
    setDateBSearch([moment(queryNew?.start_date_period_b || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date_period_b || "", 'Y-MM-DD').toDate()]);
    setOptionsSelected((_prev: any) => []);
  };

  const handleShowModalCreateCampaign = async () => {
    try {
      setIsOpenCreateCampaign((prev) => true);
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywordAnalysis?.list || [])?.map((item: any) => ({ value: item?.keyword, label: item?.keyword }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywordAnalysis?.list?.length);
  };


  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('#'),
        accessor: "stt",
        filterable: false,
        sortable: true,
        thWidth: 70,
        thClass: 'align-middle text-start',
        Cell: (cell: any) => {
          return (<>
            <div className="text-start" style={{ minWidth: '50px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
          </>)
        },
      },
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 40,
        thComponent: () => (
          <>
            <div className="form-check" style={{ minWidth: '40px' }}>
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.keyword, label: cell?.row?.original?.keyword })} value={cell?.row?.original?.keyword ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.keyword, label: cell?.row?.original?.keyword }), query?.page, e)} id={`cell-check-${cell?.row?.original?.keyword ?? ''}`} />
              <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.keyword ?? ''}`}></label>
            </div>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: false,
        sortable: true,
        thWidth: 200,
        thClass: 'align-middle',
        description: t('Description Keyword that flowed by searching'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (<div className="d-flex align-items-center" style={{ minWidth: '130px' }}>
            <div className="form-check form-switch form-switch-sm text-center me-2 pb-1" dir="ltr">
              <input type="checkbox" className="form-check-input" name="is_runing" id="customSwitchsizelg" checked={Boolean(item?.is_active)}
                onChange={(event: any) => {
                  if (isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_UPDATE, userPermissions)) {
                    handleShowConfirmUpdateStatus({ keywords: [cell?.value], is_active: Number(event.target.checked), type: item?.type });
                  }
                }}
              />
            </div>
            <CopyWrapper contentCopy={cell?.value}>
              <Link className='text-normal' to={renderUrlSearchKeywordInNaver(cell?.value)} target="_blank">
                {cell?.value}
              </Link>
            </CopyWrapper>
          </div>
          )
        },
      },
      {
        Header: t('Trend Index'),
        thClass: 'text-end',
        filterable: false,
        sortable: false,
        thWidth: 350,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t('Search Volume')}
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1 cursor-pointer " style={{ width: '150px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'daily_search_volume', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'daily_search_volume') ? 'ASC' : 'DESC' })
                  }}>
                  {t('Daily Trend Index')}
                  <TooltipCustom
                    title={t('Description Daily Trend Index')}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                  {query?.sort_by === 'daily_search_volume' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '150px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'total_search_volume', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'total_search_volume') ? 'ASC' : 'DESC' })
                  }}>
                  {t('30 Days Trend Index')}
                  <TooltipCustom
                    title={t('Description 30 Days Trend Index')}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                  {query?.sort_by === 'total_search_volume' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: '150px', textAlign: 'right', paddingRight: '50px' }}>{item.daily_search_volume || 0}</div>
              <div style={{ width: '150px', textAlign: 'right', paddingRight: '50px', borderLeft: "1px solid #eaeaea", }}>{item.total_search_volume || 0}</div>
            </div>
          );
        },
      },
      {
        Header: t('PV'),
        thClass: 'text-end',
        filterable: false,
        sortable: false,
        thWidth: 400,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t('PV')}
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '200px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'pv_period_a', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'pv_period_a') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t('Period A (Daily)')}
                  <TooltipCustom
                    title={`${t('Description PV of Keyword that flowed in specific period :  from January 1, 2024 ago and 7 day ago default')}`}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                  {query?.sort_by === 'pv_period_a' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
                <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '200px' }}
                  onClick={() => {
                    handleChangeSorting({ sort_by: 'pv_period_b', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'pv_period_b') ? 'ASC' : 'DESC' })
                  }}
                >
                  {t('Period B (Daily)')}
                  <TooltipCustom
                    title={`${t('Description PV of Keyword that flowed in specific period : from 2 days ago and 1 day ago default')}`}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                  {query?.sort_by === 'pv_period_b' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                    {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                  </span>)}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: '200px', textAlign: 'right', paddingRight: '50px' }}>
                <div className="d-flex justify-content-end" >
                  <div>
                    {item?.is_compare_a ? item.compare_pv_period_a : ''} <br />
                    <span className="text-muted">{item?.is_compare_a ? "(" + item.daily_period_a + ")" : ''}</span>
                  </div>
                  <div className="px-2">
                    {item?.is_compare_a ? "  " + (item?.arrow_a || '') + "  " : ''} <br />
                  </div>
                  <div>
                    {item.pv_period_a || 0} <br />
                    <span className="text-muted">({item.daily_period_a_default || 0})</span>
                  </div>
                </div>
              </div>
              <div style={{ width: '200px', textAlign: 'right', paddingRight: '50px', borderLeft: "1px solid #eaeaea" }}>
                <div className="d-flex justify-content-end" >
                  <div>
                    {item?.is_compare_b ? item.compare_pv_period_b : ''} <br />
                    <span className="text-muted">{item?.is_compare_b ? "(" + item.daily_period_b + ")" : ''}</span>
                  </div>
                  <div className="px-2">
                    {item?.is_compare_b ? "  " + (item?.arrow_b || '') + "  " : ''} <br />
                  </div>
                  <div>
                    {item.pv_period_b || 0} <br />
                    <span className="text-muted">({item.daily_period_b_default || 0})</span>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: t('Session'),
        accessor: "page_sequence",
        filterable: false,
        sortable: true,
        thWidth: 130,
        thClass: 'text-center align-middle',
        description: t('Description The news section sequence of keyword on Naver'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className={`text-center ${(item?.sequence > 0) ? '' : 'row-bg-secondary'}`} style={{ minWidth: '120px' }}>
                {item?.page_sequence || 'N'}
              </div>
            </>
          );
        },
      },
      {
        Header: t('Sequence'),
        accessor: "sequence",
        filterable: false,
        sortable: true,
        thWidth: 160,
        thClass: 'text-center align-middle',
        description: t('Description The impression sequence of media when search keyword in Naver News tab'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className={`text-center ${(item?.sequence > 0) ? '' : 'row-bg-secondary'}`} style={{ minWidth: '160px' }}>
                {item?.sequence || 'N'}
              </div>
            </>
          );
        },
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 150,
        description: t('Description Exposure rankings are tracked up to the 50th position, and any rankings beyond this are displayed as 0'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer" style={{ minWidth: '150px' }}>
              {cell?.value ? (<> <span>
                {/* {cell?.value} */}
                {listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0]?.name || ''}
              </span>
                <br />
                <Link className='text-normal' to={(String(cell?.value).includes('https://') || String(cell?.value).includes('http://')) ? cell?.value : `https://${cell?.value}`} target="_blank">
                  {cell?.value}
                </Link>
              </>
              ) : ''}
            </div>
          </>)
        },
      },
      {
        Header: t('Updated at Keyword Analysis'),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end align-middle",
        description: t('Description Last updated time of impression sequence'),
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '130px' }}>
              <span><span>{String(cell?.value || '').split('T')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split('T')[1]).split('.')[0] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language, JSON.stringify(listMedia), JSON.stringify(query), JSON.stringify(optionsSelected), idKeywordLoading]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {

    const date = value ? moment(value).format("YYYY-MM-DD") : '';

    const start_date_period_a = date ? moment("2024-01-01", "YYYY-MM-DD").format("YYYY-MM-DD") : undefined;
    const end_date_period_a = date ? moment(date).subtract(7, 'days').format("YYYY-MM-DD") : undefined;
    const start_date_period_b = date ? moment(date).subtract(2, 'days').format("YYYY-MM-DD") : undefined;
    const end_date_period_b = date ? moment(date).subtract(1, 'days').format("YYYY-MM-DD") : undefined;

    setDateSearch((_prev) => date);
    setDateASearch([start_date_period_a ? moment(start_date_period_a || "", 'Y-MM-DD').toDate() : undefined, end_date_period_a ? moment(end_date_period_a || "", 'Y-MM-DD').toDate() : undefined]);
    setDateBSearch([start_date_period_b ? moment(start_date_period_b || "", 'Y-MM-DD').toDate() : undefined, end_date_period_b ? moment(end_date_period_b || "", 'Y-MM-DD').toDate() : undefined]);
  }

  const handleChangePickerA = (values: any[] = []) => {
    setDateASearch((_prev: any) => values);
  }

  const handleChangePickerB = (values: any[] = []) => {
    setDateBSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resMedia, resTemplate]: any = await Promise.all([getAllMedias(), getAllTemplates()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }));
      setListTemplate((_prev) => resTemplate?.data || []);
    } catch (error: any) {
      return error;
    }
  };


  // Begin:: update status keyword
  const [itemUpdate, setItemUpdate] = useState<{ keywords: string[], is_active: number, type: number } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdateStatus = (item: { keywords: string[], is_active: number, type: number } | null) => {
    setItemUpdate(item)
  };
  const handleActionUpdateStatus = (is_active: number) => {
    const keywords: string[] = listKeyword?.map((item) => item?.value);
    handleShowConfirmUpdateStatus({ keywords: keywords || [], is_active: is_active, type: Number(query?.type) });
  }
  const handleOnConfirmUpdate = async () => {
    try {
      if (!itemUpdate) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await updateStatusFlowedKeywordAnalysis(itemUpdate);
      setIsLoadingUpdate((_prev) => false);
      if (response?.data) {
        setItemUpdate((_prev) => null);
        setOptionsSelected((_prev: any) => []);
        const newState: any = (keywordAnalysis?.list || [])?.map((item: any) => {
          if ((itemUpdate?.keywords)?.includes(item?.keyword) && item?.type === itemUpdate?.type) {
            return ({ ...item, is_active: itemUpdate?.is_active });
          }
          return item;
        });
        dispatch(changeStatusFlowedKeywordAnalysis({
          ...keywordAnalysis,
          list: newState
        }));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // End::update status keyword

  // Begin:: Create Campaign
  function onCloseCreateCampaignClick() {
    setIsOpenCreateCampaign((_prev) => !_prev);
  }
  const handleRefetching = async () => {
    setIsOpenCreateCampaign((_prev) => false);
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0])
    }

    if (keywordDisplaySearch) {
      setKeywordDisplaySearch((_prev: any) =>
        KEYWORD_DISPLAY_OPTIONS_LANG?.filter((e: any) => e.value === keywordDisplaySearch?.value)[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Create Campaign (Core Keyword)')} - ${t('Campaign')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    setDurationResponses([
      {
        name: 'Create Campaign (Core Keyword)',
        time: keywordAnalysis?.pagination?.duration || 0
      }
    ]);
  }, [keywordAnalysis?.pagination?.duration]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Create Campaign (Core Keyword)')} pageTitle={t('Campaign')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList" className="ribbon-box right">
                  <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('The data is updated every day at 9am')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-3">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-4 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isKeywordAnalysisLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={keywordAnalysis?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0 mb-md-2 mt-2">
                          <Col md={6} lg={3} xl={3} className="date-picker-wrapper-custom mt-3" >
                            <LabelWrapper label={`${t('Date Range A Keyword Inflow PV')}`} isShow={!!startDateA || !!endDateA} >
                              <DatePickerCustom
                                placeholder={`${t('A From Range')} - ${t('A To Range')}`}
                                startDate={startDateA || null}
                                endDate={endDateA || null}
                                disabled={false}
                                isClearable={true}
                                minDate={moment("2024-01-01", 'Y-MM-DD').toDate()}
                                onChangePicker={handleChangePickerA}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={6} lg={3} xl={3} className="date-picker-wrapper-custom mt-3" >
                            <LabelWrapper label={`${t('Date Range B Keyword Inflow PV')}`} isShow={!!startDateB || !!endDateB}>
                              <DatePickerCustom
                                placeholder={`${t('B From Range')} - ${t('B To Range')}`}
                                startDate={startDateB || null}
                                endDate={endDateB || null}
                                disabled={false}
                                isClearable={true}
                                minDate={moment("2024-01-01", 'Y-MM-DD').toDate()}
                                onChangePicker={handleChangePickerB}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={6} lg={2} xl={2} className="date-picker-wrapper-custom mt-3">
                            <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                              <DatePicker
                                className="form-control search"
                                placeholderText={`${t('Date')}...`}
                                value={dateSearch || undefined}
                                selected={dateSearch ? new Date(dateSearch) : undefined}
                                dateFormat="yyyy-MM-dd"
                                isClearable={true}
                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                minDate={moment("2024-03-07", 'Y-MM-DD').toDate()}
                                onChange={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={6} lg={2} xl={2} className="mt-3">
                            <LabelWrapper label={t('News Type')} isShow={!!typeNewsSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={TYPE_NEWS_OPTIONS_LANG}
                                placeholder={`${t("News Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={typeNewsSearch || null}
                                onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={6} lg={2} xl={2} className="mt-3">
                            <LabelWrapper label={t('Keyword Display')} isShow={!!keywordDisplaySearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={KEYWORD_DISPLAY_OPTIONS_LANG}
                                placeholder={`${t("Keyword Display")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={keywordDisplaySearch || null}
                                onChangeSelect={(e: any) => setKeywordDisplaySearch(e)}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={6} lg={4} xl={4} className="mt-3 ">
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={12} lg={4} xl={4} className="mt-3">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={PVAFromRangeSearch}
                              valueTo={PVAToRangeSearch}
                              placeholderFrom={`${t('Period A PV (Start)')}...`}
                              placeholderTo={`${t('Period A PV (End)')}...`}
                              labelFrom={`${t('Period A PV (Start)')}`}
                              labelTo={`${t('Period A PV (End)')}`}
                              onChangeFrom={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setPVAFromRangeSearch(onlyNumbers || '');
                              }}
                              onChangeTo={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setPVAToRangeSearch(onlyNumbers || '');
                              }}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col md={12} lg={4} xl={4} className="mt-3">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={PVBFromRangeSearch}
                              valueTo={PVBToRangeSearch}
                              placeholderFrom={`${t('Period B PV (Start)')}...`}
                              placeholderTo={`${t('Period B PV (End)')}...`}
                              labelFrom={`${t('Period B PV (Start)')}`}
                              labelTo={`${t('Period B PV (End)')}`}
                              onChangeFrom={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setPVBFromRangeSearch(onlyNumbers || '');
                              }}
                              onChangeTo={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setPVBToRangeSearch(onlyNumbers || '');
                              }}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col md={12} lg={4} xl={4} className="mt-3">
                            <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Keyword')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={12} lg={4} xl={4} className="mt-3">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={sectionFromRangeSearch}
                              valueTo={sectionToRangeSearch}
                              placeholderFrom={`${t('Section (Start)')}...`}
                              placeholderTo={`${t('Section (End)')}...`}
                              labelFrom={`${t('Section (Start)')}`}
                              labelTo={`${t('Section (End)')}`}
                              onChangeFrom={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setSectionFromRangeSearch(onlyNumbers || '');
                              }}
                              onChangeTo={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setSectionToRangeSearch(onlyNumbers || '');
                              }}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />

                          </Col>
                          <Col md={12} lg={4} xl={4} className="mt-3">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={sequenceFromRangeSearch}
                              valueTo={sequenceToRangeSearch}
                              placeholderFrom={`${t('Sequence (Start)')}...`}
                              placeholderTo={`${t('Sequence (End)')}...`}
                              labelFrom={`${t('Sequence (Start)')}`}
                              labelTo={`${t('Sequence (End)')}`}
                              onChangeFrom={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setSequenceFromRangeSearch(onlyNumbers || '');
                              }}
                              onChangeTo={(e) => {
                                const input = e;
                                const onlyNumbers = input.replace(/\D/g, '');
                                setSequenceToRangeSearch(onlyNumbers || '');
                              }}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Row>
                      <Col sm={12} md={4} lg={4} className="mt-4 mt-md-2 order-1 order-md-0">
                        <div className="text-start text-md-start">
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={() => handleActionUpdateStatus(1)}
                            disabled={countRowSelected <= 0 || isLoadingUpdate}
                          >
                            {t('Button ON')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-light fs-14"
                            onClick={() => handleActionUpdateStatus(0)}
                            disabled={countRowSelected <= 0 || isLoadingUpdate}
                            style={{ borderColor: '#d4d4d4' }}
                          >
                            {t('Button OFF')}
                          </button>
                        </div>
                        <div className="text-primary w-100 text-start">
                          <strong>{formatNumberWithCommas(countRowSelected)}</strong> {t('item selected')}.
                        </div>
                      </Col>
                      <Col sm={12} md={8} lg={8} className="order-0 order-md-1 hstack gap-1 flex-column flex-sm-row justify-content-sm-center justify-content-md-end mt-4 mt-md-2">
                        <div className="mb-4 mb-sm-0">
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isKeywordAnalysisLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-success ms-4"
                            onClick={() => handleShowModalCreateCampaign()}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            {t('Button Create Campaign')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={keywordAnalysis?.list?.length ? keywordAnalysis?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={keywordAnalysis?.pagination?.total}
                        customPageCount={Math.ceil(Number(keywordAnalysis?.pagination?.total) / Number(keywordAnalysis?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isKeywordAnalysisLoading}
                        isScrollToTop={true}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={isOpenCreateCampaign}
          centered={true}
          size="xl modal-xxll"
          scrollable={false}
        >
          <ModalHeader toggle={onCloseCreateCampaignClick}>
            {t("Button Create Campaign")}
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <CampaignFormByFlowedKeywordAnalysis
                queryDefault={query}
                listKeyword={[]}
                listMedia={listMedia?.filter((item) => !!item?.id)}
                listTemplate={listTemplate}
                maxNumberAutoSelectKeywordOnPage={0}
                handleRefetching={handleRefetching}
              />
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          textButtonConfirm={t('Button Update Status Keyword')}
          classButtonConfirm='btn-primary'
          classIconButtonConfirm=''
          header={itemUpdate?.is_active ? t('Confirm to Display keyword') : t('Confirm to Hide keyword')}
          title={t('Are you sure you want to update this?')}
          content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
          isShowIcon={false}
          isOpen={!!itemUpdate}
          isLoading={isLoadingUpdate}
          onClose={() => setItemUpdate((prev: any) => null)}
          onConfirm={handleOnConfirmUpdate}
        />
      </div>
    </React.Fragment >
  );
};

export default CampaignFlowedKeyword;
