import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container
} from "reactstrap";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import { IMedia, IType } from "api/types/_media";
import { ITemplate } from "api/types/_template";
import { ICategory } from "api/types/_category";
import { Option } from "api/types/_public";
import { getAllCategoriesNaverNormal, getAllCategoriesStockNormal, getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes } from "store/thunks";
import CampaignFormMulti from "../../CampaignForm/CampaignFormMulti";


const CampaignLottoCreate = () => {
  const { t, i18n } = useTranslation();

  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listClassifications, setListClassifications] = useState<Option[]>([]);

  const [listCategoriesNaver, setListCategoriesNaver] = useState<ICategory[]>([]);

  const [listCategoriesStock, setListCategoriesStock] = useState<ICategory[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  const handleCallAllOption = async () => {
    try {
      const [
        resMedia,
        resTemplate,
        resType,
        resCategoriesType,
        resCategoriesNaver,
        resCategoriesStock,
        resCategoriesTrendIndex,
      ]: any = await Promise.all([
        getAllMedias(),
        getAllTemplates(),
        getAllTypes(),
        getAllCategoriesType(),
        getAllCategoriesNaverNormal(),
        getAllCategoriesStockNormal(),
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListMedia((_prev) => resMedia?.data || []);
      setListTemplate((_prev) => resTemplate?.data || []);
      setListType((_prev) => resType?.data || []);
      setListCategoriesType(
        (_prev) =>
          resCategoriesType?.data?.map((item: any) => ({
            value: item?.type,
            label: item?.name,
          })) || []
      );
      // Begin::Classification options
      setListClassifications((_prev) => resCategoriesType?.data?.reduce((arr: any, item: any) => {
        const sub: any = (item?.classifications || [])?.map((it: any) => ({ label: it, value: it, type: item?.type }));
        return arr.concat(sub);
      }, []));
      // End::Classification options
      setListCategoriesNaver((_prev) => resCategoriesNaver?.data || []);
      setListCategoriesStock((_prev) => resCategoriesStock?.data || []);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("Create Campaign Lotto")} - ${t("Campaign")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Create Campaign Lotto")}
            centerTitle={t('Trend Ranking News')}
            pageTitle={t("Campaign")}
          />
          <CampaignFormMulti
            typeCampaignForm='LOTTO'
            listType={listType}
            listMedia={listMedia}
            listTemplate={listTemplate}
            listCategoriesType={listCategoriesType}
            listClassifications={listClassifications}
            listCategoriesNaver={listCategoriesNaver}
            listCategoriesStock={listCategoriesStock}
            listCategoriesTrendIndex={listCategoriesTrendIndex}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignLottoCreate;