import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
interface Option {
  label: string;
  value: string;
  image?: string;
}
interface SearchTemplateChartProps {
  name?: string,
  templates?: any[],
  type?: 'title' | 'body'
  initialValue?: Option;
  onChangeSelect?: (params: Option | null) => void;
  placeholder?: string;
  isClearable?: boolean;
  disabled?: boolean
}

const SearchTemplateChart = ({
  name = '',
  initialValue,
  templates,
  type = 'title',
  onChangeSelect,
  placeholder,
  isClearable = false,
  disabled = false
}: SearchTemplateChartProps) => {
  const { t, i18n } = useTranslation();
  const [option, setOption] = useState<Option>();
  const [optionChoose, setOptionChoose] = useState<Option | null>(initialValue ?? null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item)
  };

  const onClickToggleModal = () => {
    if (!isOpen) {
      setOptionChoose((_prev) => (initialValue ?? null));
    }
    setIsOpen((_prev) => !_prev);
  };

  const onClickSetValue = () => {
    if (optionChoose) {
      onChangeSelect && onChangeSelect(optionChoose);
    }
    onClickToggleModal();
  };
  const handleClearable = () => {
    setOption((_prev) => undefined);
    onChangeSelect && onChangeSelect(null);
  }

  const options: Option[] = useMemo(() => {
    return templates?.map((item) => ({ label: i18n?.language === 'en' ? item?.name : item?.name_korea, value: item?.code, image: item?.image_url })) || [];
  }, [templates, type, i18n?.language]);

  useEffect(() => {
    if (initialValue) {
      const op = options?.filter((item) => item?.value === initialValue?.value)[0];
      setOption((_prev) => op);
    }
  }, [initialValue, options]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-end position-relative">
        <Select
          options={options}
          value={option}
          name={name}
          onChange={(e: any) => setOnChangeOption(e)}
          placeholder={<div>{placeholder || ''}</div>}
          loadingMessage={() => (<div>{t('Loading')}...</div>)}
          noOptionsMessage={() => (<div>{t('No Options')}</div>)}
          formatOptionLabel={(option: any) => {
            return (<div className="d-flex align-items-center">
              <div className="op me-2">
                <img src={option?.image || ''} className="flex-shrink-0 rounded" alt='chart' style={{ objectFit: 'contain' }} />
              </div>
              {option?.label}
            </div>)
          }}
          className="search-template-chart w-100"
          classNamePrefix="name-prefix"
        />
        <button className="btn btn-light fs-14 box-hover-primary" color="success" type="button"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            backgroundColor: 'transparent',
          }}
          onClick={onClickToggleModal}>
        </button>
        {(isClearable && !!initialValue?.value && !disabled) && (
          <span style={{
            position: 'absolute',
            top: -1,
            right: 38,
            bottom: 0,
            borderRadius: 'var(--vz-border-radius)',
            zIndex: 3,
            background: 'transparent',
            transition: 'color 150ms',
            padding: '8px',
            boxSizing: 'border-box',
          }}
            className="btn-clearable-value"
            onClick={(e) => {
              e.preventDefault();
              handleClearable()
            }
            }
          >
            <svg height="20" width="20" viewBox="0 0 20 20" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
          </span>)}
      </div>
      <Modal isOpen={isOpen} centered={true} size="xl" toggle={onClickToggleModal}>
        <ModalHeader toggle={onClickToggleModal}>
          {t('Info-Graphic')}
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <Col md={12}>
              <div className="table-light color-scrollbar-os w-100 rounded-3" style={{ maxHeight: 'calc(100dvh - 150px)', minHeight: 'calc(100dvh - 200px)', border: '1px solid var(--vz-table-border-color)', overflow: 'hidden' }}>
                <Row className="cursor-pointer" style={{ position: 'sticky', top: -1, zIndex: 9, backgroundColor: 'var(--vz-table-bg)' }}>
                  <Col xs={4} className='px-4 py-3 text-muted fs-14 fw-semibold' style={{ borderRight: '1px solid var(--vz-table-border-color)' }}>
                    {t('Info-Graphic Template Preview')}
                  </Col>
                  <Col xs={8} className='px-4 py-3 text-muted fs-14 fw-semibold' style={{}}>
                    {t('Info-Graphic Sample Preview')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={{ borderRight: '1px solid var(--vz-table-border-color)' }}>
                    <div className="color-scrollbar-os" style={{ height: 'calc(100dvh - 250px)', overflowY: 'scroll' }}>
                      {options?.map((item, index: number) => (
                        <Row key={item?.value}
                          className="cursor-pointer table-hover-td-content-preview"
                          style={
                            {
                              ...((item?.value === optionChoose?.value) ? { backgroundColor: 'rgba(37,160,226,0.3)' } : ((index % 2 === 0) ? { backgroundColor: 'var(--vz-input-bg-custom)' } : { backgroundColor: 'var(--vz-table-bg)' })),
                              ...(index === (options?.length - 1) ? { borderBottom: '1px solid var(--vz-table-border-color)' } : {})
                            }}
                          onClick={() => setOptionChoose(item)}>
                          <Col xs={12} className='px-4 py-3' >
                            <div className="d-flex justify-content-between align-items-center form-check" style={{ verticalAlign: 'middle', display: 'inline-flex', }} >
                              <div className="d-flex justify-content-start">
                                <input className="form-check-input me-2" type="radio"
                                  name="flexRadioDefault"
                                  id={`flexRadioDefault${index}`}
                                  checked={item?.value === optionChoose?.value}
                                  style={{ minWidth: '16px' }}
                                />
                                <label className="form-check-label" htmlFor={`flexRadioDefault${index}`} >
                                  {item?.label}
                                </label>
                              </div>
                              <img src={item?.image || ''} alt='chart' className="flex-shrink-0 rounded" style={{ objectFit: 'contain', width: '60px' }} />
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Col>
                  <Col xs={8} >
                    <div className="w-100 color-scrollbar-os" style={{ height: 'calc(100dvh - 250px)', overflowY: 'scroll' }}>
                      <div className="d-flex justify-content-center align-items-center flex-column my-2">
                        {optionChoose?.value && (<><img src={optionChoose?.image} alt={`${optionChoose?.label}`} width="95%" />
                          <div className='fs-14 mt-2'><strong>{optionChoose?.label}</strong> </div></>)}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="text-end mt-3">
                <button className="btn btn-primary fs-14" type="button" onClick={onClickSetValue}>
                  <i className="ri-check-double-fill align-bottom me-1"></i>
                  {t('Button Choose')}
                </button>
                <button className="btn btn-light fs-14 ms-4" type="button" onClick={onClickToggleModal}>
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t('Button Close')}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal >
    </React.Fragment>
  );
};

export default SearchTemplateChart;



