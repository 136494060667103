import { Option } from 'api/types/_public';
import { ICategorySport, ISport } from 'api/types/_sport';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CollapseTag from 'components/Common/CollapseTag';
import DropdownCategoryMultiSub from 'components/Common/DropdownCategoryMultiSub';
import DropdownStatusCampaign from 'components/Common/DropdownStatusCampaign';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { COLORS_SPORT_STATUS_CHANGE, COLORS_STATUS_ACTIVE_SPORT_TEAM, STATUS_ACTIVE_SPORT_TEAM_OPTIONS } from 'helpers/constans';
import { formatLinkHTTPS, formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { changeStatusActiveSports, deleteTeamInCampaignSport, getAllCategoriesSportNormal, getAllMedias, getCampaignSports, getSports as onGetSports, postCampaignSports, putStatusActiveSportTeam } from "../../../store/thunks";
import LabelWrapper from 'components/Common/LabelWrapper';
import { IMedia } from 'api/types/_media';
import { Link } from 'react-router-dom';

const typeQuery = {
  'acml_vol': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'gain_point';
registerLocale('en', en);
registerLocale('ko', ko);

const TODAY_DATE_DEFAULT = `${moment().format("Y-MM-DD")}`;
const STATUS_CHANGE_SPORT_OPTIONS: Option[] = [];
const TYPE_SPORT_OPTIONS: Option[] = [];
const SportKeyword = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const TYPE_SPORT_OPTIONS_LANG = TYPE_SPORT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_CHANGE_SPORT_OPTIONS_LANG = STATUS_CHANGE_SPORT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_ACTIVE_SPORT_TEAM_OPTIONS_LANG = STATUS_ACTIVE_SPORT_TEAM_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: withDefault(NumberParam, null),
    name: withDefault(StringParam, ''),
    code: withDefault(StringParam, ''),
    is_active: withDefault(StringParam, ''),
    ...typeQuery
  });

  const rangeValue = useRef<any>();


  const [dateSearch, setDateSearch] = useState<string>(query?.date_version || TODAY_DATE_DEFAULT);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");

  const [codeSearch, setCodeSearch] = useState<string>(query?.code || "");

  const [SportCodeSearch, setSportCodeSearch] = useState<Option | null>(null);

  const [listSportCodes, setListSportCodes] = useState<ISport[]>([]);

  const [listCategoriesSport, setListCategoriesSport] = useState<ICategorySport[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [typeSportSearch, setTypeSportSearch] = useState<Option | null>(TYPE_SPORT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.Sport_type))[0]);

  const [statusChangeSearch, setStatusChangeSearch] = useState<Option | null>(STATUS_CHANGE_SPORT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.prdy_vrss_sign))[0]);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_ACTIVE_SPORT_TEAM_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Sport;

  const SportProperties = createSelector(
    selectLayoutState,
    (state) => ({
      sports: state.sports,
      isSportsSuccess: state.isSportsSuccess,
      isSportsLoading: state.isSportsLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { sports, isSportsLoading } = useSelector(SportProperties);

  useEffect(() => {
    dispatch(onGetSports(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      sort_by: TYPE_SELECT_DEFAULT,
      category_id: categorySearch?.value,
      name: keywordSearch,
      code: codeSearch,
      is_active: statusSearch?.value || '',
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      sport_code: undefined,
      category_id: undefined,
      name: '',
      code: '',
      is_active: '',
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
    setCodeSearch("");
    setSportCodeSearch(null);
    setCategorySearch(null);
    setTypeSportSearch(null);
    setStatusChangeSearch(null);
    setStatusSearch(null);
    setDateSearch(TODAY_DATE_DEFAULT);
  };


  // Begin::Update Status
  const [optionUpdateStatus, setOptionUpdateStatus] = useState<{ id: string | number, is_active: number } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdateStatus = (status: boolean, itemId: string | number) => {
    setOptionUpdateStatus((_prev) => ({
      id: itemId,
      is_active: Number(status)
    }));
  };


  const handleOnConfirmUpdate = async () => {
    if (!optionUpdateStatus) {
      return;
    }
    try {
      setIsLoadingUpdate((_prev) => true);
      const response: any = await putStatusActiveSportTeam(optionUpdateStatus?.id, {
        is_active: optionUpdateStatus?.is_active
      });
      setIsLoadingUpdate((_prev) => false);
      if (response?.code === 200) {
        setOptionUpdateStatus((_prev: any) => null);
        const newState: any = (sports?.list || [])?.map((item: any) => {
          const val: string = String(item?.id || '');
          return ((String(optionUpdateStatus?.id) === val) ? ({ ...item, is_active: Number(optionUpdateStatus?.is_active) }) : item);
        });
        dispatch(changeStatusActiveSports({
          ...sports,
          list: newState
        }));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  const onCloseUpdateStatusClick = () => {
    setOptionUpdateStatus((_prev) => null);
  }
  // End::Update Status

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (sports?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.name }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === sports?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Begin:: Add Media for team
  const [showSelectMedia, setShowSelectMedia] = useState<boolean>(false);
  const [isLoadingCreateCampaign, setIsLoadingCreateCampaign] = useState<boolean>(false);
  const [isLoadingGetCampaign, setIsLoadingGetCampaign] = useState<boolean>(false);
  const [mediasSelected, setMediasSelected] = useState<number[]>([]);
  const [campaignSports, setCampaignSports] = useState<any>(null);
  const [teamDeleteInCampaignSport, setTeamDeleteInCampaignSport] = useState<any>(null);
  const [isConfirmDeleteLoading, setIsConfirmDeleteLoading] = useState<boolean>(false);

  const [firstHalf, secondHalf] = useMemo(() => {
    const clone = structuredClone(listMedia);
    let midPoint;
    if (clone.length % 2 === 1) {
      midPoint = Math.ceil(clone.length / 2);
    } else {
      midPoint = clone.length / 2;
    }
    const firstHalf = clone.slice(0, midPoint);
    const secondHalf = clone.slice(midPoint);
    return [firstHalf, secondHalf];
  }, [JSON.stringify(listMedia)]);

  const handleCheckMedia = (idItem: number, e: any) => {
    if (!e.target.checked) {
      setMediasSelected((prev: any) => {
        return prev?.filter((item: number) => item !== idItem);
      });
    } else {
      setMediasSelected((prev: any) => {
        return [...prev, idItem];
      });
    }
  }

  const handleCreateCampaignSports = async () => {
    try {
      setIsLoadingCreateCampaign((_prev) => true);
      const team_ids = (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const partner_ids = mediasSelected || [];
      const response: any = await postCampaignSports({
        team_ids: team_ids,
        partner_ids: partner_ids
      });
      setIsLoadingCreateCampaign((_prev) => false);
      setShowSelectMedia((_prev) => false);
      setOptionsSelected((_prev: any) => []);
      setMediasSelected((_prev) => []);
      if (response?.code === 200) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingCreateCampaign((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleGetCampaignSports = async () => {
    try {
      setIsLoadingGetCampaign((_prev) => true);
      const response: any = await getCampaignSports();
      setIsLoadingGetCampaign((_prev) => false);
      if (response?.code === 200) {
        setCampaignSports((prev: any) => response?.data || []);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingGetCampaign((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleConfirmDeleteTeamInCampaignSport = (team: any) => {
    setTeamDeleteInCampaignSport((_prev: any) => (team));
  };

  const handleActionDeleteTeamInCampaignSport = async () => {
    if (!teamDeleteInCampaignSport) { return; };
    try {
      setIsConfirmDeleteLoading((_prev) => true);
      const response: any = await deleteTeamInCampaignSport(teamDeleteInCampaignSport);
      if (response?.code === 200) {
        setCampaignSports((prev: any) => {
          return prev?.reduce((arr: any, item: any) => {
            if (item?.partner_id === teamDeleteInCampaignSport?.partner_id) {
              if (item?.team_count === 1) {
                return arr;
              }
              return (
                [...arr, {
                  ...item,
                  team_count: item?.team_count - 1,
                  teams: item?.teams?.filter((team: any) => team?.id !== teamDeleteInCampaignSport?.team_id)
                }]
              )
            }
            return [...arr, item];
          }, [])
        });
        setIsConfirmDeleteLoading((_prev) => false);
        setTeamDeleteInCampaignSport((_prev: any) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmDeleteLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmDeleteLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End:: Add Media for team

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Rank"),
        accessor: "rank",
        filterable: false,
        sortable: true,
        thWidth: 80,
        Cell: (cell: any) => {
          return (
            <>
              <div className={`${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : ''}`} style={{ minWidth: '60px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          );
        },
      },
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: item?.id, label: item?.name })} value={item?.id ?? ''} onChange={(e) => handleCheck(({ value: item?.id, label: item?.name }), query?.page, e)} id={`cell-check-${item?.id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${item?.id ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('code'),
        accessor: "code",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        thWidth: 70,
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center text-primary">{cell?.value || ''}</div>
            </>
          )
        },
      },
      {
        Header: t('Sport Team'),
        accessor: "name",
        filterable: false,
        sortable: false,
        thClass: "text-start",
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center text-start" style={{ minWidth: '100px' }}>
                  <span className="me-1">
                    <img width="25" height="25" alt="" src={item?.logo_url || ''} />
                  </span>
                  <span>
                    {item?.name}
                  </span>
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            if (category?.depth > 1) {
              let subs = [];
              for (let sub of category?.group_categories) {
                subs.push(sub?.name);
              }
              subs.push(category?.name);
              categoryName.push(subs.join(` > `));
            }
          }
          return (
            <div style={{ minWidth: '70px' }}>
              <CollapseTag style={{ minWidth: '200px' }} tags={categoryName} isRank={false} isInlineBlock={true} totalShow={2} isBackground={false} isShowModal={true} titleModal={t('Categories')} />
            </div>);
        },
      },
      {
        Header: t('Media information'),
        accessor: "partners",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const partners = value?.partners;
          let partnerName: string = "";
          for (let item of partners) {
            partnerName += `<span class='tag-style'>${item.name} </span>`;
          }
          return (
            <div style={{ minWidth: '150px' }} dangerouslySetInnerHTML={{ __html: partnerName }} />
          );
        }
      },
      {
        Header: t('Activated'),
        accessor: "is_active",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const active = value?.is_active;
          return (
            <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
              <input type="checkbox" className="form-check-input" name="is_active" id="customSwitchsizelg" checked={Boolean(active)}
                onChange={(event: any) => {
                  if (isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_UPDATE, userPermissions)) {
                    handleShowConfirmUpdateStatus(event.target.checked, value?.id);
                  }
                }}
              />
            </div>
          );
        }
      },
      // {
      //   Header: t('year'),
      //   accessor: "year",
      //   filterable: false,
      //   sortable: false,
      //   thClass: "text-end",
      //   Cell: (cell: any) => {
      //     return (
      //       <>
      //         <div className="text-end pe-3">{cell?.value || ''}</div>
      //       </>
      //     )
      //   },
      // },
      {
        Header: t('game_count'),
        accessor: "game_count",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '100px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('gain_point'),
        accessor: "gain_point",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '100px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('won'),
        accessor: "won",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '50px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('drawn'),
        accessor: "drawn",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '60px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('lost'),
        accessor: "lost",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '50px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('gain_goal'),
        accessor: "gain_goal",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('lose_goal'),
        accessor: "lose_goal",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('goal_gap'),
        accessor: "goal_gap",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      //
      {
        Header: t('last_result'),
        accessor: "last_result",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '90px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('league'),
        accessor: "league",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '70px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('foul'),
        accessor: "foul",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '60px' }} className={`text-center ${query?.sort_by === cell?.column?.id ? 'cell-bg-active' : 'pe-3'}`}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, TYPE_SPORT_OPTIONS_LANG, STATUS_CHANGE_SPORT_OPTIONS_LANG, COLORS_SPORT_STATUS_CHANGE]
  );


  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resCategoriesSport, resMedia]: any = await Promise.all([getAllCategoriesSportNormal(), getAllMedias()]);
      setListCategoriesSport((_prev) => resCategoriesSport?.data || []);
      setListMedia((_prev) => resMedia?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!SportCodeSearch?.value) {
      setSportCodeSearch((prev) => null);
    }

    setTypeSportSearch((_prev: any) => TYPE_SPORT_OPTIONS_LANG?.filter((e: any) => e.value === typeSportSearch?.value)[0]);

    if (statusChangeSearch?.value) {
      setStatusChangeSearch((_prev: any) => STATUS_CHANGE_SPORT_OPTIONS_LANG?.filter((e: any) => e.value === statusChangeSearch?.value)[0]);
    }

    if (statusSearch?.value) {
      setStatusSearch((_prev: any) => STATUS_ACTIVE_SPORT_TEAM_OPTIONS_LANG?.filter((e: any) => e.value === statusSearch?.value)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Create Campaign Sport")} - ${t("Campaign")} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Create Campaign Sport")}
            centerTitle={t('Trend Ranking News')}
            pageTitle={t("Campaign")} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.SPORT_TEAM_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isSportsLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={sports?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0 mt-2 mt-md-2">
                          <Col sm={6} lg={2} className='mt-2 mb-2'>
                            <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                              <DropdownStatusCampaign
                                name="status"
                                dataList={STATUS_ACTIVE_SPORT_TEAM_OPTIONS_LANG || []}
                                placeholder={`${t("Status")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={statusSearch || null}
                                onChangeSelect={(e: any) => setStatusSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Status'), value: '' }}
                                colors={COLORS_STATUS_ACTIVE_SPORT_TEAM}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} lg={4} className="mt-2 mb-2">
                            <LabelWrapper label={t('Category')} isShow={!!categorySearch?.value}>
                              <DropdownCategoryMultiSub
                                dataList={listCategoriesSport}
                                initialValue={categorySearch || undefined}
                                onChangeCategory={(event: any) => {
                                  setCategorySearch(event);
                                }}
                                isClearable={true}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-0 mt-0 mt-md-0">
                          <Col sm={6} lg={2} className='mt-2 mb-2'>
                            <LabelWrapper label={t('Search by code')} isShow={!!codeSearch}>
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t('Search by code')}...`}
                                  value={codeSearch}
                                  onChange={e => setCodeSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} lg={4} className='mt-2 mb-2 '>
                            <LabelWrapper label={t('Search by name')} isShow={!!keywordSearch}>
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t('Search by name')}...`}
                                  value={keywordSearch}
                                  onChange={e => setKeywordSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-0">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isSportsLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Col sm={12} lg={12} className="mt-4 mb-0 text-start">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success me-3"
                          onClick={() => setShowSelectMedia(true)}
                          disabled={!countRowSelected}
                        >
                          {countRowSelected > 0 && (
                            <span className="position-absolute topbar-badge badge rounded-pill bg-danger"
                              style={{ transform: 'translate(0%, -70%)' }}
                            >
                              {formatNumberWithCommas(countRowSelected)}
                            </span>)}
                          {t('Media selection window')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary fs-14"
                          onClick={() => handleGetCampaignSports()}
                          disabled={isLoadingGetCampaign}
                        >
                          {/* <i className="ri-refresh-line align-bottom me-1"></i>{" "} */}
                          {isLoadingGetCampaign && <Spinner size="sm me-2"></Spinner>}
                          {t('Sports team selection status by media')}
                        </button>
                      </div>
                    </Col>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={sports?.list?.length ? sports?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(Number(sports?.pagination?.total) / Number(sports?.pagination?.limit))}
                        customPageIndex={query.page - 1}
                        totalRecords={sports?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        classBackgroundWhenActiveSort='cell-bg-active'
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isSportsLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <Modal isOpen={showSelectMedia} toggle={() => setShowSelectMedia(false)} centered={true} size="lg" scrollable={true} keyboard={true}>
        <ModalHeader toggle={() => setShowSelectMedia(false)}>
          {t('Media selection window')}
        </ModalHeader>
        <ModalBody className="pt-0 pb-3 px-3">
          <div className="table-responsive color-scrollbar-os" style={{ minHeight: '600px', height: 'calc(100dvh - 280px)', margin: 0, overflowY: 'auto' }}>
            <table className="table table-bordered mb-0">
              <thead className="table-light" style={{
                position: 'sticky',
                top: '-1px',
                zIndex: 9999,
              }}>
                <tr>
                  <th className="text-center" colSpan={4}>
                    <span className="position-relative">
                      {t('Media')}
                      {mediasSelected?.length > 0 && (
                        <span className="position-absolute topbar-badge badge rounded-pill bg-success"
                          style={{ transform: 'translate(50%, -50%)' }}
                        >
                          {formatNumberWithCommas(mediasSelected?.length)}
                        </span>)}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {firstHalf?.map((item, index) => (
                  <tr key={item?.id}>
                    <td className="text-center cursor-pointer py-2 align-middle" style={{ width: '50px' }}>
                      <div className="form-check text-center">
                        <input className="form-check-input" type="checkbox" name="table" checked={mediasSelected?.includes(item?.id)} value={item?.id ?? ''} onChange={(e) => handleCheckMedia(item?.id, e)} id={`cell-check-${item?.id ?? ''}`} />
                        <label className="form-check-label" htmlFor={`cell-check-${item?.id ?? ''}`}></label>
                      </div>
                    </td>
                    <td className="text-start cursor-pointer py-2" style={{ width: '50%' }}>
                      {item?.name}
                      <br />
                      <Link to={formatLinkHTTPS(item?.website)} className='text-normal' target="_blank">
                        {item?.website}
                      </Link>
                    </td>
                    <td className="text-center cursor-pointer py-2 align-middle" style={{ width: '50px' }}>
                      {secondHalf?.[index] && (<div className="form-check text-center">
                        <input className="form-check-input" type="checkbox" name="table" checked={mediasSelected?.includes(secondHalf?.[index]?.id)} value={secondHalf?.[index]?.id ?? ''} onChange={(e) => handleCheckMedia(secondHalf?.[index]?.id, e)} id={`cell-check-${secondHalf?.[index]?.id ?? ''}`} />
                        <label className="form-check-label" htmlFor={`cell-check-${secondHalf?.[index]?.id ?? ''}`}></label>
                      </div>)}
                    </td>
                    <td className="text-start cursor-pointer py-2" style={{ width: '50%' }}>
                      {secondHalf?.[index] && (<>
                        {secondHalf?.[index]?.name}
                        <br />
                        <Link to={formatLinkHTTPS(secondHalf?.[index]?.website)} className='text-normal' target="_blank">
                          {secondHalf?.[index]?.website}
                        </Link>
                      </>)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-end mt-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => handleCreateCampaignSports()}
              disabled={!mediasSelected?.length || isLoadingCreateCampaign}
            >
              {isLoadingCreateCampaign && <Spinner size="sm"></Spinner>} {t('Button Create')}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={campaignSports !== null} toggle={() => setCampaignSports(null)} centered={true} size="lg" scrollable={true} keyboard={true}>
        <ModalHeader toggle={() => setCampaignSports(null)}>
          {t('Sports team selection status by media')}
        </ModalHeader>
        <ModalBody className="pt-0 pb-3 px-3">
          <div className="table-responsive color-scrollbar-os" style={{ minHeight: '600px', height: 'calc(100dvh - 280px)', margin: 0, overflowY: 'auto' }}>
            <table className="table table-bordered mb-0">
              <thead className="table-light" style={{
                position: 'sticky',
                top: '-1px',
                zIndex: 9999,
              }}>
                <tr>
                  <th className="text-center" colSpan={2}>
                    <span className="position-relative">
                      {t('Media')}
                    </span>
                  </th>
                  <th className="text-center">
                    <span className="position-relative">
                      {t('Number Count')}
                    </span>
                  </th>
                  <th className="text-center" >
                    <span className="position-relative">
                      {t('Teams')}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {campaignSports?.map((item: any, index: number) => (
                  <tr key={item?.id}>
                    <td className="text-center cursor-pointer py-2 align-middle" style={{ width: '50px' }}>
                      {index + 1}
                    </td>
                    <td className="text-start cursor-pointer py-2 align-middle">
                      <div style={{ minWidth: '150px' }}>{item?.partner?.name || ''}</div>
                    </td>
                    <td className="text-center cursor-pointer py-2 align-middle">
                      {item?.team_count || 0}
                    </td>
                    <td className="text-start cursor-pointer py-2">
                      <div>
                        {item?.teams?.map((team: any) => (
                          <span key={team?.id} className="me-2 mb-2 bg-primary text-white ps-2 d-inline-block" style={{ backgroundColor: '#eaeaea', borderRadius: '5px' }}>
                            <span className="fs-12">{team?.name}</span>
                            <button className="btn btn-sm text-white" type="button"
                              onClick={() => {
                                handleConfirmDeleteTeamInCampaignSport({
                                  partner_id: Number(item?.partner_id),
                                  team_id: team?.id
                                })
                              }}
                            >×</button>
                          </span>))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-end mt-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setCampaignSports(null)}
            >
              {t('Button Close')}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <ModalConfirm
        textButtonConfirm={t('Button Update Status Campaign')}
        classButtonConfirm='btn-primary'
        classIconButtonConfirm='ri-send-plane-line'
        header={t('Button Update Status Campaign')}
        title={t('Are you sure you want to update this?')}
        content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
        isShowIcon={false}
        isOpen={!!optionUpdateStatus}
        isLoading={isLoadingUpdate}
        onClose={onCloseUpdateStatusClick}
        onConfirm={handleOnConfirmUpdate}
      />

      <ModalConfirm
        textButtonConfirm={t('Button Delete Team')}
        classButtonConfirm='btn-soft-danger'
        classIconButtonConfirm='ri-refresh-line'
        header={t('Button Delete Team')}
        title={t('Do you want to delete team of media!')}
        content={t('After confirming the delete action, the data will be deleted and cannot be recovered. Do you want to proceed with the delete action.')}
        isShowIcon={false}
        isOpen={teamDeleteInCampaignSport !== null}
        isLoading={isConfirmDeleteLoading}
        onClose={() => setTeamDeleteInCampaignSport(null)}
        onConfirm={handleActionDeleteTeamInCampaignSport}
      />
    </React.Fragment >
  );
};

export default SportKeyword;
