import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import {  ILotto } from "./types/_lotto";
const api = new APIClient();

const path = '';
const userApi = {
  lottos(params: any):Promise<AxiosResponse<ResponseData<ILotto[]> & PaginationResponse, any>> {
    const url = `${path}/lotto-statistics/results`
    return api.get(url, params)
  },
}
export default userApi
