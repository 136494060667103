import { IMedia } from "api/types/_media";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface SearchFilterMediaProps {
  name?: string,
  dataList?: IMedia[],
  initialValue?: Option[] | Option | null | undefined;
  filterByTypeId?: string | number,
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
  isMulti?: boolean,
  isClearable?: boolean,
  id?: string,
  disabled?: boolean
}
const FILTER_BY = { page: 1, limit: 10, keyword: '' };

const SearchFilterMedia = ({
  name = '',
  initialValue = [],
  dataList = [],
  filterByTypeId = '',
  onChangeSelect,
  placeholder,
  isMulti = true,
  isClearable = false,
  id = '',
  disabled = false
}: SearchFilterMediaProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [options, setOptions] = useState<Option[]>([]);
  const [keywordSearch, setKeywordSearch] = useState<string>('');

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item) => ({ label: item?.name, value: String(item?.id || ''), website: item?.website || '' })) || [] as Option[];
    setOptions((_prev: any) => result);
    if (filterByTypeId) {
      const resultFilterByTypeId = dataList?.filter((item) => {
        const types = item?.domains?.map((top: any) => String(top?.id));
        return (!!filterByTypeId) ? types?.includes(String(filterByTypeId)) : false;
      }).map((item) => ({ label: item?.name, value: String(item?.id || '') }));
      setOnChangeOption && setOnChangeOption(resultFilterByTypeId);
    }

  }, [JSON.stringify(dataList), filterByTypeId]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        name={name}
        isClearable={isClearable}
        value={initialValue}
        isDisabled={disabled}
        onInputChange={(e: any) => setKeywordSearch(e)}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{isMulti ? t('Select Medias') : t('Select Media')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">{option?.label}
            {/* <div className="op ms-2">
              {`${option?.website ? option?.website : ''}`}
            </div> */}
          </div>)}
        // formatOptionLabel={(option: any) => (<div className="d-flex">{option?.label}</div>)}
        className="search-filter-media"
        classNamePrefix="name-prefix"
        id={id}
      />
    </React.Fragment>
  );
};

export default SearchFilterMedia;
