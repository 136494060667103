import { IMedia, IType } from "api/types/_media";
import { IPreviewNews } from "api/types/_news";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import ModalConfirm from "components/Common/ModalConfirm";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import SearchTemplate from "components/Common/SearchTemplate";
import TooltipCustom from "components/Common/TooltipCustom";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    ACTION_TYPE_CAMPAIGN,
    SCHEDULE_MONTHLY_OPTIONS,
    SCHEDULE_OPTIONS,
    SCHEDULE_WEEKLY_OPTIONS
} from "helpers/constans";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {
    getDetailCampaignGroup,
    postCampaignMultiMedia,
    previewNews,
    putCampaignGroupMultiMedia
} from "store/thunks";
import * as Yup from "yup";
import TingSearchKeyword from "../KeywordAnalyzingNews/TingSearch";


export interface Tag {
    id: string;
    text: string;
}

export interface Props {
    typeCampaignForm?: 'MANUAL_KEYWORD' | 'POPULAR_KEYWORD';
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    listType: IType[],
    listMedia: IMedia[],
    listTemplate: ITemplate[],
    listCategoriesType: Option[],
    listClassifications?: Option[],
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const CampaignFormByKeywordAnalyzing = ({
    typeCampaignForm = 'MANUAL_KEYWORD',
    isModal = false,
    id = '',
    isCopy = false,
    listType = [],
    listMedia = [],
    listTemplate = [],
    listCategoriesType = [],
    listClassifications = [],
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    }));

    const CATEGORY_TYPE = (typeCampaignForm === 'MANUAL_KEYWORD') ? 'keyword_manual' : 'keyword_popular';

    const OPTIONS_MUST_HAVE_DATE = ["weekly", "monthly"];

    const OPTIONS_MUST_HAVE_TIME = ["daily", "weekly", "monthly"];

    const MONTHLY_OPTIONS = SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({ ...item, type: "monthly", })) || [];
    const WEEKLY_OPTIONS = SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({ ...item, type: "weekly", })) || [];
    const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map((item: any) => ({ ...item, label: t(item?.label) }));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [isOpenSearching, setIsOpenSearching] = useState<boolean>(false);

    const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

    const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);

    const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(null);

    const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);

    const handleShowConfirmCreateCampaign = () => {
        if (formik?.values?.action_type === ACTION_TYPE_CAMPAIGN.SAVE) {
            handleSubmit(formik.values);
        } else {
            setIsConfirmCreate((_prev) => true);
        }
    };

    const handleCreateCampaign = () => {
        setIsConfirmCreate((_prev) => false);
        handleSubmit(formik.values);
    }

    const onCloseConfirmCreateClick = () => {
        setIsConfirmCreate((_prev) => false);
    }

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            let data: any = {};
            if (id && !isCopy) {
                data = {
                    name: values?.keyword || '',
                    schedule: values?.schedule?.value || "now",
                    time: values?.schedule?.value === "now" ? "" : values?.time || "",
                    date: OPTIONS_MUST_HAVE_DATE.includes(values?.schedule?.value) ? values?.date?.value || "" : "",
                    title_template_id: values?.is_random_template ? null : (values?.title_template_id?.value || null),
                    body_template_id: values?.is_random_template ? null : (values?.body_template_id?.value || null),
                }
            } else {
                data = {
                    schedule: values?.schedule?.value || "now",
                    publishing_schedule: values?.publishing_schedule?.value || "now",
                    type: CATEGORY_TYPE,
                    keywords: !!values?.keyword ? [values?.keyword || ''] : [],
                    tag_related_news: (values?.tags || [])?.map((item: Tag) => item?.id).join(",") || "",
                    action_type: values?.action_type || ACTION_TYPE_CAMPAIGN.POST,
                    multiple: true,
                    partners: (values?.medias || [])?.reduce((arr: any, item: any) => {
                        if (item?.value) {
                            return [...arr, {
                                partner_id: item?.value ?? "",
                                partner_code_id: '',
                                title_template_id: values?.is_random_template ? null : (values?.title_template_id?.value || null),
                                body_template_id: values?.is_random_template ? null : (values?.body_template_id?.value || null),
                                is_random_chart: false,
                                time: values?.schedule?.value === "now" ? "" : values?.time || "",
                                date: OPTIONS_MUST_HAVE_DATE.includes(values?.schedule?.value) ? values?.date?.value || "" : "",
                                publishing_time: values?.publishing_schedule?.value === "now" ? "" : values?.publishing_time || "",
                                publishing_date: OPTIONS_MUST_HAVE_DATE.includes(values?.publishing_schedule?.value) ? values?.publishing_date?.value || "" : "",
                            }];
                        }
                        return arr;
                    }, []) || [],
                };
            }

            const response: any = (id && !isCopy) ? await putCampaignGroupMultiMedia(id, data) : await postCampaignMultiMedia(data);

            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                if (id) {
                    triggerRefresh && triggerRefresh();
                } else {
                    navigate("/campaign/keyword-analyzing-news/list");
                }
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const sectionSchema = Yup.object().shape({
        id: Yup.string(),
        section_code: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
    });

    const validationSchema = Yup.object({
        name: Yup.string().nullable(),
        types: Yup.object().nullable(),
        medias: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(`${t("Please provide a id")}`),
                    value: Yup.string().required(`${t("Please provide a text")}`),
                })
            ).min(1, `${t("At least 1 media is required")}`),
        sections: Yup.array().of(sectionSchema).nullable(),
        classification: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        keyword: (typeCampaignForm === 'POPULAR_KEYWORD') ? Yup.string().nullable() : Yup.string().required(`${t("At least 1 keyword is required")}`),
        schedule: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        publishing_schedule: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        date: (typeCampaignForm === 'POPULAR_KEYWORD') ? Yup.string().nullable() : Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .when("schedule", {
                is: (schedule: any) =>
                    OPTIONS_MUST_HAVE_DATE?.includes(schedule?.value),
                then: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .required(`${t("Date is required")}`),
            }),
        time: (typeCampaignForm === 'POPULAR_KEYWORD') ? Yup.string().nullable() : Yup.string().when("schedule", {
            is: (schedule: any) => schedule?.value !== "now",
            then: (schema) =>
                Yup.string()
                    .required(`${t("Time is required")}`)
                    .when("category_type", {
                        is: (cateType: any) => cateType?.value === "exchange_rate",
                        then: (schema) =>
                            Yup.string().test(
                                "is-later-than-9-30",
                                `${t("You can start writing articles from 9:30 AM!")}`,
                                function (value) {
                                    if (!value) return true;
                                    const [hours, minutes] = value.split(":").map(Number);
                                    const timeInMinutes = hours * 60 + minutes;
                                    const nineThirtyInMinutes = 9 * 60 + 30;
                                    return timeInMinutes >= nineThirtyInMinutes;
                                }
                            ),
                    }),
        }),
        title_template_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        body_template_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        is_random_chart: Yup.boolean().nullable(),
        is_random_template: Yup.boolean().nullable(),
        tags: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string(),
                    text: Yup.string(),
                })
            ).nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            types: null,
            medias: [],
            keyword: '',
            schedule: null,
            publishing_schedule: null,
            time: '09:00',
            date: null,
            publishing_time: '09:00',
            publishing_date: null,
            title_template_id: null,
            body_template_id: null,
            is_random_chart: false,
            is_random_template: false,
            tags: "",
            action_type: "",
        },
        validationSchema,
        onSubmit: handleShowConfirmCreateCampaign,
    });

    //@ts-ignore
    const initialValueClassification = formik?.values?.classification as Option;

    //@ts-ignore
    const initialValueTitleTemplate = formik?.values?.title_template_id as Option;

    //@ts-ignore
    const initialValueBodyTemplate = formik?.values?.body_template_id as Option;

    //@ts-ignore
    const initialValueRandomInfographic = formik?.values?.is_random_chart as Option;

    //@ts-ignore
    const initialValueKeyword = formik?.values?.keyword || '';

    //@ts-ignore
    const initialValueMedias = (formik?.values?.medias as Option[]) || [];

    //@ts-ignore
    const initialValueSchedule = (formik?.values?.schedule as unknown as Option) || SCHEDULE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValueDate = (formik?.values?.date as unknown as Option) || DATE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValuePublishingSchedule = (formik?.values?.publishing_schedule as unknown as Option) || SCHEDULE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValuePublishingDate = (formik?.values?.publishing_date as unknown as Option) || DATE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValueTags = (formik?.values?.tags as Tag[]) || [];

    //@ts-ignore
    const initialValueActionType = formik?.values?.action_type || ACTION_TYPE_CAMPAIGN.POST;

    const isDisabledPreview = ((String(initialValueKeyword).trim() === '' && (typeCampaignForm === 'MANUAL_KEYWORD')));


    function onClosePreviewClick() {
        setIsOpenPreview((_prev) => !_prev);
    }

    const onSaveClick = () => {
        formik.setFieldValue("action_type", ACTION_TYPE_CAMPAIGN.SAVE);
        formik.submitForm();
    };

    const onPostClick = () => {
        formik.setFieldValue("action_type", ACTION_TYPE_CAMPAIGN.POST);
        formik.submitForm();
    };

    const onPreviewClick = async () => {
        handlePreviewTemplate();
    };

    const handlePreviewTemplate = async () => {
        try {
            setIsPreviewLoading((_prev) => true);
            const data = {
                category_type: CATEGORY_TYPE,
                title_template_id: initialValueTitleTemplate?.value || null,
                body_template_id: initialValueBodyTemplate?.value || null,
                is_random_chart: initialValueRandomInfographic,
                keyword_ids: [],
                keywords: !!initialValueKeyword ? [initialValueKeyword || ''] : []
            };
            const response: any = await previewNews(data);
            if (response?.data) {
                setPreviewTemplate(
                    (_prev) => response?.data || { title: "", content: "" }
                );
                setIsPreviewLoading((_prev) => false);
                setIsOpenPreview((_prev) => true);
            } else {
                setIsPreviewLoading((_prev) => false);
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsPreviewLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const handleSearchingKeyword = () => {
        setIsOpenSearching((prev) => true);
    };

    const handleAddKeywordFromSearching = (str: string) => {
        formik.setFieldValue("keyword", str);
        setIsOpenSearching((prev) => false);
    }

    const handleSetValueForm = (
        valueDefault: any,
        templates: ITemplate[] = listTemplate
    ) => {

        const optionTemplateTitle = templates?.filter(
            (e: any) => String(e.id) === String(valueDefault?.base_campaign?.title_template_id)
        )[0];
        const optionTemplateBody = templates?.filter(
            (e: any) => String(e.id) === String(valueDefault?.base_campaign?.body_template_id)
        )[0];

        const vRandomInfographic = !!valueDefault?.is_random_chart;

        const vRandomTemplate = !!valueDefault?.is_random_template || (!optionTemplateTitle && !optionTemplateBody);

        const vName = valueDefault?.name;

        const vKeyword = valueDefault?.name || '';

        const vSchedule = SCHEDULE_OPTIONS_LANG?.filter(
            (e: any) => e.value === valueDefault?.schedule
        )[0];
        const vTime = isCopy ? '09:00' : valueDefault?.time;
        const vDate = DATE_OPTIONS_LANG?.filter(
            (e: any) =>
                e.value === valueDefault?.date && e.type === valueDefault?.schedule
        )[0];

        const vPublishingSchedule = SCHEDULE_OPTIONS_LANG?.filter(
            (e: any) => e.value === valueDefault?.publishing_schedule
        )[0];
        const vPublishingTime = isCopy ? '09:00' : valueDefault?.publishing_time;

        const vPublishingDate = DATE_OPTIONS_LANG?.filter(
            (e: any) =>
                e.value === valueDefault?.publishing_date && e.type === valueDefault?.publishing_schedule
        )[0];

        const vTitleTemplate = {
            label: optionTemplateTitle?.name,
            value: String(optionTemplateTitle?.id),
        };
        const vBodyTemplate = {
            label: optionTemplateBody?.name,
            value: String(optionTemplateBody?.id),
        };

        const vMedias = (valueDefault?.partners || [])?.map((item: IMedia) => ({
            value: String(item?.partner_id),
            label: item?.name,
        }));

        const vRangeFromSearch = valueDefault?.base_campaign?.metadata?.[0]?.value ?? '';
        const vRangeToSearch = valueDefault?.base_campaign?.metadata?.[1]?.value ?? '';

        setTimeout(() => {
            formik.setFieldValue("name", vName);
            formik.setFieldValue("keyword", vKeyword);
            formik.setFieldValue("schedule", vSchedule);
            formik.setFieldValue("time", vTime);
            formik.setFieldValue("date", vDate);
            formik.setFieldValue("publishing_schedule", vPublishingSchedule);
            formik.setFieldValue("publishing_time", vPublishingTime);
            formik.setFieldValue("publishing_date", vPublishingDate);
            formik.setFieldValue("title_template_id", vTitleTemplate);
            formik.setFieldValue("body_template_id", vBodyTemplate);
            formik.setFieldValue("is_random_chart", vRandomInfographic);
            formik.setFieldValue("is_random_template", vRandomTemplate);

        }, 300);
        setTimeout(() => {
            // formik.setFieldValue("tags", vTags);
            formik.setFieldValue("medias", vMedias);
        }, 400);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getDetailCampaignGroup(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        document.title = `${t("Campaign Create")} - ${t("Campaign")} | NewsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        formik.setFieldValue(
            "schedule",
            SCHEDULE_OPTIONS_LANG?.find(
                (e: any) => e.value === initialValueSchedule?.value
            )
        );
        formik.setFieldValue(
            "date",
            DATE_OPTIONS_LANG?.find((e: any) => e.value === initialValueDate?.value)
        );
        formik.setFieldValue(
            "publishing_schedule",
            SCHEDULE_OPTIONS_LANG?.find(
                (e: any) => e.value === initialValuePublishingSchedule?.value
            )
        );
        formik.setFieldValue(
            "publishing_date",
            DATE_OPTIONS_LANG?.find((e: any) => e.value === initialValuePublishingDate?.value)
        );

        formik.setFieldValue(
            "classification",
            listClassifications?.find(
                (e: any) => e.value === initialValueClassification?.value
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    console.log(formik.errors)
    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <Card id="leadsList">
                            <CardBody className="pt-3">
                                <Row className={`mt-1 g-5 ${isModal ? 'mb-0' : 'mb-5'}`}>
                                    {!isModal && <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>}
                                    <Col sm={12} md={7} xl={isModal ? 6 : 6} lg={isModal ? 7 : 7} className={isModal ? 'mt-2' : ''}>
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {(typeCampaignForm === 'MANUAL_KEYWORD') && (
                                                <Col lg={12}>
                                                    <div className="position-relative">
                                                        <label className="form-label">
                                                            {t("Keyword")}
                                                            <span className="text-danger"> *</span>
                                                            <TooltipCustom
                                                                title={t("You can analyze keyword. After entering the keyword, press the 'Search' key to separate them")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(2px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            id="keyword"
                                                            name="keyword"
                                                            autoComplete="off"
                                                            value={initialValueKeyword || ''}
                                                            placeholder={`${t("Enter keyword")}...`}
                                                            onChange={(event: any) => {
                                                                formik.setFieldValue("keyword", event?.target?.value || "");
                                                            }
                                                            }
                                                            onKeyUp={(event: any) => {
                                                                event.preventDefault();
                                                                if (event.key === "Enter") {
                                                                    handleSearchingKeyword();
                                                                }
                                                            }}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="position-absolute bottom-0 end-0 btn btn-primary"
                                                            onClick={() => handleSearchingKeyword()}
                                                            disabled={(String(initialValueKeyword)?.trim() === '')}
                                                            style={{ width: '120px', borderRadius: '20px var(--vz-border-radius) var(--vz-border-radius) 20px' }}
                                                        >
                                                            <i className="ri-search-line align-bottom me-1"></i>
                                                            {t('Button Search')}
                                                        </button>
                                                    </div>
                                                    {formik.touched.keyword && formik.errors.keyword ? (
                                                        <div className="text-danger">
                                                            {formik.errors.keyword}
                                                        </div>
                                                    ) : null}
                                                </Col>)}
                                            <Col
                                                sm={12}
                                                lg={12}
                                                className="d-flex justify-content-between align-items-end"
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <label className="form-label">
                                                        {t("Title Template")}
                                                    </label>
                                                    <SearchTemplate
                                                        name="title_template_id"
                                                        templates={listTemplate}
                                                        type="title"
                                                        filterByCategoryType={["keyword_manual", "keyword_popular"]}
                                                        filterBySchedule={id ? 'now' : initialValueSchedule?.value}
                                                        placeholder={`${t("Select Title Template")}...`}
                                                        initialValue={
                                                            initialValueTitleTemplate ?? undefined
                                                        }
                                                        onChangeSelect={(event) => {
                                                            formik.setFieldValue(
                                                                "title_template_id",
                                                                event
                                                            );
                                                        }}
                                                    />
                                                    {formik.touched.title_template_id &&
                                                        formik.errors.title_template_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.title_template_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col
                                                sm={12}
                                                lg={12}
                                                className="d-flex justify-content-between align-items-end"
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <label className="form-label">
                                                        {t("Content Template")}
                                                    </label>
                                                    <SearchTemplate
                                                        name="body_template_id"
                                                        templates={listTemplate}
                                                        type="body"
                                                        filterByCategoryType={["keyword_manual", "keyword_popular"]}
                                                        filterBySchedule={id ? 'now' : initialValueSchedule?.value}
                                                        placeholder={`${t(
                                                            "Select Content Template"
                                                        )}...`}
                                                        initialValue={
                                                            initialValueBodyTemplate ?? undefined
                                                        }
                                                        onChangeSelect={(event) => {
                                                            formik.setFieldValue(
                                                                "body_template_id",
                                                                event
                                                            );
                                                        }}
                                                    />
                                                    {formik.touched.body_template_id &&
                                                        formik.errors.body_template_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.body_template_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col
                                                sm={12}
                                                lg={12}
                                            >
                                                <div className=" w-100 form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        name={`is_random_template`}
                                                        checked={formik?.values?.is_random_template}
                                                        type="checkbox"
                                                        id={`is_random_template`}
                                                        onChange={(event: any) => formik.setFieldValue(`is_random_template`, Boolean(event?.target.checked))}
                                                    />
                                                    <Label className="form-check-label text-muted" style={{ fontSize: '12px' }} htmlFor={`is_random_template`}>{t('Randomly apply template')}</Label>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={5} xl={isModal ? 6 : 4} lg={isModal ? 5 : 3} className={isModal ? 'mt-2' : ''}>
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {(typeCampaignForm === 'MANUAL_KEYWORD') && (
                                                <>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Schedule")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Select
                                                                // isDisabled
                                                                options={SCHEDULE_OPTIONS_LANG}
                                                                value={initialValueSchedule}
                                                                name="schedule"
                                                                id="idSchedule"
                                                                placeholder={`${t("Schedule")}...`}
                                                                className="select-schedule"
                                                                classNamePrefix="name-prefix"
                                                                onChange={(event: any) => {
                                                                    formik.setFieldValue("schedule", event);
                                                                    formik.setFieldValue('publishing_schedule', event);
                                                                    if (
                                                                        OPTIONS_MUST_HAVE_DATE?.includes(
                                                                            event?.value
                                                                        )
                                                                    ) {
                                                                        const val =
                                                                            DATE_OPTIONS_LANG?.filter(
                                                                                (item) => item?.type === event?.value
                                                                            )[0] || null;
                                                                        formik.setFieldValue("date", val);
                                                                        formik.setFieldValue("publishing_date", val);
                                                                    }
                                                                    if (OPTIONS_MUST_HAVE_TIME.includes(
                                                                        event?.value
                                                                    )) {
                                                                        formik.setFieldValue("time", "09:00");
                                                                        formik.setFieldValue("publishing_time", "09:00");
                                                                    }
                                                                }}
                                                            ></Select>
                                                            {formik.touched.schedule &&
                                                                formik.errors.schedule ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik?.errors?.schedule}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Date")}{" "}
                                                                {OPTIONS_MUST_HAVE_DATE?.includes(
                                                                    initialValueSchedule?.value
                                                                ) && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <Select
                                                                options={
                                                                    DATE_OPTIONS_LANG?.filter(
                                                                        (item) =>
                                                                            item?.type === initialValueSchedule?.value
                                                                    ) || []
                                                                }
                                                                value={
                                                                    !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        initialValueSchedule?.value
                                                                    )
                                                                        ? null
                                                                        : initialValueDate
                                                                }
                                                                name="date"
                                                                id="idDate"
                                                                isDisabled={
                                                                    !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        initialValueSchedule?.value
                                                                    )
                                                                }
                                                                placeholder={`${t("Date")}...`}
                                                                className="select-schedule"
                                                                classNamePrefix="name-prefix"
                                                                onChange={(event: any) => {
                                                                    formik.setFieldValue("date", event);
                                                                    formik.setFieldValue("publishing_date", event);
                                                                }

                                                                }
                                                            ></Select>
                                                            {formik.touched.date && formik.errors.date ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik?.errors?.date}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Time")}{" "}
                                                                {initialValueSchedule?.value !== "now" && (
                                                                    <span className="text-danger"> *</span>
                                                                )}
                                                            </label>
                                                            <div className="date-picker-wrapper-custom">
                                                                <DatePicker
                                                                    className="form-control search"
                                                                    placeholderText={`${t("Time")}...`}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    disabled={
                                                                        initialValueSchedule?.value === "now"
                                                                    }
                                                                    timeIntervals={5}
                                                                    autoComplete="off"
                                                                    isClearable={true}
                                                                    timeCaption={t("Time")}
                                                                    timeFormat="HH:mm"
                                                                    value={
                                                                        initialValueSchedule?.value === "now"
                                                                            ? ""
                                                                            : formik.values.time
                                                                    }
                                                                    locale={i18n?.language === "ko" ? "ko" : "en"}
                                                                    name="time"
                                                                    onChange={(value: Date | null) => {
                                                                        formik.setFieldValue("time", value ? moment(new Date(value)).format("HH:mm") : "");
                                                                        formik.setFieldValue("publishing_time", value ? moment(new Date(value)).format("HH:mm") : "")
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                            {formik.touched.time && formik.errors.time ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.time}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </>)}
                                            <Col xxl={12} className="mb-0 mb-md-3">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <SearchFilterMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        isMulti={true}
                                                        isClearable={true}
                                                        disabled={!!(id && !isCopy)}
                                                        initialValue={initialValueMedias || []}
                                                        onChangeSelect={(event: any) => {
                                                            console.log('event', event)
                                                            formik.setFieldValue("medias", event);
                                                        }}
                                                    />
                                                    {formik.touched.medias && formik.errors.medias ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.medias}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <div className="col-lg-12">
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-secondary fs-14"
                                                        color="light"
                                                        type="button"
                                                        onClick={onPreviewClick}
                                                        disabled={
                                                            isPreviewLoading ||
                                                            isLoading ||
                                                            isDisabledPreview
                                                        }
                                                    >
                                                        {isPreviewLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : (
                                                            <i className="ri-eye-line align-bottom me-1"></i>
                                                        )}
                                                        {t("Button Preview")}
                                                    </button>
                                                    <button
                                                        className="btn btn-success fs-14"
                                                        color="light"
                                                        type="button"
                                                        onClick={onSaveClick}
                                                        disabled={isLoading || isPreviewLoading}
                                                    >
                                                        {isLoading &&
                                                            initialValueActionType ===
                                                            ACTION_TYPE_CAMPAIGN.SAVE ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : (
                                                            <i className="ri-login-circle-line align-bottom me-1"></i>
                                                        )}
                                                        {t("Button Save")}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary fs-14"
                                                        color="success"
                                                        type="button"
                                                        onClick={onPostClick}
                                                        disabled={isLoading || isPreviewLoading}
                                                    >
                                                        {isLoading &&
                                                            initialValueActionType ===
                                                            ACTION_TYPE_CAMPAIGN.POST ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : (
                                                            <i className="ri-send-plane-line align-bottom me-1"></i>
                                                        )}
                                                        {t("Button Post")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {!isModal && <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </form >
            <ToastContainer closeButton={false} limit={1} />
            <Modal
                isOpen={isOpenPreview}
                centered={true}
                size="xl"
                toggle={onClosePreviewClick}
                keyboard={true}
                scrollable={true}
            >
                <ModalHeader toggle={isPreviewLoading ? () => { } : onClosePreviewClick}>
                    {t("Template Preview")}
                </ModalHeader>
                <ModalBody className="">
                    <div className="row g-4">
                        <Col md={12}>
                            <div>
                                <div className="row g-3">
                                    <Col xxl={12} className="ribbon-box right">
                                        {!!previewTemplate?.memo?.errors?.length && (
                                            <div className="ribbon ribbon-danger round-shape top-0" style={{
                                                zIndex: 9,
                                                background: 'rgb(255 255 255 / 67%)',
                                                color: '#f06548',
                                                borderRadius: 0,
                                                maxHeight: '30dvh',
                                                overflowY: 'auto',
                                            }}>
                                                <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(previewTemplate?.memo?.errors || [])?.map((mess: any) => (
                                                    <>{`${mess}`}<br /> </>
                                                ))}
                                                </span>
                                            </div>
                                        )}
                                        <div className="bg-primary-subtle position-relative ">
                                            <div className="p-5 card-body">
                                                <div className="text-center">
                                                    <h3 className="fw-semibold">
                                                        {previewTemplate?.title || ""}
                                                    </h3>
                                                </div>

                                            </div>

                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect
                                                                width="1440"
                                                                height="60"
                                                                fill="#ffffff"
                                                            ></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={12}>

                                        <div
                                            className="clear-css-p-in-box"
                                            dangerouslySetInnerHTML={{
                                                __html: previewTemplate?.content || "",
                                            }}
                                        ></div>
                                    </Col>
                                    <div className="col-lg-12">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button
                                                className="btn btn-light fs-14"
                                                type="button"
                                                color="light"
                                                onClick={onClosePreviewClick}
                                            >
                                                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                                {t("Button Close")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
            <ModalConfirm
                textButtonConfirm={t('Button Post')}
                classButtonConfirm='btn-primary'
                classIconButtonConfirm='ri-send-plane-line'
                header={isCopy ? t('Campaign Create') : t('Button Update Campaign')}
                title={t('Are you sure you want to post this?')}
                content={isCopy ? t('After confirming the post action, the data will be created. Do you want to proceed with the confirming action.') : t('After confirming the post action, the data will be updated. Do you want to proceed with the confirming action.')}
                isShowIcon={false}
                isOpen={isOpenConfirmCreate}
                onClose={onCloseConfirmCreateClick}
                onConfirm={handleCreateCampaign}
            />
            <Modal isOpen={isOpenSearching} centered={true} size="xl" className="modal-fullscreen-r" scrollable={true} >
                <ModalHeader toggle={() => setIsOpenSearching((prev) => !prev)}>
                    {t('Ting Search')}
                </ModalHeader>
                <ModalBody>
                    <div style={{ minHeight: 'calc(100dvh - 200px)' }}>
                        <TingSearchKeyword
                            isOpen={isOpenSearching}
                            keywordSearch={initialValueKeyword || ''}
                            handleAddKeyword={handleAddKeywordFromSearching}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default CampaignFormByKeywordAnalyzing;