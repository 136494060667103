import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";

import { Row, Table } from "reactstrap";
import { DefaultColumnFilter } from "./filters";

import { formatNumberWithCommas } from "helpers/format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import TooltipCustom from "./TooltipCustom";
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from "react-router-dom";

interface TableRevenueProps {
  className?: any;
  summary?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string, order_by: string };
  handleChangeSorting?: (sortBy: { sort_by: string | undefined | null, order_by: string | undefined | null }) => void,
  manualSorting?: boolean,
  isLoading?: boolean,
  isShowPagination?: boolean,
  isShowLoadingBottom?: boolean,
  isShowColumn?: boolean,
  classBackgroundWhenActiveSort?: string,
  isScrollToTop?: boolean,
}

const TableRevenue = ({
  summary,
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = '',
  isScrollToTop = true,
}: TableRevenueProps) => {

  const { t } = useTranslation();

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);

  const thRefs = useRef<any>([]); // Fixed Header Table
  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementHeaderFixedRef = useRef<any>(null); // Fixed Header Table
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [rowsShow, setRowsShow] = useState<number[]>([]);

  const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false); // Fixed Header Table

  const { events } = useDraggable(warperTableRef);

  const generateSortingIndicator = (column: string = '') => {
    return (
      column ? (
        <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
          {(column === sorting?.sort_by && sorting?.order_by === 'ASC') && <i className={`ri-arrow-up-line fs-13 text-primary`} />}
          {(column === sorting?.sort_by && sorting?.order_by === 'DESC') && <i className={`ri-arrow-down-line fs-13 text-primary`} />}
        </span>
      ) : (
        ""
      )
    );
  };
  const generateDescription = (description: any) => {
    return (
      description ? (
        <TooltipCustom
          title={description || ''}
          className="d-inline-block vertical-align-middle ms-1"
          style={{ transform: 'translateY(2px)' }}
        >
          <i className="ri-question-line align-bottom text-secondary" ></i>
        </TooltipCustom>
      ) : (<></>)
    );
  };

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft)
    if (warperTableRef.current && elementFixedRef.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
        elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft; // Fixed Header Table
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }

      // Begin::Fixed Header Table
      const rect = warperTableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top;
      if (distanceFromTop <= 70) {
        elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft;
        setIsShowHeaderTableFixed((prev) => true);
      } else {
        setIsShowHeaderTableFixed((prev) => false);
      }
      // End::Fixed Header Table

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleShowMore = (num: number) => {
    if (rowsShow?.includes(num)) {
      setRowsShow((prev) => prev?.filter((item) => item !== num));
    } else {
      setRowsShow((prev) => [...prev, num]);
    }
  }

  return (
    <Fragment>
      <div className={`table-custom-pagination ${divClass}`} {...events} ref={warperTableRef} onScroll={handleScroll}>
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ minHeight: '70px' }} >
          <thead className={theadClass} ref={tableRef}>
            <tr className={trClass}>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ width: '200px' }}
                ref={el => thRefs.current[0] = el} // Fixed Header Table
              >

                {t('Media')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '200px' }}
                ref={el => thRefs.current[1] = el} // Fixed Header Table
              >
                {t('Agency')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '220px' }}
                ref={el => thRefs.current[2] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'system_revenue', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'system_revenue', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'system_revenue' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Newshub Revenue (KRW)')}
                {generateSortingIndicator('system_revenue')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '200px' }}
                ref={el => thRefs.current[3] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'partner_revenue', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'partner_revenue', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'partner_revenue' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Media Revenue (KRW)')}
                {generateSortingIndicator('partner_revenue')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
                ref={el => thRefs.current[4] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'total_request', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'total_request', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_request' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Request')}
                {generateSortingIndicator('total_request')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
                ref={el => thRefs.current[5] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'total_pv', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'total_pv', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_pv' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Impression (PV)')}
                {generateSortingIndicator('total_pv')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
                ref={el => thRefs.current[6] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'total_click', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'total_click', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_click' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('CLICK')}
                {generateSortingIndicator('total_click')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
                ref={el => thRefs.current[7] = el} // Fixed Header Table
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'total_ctr', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'total_ctr', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_ctr' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('CTR')}
                {generateSortingIndicator('total_ctr')}
              </th>
            </tr>
            <tr >
              <th colSpan={8} className="py-0 px-0" style={{ borderBottomColor: 'transparent' }}>
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="text-center" role="cell" colSpan={2}>
                <div className="text-center">{t('Sum')}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_system_revenue || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_partner_revenue || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_request || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_pv || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_click || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_ctr || 0)}%</div>
              </td>
            </tr>
            {data.map((item: any, index: number) => (
              <>
                <tr key={item?.id}>
                  <td className="text-start" role="cell" rowSpan={rowsShow?.includes(item?.id) ? (item?.agency_revenue?.length + 1) : 1} style={{ borderRight: 'var(--vz-card-border-width) solid var(--vz-card-border-color)' }}>
                    <div className="d-flex justify-content-between align-item-center">
                      <div>
                        {item?.partner?.name} <br />
                        {item?.partner?.website && (
                          <Link className='text-normal' to={(String(item?.partner?.website).includes('https://') || String(item?.partner?.website).includes('http://')) ? item?.partner?.website : `https://${item?.partner?.website}`} target="_blank">
                            {item?.partner?.website}
                          </Link>
                        )}
                      </div>
                      <div className="p-2 cursor-pointer" onClick={() => handleShowMore(item?.id)} style={{ fontWeight: 'bold', fontSize: '22px' }}>
                        {rowsShow?.includes(item?.id) ? <i className="bx bx-minus"></i> : <i className="bx bx-plus"></i>}
                      </div>
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="d-flex justify-content-center align-item-center">
                      <div className="ps-2">{t('Sum')}</div>
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div >{formatNumberWithCommas(item?.system_revenue || 0)}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{formatNumberWithCommas(item?.partner_revenue || 0)}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{formatNumberWithCommas(item?.total_request || 0)}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{formatNumberWithCommas(item?.total_pv || 0)}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{formatNumberWithCommas(item?.total_click || 0)}</div>
                  </td>
                  <td className="text-center" role="cell">
                    <div>{formatNumberWithCommas(item?.total_ctr || 0)}%</div>
                  </td>
                </tr>
                {rowsShow?.includes(item?.id) && (<>{item?.agency_revenue?.map((agency: any, i: number) => (
                  <tr key={`${item?.id}-${i}`}>
                    <td className="text-center" role="cell">
                      <div className="text-center d-flex justify-content-center align-item-center">
                        <div>{agency?.agency?.name}</div>
                      </div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(agency?.system_revenue || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(agency?.partner_revenue || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(agency?.total_request || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(agency?.total_pv || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(agency?.total_click || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(agency?.total_ctr || 0)}%</div>
                    </td>
                  </tr>
                ))}
                </>)}
              </>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Begin::Fixed Header Table */}
      <div
        className={`table-card overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
        style={{
          position: 'fixed',
          top: 70,
          zIndex: 9,
          left: elementWarperTable?.left,
          width: elementWarperTable?.width,
          right: 0,

        }}
        ref={elementHeaderFixedRef}
      >
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'} mb-0`} style={{ height: 'auto', tableLayout: 'fixed' }} >
          <thead className={theadClass} style={{ width: elementTable?.width }}><tr className={trClass}>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[0]?.offsetWidth }}
            >

              {t('Media')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[1]?.offsetWidth }}
            >
              {t('Agency')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[2]?.offsetWidth }}
              onClick={(e) => {
                if ((!sorting?.sort_by || !sorting?.order_by)) {
                  const sortBy = { sort_by: 'system_revenue', order_by: 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                } else if (sorting?.sort_by && sorting?.order_by) {
                  const sortBy = { sort_by: 'system_revenue', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'system_revenue' ? 'ASC' : 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                }
              }}
            >
              {t('Newshub Revenue (KRW)')}
              {generateSortingIndicator('system_revenue')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[3]?.offsetWidth }}
              onClick={(e) => {
                if ((!sorting?.sort_by || !sorting?.order_by)) {
                  const sortBy = { sort_by: 'partner_revenue', order_by: 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                } else if (sorting?.sort_by && sorting?.order_by) {
                  const sortBy = { sort_by: 'partner_revenue', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'partner_revenue' ? 'ASC' : 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                }
              }}
            >
              {t('Media Revenue (KRW)')}
              {generateSortingIndicator('partner_revenue')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[4]?.offsetWidth }}
              onClick={(e) => {
                if ((!sorting?.sort_by || !sorting?.order_by)) {
                  const sortBy = { sort_by: 'total_request', order_by: 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                } else if (sorting?.sort_by && sorting?.order_by) {
                  const sortBy = { sort_by: 'total_request', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_request' ? 'ASC' : 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                }
              }}
            >
              {t('Request')}
              {generateSortingIndicator('total_request')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[5]?.offsetWidth }}
              onClick={(e) => {
                if ((!sorting?.sort_by || !sorting?.order_by)) {
                  const sortBy = { sort_by: 'total_pv', order_by: 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                } else if (sorting?.sort_by && sorting?.order_by) {
                  const sortBy = { sort_by: 'total_pv', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_pv' ? 'ASC' : 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                }
              }}
            >
              {t('Impression (PV)')}
              {generateSortingIndicator('total_pv')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[6]?.offsetWidth }}
              onClick={(e) => {
                if ((!sorting?.sort_by || !sorting?.order_by)) {
                  const sortBy = { sort_by: 'total_click', order_by: 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                } else if (sorting?.sort_by && sorting?.order_by) {
                  const sortBy = { sort_by: 'total_click', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_click' ? 'ASC' : 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                }
              }}
            >
              {t('CLICK')}
              {generateSortingIndicator('total_click')}
            </th>
            <th
              className={`text-center cursor-pointer ${thClass || ''}`}
              {...{ width: thRefs.current[7]?.offsetWidth }}
              onClick={(e) => {
                if ((!sorting?.sort_by || !sorting?.order_by)) {
                  const sortBy = { sort_by: 'total_ctr', order_by: 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                } else if (sorting?.sort_by && sorting?.order_by) {
                  const sortBy = { sort_by: 'total_ctr', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'total_ctr' ? 'ASC' : 'DESC' };
                  handleChangeSorting && handleChangeSorting(sortBy);
                }
              }}
            >
              {t('CTR')}
              {generateSortingIndicator('total_ctr')}
            </th>
          </tr>
          </thead>
        </Table>
      </div>
      {/* End::Fixed Header Table */}

      <div
        className="overflow-x-auto color-scrollbar-os"
        style={{
          position: 'fixed',
          zIndex: 999,
          left: elementWarperTable?.left,
          bottom: 0,
          width: elementWarperTable?.width,
          right: 0,
          opacity: isScrollBottom ? 0 : 1,
        }}
        ref={elementFixedRef}
        onScroll={handleScroll}
      >
        <div style={{ width: elementTable?.width, height: '1px' }}></div>
      </div>


      {isShowPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {t('Total')}:
              {" "}
              <span className="fw-semibold ms-1">{formatNumberWithCommas(totalRecords)}</span>
              {" "}
              {t('Results')}
            </div>
          </div>
          <div className="col-sm-auto">
            <ReactPaginate
              nextLabel={`${t('next')} >`}
              onPageChange={(page) => {
                if (handleChangePage) {
                  isScrollToTop && handleScrollToTop();
                  handleChangePage(page.selected);
                }
              }}
              forcePage={+customPageIndex}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={customPageCount}
              previousLabel={`< ${t('previous')}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-separated"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>)}
    </Fragment>
  );
};

TableRevenue.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableRevenue);