import { createAsyncThunk } from "@reduxjs/toolkit";
import loggingApi from 'api/loggingApi';
import { formatQueryParams } from "helpers/format";

export const getUserActivityLogs= createAsyncThunk("Logging/UserActivityLogs" , async (params: any = {}) => {
  try{
    const response = await loggingApi.userActivityLogs(formatQueryParams(params));
    return response;
  }catch (error) {
    return error;
  }
});


export const getUserActivityLogsByNormal =  async (params: any = {}) => {
  try{
    const response = await loggingApi.userActivityLogs(formatQueryParams(params));
    return response;
  }catch (error) {
    return error;
  }
};