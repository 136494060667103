import { createSlice } from "@reduxjs/toolkit";
import { getCampaignGroups, getCampaigns } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICampaign, ICampaignGroup } from "api/types/_campaign";


export interface IState {
  campaigns: ResponseData<ICampaign[]> & PaginationResponse | null,
  isCampaignLoading: boolean,
  isCampaignSuccess: boolean,

  campaignGroups: ResponseData<ICampaignGroup[]> & PaginationResponse | null,
  isCampaignGroupLoading: boolean,
  isCampaignGroupSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  campaigns: null,
  isCampaignLoading: false,
  isCampaignSuccess: false,

  campaignGroups: null,
  isCampaignGroupLoading: false,
  isCampaignGroupSuccess: false,

  error: {},
};

const CampaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get campaigns
    builder.addCase(getCampaigns.pending, (state: IState, action: any) => {
      state.isCampaignLoading = true
    });
    builder.addCase(getCampaigns.fulfilled, (state: IState, action: any) => {
      state.campaigns = action.payload.data;
      state.isCampaignSuccess = true;
      state.isCampaignLoading = false;
    });
    builder.addCase(getCampaigns.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCampaignSuccess = false;
      state.isCampaignLoading = false;
    });

    //get campaign groups
    builder.addCase(getCampaignGroups.pending, (state: IState, action: any) => {
      state.isCampaignGroupLoading = true
    });
    builder.addCase(getCampaignGroups.fulfilled, (state: IState, action: any) => {
      state.campaignGroups = action.payload.data;
      state.isCampaignGroupSuccess = true;
      state.isCampaignGroupLoading = false;
    });
    builder.addCase(getCampaignGroups.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCampaignGroupSuccess = false;
      state.isCampaignGroupLoading = false;
    });
  },
});

export default CampaignSlice.reducer;