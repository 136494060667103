import DatePickerCustom from 'components/Common/DatePickerCustom';
import LabelWrapper from 'components/Common/LabelWrapper';
import TableLotto from 'components/Common/TableLotto';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getLottos as onGetLottos } from "../../../store/thunks";
// import ChartLine from './ChartLine';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'session';
const ORDER_BY_DEFAULT: string = 'DESC';

const LottoSchedule = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 31),
    session: withDefault(StringParam, ''),
    date_from: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")),
    date_to: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    ...typeQuery
  });

  const [sessionSearch, setSessionSearch] = useState<string>(query?.session || "");

  const [dateSearch, setDateSearch] = useState<any[]>([query?.date_from ? moment(query?.date_from, 'Y-MM-DD').toDate() : null, query?.date_to ? moment(query?.date_to || '', 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Lotto;

  const LottoProperties = createSelector(
    selectLayoutState,
    (state) => ({
      lottos: state.lottos,
      isLottoSuccess: state.isLottoSuccess,
      isLottoLoading: state.isLottoLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { lottos, error, isLottoLoading } = useSelector(LottoProperties);

  useEffect(() => {
    dispatch(onGetLottos(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      session: sessionSearch || '',
      date_from: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      date_to: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      session: '',
      date_from: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
      date_to: moment(new Date()).format("Y-MM-DD"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setSessionSearch('');
    setDateSearch([moment(queryNew?.date_from || "", 'Y-MM-DD').toDate(), moment(queryNew?.date_to || "", 'Y-MM-DD').toDate()]);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  useEffect(() => {
    document.title = `${t('Lotto Result')} - ${t('Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Lotto Result')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.LOTTO_SCHEDULE_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isLottoLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={lottos?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-end mb-2 mb-md-0 mt-2">
                          <Col sm={12} lg={2} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Session Lotto')} isShow={!!sessionSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Session Lotto')}...`}
                                value={sessionSearch}
                                onChange={(e) => setSessionSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isLottoLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableLotto
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        summary={lottos?.summary}
                        data={lottos?.list?.length ? lottos?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={lottos?.pagination?.total}
                        customPageCount={Math.ceil(Number(lottos?.pagination?.total) / Number(lottos?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isLottoLoading}
                        isShowPagination={true}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LottoSchedule;
