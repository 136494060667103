import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IKeyword } from "api/types/_keyword";
import { getSearchKeywords } from "./thunk";



export interface IState {
  keywords: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordLoading: boolean,
  isKeywordSuccess: boolean,

  allKeyword: ResponseData<IKeyword[]> | null,

  error: any,
};

export const initialState: IState = {
  keywords: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,

  allKeyword: null,

  error: {},
};

const KeywordSlice = createSlice({
  name: "Keyword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Keywords
    builder.addCase(getSearchKeywords.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(getSearchKeywords.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(getSearchKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });
  },
});

export default KeywordSlice.reducer;