import { createSlice } from "@reduxjs/toolkit";
import { getCampaignStatistics, getCategoryStatistics, getFlowedKeywordAnalysis, getKeywordAnalysis, getKeywordInflows, getKeywordStatistics, getMediaRankingStatistics, getMediaRevenueStatistics, getNewsStatistics, getRevenueStatistics, getVisitorStatistics, getWebsiteStatistics } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICampaignStatistic, ICategoryStatistic, IFlowedKeywordAnalysis, IKeywordAnalysis, IKeywordInflow, IKeywordStatistic, IMediaRanking, INewsLog, INewsStatistic, IRevenueStatistic, IVisitorStatistic, IWebsiteLog, IWebsiteStatistic } from "api/types/_statistic";


export interface IState {
  newsStatistics: ResponseData<INewsStatistic[]> & PaginationResponse | null,
  isNewsStatisticLoading: boolean,
  isNewsStatisticSuccess: boolean,


  websiteStatistics: ResponseData<IWebsiteStatistic[]> & PaginationResponse | null,
  isWebsiteStatisticLoading: boolean,
  isWebsiteStatisticSuccess: boolean,

  keywordStatistics: ResponseData<IKeywordStatistic[]> & PaginationResponse | null,
  isKeywordStatisticLoading: boolean,
  isKeywordStatisticSuccess: boolean,

  campaignStatistics: ResponseData<ICampaignStatistic[]> & PaginationResponse | null,
  isCampaignStatisticLoading: boolean,
  isCampaignStatisticSuccess: boolean,

  categoryStatistics: ResponseData<ICategoryStatistic[]> & PaginationResponse | null,
  isCategoryStatisticLoading: boolean,
  isCategoryStatisticSuccess: boolean,

  keywordInflows: ResponseData<IKeywordInflow[]> & PaginationResponse | null,
  isKeywordInflowLoading: boolean,
  isKeywordInflowSuccess: boolean,

  keywordAnalysis: ResponseData<IKeywordAnalysis[]> & PaginationResponse | null,
  isKeywordAnalysisLoading: boolean,
  isKeywordAnalysisSuccess: boolean,

  flowedKeywordAnalysis: ResponseData<IFlowedKeywordAnalysis[]> & PaginationResponse | null,
  isFlowedKeywordAnalysisLoading: boolean,
  isFlowedKeywordAnalysisSuccess: boolean,

  visitorStatistics: ResponseData<IVisitorStatistic[]> & PaginationResponse | null,
  isVisitorStatisticLoading: boolean,
  isVisitorStatisticSuccess: boolean,

  revenueStatistics: ResponseData<IRevenueStatistic[]> & PaginationResponse | null,
  isRevenueStatisticLoading: boolean,
  isRevenueStatisticSuccess: boolean,

  mediaRevenueStatistics: ResponseData<IRevenueStatistic[]> & PaginationResponse | null,
  isMediaRevenueStatisticLoading: boolean,
  isMediaRevenueStatisticSuccess: boolean,

  mediaRankingStatistics: ResponseData<IMediaRanking[]> & PaginationResponse | null,
  isMediaRankingStatisticLoading: boolean,
  isMediaRankingStatisticSuccess: boolean,


  error: any,
};

export const initialState: IState = {
  newsStatistics: null,
  isNewsStatisticLoading: false,
  isNewsStatisticSuccess: false,

  websiteStatistics: null,
  isWebsiteStatisticLoading: false,
  isWebsiteStatisticSuccess: false,

  keywordStatistics: null,
  isKeywordStatisticLoading: false,
  isKeywordStatisticSuccess: false,

  campaignStatistics: null,
  isCampaignStatisticLoading: false,
  isCampaignStatisticSuccess: false,

  categoryStatistics: null,
  isCategoryStatisticLoading: false,
  isCategoryStatisticSuccess: false,

  keywordInflows: null,
  isKeywordInflowLoading: false,
  isKeywordInflowSuccess: false,

  keywordAnalysis: null,
  isKeywordAnalysisLoading: false,
  isKeywordAnalysisSuccess: false,

  flowedKeywordAnalysis: null,
  isFlowedKeywordAnalysisLoading: false,
  isFlowedKeywordAnalysisSuccess: false,

  visitorStatistics: null,
  isVisitorStatisticLoading: false,
  isVisitorStatisticSuccess: false,

  revenueStatistics: null,
  isRevenueStatisticLoading: false,
  isRevenueStatisticSuccess: false,

  mediaRevenueStatistics: null,
  isMediaRevenueStatisticLoading: false,
  isMediaRevenueStatisticSuccess: false,

  mediaRankingStatistics: null,
  isMediaRankingStatisticLoading: false,
  isMediaRankingStatisticSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {
    changeStatusCampaignsAction(state: any, action : any) {
      state.campaignStatistics = action.payload;
    },
    changeStatusFlowedKeywordAnalysisAction(state: any, action : any) {
      state.flowedKeywordAnalysis = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get NewsStatistics
    builder.addCase(getNewsStatistics.pending, (state: IState, action: any) => {
      state.isNewsStatisticLoading = true
    });
    builder.addCase(getNewsStatistics.fulfilled, (state: IState, action: any) => {
      state.newsStatistics = action.payload.data;
      state.isNewsStatisticSuccess = true;
      state.isNewsStatisticLoading = false;
    });
    builder.addCase(getNewsStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNewsStatisticSuccess = false;
      state.isNewsStatisticLoading = false;
    });

    //get WebsiteStatistics
    builder.addCase(getWebsiteStatistics.pending, (state: IState, action: any) => {
      state.isWebsiteStatisticLoading = true
    });
    builder.addCase(getWebsiteStatistics.fulfilled, (state: IState, action: any) => {
      state.websiteStatistics = action.payload.data;
      state.isWebsiteStatisticSuccess = true;
      state.isWebsiteStatisticLoading = false;
    });
    builder.addCase(getWebsiteStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isWebsiteStatisticSuccess = false;
      state.isWebsiteStatisticLoading = false;
    });

    //get KeywordInflows
    builder.addCase(getKeywordInflows.pending, (state: IState, action: any) => {
      state.isKeywordInflowLoading = true
    });
    builder.addCase(getKeywordInflows.fulfilled, (state: IState, action: any) => {
      state.keywordInflows = action.payload.data;
      state.isKeywordInflowSuccess = true;
      state.isKeywordInflowLoading = false;
    });
    builder.addCase(getKeywordInflows.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordInflowSuccess = false;
      state.isKeywordInflowLoading = false;
    });

    //get KeywordStatistics
    builder.addCase(getKeywordStatistics.pending, (state: IState, action: any) => {
      state.isKeywordStatisticLoading = true
    });
    builder.addCase(getKeywordStatistics.fulfilled, (state: IState, action: any) => {
      state.keywordStatistics = action.payload.data;
      state.isKeywordStatisticSuccess = true;
      state.isKeywordStatisticLoading = false;
    });
    builder.addCase(getKeywordStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordStatisticSuccess = false;
      state.isKeywordStatisticLoading = false;
    });

    //get KeywordAnalysis
    builder.addCase(getKeywordAnalysis.pending, (state: IState, action: any) => {
      state.isKeywordAnalysisLoading = true
    });
    builder.addCase(getKeywordAnalysis.fulfilled, (state: IState, action: any) => {
      state.keywordAnalysis = action.payload.data;
      state.isKeywordAnalysisSuccess = true;
      state.isKeywordAnalysisLoading = false;
    });
    builder.addCase(getKeywordAnalysis.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordAnalysisSuccess = false;
      state.isKeywordAnalysisLoading = false;
    });

    //get FlowedKeywordAnalysis
    builder.addCase(getFlowedKeywordAnalysis.pending, (state: IState, action: any) => {
      state.isFlowedKeywordAnalysisLoading = true
    });
    builder.addCase(getFlowedKeywordAnalysis.fulfilled, (state: IState, action: any) => {
      state.flowedKeywordAnalysis = action.payload.data;
      state.isFlowedKeywordAnalysisSuccess = true;
      state.isFlowedKeywordAnalysisLoading = false;
    });
    builder.addCase(getFlowedKeywordAnalysis.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isFlowedKeywordAnalysisSuccess = false;
      state.isFlowedKeywordAnalysisLoading = false;
    });

    //get CategoryStatistics
    builder.addCase(getCategoryStatistics.pending, (state: IState, action: any) => {
      state.isCategoryStatisticLoading = true
    });
    builder.addCase(getCategoryStatistics.fulfilled, (state: IState, action: any) => {
      state.categoryStatistics = action.payload.data;
      state.isCategoryStatisticSuccess = true;
      state.isCategoryStatisticLoading = false;
    });
    builder.addCase(getCategoryStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategoryStatisticSuccess = false;
      state.isCategoryStatisticLoading = false;
    });

    //get CampaignStatistics
    builder.addCase(getCampaignStatistics.pending, (state: IState, action: any) => {
      state.isCampaignStatisticLoading = true
    });
    builder.addCase(getCampaignStatistics.fulfilled, (state: IState, action: any) => {
      state.campaignStatistics = action.payload.data;
      state.isCampaignStatisticSuccess = true;
      state.isCampaignStatisticLoading = false;
    });
    builder.addCase(getCampaignStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCampaignStatisticSuccess = false;
      state.isCampaignStatisticLoading = false;
    });

     //get Visitor
     builder.addCase(getVisitorStatistics.pending, (state: IState, action: any) => {
      state.isVisitorStatisticLoading = true
    });
    builder.addCase(getVisitorStatistics.fulfilled, (state: IState, action: any) => {
      state.visitorStatistics = action.payload.data;
      state.isVisitorStatisticSuccess = true;
      state.isVisitorStatisticLoading = false;
    });
    builder.addCase(getVisitorStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isVisitorStatisticSuccess = false;
      state.isVisitorStatisticLoading = false;
    });


    //get Revenue
    builder.addCase(getRevenueStatistics.pending, (state: IState, action: any) => {
      state.isRevenueStatisticLoading = true
     });
    builder.addCase(getRevenueStatistics.fulfilled, (state: IState, action: any) => {
      state.revenueStatistics = action.payload.data;
      state.isRevenueStatisticSuccess = true;
      state.isRevenueStatisticLoading = false;
     });
    builder.addCase(getRevenueStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRevenueStatisticSuccess = false;
      state.isRevenueStatisticLoading = false;
     });


   //get MediaRevenue
    builder.addCase(getMediaRevenueStatistics.pending, (state: IState, action: any) => {
      state.isMediaRevenueStatisticLoading = true
     });
    builder.addCase(getMediaRevenueStatistics.fulfilled, (state: IState, action: any) => {
      state.mediaRevenueStatistics = action.payload.data;
      state.isMediaRevenueStatisticSuccess = true;
      state.isMediaRevenueStatisticLoading = false;
     });
    builder.addCase(getMediaRevenueStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaRevenueStatisticSuccess = false;
      state.isMediaRevenueStatisticLoading = false;
     });

    //get MediaRanking
    builder.addCase(getMediaRankingStatistics.pending, (state: IState, action: any) => {
      state.isMediaRankingStatisticLoading = true
     });
    builder.addCase(getMediaRankingStatistics.fulfilled, (state: IState, action: any) => {
      state.mediaRankingStatistics = action.payload.data;
      state.isMediaRankingStatisticSuccess = true;
      state.isMediaRankingStatisticLoading = false;
     });
    builder.addCase(getMediaRankingStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaRankingStatisticSuccess = false;
      state.isMediaRankingStatisticLoading = false;
     });
  },
});

export const {
  changeStatusCampaignsAction, changeStatusFlowedKeywordAnalysisAction
} = StatisticSlice.actions;

export default StatisticSlice.reducer;