import { IGraphic } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteGraphic, getAllParameters as onGetAllParameters, getGraphics as onGetGraphics, postGraphic, putGraphic } from "../../../store/thunks";

import ModalConfirm from 'components/Common/ModalConfirm';
import { useRole } from 'components/Hooks/UserHooks';
import { SCHEDULE_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';

interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'id': StringParam,
  'schedule': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';
registerLocale('en', en);
registerLocale('ko', ko);

const TemplateImage = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.reduce((arr: any, item: any) => {
    return item?.value !== 'now' ? ([...arr, ({ value: item?.value, label: t(item?.label) })]) : arr;
  }, []);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    name: StringParam,
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFullView, setIsFullView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [initialValuesDefault, setInitialValuesDefault] = useState<IGraphic | null>(null);

  const [linkImage, setLinkImage] = useState<string>('');

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => {
    console.log('state', state);
    return state.Template
  };

  const GraphicProperties = createSelector(
    selectLayoutState,
    (state) => ({
      graphics: state.graphics,
      parameters: state.allParameter,
      isGraphicSuccess: state.isGraphicSuccess,
      isGraphicLoading: state.isGraphicLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { graphics, parameters, error, isGraphicLoading } = useSelector(GraphicProperties);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      name: '',
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
  };

  // Form
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        name: values?.name ?? '',
        name_korea: values?.name_korea ?? '',
      };
      const response: any = !!(initialValuesDefault?.id) ? await putGraphic(initialValuesDefault?.id, data) : await postGraphic(data);
      if (response?.data) {
        dispatch(onGetGraphics(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleResetForm();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t('Info-Graphic Name is required')}`),
    name_korea: Yup.string().required(`${t('Info-Graphic Name Korea is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      name_korea: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEditTemplate = (valueDefault: IGraphic) => {
    setInitialValuesDefault((_prev) => valueDefault);
    setIsOpen((_prev) => true);
    formik.setFieldValue('name', valueDefault?.name);
    formik.setFieldValue('name_korea', valueDefault?.name_korea);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleResetForm = () => {
    formik.setFieldValue('name', null);
    formik.setFieldValue('name_korea', null);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleCreateTemplate = () => {
    setInitialValuesDefault((_prev) => null);
    setIsOpen((_prev) => true);
    handleResetForm();
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IGraphic) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteGraphic(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetGraphics(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen((_prev) => false);
    setInitialValuesDefault((_prev) => null);
    setTimeout(() => {
      formik.setFieldValue('name', null);
      formik.setFieldValue('name_korea', null);
    }, 300);
  };


  const onCloseImageClick = (e: any) => {
    setLinkImage((_prev) => '');
  };

  // Effect
  useEffect(() => {
    dispatch(onGetGraphics(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(onGetAllParameters({}));
  }, []);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Image'),
        accessor: "image_url",
        thClass: 'text-center',
        filterable: true,
        sortable: false,
        thWidth: 140,
        Cell: (cell: any) => (
          <>
            <div className="w-100 text-center ">
              <div style={{ width: '90px' }} className="m-auto cursor-pointer" onClick={() => setLinkImage((prev) => cell?.value)}>
                <img src={cell?.value} className="flex-shrink-0 rounded w-100" alt="chart" />
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="limit-text-to-two-line cursor-pointer">{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Name Korea'),
        accessor: "name_korea",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="limit-text-to-two-line cursor-pointer">{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 200,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end">
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 200,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end">
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      // {
      //   Header: t('Button Action'),
      //   thClass: 'text-center',
      //   thWidth: 105,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul className="list-inline hstack gap-1 mb-0" style={{ width: '80px' }}>
      //         {isHavePermissionRole(ROLES_FOR_APP.INFO_GRAPHIC_UPDATE, userPermissions) && (
      //           <TooltipCustom
      //             title={t('Button Update Info-Graphic')}
      //             id={`update-tpl-til-${item?.id}`}
      //           >
      //             <li className="list-inline-item" id={`update-tpl-til-${item?.id}`}>
      //               <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
      //                 onClick={(e) => { e.preventDefault(); handleEditTemplate(item) }}
      //               >
      //                 <i className="ri-pencil-fill align-bottom"></i>
      //               </Link>
      //             </li>
      //           </TooltipCustom>)}
      //         {isHavePermissionRole(ROLES_FOR_APP.INFO_GRAPHIC_DELETE, userPermissions) && (
      //           <TooltipCustom
      //             title={t('Button Delete Info-Graphic')}
      //             id={`delete-tpl-til-${item?.id}`}
      //           >
      //             <li className=" list-inline-item" id={`delete-tpl-til-${item?.id}`}>
      //               <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
      //                 onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
      //               >
      //                 <i className="ri-delete-bin-3-fill align-bottom"></i>
      //               </Link>
      //             </li>
      //           </TooltipCustom>)}
      //       </ul>
      //     );
      //   },
      // },
    ],
    [i18n?.language]
  );


  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  useEffect(() => {
    document.title = `${t('Image')} - ${t('Template')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Image')} pageTitle={t('Template')} />
          <Row>
            <div className='text-center mt-5'>
              {t('Under development')}
            </div>
          </Row>
        </Container>

      </div>
    </React.Fragment>
  );
};

export default TemplateImage;