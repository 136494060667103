import { Option } from 'api/types/_public';
import { ICategorySport, ISport } from 'api/types/_sport';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownCategoryMultiSub from 'components/Common/DropdownCategoryMultiSub';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getAllCategoriesSportNormal, getAllSports, getGames as onGetGames } from "../../../store/thunks";
import CollapseTag from 'components/Common/CollapseTag';
import { formatNumberWithCommas } from 'helpers/format';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownOption from 'components/Common/DropdownOption';
import { ARR_INDEX_TEXT_DAY_OF_WEEK, STATUS_GAME_CREATE_NEWS_OPTIONS } from 'helpers/constans';
import CountUp from 'react-countup';
import LabelWrapper from 'components/Common/LabelWrapper';

const typeQuery = {
  'acml_vol': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'datetime';
registerLocale('en', en);
registerLocale('ko', ko);

const TODAY_DATE_DEFAULT = `${moment().format("Y-MM-DD")}`;
const STATUS_CHANGE_SPORT_OPTIONS: Option[] = [];
const TYPE_SPORT_OPTIONS: Option[] = [];
const SportSchedule = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const TYPE_SPORT_OPTIONS_LANG = TYPE_SPORT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_CHANGE_SPORT_OPTIONS_LANG = STATUS_CHANGE_SPORT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const COLORS_SPORT_STATUS_CHANGE = ['warning', 'warning', 'danger', 'secondary', 'success', 'primary'];

  const STATUS_GAME_CREATE_NEWS_OPTIONS_LANG = STATUS_GAME_CREATE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: withDefault(NumberParam, null),
    q: withDefault(StringParam, ''),
    datetime_from: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    datetime_to: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    status: withDefault(StringParam, ''),
    ...typeQuery
  });

  const rangeValue = useRef<any>();

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.datetime_from || "", 'Y-MM-DD').toDate(), moment(query?.datetime_to || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;


  const [codeSearch, setCodeSearch] = useState<string>(query?.q || "");


  const [sportCodeSearch, setSportCodeSearch] = useState<Option | null>(null);

  const [listCategoriesSport, setListCategoriesSport] = useState<ICategorySport[]>([]);

  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_GAME_CREATE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Sport;

  const GameProperties = createSelector(
    selectLayoutState,
    (state) => ({
      games: state.games,
      isGamesSuccess: state.isGamesSuccess,
      isGamesLoading: state.isGamesLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { games, isGamesLoading } = useSelector(GameProperties);

  useEffect(() => {
    dispatch(onGetGames(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      sort_by: TYPE_SELECT_DEFAULT,
      category_id: categorySearch?.value,
      q: codeSearch,
      datetime_from: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      datetime_to: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      status: statusSearch?.value ?? '',
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      sport_code: undefined,
      category_id: undefined,
      q: '',
      datetime_from: moment(new Date()).format("Y-MM-DD"),
      datetime_to: moment(new Date()).format("Y-MM-DD"),
      status: '',
      time_request: + new Date()
    }, "push")
    setCodeSearch("");
    setCategorySearch(null);
    setStatusSearch(null);
    setDateSearch([moment(moment(new Date()).format("Y-MM-DD") || "", 'Y-MM-DD').toDate(), moment(moment(new Date()).format("Y-MM-DD") || "", 'Y-MM-DD').toDate()]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "datetime",
        filterable: false,
        sortable: true,
        thWidth: 120,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
          const classText = num === 0 ? 'text-danger' : (num === 6 ? 'text-primary' : 'text-secondary');
          const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
          return (
            <div className="text-start" style={{ minWidth: '150px' }}>
              <span>
                <span className="text-dark mb-0" style={{ fontSize: '30px', lineHeight: '20px' }}>{item?.time || ''}</span>
                <br />
                <span className={`fs-11 ${classText}`}>{moment(cell?.value || '', 'Y-MM-DD').format("Y-MM-DD")} ({textNote})</span>
              </span>
            </div>
          )
        },
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            if (category?.depth > 1) {
              let subs = [];
              for (let sub of category?.group_categories) {
                subs.push(sub?.name);
              }
              subs.push(category?.name);
              categoryName.push(subs.join(` > `));
            }
          }

          return (<CollapseTag style={{ minWidth: '200px' }} tags={categoryName} isRank={false} isInlineBlock={true} totalShow={2} isBackground={false} isShowModal={true} titleModal={t('Categories')} />);
        },
      },
      {
        Header: t('Game Type'),
        accessor: "game_type",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>{t(`Game_Type_${cell?.value || ''}`)}</div>
            </>
          )
        },
      },
      {
        Header: t('Stadium'),
        accessor: "stadium",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center text-primary" style={{ minWidth: '150px' }}>{cell?.value || ''}</div>
            </>
          )
        },
      },
      {
        Header: t('Game'),
        accessor: "homeTeam",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        // thWidth: 400,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="row justify-content-center align-items-center text-center" style={{ minWidth: '450px' }}>
                <div className="col px-0 d-flex align-items-center justify-content-end">
                  <span className="me-2" style={{ padding: '0px 3px', fontSize: '10px', borderRadius: '3px', backgroundColor: 'rgba(48,48,56,0.4)', color: '#fff' }}>{t('Team Home')}</span>
                  <div className="bold">
                    {item?.homeTeam?.name}
                  </div>
                  <div className="ms-2">
                    <img width="32" height="32" alt={item?.homeTeam?.name} loading="lazy" src={item?.homeTeam?.logo_url || ''} />
                  </div>
                  <div className={`h3 mb-0 text-center ${(item?.winner === "HOME" || item?.winner === "DRAW") ? 'text-dark' : 'text-secondary'}`} style={{ minWidth: '60px' }}>
                    {item?.status_code === "RESULT" ? item?.home_team_score : '-'}
                  </div>
                </div>
                <div className={`px-0 fs-12 ${item?.status_code === "RESULT" ? 'text-secondary' : 'text-primary'}`} style={{ width: '50px' }}>
                  {t('Game Planned')}
                </div>
                <div className="col px-0 d-flex align-items-center justify-content-start">
                  <div className={`h3 mb-0 text-center ${(item?.winner === "AWAY" || item?.winner === "DRAW") ? 'text-dark' : 'text-secondary'}`} style={{ minWidth: '60px' }} >
                    {item?.status_code === "RESULT" ? item?.away_team_score : '-'}
                  </div>
                  <div className="me-2">
                    <img width="32" height="32" alt={item?.awayTeam?.name} loading="lazy" src={item?.awayTeam?.logo_url || ''} />
                  </div>
                  <div className="bold">
                    {item?.awayTeam?.name}
                  </div>
                </div>
              </div >
            </>
          )
        },
      },
      {
        Header: t('Match Round'),
        accessor: "match_round",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center" style={{ minWidth: '100px' }}>{cell?.value || 0}</div>
            </>
          )
        },
      },
      {
        Header: t('Game Id'),
        accessor: "game_id",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">{cell?.value || ''}</div>
            </>
          )
        },
      },
      {
        Header: t('Status'),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <div className="text-center">
              <div className={`text-center badge ${!!cell?.value ? 'bg-success' : 'bg-danger'}`}>{t(`Status_Game_Created_News_${cell?.value}`)}</div>
            </div>
          )
        },
      },
      // {
      //   Header: t('Created at'),
      //   accessor: "created_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end">
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: t('Updated at'),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end">
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, TYPE_SPORT_OPTIONS_LANG, STATUS_CHANGE_SPORT_OPTIONS_LANG, COLORS_SPORT_STATUS_CHANGE]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resCategoriesSport]: any = await Promise.all([getAllCategoriesSportNormal()]);
      setListCategoriesSport((_prev) => resCategoriesSport?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!sportCodeSearch?.value) {
      setSportCodeSearch((prev) => null);
    }

    if (statusSearch) {
      setStatusSearch((_prev: any) =>
        STATUS_GAME_CREATE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === statusSearch?.value)[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Sports Schedule')} - ${t('Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Sports Schedule')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.SPORT_SCHEDULE_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isGamesLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={games?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0 mb-md-2  mt-2">
                          <Col sm={6} lg={3} className="mt-2 mb-2 mb-sm-0">
                            <LabelWrapper label={t('Category')} isShow={!!categorySearch?.value}>
                              <DropdownCategoryMultiSub
                                dataList={listCategoriesSport}
                                initialValue={categorySearch || undefined}
                                onChangeCategory={(event: any) => {
                                  setCategorySearch(event);
                                }}
                                isClearable={true}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} lg={3} className="mt-2 mb-0 mb-sm-0 date-picker-wrapper-custom ">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={6} lg={3} className='mt-2 mb-2 mb-sm-0'>
                            <LabelWrapper label={t('Search by game code or team code')} isShow={!!codeSearch}>
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t('Search by game code or team code')}...`}
                                  value={codeSearch}
                                  onChange={e => setCodeSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} lg={3} className='mt-2 mb-2 mb-sm-0'>
                            <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={STATUS_GAME_CREATE_NEWS_OPTIONS_LANG}
                                placeholder={`${t("Status")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={statusSearch || null}
                                onChangeSelect={(e: any) => setStatusSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Status'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isGamesLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={games?.list?.length ? games?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(Number(games?.pagination?.total) / Number(games?.pagination?.limit))}
                        customPageIndex={query.page - 1}
                        totalRecords={games?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isGamesLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default SportSchedule;
