import { ICategory } from "api/types/_category";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import SimpleBar from "simplebar-react";

interface Option {
  label: string;
  value: string;
  depth?: number | null;
}

interface DropdownCategoryKeywordProps {
  name?: string;
  initialValue?: Option | null | undefined;
  categoryType?: string;
  dataListNaver?: ICategory[];
  dataListStock?: ICategory[];
  dataListCar?: ICategory[];
  dataListCoin?: ICategory[];
  dataListTrendIndex?: ICategory[];
  onChangeCategory?: (params: any) => void;
  filterByTopics?: string[];
  disabled?: boolean;
  placeholder?: string;
  isClearable?: boolean;
}

const DropdownCategoryKeyword = ({
  name = "",
  initialValue,
  onChangeCategory,
  categoryType = "default",
  filterByTopics = [],
  dataListNaver = [],
  dataListStock = [],
  dataListCar = [],
  dataListCoin = [],
  dataListTrendIndex = [],
  disabled = false,
  placeholder = "",
  isClearable = false
}: DropdownCategoryKeywordProps) => {

  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [keywordSearch, setKeywordSearch] = useState<string | null>("");
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [sub1, setSub1] = useState<string>("");
  const [sub2, setSub2] = useState<string>("");
  const [sub3, setSub3] = useState<string>("");

  const onChangeValueCategory = useCallback(
    (sub1: any = {}, sub2: any = {}, sub3: any = {}) => {
      let subs = [];
      let keyword_count: string = "";
      let value: string | number = "";
      let depth: number | null = null;
      subs.push(sub1?.name);
      setSub1((_prev) => String(sub1?.category_id));
      keyword_count = sub1?.keyword_count;
      value = sub1?.category_id;
      depth = sub1?.depth;
      if (sub2?.category_id) {
        subs.push(sub2?.name);
        setSub2((_prev) => String(sub2?.category_id));
        keyword_count = sub2?.keyword_count;
        value = sub2?.category_id;
        depth = sub2?.depth;
      }
      if (sub3?.category_id) {
        subs.push(sub3?.name);
        setSub3((_prev) => String(sub3?.category_id));
        keyword_count = sub3?.keyword_count;
        value = sub3?.category_id;
        depth = sub3?.depth;
      }
      onChangeCategory &&
        onChangeCategory({
          label: `${subs.join(" > ")}  (${keyword_count})`,
          value: String(value),
          depth: depth,
        });
      setKeywordSearch((_prev) => "");
    },
    [onChangeCategory]
  );

  const handleClearable = () => {
    setSub1((prev) => "");
    setSub2((prev) => "");
    setSub3((prev) => "");
    onChangeCategory && onChangeCategory(null);
  }

  const checkIncludeItem = (key: string | null, item: any): boolean => {
    if (String(item?.name).toUpperCase().includes(String(key).toUpperCase())) {
      return true;
    }
    if (item?.subs?.length > 0) {
      return (
        item?.subs?.filter((sub: any) => checkIncludeItem(key, sub))?.length > 0
      );
    }
    return false;
  };

  const checkIncludeItemById = (
    itemId: string | number | null,
    item: any
  ): boolean => {
    if (String(item?.category_id) === String(itemId)) {
      return true;
    }
    if (item?.subs?.length > 0) {
      return (
        item?.subs?.filter((sub: any) => checkIncludeItemById(itemId, sub))
          ?.length > 0
      );
    }
    return false;
  };

  const getOptionCategoryById = (
    itemId: string | number | null,
    arr: any,
    result: any = []
  ): any => {
    const cate =
      arr?.filter((sub: any) => checkIncludeItemById(itemId, sub))[0] || {};
    if (cate?.subs?.length > 0) {
      return getOptionCategoryById(itemId, cate?.subs, [...result, cate]);
    }
    return [...result, cate];
  };

  useEffect(() => {
    setCategories((_prev: any) => {
      if (categoryType === "trend_index") {
        return dataListTrendIndex;
      }
      if (categoryType === "stock") {
        return dataListStock;
      }
      if (categoryType === "car") {
        return dataListCar;
      }
      if (categoryType === "coin") {
        return dataListCoin;
      }
      return dataListNaver || [];
    }
    );
  }, [categoryType, JSON.stringify(dataListTrendIndex), JSON.stringify(dataListStock),
    JSON.stringify(dataListCar), JSON.stringify(dataListCoin), JSON.stringify(dataListNaver)]);

  useEffect(() => {
    if (initialValue && initialValue?.label === "" && initialValue?.value) {
      const val = getOptionCategoryById(initialValue?.value, categories) || [];
      if (val.length <= 0 || (val.length === 1 && !val[0].id)) {
        return;
      }
      onChangeValueCategory(val[0], val[1], val[2]);
    }
  }, [initialValue, categories]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isOpen || !!keywordSearch}
        toggle={() => {
          if (isOpen) {
            setKeywordSearch((_prev) => "");
          }
          setOpen((_prev) => !isOpen);
        }}
      >
        <div className="keyword-search">
          <DropdownToggle tag="div" className="position-relative">
            <Input
              value={keywordSearch || ""}
              type="text"
              onChange={(e: any) => setKeywordSearch(e.target.value)}
              placeholder=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "var(--vz-border-radius)",
                zIndex: 2,
                background: "transparent",
                padding: "2px 10px",
              }}
              disabled={disabled}
            />
            {(isClearable && !!initialValue?.value && !disabled) && (
              <span style={{
                position: 'absolute',
                top: 0,
                right: 38,
                bottom: 0,
                borderRadius: 'var(--vz-border-radius)',
                zIndex: 3,
                background: 'transparent',
                transition: 'color 150ms',
                padding: '8px',
                boxSizing: 'border-box',
              }}
                className="btn-clearable-value"
                onClick={(e) => {
                  e.preventDefault();
                  handleClearable()
                }
                }
              >
                <svg height="20" width="20" viewBox="0 0 20 20" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
              </span>)}
            <Select
              name={name}
              options={[]}
              value={keywordSearch ? null : initialValue}
              placeholder={
                <div>
                  {keywordSearch ? "" : placeholder || t("Select category")}
                </div>
              }
              components={{ Menu: () => <></> }}
              isDisabled={disabled}
            />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu  py-0"
            style={{ width: "auto", minWidth: '250px', maxWidth: "300px", }}>
            {categories && categories?.length ? (
              categories.map((item: any, index: number) => (
                <div
                  className={`keyword-wrapper ${checkIncludeItem(keywordSearch, item) ? "" : "d-none"
                    }`}
                  key={`${item.category_id}`}
                >
                  <div
                    key={`${item.category_id}`}
                    className={`${!!keywordSearch && "result-search-menu"}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChangeValueCategory(item);
                    }}
                  >
                    <DropdownItem
                      tag="div"
                      className="flex-between-center keyword-item cursor-pointer"
                      active={sub1 === item.category_id}
                    >
                      <div className="d-flex">
                        {item.name}{" "}
                        <div className="drop ms-2">{item.keyword_count}</div>
                      </div>
                      {item?.subs && item?.subs?.length > 0 && (
                        <i className="ri-arrow-right-line align-middle fs-18 text-muted" />
                      )}
                    </DropdownItem>
                  </div>

                  {item?.subs && item?.subs?.length > 0 && (
                    <DropdownMenu
                      className={`dropdown-submenu py-0 `}
                      style={{ width: "auto", minWidth: '250px', maxWidth: "300px", }}
                    >
                      {item?.subs?.map((sub: any, ii: number) => (
                        <div
                          className={`keyword-wrapper__child ${checkIncludeItem(keywordSearch, sub) ? "" : "d-none"
                            }`}
                          key={`${sub.category_id}`}
                        >
                          <div
                            key={`${sub.category_id}`}
                            className={`${!!keywordSearch && "result-search-sub"
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              onChangeValueCategory(item, sub);
                            }}
                          >
                            <DropdownItem
                              tag="div"
                              className="flex-between-center keyword-item__child cursor-pointer"
                              active={sub2 === sub.category_id}
                            >
                              <div className="d-flex">
                                {sub.name}{" "}
                                <div className="drop ms-2">
                                  {sub.keyword_count}
                                </div>
                              </div>
                              {sub?.subs && sub?.subs?.length > 0 && (
                                <i className="ri-arrow-right-line align-middle fs-18 text-muted" />
                              )}
                            </DropdownItem>
                          </div>

                          {sub?.subs && sub?.subs?.length > 0 && (
                            <DropdownMenu
                              className={`dropdown-submenu__child py-0`}
                              style={{ width: "auto", minWidth: '320px', maxWidth: "360px", }}
                            >
                              <SimpleBar style={{ maxHeight: "440px" }}>
                                {sub?.subs?.map((s: any, i: number) => (
                                  <div
                                    key={`${s.category_id}`}
                                    className={`${!!keywordSearch &&
                                      "result-search-sub__child"
                                      }  ${checkIncludeItem(keywordSearch, s)
                                        ? ""
                                        : "d-none"
                                      }`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onChangeValueCategory(item, sub, s);
                                    }}
                                  >
                                    <DropdownItem
                                      tag="div"
                                      className="flex-between-center cursor-pointer"
                                      active={sub3 === s.category_id}
                                    >
                                      <div className="d-flex">
                                        {s.name}{" "}
                                        <div className="drop ms-2">
                                          {s.keyword_count}
                                        </div>
                                      </div>
                                    </DropdownItem>
                                  </div>
                                ))}
                              </SimpleBar>
                            </DropdownMenu>
                          )}
                        </div>
                      ))}
                    </DropdownMenu>
                  )}
                </div>
              ))
            ) : (
              <></>
            )}
          </DropdownMenu>
        </div>
      </Dropdown>
    </React.Fragment>
  );
};

export default DropdownCategoryKeyword;
