import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICampaignStatistic, ICategoryStatistic, INewsStatistic } from "./types/_statistic";
const api = new APIClient();

const path = '/trackings';
const labApi = {
  newsStatistics(params: any):Promise<AxiosResponse<ResponseData<INewsStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/news-statistics/newsid`
    return api.get(url, params)
  },
  campaignStatistics(params: any):Promise<AxiosResponse<ResponseData<ICampaignStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/campaign-statistics`
    return api.get(url, params)
  },
  categoryStatistics(params: any):Promise<AxiosResponse<ResponseData<ICategoryStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/category-statistics`
    return api.get(url, params)
  },
}
export default labApi
