import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import templateApi from 'api/templateApi';
import { formatQueryParams } from "helpers/format";

export const getTemplates = createAsyncThunk("Templates" , async (params: any = {}) => {
  try{
    const response = await templateApi.templates(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getGraphics = createAsyncThunk("Graphics" , async (params: any = {}) => {
  try{
    const response = await templateApi.graphics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getParameters = createAsyncThunk("Parameters" , async (params: any = {}) => {
  try{
    const response = await templateApi.parameters(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllTemplates = async (params: any = {}) => {
  try{
    const response = await templateApi.allTemplates(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const getAllParameters = createAsyncThunk("template/getallParameters" , async (params: any = {}) => {
  try{
    const response = await templateApi.allParameters(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
})

export const postTemplate = async (data: any = {}) => {
  try{
    const response = await templateApi.postTemplate(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putTemplate = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.putTemplate(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteTemplate = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.deleteTemplate(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const previewTemplate = async (data: any = {}) => {
  try{
    const response = await templateApi.previewTemplate(data);
    return response;
  }catch (error) {
    return error;
  }
};


export const postParameter = async (data: any = {}) => {
  try{
    const response = await templateApi.postParameter(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putParameter = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.putParameter(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteParameter = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.deleteParameter(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const getAllCharts = async (params: any = {}) => {
  try{
    const response = await templateApi.allCharts(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const postChart = async (data: any = {}) => {
  try{
    const response = await templateApi.postChart(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putChart = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.putChart(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteChart = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.deleteChart(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const postGraphic = async (data: any = {}) => {
  try{
    const response = await templateApi.postGraphic(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putGraphic = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.putGraphic(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteGraphic = async (id: string | number , data: any = {}) => {
  try{
    const response = await templateApi.deleteGraphic(id, data);
    return response;
  }catch (error) {
    return error;
  }
};