import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllMedias, getMediaRankingStatistics as onGetMediaRankingStatistics } from "../../../store/thunks";

import { IAgency, IMedia } from 'api/types/_media';
import LabelWrapper from 'components/Common/LabelWrapper';
import TableMediaRanking from 'components/Common/TableMediaRanking';
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import CountUp from 'react-countup';
import ChartLine from './ChartLine';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const SORT_BY_DEFAULT: string = '10-04-2024';
const ORDER_BY_DEFAULT: string = 'DESC';

const TODAY_DATE_DEFAULT = `${moment().format("Y-MM-DD")}`;

const MediaRanking = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    // sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    // order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    date: withDefault(StringParam, TODAY_DATE_DEFAULT),
    ...typeQuery
  });
  const [dateSearch, setDateSearch] = useState<string>(query?.date || TODAY_DATE_DEFAULT);

  const [listAgency, setListAgency] = useState<IAgency[]>([]);
  const [agencySearch, setAgencySearch] = useState<Option | null>(
    {
      label: listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.name || t('All Agency'),
      value: `${listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.id || ''}`
    }
  );

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.id || ''}`
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const MediaRankingStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      mediaRankingStatistics: state.mediaRankingStatistics,
      isMediaRankingSuccess: state.isMediaRankingStatisticsSuccess,
      isMediaRankingStatisticLoading: state.isMediaRankingStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { mediaRankingStatistics, error, isMediaRankingStatisticLoading } = useSelector(MediaRankingStatisticsProperties);

  useEffect(() => {
    dispatch(onGetMediaRankingStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      date: dateSearch ? moment(dateSearch).format("Y-MM-DD") : '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      date: TODAY_DATE_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch(queryNew?.date);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resMedia]: any = await Promise.all([getAllMedias()]);
      const listM = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => listM);
      setMediaSearch((_prev) => ({
        label: listM?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.name || t('All Media'),
        value: `${listM?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.id || ''}`
      }));
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Media Ranking')} - ${t('Lab')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const categories = useMemo(() => {
    return mediaRankingStatistics?.list?.[0]?.data?.map((item: any) => item?.date) || [];
  }, [JSON.stringify(mediaRankingStatistics?.list), , i18n?.language]);

  const series = useMemo(() => {
    const arrChart = mediaRankingStatistics?.list?.reduce((arr: any, item: any, index: number) => {
      const media = listMedia?.find((m: any) => String(m?.website) === String(item?.domain));
      const pv = {
        name: media?.name + ' (PV)' || '',
        type: 'column',
        group: 'pv',
        data: item?.data?.map((a: any) => Number((a?.pv).replace(/,/g, ''))),
      };
      const uv = {
        name: media?.name + ' (UV)' || '',
        type: 'column',
        group: 'uv',
        data: item?.data?.map((a: any) => Number((a?.uv).replace(/,/g, ''))),
      };
      return [...arr, pv, uv];
    }, []) || [];

    return arrChart;


  }, [JSON.stringify(mediaRankingStatistics?.list), JSON.stringify(listMedia), i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Media Ranking')} pageTitle={t('Lab')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_INFLOW_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isMediaRankingStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={mediaRankingStatistics?.list?.length || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-end mb-2 mb-md-0 mt-2">
                          {/* <Col sm={12} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col> */}
                          <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                              <DatePicker
                                className="form-control search"
                                placeholderText={`${t('version_date')}...`}
                                value={dateSearch || undefined}
                                selected={new Date(dateSearch || TODAY_DATE_DEFAULT)}
                                dateFormat="yyyy-MM-dd"
                                isClearable={false}
                                maxDate={new Date()}
                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                onChange={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={9} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isMediaRankingStatisticLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  {/* <ChartLine
                    className="mb-5"
                    titles={[t('Media Ranking')]}
                    categories={categories}
                    series={series}
                  /> */}
                  <CardBody className="pt-4">
                    <div>
                      <TableMediaRanking
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle sticky-table-media-ranking-trending"
                        theadClass="table-light"
                        medias={listMedia || []}
                        summary={mediaRankingStatistics?.total || []}
                        data={mediaRankingStatistics?.list || []}
                        customPageSize={mediaRankingStatistics?.list?.length}
                        customPageIndex={1}
                        totalRecords={mediaRankingStatistics?.list?.length}
                        customPageCount={1}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isMediaRankingStatisticLoading}
                        isShowPagination={true}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MediaRanking;
