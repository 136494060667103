import { IStock } from "api/types/_stock";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;

}

interface DropdownStockProps {
  name?: string,
  dataList?: IStock[],
  initialValue?: Option[] | Option | undefined | null;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
}

const DropdownStock = ({
  name = '',
  initialValue = [],
  dataList = [],
  onChangeSelect,
  isHasOptionAll = false,

}: DropdownStockProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };
  const optionAll: any = { label: '', name: t('All Stock Code'), code: '', value: '' };
  useEffect(() => {
    const resultOptions = dataList?.map((item) => ({ code: item?.code, name: item?.name, label: `${item?.name || ''} / ${item?.code || ''} / ${item?.type_name || ''}`, value: String(item?.code), type_name: item?.type_name })) || [] as Option[];
    const result: any = isHasOptionAll ? [optionAll].concat(resultOptions) : resultOptions;
    setOptions((_prev: any) => result);
    if (initialValue) {
      const val = result?.filter((e: any) => e.value === (initialValue as any)?.value)[0];
      setOnChangeOption(val);
    } else {
      setOnChangeOption(isHasOptionAll ? result[0] : null);
    }
  }, [JSON.stringify(dataList), JSON.stringify(initialValue)]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={false}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{t('Select Stock Code')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (<div className="d-flex">{option?.name} <div className="op ms-2"> {`${String(option?.code || '')} ${String(option?.type_name ? (' / ' + option?.type_name) : '')}`}</div> </div>)}
        className="search-filter-currencies"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default DropdownStock;
