import { createSlice } from "@reduxjs/toolkit";
import { ICoin, ICoinSearch } from "api/types/_coin";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCoins } from "./thunk";

export interface IState {
  coins: ResponseData<ICoinSearch[]> & PaginationResponse | null,
  isCoinLoading : boolean,
  isCoinSuccess : boolean,

  error: any,
};

export const initialState: IState = {
  coins: null,
  isCoinLoading : false,
  isCoinSuccess : false,

  error: {},
};

const coinSlice = createSlice({
  name: "coin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get coins
    builder.addCase(getCoins.pending, (state: IState, action: any) => {
      state.isCoinLoading = true
    });
    builder.addCase(getCoins.fulfilled, (state: IState, action: any) => {
      state.coins = action.payload.data;
      state.isCoinSuccess = true;
      state.isCoinLoading = false;
    });

    builder.addCase(getCoins.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCoinSuccess = false;
      state.isCoinLoading = false;
    });

  },
});

export default coinSlice.reducer;