import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import { useRole } from "components/Hooks/UserHooks";
import { formatNumberWithCommas } from "helpers/format";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardHeader, Col, Input, Row, Spinner, Table } from "reactstrap";
import { getSearchKeyword } from "store/keyword/thunk";
import ChartLine from "./ChartLine";

interface Props {
  isOpen?: boolean;
  keywordSearch?: string;
  dataSearch?: any;
  handleAddKeyword?: (str: string) => void;
  isShowButtonSelect?: boolean;
}

interface Option {
  label: string;
  value: string;
}

const TingSearchKeyword = ({
  isOpen = false,
  keywordSearch = "",
  dataSearch,
  handleAddKeyword,
  isShowButtonSelect = true,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const TYPE_GROUP_LANG = [
    { label: t("Daily"), value: "daily" },
    { label: t("Monthly"), value: "monthly" },
  ];
  const TYPE_CHART_LANG = [
    { label: t("Line Chart"), value: "line" },
    { label: t("Bar Chart"), value: "column" },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>(keywordSearch);

  const [groupType, setGroupType] = useState<Option>(TYPE_GROUP_LANG[0]);

  const [typeChart, setTypeChart] = useState<Option>(TYPE_CHART_LANG[0]);

  const [dataSearchKeyword, setDataSearchKeyword] = useState<any>(
    dataSearch || {}
  );

  const getClassTextMinMaxAgeFromArr = (ob: any, num: number) => {
    const arr = [
      ob?.age10_ratio,
      ob?.age20_ratio,
      ob?.age30_ratio,
      ob?.age40_ratio,
      ob?.age50_ratio,
    ];
    return Math.min(...arr) === num
      ? "text-secondary"
      : Math.max(...arr) === num
      ? "text-danger"
      : "";
  };

  const getClassTextMinMaxGenderFromArr = (ob: any, num: number) => {
    const arr = [ob?.male_ratio, ob?.female_ratio];
    return Math.min(...arr) === num
      ? "text-secondary"
      : Math.max(...arr) === num
      ? "text-danger"
      : "";
  };

  // End::Add New Keyword
  const searchData = async () => {
    try {
      if (String(keyword)?.trim() === "") {
        return;
      }
      setIsLoading((_prev) => true);
      const data = {
        keyword: keyword,
        group_type: groupType?.value,
      };
      const response: any = await getSearchKeyword(data);

      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setDataSearchKeyword((_prev: any) => ({
          ...response?.data,
          keyword: keyword,
        }));
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleSelectKeyword = () => {
    handleAddKeyword && handleAddKeyword(dataSearchKeyword?.keyword);
  };

  useEffect(() => {
    if (isOpen) {
      searchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupType?.value]);

  // End::Show Autocomplete Keywords
  const categories = useMemo(() => {
    return (
      (dataSearchKeyword?.trend_data?.data || [])?.map(
        (item: any) => item?.period
      ) || []
    );
  }, [i18n?.language, JSON.stringify(dataSearchKeyword?.trend_data)]);

  const series = useMemo(() => {
    const obChart =
      (dataSearchKeyword?.trend_data?.data || []).reduce(
        (ob: any, item: any) => {
          ob.key?.push(Number(item?.value));
          return ob;
        },
        { key: [] }
      ) || [];
    return [
      {
        name: dataSearchKeyword?.keyword || "",
        type: typeChart?.value,
        data: obChart?.key,
      },
    ];
  }, [
    i18n?.language,
    typeChart?.value,
    JSON.stringify(dataSearchKeyword?.trend_data),
  ]);

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card id="leadsList">
          <CardHeader className="border-0">
            <Row className="mt-3">
              <Col sm={12} lg={12} className="d-flex justify-content-between">
                <div className="search-box w-100">
                  <Input
                    type="text"
                    className="form-control search"
                    placeholder={`${t(
                      "You can analyze keyword. After entering the keyword, press the 'Enter' key to separate them"
                    )}...`}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchData();
                      }
                    }}
                    style={{ borderRadius: "20px" }}
                  />
                  <i className="ri-search-line search-icon"></i>
                  <button
                    type="button"
                    className="position-absolute top-0 bottom-0 end-0 btn btn-primary"
                    onClick={searchData}
                    disabled={isLoading || String(keyword)?.trim() === ""}
                    style={{ width: "130px", borderRadius: "20px" }}
                  >
                    {isLoading ? (
                      <Spinner
                        size="sm"
                        className="me-1"
                        style={{ width: "14px", height: "14px" }}
                      />
                    ) : (
                      <i className="ri-search-line align-bottom me-1"></i>
                    )}
                    {t("Button Search")}
                  </button>
                </div>
                {isShowButtonSelect && (
                  <button
                    type="button"
                    className="btn btn-success ms-4"
                    onClick={handleSelectKeyword}
                    style={{ width: "220px", borderRadius: "20px" }}
                  >
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("Button Select This Keyword")}
                  </button>
                )}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12} lg={12} className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="me-2">{t("Period")}</div>
                  <DropdownOption
                    name="category-type"
                    dataList={TYPE_GROUP_LANG || []}
                    placeholder={`${t("Period")}...`}
                    className="search-filter-category-type search-filter-custom-style"
                    classNamePrefix="name-prefix"
                    initialValue={groupType || TYPE_GROUP_LANG[0]}
                    onChangeSelect={(e: any) => setGroupType(e)}
                    isHasOptionAll={false}
                  />
                </div>

                <DropdownOption
                  name="category-type"
                  dataList={TYPE_CHART_LANG || []}
                  placeholder={`${t("Chart")}...`}
                  className="search-filter-category-type search-filter-custom-style w-150px"
                  classNamePrefix="name-prefix"
                  initialValue={typeChart || TYPE_CHART_LANG[0]}
                  onChangeSelect={(e: any) => setTypeChart(e)}
                  isHasOptionAll={false}
                />
              </Col>
            </Row>
            <ChartLine
              className="mb-4 mt-2"
              title={t("Ting Search")}
              categories={categories}
              series={series}
              typeFormatDate={
                groupType?.value === "monthly" ? "YYYY-MM" : "MM-DD"
              }
            />
            <Row className="mb-5">
              <Col md={6} lg={6} xxl={4} className="mb-4">
                <div className="table-responsive">
                  <Table className="caption-top table-bordered table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th
                          scope="col"
                          colSpan={4}
                          className="text-center bold"
                        >
                          {t("Col_Search_Volume")}
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" className="text-center bold">
                          {t("Col_Keyword")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Volume_Mobile")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Volume_PC")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Volume_Total")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fw-medium text-center h6">
                          {dataSearchKeyword?.keyword || ""}
                        </td>
                        <td className={`text-center h6`}>
                          {dataSearchKeyword?.search_mobile >= 0 &&
                            formatNumberWithCommas(
                              dataSearchKeyword?.search_mobile
                            )}
                        </td>
                        <td className={`text-center h6`}>
                          {dataSearchKeyword?.search_pc >= 0 &&
                            formatNumberWithCommas(
                              dataSearchKeyword?.search_pc
                            )}
                        </td>
                        <td className={`text-center h6 text-danger`}>
                          {dataSearchKeyword?.search_total >= 0 &&
                            formatNumberWithCommas(
                              dataSearchKeyword?.search_total
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col md={6} lg={6} xxl={3} className="mb-4">
                <div className="table-responsive">
                  <Table className="caption-top table-bordered table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th
                          scope="col"
                          colSpan={3}
                          className="text-center bold"
                        >
                          {t("Col_Gender")}
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" className="text-center bold">
                          {t("Col_Keyword")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Gender_Male")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Gender_FeMale")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fw-medium text-center h6">
                          {dataSearchKeyword?.keyword || ""}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxGenderFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.male_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.male_ratio}
                          {dataSearchKeyword?.user_stats?.male_ratio >= 0 &&
                            "%"}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxGenderFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.female_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.female_ratio}
                          {dataSearchKeyword?.user_stats?.female_ratio >= 0 &&
                            "%"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={12} lg={12} xxl={5} className="mb-4">
                <div className="table-responsive">
                  <Table className="caption-top table-bordered table-nowrap mb-0 ">
                    <thead className="table-light">
                      <tr>
                        <th
                          scope="col"
                          colSpan={6}
                          className="text-center bold"
                        >
                          {t("Col_Age")}
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" className="text-center bold">
                          {t("Col_Keyword")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Age_10")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Age_20")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Age_30")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Age_40")}
                        </th>
                        <th scope="col" className="text-center bold">
                          {t("Col_Age_50")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fw-medium text-center h6">
                          {dataSearchKeyword?.keyword || ""}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxAgeFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.age10_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.age10_ratio}
                          {dataSearchKeyword?.user_stats?.age10_ratio >= 0 &&
                            "%"}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxAgeFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.age20_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.age20_ratio}
                          {dataSearchKeyword?.user_stats?.age20_ratio >= 0 &&
                            "%"}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxAgeFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.age30_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.age30_ratio}
                          {dataSearchKeyword?.user_stats?.age30_ratio >= 0 &&
                            "%"}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxAgeFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.age40_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.age40_ratio}
                          {dataSearchKeyword?.user_stats?.age40_ratio >= 0 &&
                            "%"}
                        </td>
                        <td
                          className={`text-center h6 ${getClassTextMinMaxAgeFromArr(
                            dataSearchKeyword?.user_stats,
                            dataSearchKeyword?.user_stats?.age50_ratio
                          )}`}
                        >
                          {dataSearchKeyword?.user_stats?.age50_ratio}
                          {dataSearchKeyword?.user_stats?.age50_ratio >= 0 &&
                            "%"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={12} lg={12} xxl={12} className="mb-4">
                <div className="table-responsive">
                  <Table className="caption-top table-bordered table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th
                          scope="col"
                          colSpan={2}
                          className="text-center bold"
                        >
                          {t("Col_Keywords")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="fw-medium text-start h6">
                          {t("Col_Autocomplete_Keywords")}
                        </td>
                        <td className={`text-start`}>
                          {dataSearchKeyword?.autocomplete_keywords?.map(
                            (item: any) => (
                              <span
                                key={item}
                                className="tag-style px-2 me-2"
                                style={{ cursor: "text" }}
                              >
                                {item}
                              </span>
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium text-start h6">
                          {t("Col_Related_Keywords")}
                        </td>
                        <td className={`text-start`}>
                          {dataSearchKeyword?.related_keywords?.map(
                            (item: any) => (
                              <span
                                key={item}
                                className="tag-style px-2 me-2"
                                style={{ cursor: "text" }}
                              >
                                {item}
                              </span>
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TingSearchKeyword;
