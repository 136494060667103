import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategorySport, IGame, ISport, ISportCategory, ISportStatistics } from "./types/_sport";
const api = new APIClient();

const path = '/sports';
const userApi = {
  sports(params: any):Promise<AxiosResponse<ResponseData<ISportStatistics[]> & PaginationResponse, any>> {
    const url = `${path}/football`
    return api.get(url, params)
  },
  games(params: any):Promise<AxiosResponse<ResponseData<IGame[]> & PaginationResponse, any>> {
    const url = `${path}/games`
    return api.get(url, params)
  },
  allSports(params: any = {}):Promise<AxiosResponse<ResponseData<ISport[]>, any>> {
    const url = `masters/sports`
    return api.get(url, params)
  },
  categories(params: any = {}):Promise<AxiosResponse<ResponseData<ISportCategory[]> & PaginationResponse, any>> {
    const url = `categories`
    return api.get(url, {...params, type: 'sport',})
  },
  searchCategories(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<ICategorySport[]>, any>> {
    const url = `masters/categories`;
    return api.get(url, params);
  },
  putStatusActiveSportTeam(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ISport[]>, any>>  {
    const url = `${path}/football/${id}/status`;
    return api.post(url, dataForm)
  },
}
export default userApi
