import { IMedia } from 'api/types/_media';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK, TYPE_NEWS_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllMedias, getVisitorStatistics as onGetVisitorStatistics } from "../../../store/thunks";
import ChartLine from './ChartLine';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import LabelWrapper from 'components/Common/LabelWrapper';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import DropdownOption from 'components/Common/DropdownOption';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'date';
const VisitorStatisticList = () => {

  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 1500),
    domain: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    is_newshub: withDefault(StringParam, '1'),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_newshub))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const VisitorStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => {
      return ({
        visitorStatistics: state.visitorStatistics,
        isVisitorStatisticSuccess: state.isVisitorStatisticSuccess,
        isVisitorStatisticLoading: state.isVisitorStatisticLoading,
        error: state.error,
      })
    }
  );

  // Inside your component
  const { visitorStatistics, error, isVisitorStatisticLoading } = useSelector(VisitorStatisticsProperties);

  const totalPublicNews = (visitorStatistics?.list || [])?.reduce((total: number, item: any) => (Number(total) + Number(item?.total_news_count || 0)), 0);

  useEffect(() => {
    dispatch(onGetVisitorStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      is_newshub: typeNewsSearch?.value ?? '',
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      is_newshub: TYPE_NEWS_OPTIONS_LANG[0]?.value, // 
      domain: '',
      start_date: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setTypeNewsSearch(TYPE_NEWS_OPTIONS_LANG[0]); // 
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: true,
        thWidth: 100,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
          const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
          const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
          return (
            <div className="text-start" style={{ minWidth: '100px' }}>
              <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
            </div>
          )
        },
      },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: false,
        sortable: true,
        thClass: 'text-end align-middle',
        description: t('Description PV'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className="text-end me-3" style={{ minWidth: '60px' }}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('UV'),
        accessor: "uv",
        filterable: false,
        sortable: true,
        thClass: 'text-end align-middle',
        description: t('Description UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: '60px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Total News Count'),
        accessor: "total_news_count",
        filterable: false,
        sortable: true,
        thClass: 'text-end align-middle',
        description: t('Description Published news count a day'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: '60px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('None1'),
        thClass: 'text-center',
        filterable: false,
        sortable: false,
        thWidth: 50,
        thComponent: () => (<></>)
      },
      {
        Header: t('PV Platform'),
        thClass: 'text-end',
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t('PV Platform')}
                  <TooltipCustom
                    title={t('Description PV field each platform')}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Visitor Naver')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Visitor Google')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Visitor Etc')}</th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: '100px' }}>{item.pv_naver_rate}</div>
              <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea" }}>{item.pv_google_rate}</div>
              <div style={{ width: '100px' }}>{item.pv_etc_rate}</div>
            </div>
          );
        },
      },
      {
        Header: t('None2'),
        thClass: 'text-center',
        filterable: false,
        sortable: false,
        thWidth: 50,
        thComponent: () => (<></>)
      },
      {
        Header: t('Referrer Rate'),
        thClass: 'text-center',
        filterable: false,
        sortable: false,
        // description: t('Referrer Rate'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t('Referrer Rate')}
                  <TooltipCustom
                    title={t('Referrer Rate')}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom>
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Visitor Naver')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Visitor Google')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Visitor Etc')}</th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: '100px' }}>{item.naver_rate}%</div>
              <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea" }}>{item.google_rate}%</div>
              <div style={{ width: '100px' }}>{item.etc_rate}%</div>
            </div>
          );
        },
      },
      // {
      //   Header: t('UV (Yesterday)'),
      //   accessor: "uv_yesterday",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV (Yesterday)'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      // {
      //   Header: t('UV (2 Days ago)'),
      //   accessor: "uv_2days_ago",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV of news which were published between 2 and 7 days ago'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      {
        Header: t('UV (8 Days ago)'),
        accessor: "uv_8days_ago",
        filterable: false,
        sortable: true,
        thClass: 'text-end align-middle',
        description: t('Description UV of news which were published 8 days before'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: '120px' }}>{cell?.value}</div>
          </>
        ),
      },
    ],
    [i18n?.language, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }


  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Visitor Statistics')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const categories = useMemo(() => {
    return visitorStatistics?.list?.map((item: any) => item?.date).reverse() || [];
  }, [visitorStatistics?.list, i18n?.language]);

  const series = useMemo(() => {
    const obChart = visitorStatistics?.list?.reduce((ob: any, item: any) => {
      ob.pv?.push(Number((item?.pv).replace(/,/g, '')));
      ob.uv?.push(Number((item?.uv).replace(/,/g, '')));
      ob.news?.push(Number(item?.total_news_count));
      ob.uv_2days_ago?.push(Number((item?.uv_2days_ago).replace(/,/g, '')));
      ob.uv_8days_ago?.push(Number((item?.uv_8days_ago).replace(/,/g, '')));
      return ob;
    }, { pv: [], uv: [], news: [], uv_2days_ago: [], uv_8days_ago: [] }) || [];
    return (
      [
        {
          name: t('UV'),
          type: 'line',
          data: obChart?.uv?.reverse(),
        },
        {
          name: t('News'),
          type: 'column',
          data: obChart?.news?.reverse(),
        },

      ]
    )
  }, [visitorStatistics?.list, i18n?.language]);


  useEffect(() => {
    setDurationResponses([
      {
        name: 'Visitor Statistics',
        time: visitorStatistics?.pagination?.duration || 0
      }
    ]);
  }, [visitorStatistics?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Visitor Statistics')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.VISITOR_STATISTICS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList" className="ribbon-box right">
                  <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per 10 mins')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <div className="d-flex mb-4 mb-md-0 flex-column flex-sm-row">
                        <Card className="card-animate mb-0 me-4 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isVisitorStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={visitorStatistics?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total News')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isVisitorStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={totalPublicNews || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} md={3} lg={3} className="mt-2" >
                            <div>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mt-0">
                          <Col sm={6} md={3} lg={3} className='mt-2'>
                            <LabelWrapper label={t('News Type')} isShow={true}>
                              <DropdownOption
                                name=""
                                dataList={TYPE_NEWS_OPTIONS_LANG?.filter((item) => ['0', '1']?.includes(item?.value))}
                                placeholder={`${t("News Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={typeNewsSearch || null}
                                onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Data'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={3} lg={3} className="date-picker-wrapper-custom mt-2">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                                showOptions={[
                                  'today', 'yesterday',
                                  'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                  'this_week', 'last_week',
                                  'this_month', 'last_month',
                                ]}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={6} lg={6} className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isVisitorStatisticLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <ChartLine
                      className="mb-5"
                      titles={[t('Visitor'), t('Number of articles')]}
                      categories={categories}
                      series={series}
                    />
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={visitorStatistics?.list?.length ? visitorStatistics?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={visitorStatistics?.pagination?.total}
                        customPageCount={Math.ceil(Number(visitorStatistics?.pagination?.total) / Number(visitorStatistics?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isVisitorStatisticLoading}
                        isShowPagination={false}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VisitorStatisticList;
