import { IParameter } from "api/types/_template";
import { CONFIG_OPTION_TOAST_COPY } from "common/toast";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Input } from "reactstrap";

interface SearchParameterProps {
  data?: IParameter[];
  style?: any
}

const SearchParameter = ({
  data = [],
  style = {},
}: SearchParameterProps) => {
  const { t } = useTranslation();
  const [valueSearch, setValueSearch] = useState<string>('');
  const [parameters, setParameters] = useState<IParameter[]>(data);
  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    toast(`${t('Copied Successfully')}`, CONFIG_OPTION_TOAST_COPY);
  };

  useEffect(() => {
    if (valueSearch) {
      setParameters((prev) => data?.filter((item: IParameter) => ((item?.key?.includes(valueSearch) || item?.description?.includes(valueSearch)) && !!item?.is_active)));
    } else {
      setParameters((prev) => data?.filter((item: IParameter) => !!item?.is_active));
    }
  }, [valueSearch, JSON.stringify(data)]);

  return (
    <React.Fragment>
      <div className="table-responsive w-100 rounded-3" style={{ maxHeight: 'calc(100dvh - 150px)', ...style }}>
        <table className="table table-borderless table-light table-hover table-nowrap rounded-3 align-middle mb-0">
          <thead className="table-primary" style={{ position: 'sticky', top: 0 }}>
            <tr className="text-muted">
              <th scope="col">
                <div className="d-flex align-items-center justify-content-flex-start">
                  <div>{t('Parameter Key')}</div>
                  <div className="app-search p-0 ps-2 w-100">
                    <div className="position-relative" style={{
                      width: '250px'
                    }}>
                      <Input type="text" className="form-control bg-white" placeholder={`${t('Button Search')}...`}
                        id="search-options"
                        autoComplete="off"
                        value={valueSearch}
                        onChange={e => {
                          setValueSearch((prev) => e.target.value);
                        }}
                      />
                      <span style={{
                        position: 'absolute',
                        bottom: '2px',
                        right: '5px',
                        fontSize: '10px',
                      }} className="text-danger">{parameters?.length || 0} {t('Results')} </span>
                      <span className="mdi mdi-magnify search-widget-icon"></span>
                      <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"></span>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="d-flex align-items-center" style={{ height: '38px' }}>{t('Parameter Example')}</div>
              </th>
              <th scope="col" >
                <div className="d-flex align-items-center" style={{ height: '38px' }}>{t('Parameter Description')}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {parameters?.map((item: IParameter) => (
              <tr key={item?.scope}>
                <td>
                  <span className="cursor-pointer" style={{ verticalAlign: 'middle', display: 'inline-flex', }} onClick={() => copyTextToClipboard(item?.key)}>
                    {item?.key}
                    <i className=" ri-file-copy-line text-primary ms-1" style={{ fontSize: '1rem' }}></i>
                  </span>
                </td>
                <td>{item?.sample}</td>
                <td>{item?.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment >
  );
};

export default SearchParameter;
