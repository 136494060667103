import { ICategory } from "api/types/_category";
import { IMedia, IType } from "api/types/_media";
import { ITemplate } from "api/types/_template";
import SearchKeyword from "components/Common/SearchKeyword";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { typeSearch } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import CampaignFormMultiByKeyword from "pages/Campaign/CampaignForm/CampaignFormMultiByKeyword";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes, getKeywords as onGetKeywords } from "../../../store/thunks";
import LabelWrapper from "components/Common/LabelWrapper";

interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'search_monthly': StringParam,
  'search_monthly_pc': StringParam,
  'search_monthly_mobile': StringParam,
  'click_avg_monthly': StringParam,
  'click_avg_monthly_pc': StringParam,
  'click_avg_monthly_mobile': StringParam,
  'click_rate_monthly': StringParam,
  'click_rate_monthly_pc': StringParam,
  'click_rate_monthly_mobile': StringParam,
  'impressions_avg_monthly': StringParam,
}
const TYPE_SELECT_DEFAULT: string = 'search_monthly';
registerLocale('en', en);
registerLocale('ko', ko);


const NaverKeyword = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: NumberParam,
    competition_level: StringParam,
    keyword: StringParam,
    status: withDefault(StringParam, ''),
    ...typeQuery,
  });
  const typeSearchLang = typeSearch?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [typeSelect, setTypeSelect] = useState(typeSearchLang?.find((e: any) => e.value === query.sort_by));
  const rangeValue = useRef<any>();
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");
  const [categorySelect, setCategorySelect] = useState<Option | null>();

  const [optionsSelected, setOptionsSelected] = useState<any>({});

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const [isOpenCategory, setIsOpenCategory] = useState<boolean>(false);

  // Begin::List for create category
  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listClassifications, setListClassifications] = useState<Option[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  // End::List for create category

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Naver;
  const naverCategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.keywords,
      isKeywordSuccess: state.isKeywordSuccess,
      isKeywordLoading: state.isKeywordLoading,
      error: state.error,
    })
  );
  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(naverCategoryProperties);

  useEffect(() => {
    dispatch(onGetKeywords(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  const searchData = () => {
    const rangeQuery = (fromRangeSearch && toRangeSearch) ? `gt:${fromRangeSearch}-lt:${toRangeSearch}` : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      keyword: keywordSearch || undefined,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      category_id: categorySelect?.value,
      time_request: + new Date()
    });

  };

  const resetData = () => {
    setOptionsSelected((_prev: any) => ({}));
    setQuery({
      keyword: undefined,
      sort_by: undefined,
      [typeSelect?.value]: undefined,
      category_id: undefined,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
    setTypeSelect(typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT));
    setFromRangeSearch("");
    setToRangeSearch("");
    setCategorySelect(null);
  };


  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywords?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.keyword }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywords?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])
  }, [optionsSelected]);

  // End:: handle select table 

  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen((_prev) => false);
  };

  const onCloseCategoryClick = (e: any) => {
    e.preventDefault();
    setIsOpenCategory((_prev) => false);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Search Monthly'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check" style={{ minWidth: '20px' }}>
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
              <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
            </div>
          </>
        ),
      },
      {
        Header: t('Search Monthly'),
        accessor: "search_monthly",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '90px' }}>{cell?.value ?? '-'}</div>
          </>
        ),
      },
      {
        Header: t('Categories'),
        accessor: "categories",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories;
          let categoryName: string = "";
          for (let category of categories) {
            for (let sub of category?.categories) {
              categoryName += "- " + sub?.name + "<br/>";
            }
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: categoryName }} style={{ minWidth: '200px' }} />
          );
        }
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '120px' }}>{cell.value ?? "-"}</div>
          </>
        ),
      },
      {
        Header: t('Search Monthly PC'),
        accessor: "search_monthly_pc",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '90px' }}>{cell?.value ?? '-'}</div>
          </>
        ),
      },
      {
        Header: t('Search Monthly Mobile'),
        accessor: "search_monthly_mobile",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '110px' }}>{cell?.value ?? '-'}</div>
          </>
        ),
      },
      {
        Header: t('Click Avg Monthly'),
        accessor: "click_avg_monthly",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '110px' }}>{cell?.value ?? '-'}</div>
          </>
        ),
      },
      {
        Header: t('Click Avg Monthly PC'),
        accessor: "click_avg_monthly_pc",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '130px' }}>{cell?.value ?? '-'}</div>
          </>
        ),
      },
      {
        Header: t('Click Avg Monthly Mobile'),
        accessor: "click_avg_monthly_mobile",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '150px' }}>{cell?.value ?? '-'}</div>
          </>
        ),
      },
    ],
    [i18n?.language, JSON.stringify(optionsSelected), query?.page, JSON.stringify(keywords?.list)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.find((e: any) => e.value === typeSelect?.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Keyword')} - ${t('Naver Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  const handleCallAllOption = async () => {
    try {
      const [resType, resMedia, resCategoriesType, resTemplate, resCategoriesTrendIndex]: any = await Promise.all([getAllTypes(), getAllMedias(), getAllCategoriesType(), getAllTemplates(), getAllCategoriesTrendIndexNormal()]);
      setListType((_prev) => resType?.data || []);
      setListMedia((_prev) => resMedia?.data || []);
      setListCategoriesType((_prev) => (resCategoriesType?.data?.map((item: any) => ({ value: item?.type, label: item?.name })) || []));
      // Begin::Classification options
      setListClassifications((_prev) => resCategoriesType?.data?.reduce((arr: any, item: any) => {
        const sub: any = (item?.classifications || [])?.map((it: any) => ({ label: it, value: it, type: item?.type }));
        return arr.concat(sub);
      }, []));
      // End::Classification options
      setListTemplate((_prev) => resTemplate?.data || []);
      setListCategoriesTrendIndex((_prev) => resCategoriesTrendIndex?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  const handleRefetchCategoriesTrendIndex = useCallback(async () => {
    try {
      const [resCategoriesTrendIndex]: any = await Promise.all([getAllCategoriesTrendIndexNormal()]);
      setListCategoriesTrendIndex((_prev) => resCategoriesTrendIndex?.data || []);
    } catch (error: any) {
      return error;
    }
  }, []);

  const handleClearSelectedKeywords = useCallback(async () => {
    try {
      setOptionsSelected((_prev: any) => ({}));
    } catch (error: any) {
      return error;
    }
  }, []);

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Naver Keyword')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.NAVER_KEYWORD_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center mt-2">
                      <Col sm={6} lg={3} className="mt-0 mt-sm-2 mb-3 mb-sm-2">
                        <LabelWrapper label={t('Category')} isShow={!!categorySelect?.value}>
                          <SearchKeyword
                            initialValue={categorySelect ?? undefined}
                            onChangeCategory={setCategorySelect}
                            isClearable={true}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} lg={4} className="mt-0 mt-sm-2 mb-2 mb-sm-2">
                        <LabelWrapper label={t('Search by keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Search by keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mt-0">
                      {/* type search */}
                      <Col sm={6} lg={3} className="mt-2 mb-2 mb-sm-3">
                        <LabelWrapper label={t('Search by field')} isShow={!!typeSelect?.value}>
                          <Select
                            options={typeSearchLang}
                            value={typeSelect}
                            onChange={(e: any) => setTypeSelect(e)}
                            placeholder={<div>{`${t('Search by field')}...`}</div>}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={3} lg={2} className="mt-2 mb-2 mb-sm-3">
                        <LabelWrapper label={t('From Range')} isShow={!!fromRangeSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('From Range')}...`}
                            value={fromRangeSearch}
                            onChange={(e) => setFromRangeSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={3} lg={2} className="mt-2 mb-2 mb-sm-3">
                        <LabelWrapper label={t('To Range')} isShow={!!toRangeSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('To Range')}...`}
                            value={toRangeSearch}
                            onChange={(e) => setToRangeSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={5} className="hstack gap-1 flex-column flex-sm-row justify-content-sm-center justify-content-md-between mt-2 mb-2 mb-sm-3">
                        <div className="mb-4 mb-sm-0 w-100 text-center text-sm-start">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={searchData}
                            disabled={isKeywordLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14 ms-1"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                        <div className="w-100 w-sm-auto text-end">
                          {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions) && (
                            <button
                              type="button"
                              className="btn btn-success fs-14"
                              onClick={() => setIsOpen((_prev) => true)}
                              disabled={countRowSelected <= 0}
                            >
                              <i className="ri-add-fill align-bottom me-1"></i>{" "}
                              {countRowSelected > 0 && (
                                <span className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                  style={{ transform: 'translate(0%, -70%)' }}
                                >
                                  {formatNumberWithCommas(countRowSelected)}
                                  <span className="visually-hidden">total keywords selected</span>
                                </span>)}
                              {t('Button Create Campaign')}
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card table-has-checkbox-select"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={keywords?.list?.length ? keywords.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={keywords?.pagination?.total}
                        customPageCount={Math.ceil(Number(keywords?.pagination?.total) / Number(keywords?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isKeywordLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <Modal isOpen={isOpen} centered={true} size="xl" className="modal-fullscreen-r" scrollable={true} >
        <ModalHeader toggle={onCloseClick}>
          {t('Button Create Campaign')}
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: 'calc(100dvh - 200px)' }}>
            <CampaignFormMultiByKeyword
              listKeyword={listKeyword || []}
              listType={listType}
              listMedia={listMedia}
              listTemplate={listTemplate}
              listCategoriesType={listCategoriesType}
              listClassifications={listClassifications}
              listCategoriesTrendIndex={listCategoriesTrendIndex}
              handleRefetchCategoriesTrendIndex={handleRefetchCategoriesTrendIndex}
              handleClearSelectedKeywords={handleClearSelectedKeywords}
            />
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default NaverKeyword;
