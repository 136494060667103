import { createSlice } from "@reduxjs/toolkit";
import { getTemplates, getAllTemplates, getAllParameters, getParameters, getGraphics } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IGraphic, IParameter, ITemplate, IVariable } from "api/types/_template";


export interface IState {
  templates: ResponseData<ITemplate[]> & PaginationResponse | null,
  isTemplateLoading: boolean,
  isTemplateSuccess: boolean,

  graphics: ResponseData<IGraphic[]> & PaginationResponse | null,
  isGraphicLoading: boolean,
  isGraphicSuccess: boolean,

  parameters: ResponseData<IParameter[]> & PaginationResponse | null,
  isParameterLoading: boolean,
  isParameterSuccess: boolean,
  

  allParameter: ResponseData<IParameter[]> | null,
  isAllParameterSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  templates: null,
  isTemplateLoading: false,
  isTemplateSuccess: false,

  graphics: null,
  isGraphicLoading: false,
  isGraphicSuccess: false,

  parameters: null,
  isParameterLoading: false,
  isParameterSuccess: false,

  allParameter: null,
  isAllParameterSuccess: false,

  error: {},
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get Templates
    builder.addCase(getTemplates.pending, (state: IState, action: any) => {
      state.isTemplateLoading = true
    });
    builder.addCase(getTemplates.fulfilled, (state: IState, action: any) => {
      state.templates = action.payload.data;
      state.isTemplateSuccess = true;
      state.isTemplateLoading = false;
    });
    builder.addCase(getTemplates.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isTemplateSuccess = false;
      state.isTemplateLoading = false;
    });

    //get Graphics
    builder.addCase(getGraphics.pending, (state: IState, action: any) => {
      state.isGraphicLoading = true
    });
    builder.addCase(getGraphics.fulfilled, (state: IState, action: any) => {
      state.graphics = action.payload.data;
      state.isGraphicSuccess = true;
      state.isGraphicLoading = false;
    });
    builder.addCase(getGraphics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isGraphicSuccess = false;
      state.isGraphicLoading = false;
    });

    //get Parameter
    builder.addCase(getParameters.pending, (state: IState, action: any) => {
      state.isParameterLoading = true
    });
    builder.addCase(getParameters.fulfilled, (state: IState, action: any) => {
      state.parameters = action.payload.data;
      state.isParameterSuccess = true;
      state.isParameterLoading = false;
    });
    builder.addCase(getParameters.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isParameterSuccess = false;
      state.isParameterLoading = false;
    });

    //get all parameter
    builder.addCase(getAllParameters.fulfilled, (state: IState, action: any) => {
      state.allParameter = action.payload.data;
      state.isAllParameterSuccess = true;
    });

    builder.addCase(getAllParameters.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAllParameterSuccess = false;
    });

  },
});

export default templateSlice.reducer;