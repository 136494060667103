import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICampaignStatistic, ICategoryStatistic, IChartForKeyword, IFlowedKeywordAnalysis, IKeywordAnalysis, IKeywordStatistic, IMediaRanking, INewsLog, INewsStatistic, IRevenueStatistic, IVisitorStatistic, IWebsiteLog, IWebsiteStatistic, IWebsiteSummariesLog} from "./types/_statistic";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/trackings';
const statisticApi = {
  newsLogs(params: any):Promise<AxiosResponse<ResponseData<INewsLog[]> & PaginationResponse, any>> {
    const url = `${path}/news`
    return api.get(url, params)
  },
  newsStatistics(params: any):Promise<AxiosResponse<ResponseData<INewsStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/analytics/news-statistics`
    return api.get(url, params)
  },
  websiteSummariesLogs(params: any):Promise<AxiosResponse<ResponseData<IWebsiteSummariesLog[]> & PaginationResponse, any>> {
    const url = `${path}/analytics/summaries`
    return api.get(url, params)
  },
  websiteLogs(params: any):Promise<AxiosResponse<ResponseData<IWebsiteLog[]> & PaginationResponse, any>> {
    const url = `${path}/analytics`
    return api.get(url, params)
  },
  keywordStatistics(params: any):Promise<AxiosResponse<ResponseData<IKeywordStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/analytics/keyword-statistics-by-day`
    return api.get(url, params)
  },
  keywordInflows(params: any):Promise<AxiosResponse<ResponseData<IKeywordStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/analytics/keywords`
    return api.get(url, params)
  },
  keywordAnalysis(params: any):Promise<AxiosResponse<ResponseData<IKeywordAnalysis[]> & PaginationResponse, any>> {
    const url = `${path}/keyword-analysis`
    return api.get(url, params)
  },
  flowedKeywordAnalysis(params: any):Promise<AxiosResponse<ResponseData<IFlowedKeywordAnalysis[]> & PaginationResponse, any>> {
    const url = `${path}/flowed-keywords`
    return api.get(url, params)
  },
  updateStatusFlowedKeywordAnalysis(data: any = {}):Promise<AxiosResponse<ResponseData<IFlowedKeywordAnalysis[]>, any>>  {
    const url = `${path}/flowed-keywords`
    return api.put(url, data)
  },
  dataChartForKeywords(params: any):Promise<AxiosResponse<ResponseData<IChartForKeyword[]>, any>> {
    const url = `${path}/analytics/chart-keywords`
    return api.get(url, params)
  },
  websiteStatistics(params: any):Promise<AxiosResponse<ResponseData<IWebsiteStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/medias`
    return api.get(url, params)
  },
  mediaRankingStatistics(params: any):Promise<AxiosResponse<ResponseData<IMediaRanking[]> & PaginationResponse, any>> {
    const url = `${path}/medias/ranking`
    return api.get(url, params)
  },
  campaignStatistics(params: any):Promise<AxiosResponse<ResponseData<ICampaignStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/campaigns`
    return api.get(url, params)
  },
  categoryStatistics(params: any):Promise<AxiosResponse<ResponseData<ICategoryStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  visitorStatistics(params: any):Promise<AxiosResponse<ResponseData<IVisitorStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/visitors`
    return api.get(url, params)
  },
  revenueStatistics(params: any):Promise<AxiosResponse<ResponseData<IRevenueStatistic[]> & PaginationResponse, any>> {
    const url = `partner-ads/revenue-statistics`
    return api.get(url, params)
  },
}
export default statisticApi
