import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import carApi from "api/carApi";
import { formatQueryParams } from "helpers/format";

export const getModels = createAsyncThunk(
  "models",
  async (params: any = {}) => {
    try {
      const response = await carApi.models(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllModels = async (params: any = {}) => {
  try {
    const response = await carApi.allModels(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const getBrands = createAsyncThunk(
  "brands",
  async (params: any = {}) => {
    try {
      const response = await carApi.brands(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllBrands = async (params: any = {}) => {
  try {
    const response = await carApi.allBrands(params);
    return response;
  } catch (error) {
    return error;
  }
};
export const getAllCarType = async (params: any = {}) => {
  try {
    const response = await carApi.allCarType(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCategoriesCar = createAsyncThunk(
  "cars/getCategoriesCar",
  async (params: any = {}) => {
    try {
      const response = await carApi.categories(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCars = createAsyncThunk("cars", async (params: any = {}) => {
  try {
    const response = await carApi.cars(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const importCars = createAsyncThunk(
  "cars/import",
  async (data: any = {}) => {
    try {
      const response = await carApi.importCars(formatQueryParams(data));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllCategoriesCarNormal = async (params: any = { type: "car" }) => {
  try {
    const response = await carApi.searchCategories(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCarKeywordsByNormal = async (params: any = {}) => {
  try {
    const response = await carApi.cars(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const importCarsByNormal = async (data: any = {}) => {
  try {
    const response = await carApi.importCars(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCarKeyword = async (id: any, data: any = {}) => {
  try {
    const response = await carApi.updateCarKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};