import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input
} from "reactstrap";

// redux
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import SimpleBar from "simplebar-react";
import { getAllCategories as onGetAllCategories } from "../../store/thunks";
import Loader from "./Loader";

interface Option {
  label: string;
  value: string;
}

interface SearchKeywordProps {
  name?: string,
  initialValue?: Option | null | undefined;
  onChangeCategory?: (params: any) => void;
  filterByTopics?: string[];
  isClearable?: boolean,
  disabled?: boolean
}

const SearchKeyword = ({
  name = '',
  initialValue,
  onChangeCategory,
  filterByTopics = [],
  isClearable = false,
  disabled = false
}: SearchKeywordProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [keywordSearch, setKeywordSearch] = useState<string | null>('');
  const [sub1, setSub1] = useState<string>('');
  const [sub2, setSub2] = useState<string>('');
  const [sub3, setSub3] = useState<string>('');

  const onChangeValueCategory = useCallback((sub1: any = {}, sub2: any = {}, sub3: any = {}) => {
    let subs = [];
    let keyword_count: string = '';
    let value: string | number = '';
    subs.push(sub1?.name);
    setSub1((_prev) => String(sub1?.category_id));
    keyword_count = sub1?.keyword_count;
    value = sub1?.category_id;
    if (sub2?.category_id) {
      subs.push(sub2?.name);
      setSub2((_prev) => String(sub2?.category_id));
      keyword_count = sub2?.keyword_count;
      value = sub2?.category_id;
    }
    if (sub3?.category_id) {
      subs.push(sub3?.name);
      setSub3((_prev) => String(sub3?.category_id));
      keyword_count = sub3?.keyword_count;
      value = sub3?.category_id;
    }
    onChangeCategory && onChangeCategory({
      label: `${subs.join(' > ')}  (${keyword_count})`,
      value: String(value),
    });
    setKeywordSearch((_prev) => '');
  }, [onChangeCategory]);

  const selectCategoryState = (state: any) => state.Naver;
  const categoryProperties = createSelector(selectCategoryState, (state) => ({
    categories: state.allCategories,
    isCategorySuccess: state.isCategorySuccess,
    error: state.error,
  }));

  const { categories, error } = useSelector(categoryProperties);

  const checkIncludeItem = (key: string | null, item: any): boolean => {
    if (String(item?.name).toUpperCase().includes(String(key).toUpperCase())) {
      return true;
    }
    if (item?.subs?.length > 0) {
      return item?.subs?.filter((sub: any) => checkIncludeItem(key, sub))?.length > 0;
    }
    return false;
  };

  const checkIncludeItemById = (itemId: string | number | null, item: any): boolean => {
    if (String(item?.category_id) === String(itemId)) {
      return true;
    }
    if (item?.subs?.length > 0) {
      return item?.subs?.filter((sub: any) => checkIncludeItemById(itemId, sub))?.length > 0;
    }
    return false;
  };

  const getOptionCategoryById = (itemId: string | number | null, arr: any, result: any = []): any => {
    const cate = arr?.filter((sub: any) => checkIncludeItemById(itemId, sub))[0] || {};
    if (cate?.subs?.length > 0) {
      return getOptionCategoryById(itemId, cate?.subs, [...result, cate]);
    }
    return [...result, cate];
  }

  const handleClearable = () => {
    setSub1((prev) => "");
    setSub2((prev) => "");
    setSub3((prev) => "");
    onChangeCategory && onChangeCategory(null);
  }

  useEffect(() => {
    const params: any = {
      page: 1,
      limit: 100,
    };
    dispatch(onGetAllCategories(params));
  }, [dispatch]);

  // useEffect(() => {
  //   if (initialValue && (initialValue?.label === '') && categories?.length > 0) {
  //     const cate = getOptionCategoryById(initialValue?.value, categories) || [];
  //     onChangeValueCategory(cate[0], cate[1], cate[2]);
  //   }
  // }, [initialValue, categories]);

  return (
    <React.Fragment>
      <Dropdown isOpen={isOpen || !!keywordSearch} toggle={() => {
        if (isOpen) { setKeywordSearch((_prev) => '') }
        setOpen((_prev) => !isOpen);
      }}>
        <div className="keyword-search">
          <DropdownToggle tag="div" className="position-relative">
            <Input
              value={keywordSearch || ''}
              type="text"
              onChange={(e: any) => setKeywordSearch(e.target.value)}
              placeholder=""
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 'var(--vz-border-radius)',
                zIndex: 2,
                background: 'transparent',
                padding: '2px 10px',
              }}
            />
            {(isClearable && !!initialValue?.value && !disabled) && (
              <span style={{
                position: 'absolute',
                top: 0,
                right: 38,
                bottom: 0,
                borderRadius: 'var(--vz-border-radius)',
                zIndex: 3,
                background: 'transparent',
                transition: 'color 150ms',
                padding: '8px',
                boxSizing: 'border-box',
              }}
                className="btn-clearable-value"
                onClick={(e) => {
                  e.preventDefault();
                  handleClearable()
                }
                }
              >
                <svg height="20" width="20" viewBox="0 0 20 20" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
              </span>)}
            <Select
              name={name}
              options={[]}
              value={keywordSearch ? null : initialValue}
              placeholder={<div>{keywordSearch ? '' : t('Select category')}</div>}
              components={{ Menu: () => <></> }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu py-0"
            style={{ width: "auto", minWidth: '250px', maxWidth: "300px", }}
          >
            {categories && categories?.length ? (
              categories.map((item: any, index: number) => (
                <div className={`keyword-wrapper ${checkIncludeItem(keywordSearch, item) ? '' : 'd-none'}`} key={`${item.category_id}`}>
                  <div
                    key={`${item.category_id}`}
                    className={`${!!keywordSearch && 'result-search-menu'}`}
                    onClick={(e) => { e.stopPropagation(); onChangeValueCategory(item) }}
                  >
                    <DropdownItem
                      tag='div'
                      className="flex-between-center keyword-item cursor-pointer"
                      active={sub1 === item.category_id}
                    >
                      <div className="d-flex">{item.name} <div className="drop ms-2">{item.keyword_count}</div></div>
                      {(item?.subs && item?.subs?.length > 0) && <i className="ri-arrow-right-line align-middle fs-18 text-muted" />}
                    </DropdownItem>
                  </div>

                  {item?.subs && item?.subs?.length > 0 && (
                    <DropdownMenu className={`dropdown-submenu py-0`}
                      style={{ width: "auto", minWidth: '250px', maxWidth: "300px", }}
                    >
                      {item?.subs?.map((sub: any, ii: number) => (
                        <div className={`keyword-wrapper__child ${checkIncludeItem(keywordSearch, sub) ? '' : 'd-none'}`} key={`${sub.category_id}`}>
                          <div
                            key={`${sub.category_id}`}
                            className={`${!!keywordSearch && 'result-search-sub'}`}
                            onClick={(e) => { e.stopPropagation(); onChangeValueCategory(item, sub) }}
                          >
                            <DropdownItem
                              tag='div'
                              className="flex-between-center keyword-item__child cursor-pointer"
                              active={sub2 === sub.category_id}

                            >
                              <div className="d-flex">{sub.name} <div className="drop ms-2">{sub.keyword_count}</div></div>
                              {sub?.subs && sub?.subs?.length > 0 && (
                                <i className="ri-arrow-right-line align-middle fs-18 text-muted" />
                              )}
                            </DropdownItem>
                          </div>

                          {sub?.subs && sub?.subs?.length > 0 && (
                            <DropdownMenu className={`dropdown-submenu__child py-0`}
                              style={{ width: "auto", minWidth: '320px', maxWidth: "360px", }}
                            >
                              <SimpleBar style={{ maxHeight: "440px" }}>
                                {sub?.subs?.map((s: any, i: number) => (
                                  <div
                                    key={`${s.category_id}`}
                                    className={`${!!keywordSearch && 'result-search-sub__child'}  ${checkIncludeItem(keywordSearch, s) ? '' : 'd-none'}`}
                                    onClick={(e) => { e.stopPropagation(); onChangeValueCategory(item, sub, s) }}
                                  >
                                    <DropdownItem
                                      tag='div'
                                      className="flex-between-center cursor-pointer"
                                      active={sub3 === s.category_id}

                                    >
                                      <div className="d-flex">{s.name} <div className="drop ms-2">{s.keyword_count}</div></div>
                                    </DropdownItem>
                                  </div>

                                ))}
                              </SimpleBar>
                            </DropdownMenu>
                          )}
                        </div>

                      ))}
                    </DropdownMenu>
                  )}
                </div>
              ))
            ) : (
              <Loader error={error} />
            )}
          </DropdownMenu>
        </div>
      </Dropdown>
    </React.Fragment>
  );

};

export default SearchKeyword;