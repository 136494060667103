import { Option } from 'api/types/_public';
import { ICategoryStock, IStock } from 'api/types/_stock';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownStock from 'components/Common/DropdownStock';
import TableContainer from 'components/Common/TableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getAllCategoriesStockNormal, getAllStocks, getStocks as onGetStocks } from "../../../store/thunks";
import DropdownCategoryMultiSub from 'components/Common/DropdownCategoryMultiSub';
import { COLORS_STOCK_STATUS_CHANGE, STATUS_CHANGE_STOCK_OPTIONS, TYPE_STOCK_OPTIONS } from 'helpers/constans';
import DropdownOption from 'components/Common/DropdownOption';
import CollapseTag from 'components/Common/CollapseTag';
import { formatNumberWithCommas } from 'helpers/format';
import DropdownStatusChangeStock from 'components/Common/DropdownStatusChangeStock';
import LabelWrapper from 'components/Common/LabelWrapper';

const typeQuery = {
  'acml_vol': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'acml_vol';
registerLocale('en', en);
registerLocale('ko', ko);

const TODAY_DATE_DEFAULT = `${moment().format("Y-MM-DD")}`;

const StockKeyword = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const TYPE_STOCK_OPTIONS_LANG = TYPE_STOCK_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_CHANGE_STOCK_OPTIONS_LANG = STATUS_CHANGE_STOCK_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: withDefault(NumberParam, null),
    stock_type: withDefault(StringParam, ''),
    prdy_vrss_sign: withDefault(StringParam, ''),
    stock_code: withDefault(StringParam, ''),
    date_version: withDefault(StringParam, TODAY_DATE_DEFAULT),
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<string>(query?.date_version || TODAY_DATE_DEFAULT);

  const [stockCodeSearch, setStockCodeSearch] = useState<Option | null>(null);
  const [listStockCodes, setListStockCodes] = useState<IStock[]>([]);

  const [listCategoriesStock, setListCategoriesStock] = useState<ICategoryStock[]>([]);

  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [typeStockSearch, setTypeStockSearch] = useState<Option | null>(TYPE_STOCK_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.stock_type))[0]);

  const [statusChangeSearch, setStatusChangeSearch] = useState<Option | null>(STATUS_CHANGE_STOCK_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.prdy_vrss_sign))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Stock;

  const StockProperties = createSelector(
    selectLayoutState,
    (state) => ({
      stocks: state.stocks,
      isStocksSuccess: state.isStocksSuccess,
      isStocksLoading: state.isStocksLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { stocks, isStocksLoading } = useSelector(StockProperties);

  useEffect(() => {
    dispatch(onGetStocks(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      stock_code: stockCodeSearch?.value ?? undefined,
      date_version: dateSearch,
      sort_by: TYPE_SELECT_DEFAULT,
      category_id: categorySearch?.value,
      stock_type: typeStockSearch?.value,
      prdy_vrss_sign: statusChangeSearch?.value,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      stock_code: undefined,
      category_id: undefined,
      stock_type: '',
      prdy_vrss_sign: '',
      time_request: + new Date()
    }, "push")
    setStockCodeSearch(null);
    setCategorySearch(null);
    setTypeStockSearch(null);
    setStatusChangeSearch(null);
    setDateSearch(TODAY_DATE_DEFAULT);
  };


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Index Trend"),
        accessor: "naver_trend",
        filterable: false,
        sortable: true,
        thWidth: 110,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          );
        },
      },
      {
        Header: t('Stock'),
        accessor: "stock",
        filterable: false,
        sortable: false,
        thClass: "text-start",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer w-100 text-start" style={{ minWidth: '80px' }}>
                <span>
                  {item?.name}
                </span>
                <br />
                <span className='text-primary'>
                  {item?.code}
                </span>
              </div >
            </>
          )
        },
      },
      {
        Header: t('Stock Type'),
        accessor: "type",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        thWidth: 80,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const color = (item?.type === 1) ? 'success' : 'primary';
          return (
            <>
              <div className="cursor-pointer w-100 text-center" style={{ minWidth: '60px' }}>
                <span className={`badge bg-${color}`}>
                  {TYPE_STOCK_OPTIONS_LANG?.filter((el) => String(el?.value) === String(item?.type))[0]?.label || ''}
                </span>
              </div >
            </>
          )
        },
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            if (category?.depth > 1) {
              let subs = [];
              for (let sub of category?.group_categories) {
                subs.push(sub?.name);
              }
              subs.push(category?.name);
              categoryName.push(subs.join(` > `));
            }
          }

          return (
            <div style={{ minWidth: '100px' }}>
              <CollapseTag tags={categoryName} isRank={false} isInlineBlock={true} totalShow={2} isBackground={false} isShowModal={true} titleModal={t('Categories')} />
            </div>
          );
        },
      },
      {
        Header: t('stck_prpr'),
        accessor: "stck_prpr",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '80px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('prdy_vrss'),
        accessor: "prdy_vrss",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className={`text-end pe-3 ${Number(cell?.value) < 0 ? 'text-primary' : 'text-danger'}`} style={{ minWidth: '80px' }}>
                {Number(cell?.value) !== 0 && (Number(cell?.value) > 0 ? <span className="text-danger" style={{ fontSize: '14px', marginRight: '3px' }}>▲</span> : <span className="text-primary" style={{ fontSize: '13px', marginRight: '2px' }}>▼</span>)}
                {formatNumberWithCommas(cell?.value || 0)}
              </div>
            </>
          )
        },
      },
      {
        Header: t('prdy_vrss_sign'),
        accessor: "prdy_vrss_sign",
        filterable: false,
        sortable: true,
        thClass: "text-center",
        thWidth: 80,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const color = COLORS_STOCK_STATUS_CHANGE[Number(item?.prdy_vrss_sign || 1)];
          return (
            <>
              <div className="cursor-pointer w-100 text-center" style={{ minWidth: '100px' }}>
                <span className={`badge bg-${color}`}>
                  {STATUS_CHANGE_STOCK_OPTIONS_LANG?.filter((el) => String(el?.value) === String(item?.prdy_vrss_sign))[0]?.label || ''}
                </span>
              </div >
            </>
          )
        },
      },
      {
        Header: t('prdy_ctrt'),
        accessor: "prdy_ctrt",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className={`text-end pe-3 ${Number(cell?.value) < 0 ? 'text-primary' : 'text-danger'}`} style={{ minWidth: '80px' }}>
                {formatNumberWithCommas(cell?.value || 0)}%
              </div>
            </>
          )
        },
      },
      {
        Header: t('prdy_ctrt_minus_1'),
        accessor: "prdy_ctrt_minus_1",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className={`text-end pe-3 ${Number(cell?.value) < 0 ? 'text-primary' : 'text-danger'}`} style={{ minWidth: '80px' }}>
                {formatNumberWithCommas(cell?.value || 0)}%
              </div>
            </>
          )
        },
      },
      {
        Header: t('acml_vol'),
        accessor: "acml_vol",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '110px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('acml_tr_pbmn'),
        accessor: "acml_tr_pbmn",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '110px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('prdy_vol'),
        accessor: "prdy_vol",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('hts_avls'),
        accessor: "hts_avls",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('prsn_ntby_qty'),
        accessor: "prsn_ntby_qty",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '140px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('prsn_seln_vol'),
        accessor: "prsn_seln_vol",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '110px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('orgn_ntby_qty'),
        accessor: "orgn_ntby_qty",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '150px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('orgn_seln_vol'),
        accessor: "orgn_seln_vol",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('frgn_ntby_qty'),
        accessor: "frgn_ntby_qty",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '150px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },
      {
        Header: t('frgn_seln_vol'),
        accessor: "frgn_seln_vol",
        filterable: false,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end pe-3" style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
            </>
          )
        },
      },


    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, TYPE_STOCK_OPTIONS_LANG, STATUS_CHANGE_STOCK_OPTIONS_LANG, COLORS_STOCK_STATUS_CHANGE]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resStocks, resCategoriesStock]: any = await Promise.all([getAllStocks(), getAllCategoriesStockNormal()]);
      setListStockCodes((_prev) => resStocks?.data || []);
      setListCategoriesStock((_prev) => resCategoriesStock?.data || []);
      // Begin::set value default for form filter
      const stock_code = String(query?.stock_code || '');
      const optionStock = (resStocks?.data || [])?.filter((item: any) => String(item?.code || '') === stock_code)[0];
      setStockCodeSearch((_prev) => optionStock ? ({ value: String(optionStock?.code), label: `${optionStock?.name} / ${optionStock?.code} / ${optionStock?.type_name}` }) : null);
      // End::set value default for form filter
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!stockCodeSearch?.value) {
      setStockCodeSearch((prev) => null);
    }

    setTypeStockSearch((_prev: any) => TYPE_STOCK_OPTIONS_LANG?.filter((e: any) => e.value === typeStockSearch?.value)[0]);

    if (statusChangeSearch?.value) {
      setStatusChangeSearch((_prev: any) => STATUS_CHANGE_STOCK_OPTIONS_LANG?.filter((e: any) => e.value === statusChangeSearch?.value)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Stock')} - ${t('Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Stock')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.STOCK_KEYWORD_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-2">
                        <LabelWrapper label={t('version_date')} isShow={!!dateSearch}>
                          <DatePicker
                            className="form-control search"
                            placeholderText={`${t('version_date')}...`}
                            value={dateSearch || undefined}
                            selected={new Date(dateSearch) || undefined}
                            dateFormat="yyyy-MM-dd"
                            isClearable={true}
                            maxDate={new Date()}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="mt-2">
                        <LabelWrapper label={t('Category')} isShow={!!categorySearch?.value}>
                          <DropdownCategoryMultiSub
                            dataList={listCategoriesStock}
                            initialValue={categorySearch || undefined}
                            onChangeCategory={(event: any) => {
                              setCategorySearch(event);
                            }}
                            isClearable={true}
                          />
                        </LabelWrapper>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} md={3} lg={3} className='mt-2'>
                        <LabelWrapper label={t('Stock Type')} isShow={!!typeStockSearch?.value}>
                          <DropdownOption
                            name="type"
                            dataList={TYPE_STOCK_OPTIONS_LANG || []}
                            placeholder={`${t("Stock Type")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={typeStockSearch || null}
                            onChangeSelect={(e: any) => setTypeStockSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Type Stock'), value: '' }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className='mt-2'>
                        <LabelWrapper label={t('Stock')} isShow={!!stockCodeSearch?.value}>
                          <DropdownStock
                            dataList={listStockCodes}
                            initialValue={stockCodeSearch}
                            onChangeSelect={(event: any) => {
                              setStockCodeSearch((_prev) => event);
                            }}
                            isHasOptionAll={true}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={3} lg={3} className='mt-2'>
                        <LabelWrapper label={t('Status')} isShow={!!statusChangeSearch?.value}>
                          <DropdownStatusChangeStock
                            name="status"
                            dataList={STATUS_CHANGE_STOCK_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={statusChangeSearch || null}
                            onChangeSelect={(e: any) => setStatusChangeSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Status'), value: '' }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isStocksLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={stocks?.list?.length ? stocks?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(Number(stocks?.pagination?.total) / Number(stocks?.pagination?.limit))}
                        customPageIndex={query.page - 1}
                        totalRecords={stocks?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isStocksLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StockKeyword;
