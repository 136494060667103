import { ICategory } from 'api/types/_category';
import { IPreviewNews } from 'api/types/_news';
import { IChart, ITemplate } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import CategoryType from 'components/Common/CategoryType';
import DropdownCategoryKeyword from 'components/Common/DropdownCategoryKeyword';
import DropdownOption from 'components/Common/DropdownOption';
import ModalConfirm from 'components/Common/ModalConfirm';
import ScheduleType from 'components/Common/ScheduleType';
import SearchParameter from 'components/Common/SearchParameter';
import SearchTemplateChart from 'components/Common/SearchTemplateChart';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import { SCHEDULE_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteTemplate, getAllCategoriesCarNormal, getAllCategoriesCoinNormal, getAllCategoriesStockNormal, getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllCharts, getAllParameters as onGetAllParameters, getTemplates as onGetTemplates, postTemplate, previewTemplate, putTemplate } from "../../../store/thunks";
import CountUp from 'react-countup';
import LabelWrapper from 'components/Common/LabelWrapper';

const typeQuery = {
  'id': StringParam,
  'schedule': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';
registerLocale('en', en);
registerLocale('ko', ko);

const modules = {
  toolbar: [
    // [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, false] }], //
    ["bold", "italic", "underline", "blockquote"],
    // [{ background: [] }],
    [{ align: "right" }, { align: "center" }, { align: "justify" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
  ]
};
const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font"
];

interface Option {
  label: string;
  value: string;
}

const TemplateListNewsContent = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.reduce((arr: any, item: any) => {
    return item?.value !== 'now' ? ([...arr, ({ value: item?.value, label: t(item?.label) })]) : arr;
  }, []);

  const CATEGORIES_REQUIRED_INFO_GRAPHIC = ['trend_index'];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    type: withDefault(StringParam, `body`),
    category_type: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    name: StringParam,
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFullView, setIsFullView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [initialValuesDefault, setInitialValuesDefault] = useState<ITemplate & { isCopy?: boolean } | null>(null);

  const [linkImage, setLinkImage] = useState<string>('');

  const [listChart, setListChart] = useState<IChart[]>([]);

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [scheduleTypeSearch, setScheduleTypeSearch] = useState<Option | null>(null);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const listCategoriesTypeLang = listCategoriesType?.map((item: any) => ({ ...item, label: t(`Category_Type_[${item?.value}]_For_Campaign`) }));

  const [listCategoriesStock, setListCategoriesStock] = useState<ICategory[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  const [listCategoriesCar, setListCategoriesCar] = useState<ICategory[]>([]);

  const [listCategoriesCoin, setListCategoriesCoin] = useState<ICategory[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Template;

  const TemplateProperties = createSelector(
    selectLayoutState,
    (state) => ({
      templates: state.templates,
      parameters: state.allParameter,
      isTemplateSuccess: state.isTemplateSuccess,
      isTemplateLoading: state.isTemplateLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { templates, parameters, isTemplateLoading } = useSelector(TemplateProperties);


  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      category_type: categoryTypeSearch?.value || '',
      schedule: scheduleTypeSearch?.value || '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      type: 'body',
      name: '',
      category_type: undefined,
      schedule: undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
    setCategoryTypeSearch(null);
    setScheduleTypeSearch(null);
  };

  // Form
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        type: 'body',
        category_type: values?.category_type?.value ?? '',
        schedule: values?.schedule?.value ?? '',
        name: values?.name ?? '',
        content: values?.content ?? '',
        chart_code: values?.chart_template_id?.value ?? '',
        chart_title: values?.info_graphic ?? '',
        chart_source: values?.info_graphic_source ?? '',
        table_title: values?.info_table ?? '',
        sample_content: ''
      };
      const response: any = (!!(initialValuesDefault?.id) && !(initialValuesDefault?.isCopy)) ? await putTemplate(initialValuesDefault?.id, data) : await postTemplate(data);
      if (response?.data) {
        dispatch(onGetTemplates(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleResetForm();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    category_type: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Category Type is required')}`),
    schedule: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Schedule is required')}`),
    name: Yup.string().required(`${t('Template Name is required')}`),
    content: Yup.string().required(`${t('Content is required')}`),
    sample_content: Yup.string().nullable(),
    chart_template_id: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).when("category_type", {
      is: (category_type: any) => (CATEGORIES_REQUIRED_INFO_GRAPHIC?.includes(category_type?.value)),
      then: (schema) => Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).required(`${t('Info-Graphic is required')}`),
      otherwise: (schema) =>
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).nullable(),
    }),
    info_graphic: Yup.string().when("category_type", {
      is: (category_type: any) => (CATEGORIES_REQUIRED_INFO_GRAPHIC?.includes(category_type?.value)),
      then: (schema) =>
        Yup.string().required(`${t('Info-Graphic Title is required')}`),
      otherwise: (schema) =>
        Yup.string().nullable(),
    }),
    info_graphic_source: Yup.string().nullable(),
    info_table: Yup.string().required(`${t('Data-Table Title is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      category_type: null,
      schedule: null,
      name: '',
      content: '',
      chart_template_id: null,
      info_graphic: '',
      info_graphic_source: '',
      info_table: '',
      sample_content: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });


  //@ts-ignore
  const initialValueInfoGraphic = formik?.values?.info_graphic as Option;

  //@ts-ignore
  const initialValueInfoTable = formik?.values?.info_table as Option;

  //@ts-ignore
  const initialValueInfoGraphicSource = formik?.values?.info_graphic_source as Option;

  //@ts-ignore
  const initialValueChartTemplate = formik?.values?.chart_template_id as Option;

  const handleEditTemplate = (valueDefault: ITemplate & { isCopy?: boolean }) => {
    setInitialValuesDefault((_prev) => valueDefault);
    setIsOpen((_prev) => true);
    const vChartTemplate = ({ label: valueDefault?.chart?.name, value: valueDefault?.chart?.code, image: valueDefault?.chart?.image_url });
    const vInfoGraphic = valueDefault?.chart_title;
    const vInfoGraphicSource = valueDefault?.chart_source;
    const vInfoTable = valueDefault?.table_title;
    const vCategoryType = listCategoriesTypeLang.filter((item) => String(valueDefault?.category_type) === String(item?.value))[0] || listCategoriesTypeLang[0];
    const vSchedule = SCHEDULE_OPTIONS_LANG.filter((item: any) => String(valueDefault?.schedule) === String(item?.value))[0] || SCHEDULE_OPTIONS_LANG[0];
    formik.setFieldValue('category_type', vCategoryType);
    formik.setFieldValue('schedule', vSchedule);
    formik.setFieldValue('name', valueDefault?.name);
    formik.setFieldValue('content', valueDefault?.content);
    formik.setFieldValue('sample_content', valueDefault?.sample_content);
    formik.setFieldValue('chart_template_id', vChartTemplate);
    formik.setFieldValue('info_graphic', vInfoGraphic);
    formik.setFieldValue('info_table', vInfoTable);
    formik.setFieldValue('info_graphic_source', vInfoGraphicSource);

    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleResetForm = () => {
    formik.setFieldValue('category_type', listCategoriesTypeLang[0]);
    formik.setFieldValue('schedule', SCHEDULE_OPTIONS_LANG[0]);
    formik.setFieldValue('name', null);
    formik.setFieldValue('content', null);
    formik.setFieldValue('sample_content', null);
    formik.setFieldValue('chart_template_id', null);
    formik.setFieldValue('info_graphic', null);
    formik.setFieldValue('info_table', null);
    formik.setFieldValue('info_graphic_source', null);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleCreateTemplate = () => {
    setInitialValuesDefault((_prev) => null);
    setIsOpen((_prev) => true);
    formik.setErrors({});
  }

  // Begin::Delete
  const handleConfirmDelete = (item: ITemplate) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteTemplate(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetTemplates(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Begin::Preview Template
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);
  const [valueCategoryToPreview, setValueCategoryToPreview] = useState<any>(null);
  const [dataPreview, setDataPreview] = useState<IPreviewNews | null>(null);

  const handleOnClosePreview = () => {
    setIsOpenPreview((_prev) => false);
    setValueCategoryToPreview((_prev: any) => null);
  }

  const onClosePreviewClick = () => {
    setDataPreview((_prev) => null);
  }

  const handleActionPreview = async () => {
    const values: any = formik?.values || {};
    if (['trend_index', 'stock', 'car', 'coin']?.includes(values?.category_type?.value) && valueCategoryToPreview === null) {
      setIsOpenPreview((_prev) => true);
      return;
    }
    try {
      setIsLoadingPreview((_prev) => true);
      const data = {
        category_type: values?.category_type?.value ?? '',
        category_id: valueCategoryToPreview?.value || '', // required when category_type is : trend_index, stock
        template: {
          content: values?.content ?? '',
          chart_code: values?.chart_template_id?.value ?? '',
          chart_title: values?.info_graphic ?? '',
          table_title: values?.info_table ?? '',
          chart_source: values?.info_graphic_source ?? ''
        },
        is_random_chart: false,
        keyword_ids: []
      };
      const response: any = await previewTemplate(data);
      if (response?.code === 200) {
        setIsLoadingPreview((_prev) => false);
        setDataPreview((_prev: any) => response?.data);
      } else {
        setIsLoadingPreview((_prev) => false);
        setDataPreview((_prev: any) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingPreview((_prev) => false);
      setDataPreview((_prev: any) => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End:: Preview Template

  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen((_prev) => false);
    setInitialValuesDefault((_prev) => null);
    setTimeout(() => {
      formik.setFieldValue('category_type', listCategoriesTypeLang[0]);
      formik.setFieldValue('schedule', SCHEDULE_OPTIONS_LANG[0]);
      formik.setFieldValue('name', null);
      formik.setFieldValue('content', null);
      formik.setFieldValue('sample_content', null);
      formik.setFieldValue('chart_template_id', null);
      formik.setFieldValue('info_graphic', null);
      formik.setFieldValue('info_table', null);
      formik.setFieldValue('info_graphic_source', null);

    }, 300);
  };

  const handleCallAllOption = async () => {
    try {
      const [resChart, resCategoriesType, resCategoriesStock, resCategoriesTrendIndex, resCategoriesCar, resCategoriesCoin]: any = await Promise.all([getAllCharts(), getAllCategoriesType(), getAllCategoriesStockNormal(), getAllCategoriesTrendIndexNormal(), getAllCategoriesCarNormal(), getAllCategoriesCoinNormal()]);
      setListChart((_prev) => resChart?.data || []);
      setListCategoriesType((_prev) => (resCategoriesType?.data?.map((item: any) => ({ value: item?.type, label: item?.name })) || []));
      setListCategoriesStock((_prev) => resCategoriesStock?.data || []);
      setListCategoriesTrendIndex((_prev) => resCategoriesTrendIndex?.data || []);
      setListCategoriesCar((_prev) => resCategoriesCar?.data || []);
      setListCategoriesCoin((_prev) => resCategoriesCoin?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  const onCloseImageClick = (e: any) => {
    setLinkImage((_prev) => '');
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  // Effect
  useEffect(() => {
    dispatch(onGetTemplates(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(onGetAllParameters({}));
  }, []);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: true,
        thWidth: 70,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '60px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Category Type'),
        accessor: "category_type",
        filterable: false,
        sortable: false,
        thWidth: 160,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }}>{cell?.value ? t(`Category_Type_[${cell?.value}]_For_Campaign`) : ''}</div>
            </>
          )
        },
      },
      {
        Header: t('Info-Graphic'),
        accessor: "chart",
        thClass: 'text-center',
        filterable: true,
        sortable: false,
        thWidth: 140,
        Cell: (cell: any) => {
          const chart = cell?.row?.original?.chart;
          return (
            <>
              <div className="w-100 text-center">
                <div style={{ width: '90px' }} className="m-auto cursor-pointer" onClick={() => setLinkImage((prev) => chart?.image_url)}>
                  <img src={chart?.image_url || ''} className="flex-shrink-0 rounded w-100" alt={chart?.name_korea} />
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: t('Template Name'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="limit-text-to-two-line cursor-pointer" onClick={() => handleEditTemplate(item)} style={{ minWidth: '100px' }}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Schedule'),
        accessor: "schedule",
        filterable: true,
        sortable: false,
        thWidth: 140,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '60px' }} className="text-center">{SCHEDULE_OPTIONS_LANG?.filter((item: any) => (String(item?.value) === String(cell?.value)))[0]?.label || ''}</div>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 140,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0" style={{ width: '100px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_BODY_UPDATE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Update Template')}
                  id={`update-tpl-ctn-${item?.id}`}
                >
                  <li className="list-inline-item" id={`update-tpl-ctn-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary  edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleEditTemplate(item) }}
                    >
                      <i className="ri-pencil-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>)}
              {isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_BODY_UPDATE, userPermissions) && (<TooltipCustom
                title={t('Button Copy Template')}
                id={`copy-cp-${item?.id}`}
              >
                <li className="list-inline-item" id={`copy-cp-${item?.id}`}>
                  <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); handleEditTemplate({ ...item, isCopy: true }) }}
                  >
                    <i className="ri-file-copy-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>)}
              {isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_BODY_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete Template')}
                  id={`delete-tpl-ctn-${item?.id}`}
                >
                  <li className=" list-inline-item" id={`delete-tpl-ctn-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary  edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>)}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, listCategoriesTypeLang, SCHEDULE_OPTIONS_LANG, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  useEffect(() => {
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    if (scheduleTypeSearch?.value) {
      setScheduleTypeSearch((_prev: any) =>
        SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === scheduleTypeSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Template News Content')} - ${t('Template')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Template News Content')} pageTitle={t('Template')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_BODY_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-0 bg-primary-subtle text-primary border-0 d-none d-md-block" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isTemplateLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={templates?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row>
                          {isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_TITLE_CREATE, userPermissions) && (
                            <Col lg={12} className="text-end mb-3 d-block d-md-none">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={handleCreateTemplate}
                              >
                                <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                {t('Button Create Template')}
                              </button>
                            </Col>)}
                          <Col sm={12} md={6} lg={3} xl={3} className="mb-2 mb-md-0">
                            <LabelWrapper label={t('Category Type')} isShow={!!categoryTypeSearch?.value}>
                              <DropdownOption
                                name="category-type"
                                dataList={listCategoriesTypeLang?.filter((item) => !['keyword_popular', 'keyword_core']?.includes(item?.value)) || []}
                                placeholder={`${t("Category Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={categoryTypeSearch || null}
                                onChangeSelect={(e: any) => setCategoryTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Category Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={6} lg={3} xl={3} className="mt-2 mt-md-0 mb-2 mb-md-0">
                            <LabelWrapper label={t('Schedule')} isShow={!!scheduleTypeSearch?.value}>
                              <DropdownOption
                                name="schedule"
                                dataList={SCHEDULE_OPTIONS_LANG || []}
                                placeholder={`${t("Schedule")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={scheduleTypeSearch || null}
                                onChangeSelect={(e: any) => setScheduleTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Schedule'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mt-0">
                          <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                            <LabelWrapper label={t('Template Name')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Template Name')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={12} lg={6} xl={6} className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isTemplateLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                            {isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_TITLE_CREATE, userPermissions) && (
                              <button
                                type="button"
                                className="btn btn-success d-none d-md-block"
                                onClick={handleCreateTemplate}
                              >
                                <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                {t('Button Create Template')}
                              </button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={templates?.list?.length ? templates?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(Number(templates?.pagination?.total) / Number(templates?.pagination?.limit))}
                        customPageIndex={query.page - 1}
                        totalRecords={templates?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isTemplateLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={isOpen} centered={true} size="xl" className="modal-fullscreen" scrollable={false}>
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
            {t('Template News Content')}
          </ModalHeader>
          <ModalBody>
            <div className="row g-4">
              <Col md={isFullView ? 1 : 4} className="order-sm-2 order-md-1 px-0 px-md-3 position-relative">
                <SearchParameter data={parameters || []} style={{ minHeight: 'calc(100dvh - 100px)', backgroundColor: '#f3f6f9' }} />
                <div style={{
                  position: 'absolute',
                  top: '0px',
                  right: 4,
                  width: '45px',
                  height: '45px',
                  fontSize: '30px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  backgroundColor: 'var(--vz-primary)',
                  color: 'var(--vz-white)',
                  transform: 'translateX(-12px)',
                  borderTopLeftRadius: '22px',
                  borderBottomLeftRadius: '22px',
                  cursor: 'pointer',
                  boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.02)'
                }}
                  onClick={() => setIsFullView((prev) => !prev)}
                >
                  {isFullView ? <i className="mdi mdi-arrow-right-circle-outline" /> : <i className="mdi mdi-arrow-left-circle-outline" />}
                </div>
              </Col>
              <Col md={isFullView ? 11 : 8} className="order-sm-1 order-md-2 py-3 box-template-form color-scrollbar-os">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row g-3">
                    <Col sm={7} md={8} lg={8} className="d-flex justify-content-between align-items-end">
                      <div style={{ width: '100%' }}>
                        <label className="form-label">{t('Category Type')}<span className="text-danger"> *</span></label>
                        <CategoryType name="category_type" dataList={listCategoriesTypeLang?.filter((item) => !['keyword_popular', 'keyword_core']?.includes(item?.value)) || []} placeholder={`${t('Select Category Type')}...`} initialValue={formik?.values?.category_type || listCategoriesTypeLang[0]} onChangeSelect={(event) => {
                          formik.setFieldValue('category_type', event);
                        }} />
                        {formik.touched.category_type && formik.errors.category_type ? (
                          <div className="text-danger mt-2">{formik.errors.category_type}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={5} md={4} lg={4}>
                      <div>
                        <label className="form-label">
                          {t("Schedule")}
                          <span className="text-danger"> *</span>
                        </label>
                        <ScheduleType
                          name="schedule"
                          dataList={SCHEDULE_OPTIONS_LANG}
                          placeholder={`${t('Select Schedule')}...`}
                          initialValue={formik?.values?.schedule || SCHEDULE_OPTIONS_LANG[0]}
                          onChangeSelect={(event) => {
                            formik.setFieldValue('schedule', event);
                          }}
                        />
                        {formik.touched.schedule && formik.errors.schedule ? (<div className="text-danger mt-2">{formik?.errors?.schedule}</div>) : null}
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">{t('Template Name')}<span className="text-danger"> *</span></label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik?.values?.name}
                        placeholder={`${t('Template Name')}...`}
                        onChange={(event: any) => formik.setFieldValue('name', event?.target?.value || '')}
                      />
                      {formik.touched.name && formik.errors.name ? (<div className="text-danger mt-2">{formik.errors.name}</div>) : null}
                    </Col>
                    <Col sm={12} lg={3} className="d-flex justify-content-between align-items-end">
                      <div style={{ width: '100%' }}>
                        <label className="form-label">{t('Info-Graphic')} {CATEGORIES_REQUIRED_INFO_GRAPHIC?.includes((formik?.values?.category_type as any)?.value) && <span className="text-danger"> *</span>}</label>
                        <SearchTemplateChart name="chart_template_id"
                          templates={listChart}
                          type='title'
                          placeholder={`${t('Info-Graphic')}...`}
                          isClearable={true}
                          initialValue={initialValueChartTemplate ?? undefined}
                          onChangeSelect={(event) => {
                            formik.setFieldValue('chart_template_id', event);
                          }} />
                        {formik.touched.chart_template_id && formik.errors.chart_template_id ? (
                          <div className="text-danger mt-2">{formik.errors.chart_template_id}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12} lg={3}>
                      <div>
                        <label className="form-label">{t('Info-Graphic Title')}{CATEGORIES_REQUIRED_INFO_GRAPHIC?.includes((formik?.values?.category_type as any)?.value) && <span className="text-danger"> *</span>}</label>
                        <Input
                          type="text"
                          id="info_graphic"
                          name="info_graphic"
                          autocomplete={false}
                          value={String(initialValueInfoGraphic ?? '')}
                          placeholder={`${t('Info-Graphic Title')}...`}
                          onChange={(event: any) => formik.setFieldValue('info_graphic', event?.target?.value || '')}
                        />
                        {formik.touched.info_graphic && formik.errors.info_graphic ? (
                          <div className="text-danger mt-2">{formik.errors.info_graphic}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12} lg={3}>
                      <div>
                        <label className="form-label">{t('Info Graphic Source')}</label>
                        <Input
                          type="text"
                          id="info_graphic_source"
                          name="info_graphic_source"
                          autocomplete={false}
                          value={String(initialValueInfoGraphicSource ?? '')}
                          placeholder={`${t('Info Graphic Source')}...`}
                          onChange={(event: any) => formik.setFieldValue('info_graphic_source', event?.target?.value || '')}
                        />
                        {formik.touched.info_graphic_source && formik.errors.info_graphic_source ? (
                          <div className="text-danger mt-2">{formik.errors.info_graphic_source}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12} lg={3}>
                      <div>
                        <label className="form-label">{t('Data-Table Title')}<span className="text-danger"> *</span></label>
                        <Input
                          type="text"
                          id="info_table"
                          name="info_table"
                          autocomplete={false}
                          value={String(initialValueInfoTable ?? '')}
                          placeholder={`${t('Data-Table Title')}...`}
                          onChange={(event: any) => formik.setFieldValue('info_table', event?.target?.value || '')}
                        />
                        {formik.touched.info_table && formik.errors.info_table ? (
                          <div className="text-danger mt-2">{formik.errors.info_table}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">{t('Content News')}<span className="text-danger"> *</span></label>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        className="quill-container-template show-border"
                        defaultValue={formik?.values?.content}
                        onChange={(event: any) => formik.setFieldValue('content', event === '<p><br></p>' ? '' : event)}
                      />
                      {formik.touched.content && formik.errors.content ? (<div className="text-danger mt-2">{formik.errors.content}</div>) : null}
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-success fs-14" color="success" type="button" onClick={() => handleActionPreview()} disabled={isLoadingPreview || Number(Object.keys(formik?.errors)?.length || 0) > 0}>
                          {isLoadingPreview ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-eye-line align-bottom me-1"></i>}
                          {t('Button Preview Template')}
                        </button>
                        <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isLoading || isLoadingPreview || Number(Object.keys(formik?.errors)?.length || 0) > 0}>
                          {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : (!!(initialValuesDefault?.id) ? <i className="ri-login-circle-line align-bottom me-1"></i> : <i className="ri-add-fill align-bottom me-1"></i>)}
                          {(!!(initialValuesDefault?.id) && !initialValuesDefault?.isCopy) ? t('Button Update Template') : t('Button Create Template')}
                        </button>
                        <button className="btn btn-secondary fs-14" type="button" color="light" onClick={onCloseClick} disabled={isLoading}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={!!linkImage} centered={true} size="lg" scrollable={true} toggle={onCloseImageClick} keyboard={true}>
          <ModalBody className="">
            <img src={linkImage || ''} className="flex-shrink-0 rounded w-100" alt="chart" />
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Template')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal isOpen={!!isOpenPreview} centered={true} size="md" keyboard={true}>
          <ModalHeader toggle={isLoadingPreview ? () => { } : handleOnClosePreview}>
            {t("Button Preview Template")}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col sm={12}>
                <div className="row g-3">
                  <Col md={12}>
                    <div>
                      <label className="form-label required">{t('Category')}<span className="text-danger"> *</span>
                      </label>
                      <DropdownCategoryKeyword
                        name="category"
                        categoryType={(formik?.values?.category_type as any)?.value || ''}
                        dataListStock={listCategoriesStock}
                        dataListTrendIndex={listCategoriesTrendIndex}
                        dataListCar={listCategoriesCar}
                        dataListCoin={listCategoriesCoin}
                        initialValue={valueCategoryToPreview ?? null}
                        onChangeCategory={(event) => {
                          setValueCategoryToPreview((prev: any) => event);
                        }}
                      />
                    </div>
                  </Col>
                  <div className="mt-4 text-center">
                    <div className="hstack gap-2 justify-content-end">
                      {isHavePermissionRole([ROLES_FOR_APP.TEMPLATE_BODY_UPDATE, ROLES_FOR_APP.TEMPLATE_BODY_CREATE], userPermissions) && (
                        <button
                          className={`btn btn-primary fs-14`}
                          type="button"
                          onClick={handleActionPreview}
                          color="success"
                          disabled={isLoadingPreview || (valueCategoryToPreview === null)}
                        >
                          {isLoadingPreview ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-eye-line align-bottom me-1"></i>}
                          {t("Button Preview Template")}
                        </button>)}
                      <button
                        className="btn btn-soft-secondary fs-14"
                        type="button"
                        color="light"
                        onClick={handleOnClosePreview}
                        disabled={isLoadingPreview}
                      >
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t("Button Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={!!dataPreview} centered={true} size="xl" scrollable={true} toggle={onClosePreviewClick} keyboard={true} >
          <ModalHeader toggle={onClosePreviewClick}>
            {t('Button Preview Template')}
          </ModalHeader>
          <ModalBody >
            <div className="row g-4">
              <Col md={12}>
                <div >
                  <div className="row g-3">
                    <div style={{ height: 'calc(100dvh - 215px)', margin: 0, overflowY: 'auto' }}>
                      <Col xxl={12} className="ribbon-box right mt-0">
                        {!!dataPreview?.memo?.errors?.length && (
                          <div className="ribbon ribbon-danger round-shape top-0" style={{
                            zIndex: 9,
                            background: 'rgb(255 255 255 / 67%)',
                            color: '#f06548',
                            borderRadius: 0,
                            maxHeight: '30dvh',
                            overflowY: 'auto',
                          }}>
                            <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(dataPreview?.memo?.errors || [])?.map((mess: any, index: number) => (
                              <span key={index}>{`${mess}`}<br /> </span>
                            ))}
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col xxl={12}>
                        <div className="clear-css-p-in-box" dangerouslySetInnerHTML={{ __html: dataPreview?.content || '' }}></div>
                      </Col>
                    </div>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-center">
                        <button className="btn btn-secondary fs-14" type="button" color="light" onClick={onClosePreviewClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default TemplateListNewsContent;