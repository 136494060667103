import { IMedia } from "api/types/_media";
import { IPreviewNews } from "api/types/_news";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownSectionCodeMediaType from "components/Common/DropdownSectionCodeMedia";
import ModalConfirm from "components/Common/ModalConfirm";
import ScheduleType from "components/Common/ScheduleType";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import TooltipCustom from "components/Common/TooltipCustom";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import { ACTION_TYPE_CAMPAIGN } from "helpers/constans";
import { formatNumberWithCommas, renderUrlSearchKeywordInNaver } from "helpers/format";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import { getFlowedKeywordAnalysisNormal, mediaPublishedNewsToday, postCampaignGroups, previewNews, updateStatusFlowedKeywordAnalysis } from "store/thunks";
import * as Yup from 'yup';

export interface Tag {
  id: string;
  text: string;
}

const MAX_AUTO_SELECT_KEYWORDS_ON_PAGE = 50;
const MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA = 100;
const LIMIT_MAX_CALL_PAGE = 15;

interface Props {
  queryDefault?: any,
  listKeyword?: Option[],
  listMedia?: IMedia[],
  listTemplate?: ITemplate[],
  maxNumberAutoSelectKeywordOnPage?: number,
  handleRefetching?: () => void,
}

registerLocale('en', en);
registerLocale('ko', ko);

function getDefaultTime() {
  // Get today's date
  let today = moment().format('YYYY-MM-DD');

  // Default time is set to 09:00
  let defaultTime = moment(today + ' 09:00', 'YYYY-MM-DD HH:mm');

  // Format the time
  return defaultTime.format('YYYY-MM-DD HH:mm');
}

// Function to check if a given date string is more than a week old from the current datefunction isMoreThanAWeekOld(dateString: string) {
function isMoreThanAWeekOld(dateString: string) {
  const oneWeekInDays = 7; // Number of days in one week
  const oneDayInMillis = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  const date: any = new Date(dateString); // Create a Date object from the input string
  const now: any = new Date(); // Current date

  // Remove time from the current date and the given date to compare only dates
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const givenDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Calculate the time difference in days from the given date to the current date
  const timeDiffInDays = Math.floor((currentDate.getTime() - givenDate.getTime()) / oneDayInMillis);

  // If the difference in days is greater than or equal to one week, return true
  return timeDiffInDays >= oneWeekInDays;
}


const CampaignFormByFlowedKeywordAnalysis = ({
  queryDefault = {},
  listMedia = [],
  maxNumberAutoSelectKeywordOnPage = MAX_AUTO_SELECT_KEYWORDS_ON_PAGE,
  handleRefetching,
}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const tableRef = useRef<any>(null);

  const warperTableRef = useRef<any>(null);
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);

  const [isShowBodyTable, setIsShowBodyTable] = useState<boolean>(true);

  const firstLoadPageRef = useRef<boolean>(true);

  const SCHEDULE_OPTIONS_LANG = [{
    label: "Now",
    value: "now",
  }, {
    label: "Date",
    value: "one_time",
  },
  ]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const CATEGORY_TYPE = 'keyword_manual';

  const listMediaAllow = listMedia?.filter((item) => !!item?.website);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

  const [isPreviewLoading, setIsPreviewLoading] = useState<number | null>(null);

  const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(null);

  const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);

  const [stepAction, setStepAction] = useState<number>(1);

  const [query, setQuery] = useState<any>({ ...queryDefault, page: 1, });

  const [keywordAnalysis, setKeywordAnalysis] = useState<any>({});

  const [listMediaKeyword, setListMediaKeyword] = useState<any[]>([]);

  const [statusEachMedia, setStatusEachMedia] = useState<any>({}); // { 1: { id: number, name: string, website: string, number_news_registered: number, number_news_create: number }}

  const totalNewsRegistered = useMemo(() => {
    return Object.entries(statusEachMedia)?.reduce((total: any, [key, item]: any, index: number) => (total + Number(item?.number_news_registered || 0)), 0);
  }, [JSON.stringify(statusEachMedia)]);

  const totalNewsCreate = useMemo(() => {
    return Object.entries(statusEachMedia)?.reduce((total: any, [key, item]: any, index: number) => (total + Number(item?.number_news_create || 0)), 0);
  }, [JSON.stringify(statusEachMedia)]);


  const handleShowConfirmCreateCampaign = () => {
    if (formik?.values?.action_type === ACTION_TYPE_CAMPAIGN.SAVE) {
      handleSubmit(formik.values);
    } else {
      setIsConfirmCreate((_prev) => true);
    }
  };

  const handleCreateCampaign = () => {
    setIsConfirmCreate((_prev) => false);
    handleSubmit(formik.values);
  }

  const onCloseConfirmCreateClick = () => {
    setIsConfirmCreate((_prev) => false);
  }

  const [listKeywordEdit, setListKeywordEdit] = useState<any>({});
  const [listChecked, setListChecked] = useState<any>({});
  const isChecked = (idKey: string, idMedia: number | string) => {
    if (idMedia === 'ALL_PAGE') {
      const listKeyHaveMedia = listMediaKeyword?.filter((k: any) => (k?.medias?.length > 0));
      const lengthNotCheckOnPage = listKeyHaveMedia?.reduce((total: number, item: any) => {
        if ((listChecked?.[`${item?.id}`] || [])?.length === 0) {
          return total + 1;
        }
        return total;
      }, 0);
      return lengthNotCheckOnPage <= 0;
    }
    if (idMedia === 'ALL') {
      return (listChecked?.[`${idKey}`] || [])?.length > 0;
    }
    return (listChecked?.[`${idKey}`] || [])?.includes(idMedia);
  }

  const handleCheck = (idKey: string, idMedia: number | string, isAction: boolean = true, type: string = '') => {
    const findKeyword = structuredClone(listMediaKeyword?.find((item: any) => item?.id === idKey));
    if (idMedia === 'ALL_PAGE') {
      setListChecked((_prev: any) => {
        const clone = structuredClone(_prev);
        const listKeyHaveMedia = listMediaKeyword?.filter((k: any) => (k?.medias?.length > 0 || _prev?.[k?.id]?.length > 0));
        if (isAction === false) {
          for (let ind = 0; ind < listKeyHaveMedia.length; ind++) {
            delete clone[listKeyHaveMedia[ind]?.id];
          }
        } else {
          for (let ind = 0; ind < listKeyHaveMedia.length; ind++) {
            const mediaFirst = listKeyHaveMedia[ind]?.medias?.[0]?.id || _prev?.[listKeyHaveMedia?.[ind]?.id]?.[0];
            clone[`${listKeyHaveMedia[ind]?.id}`] = mediaFirst ? [mediaFirst] : [];
          }
        }
        return clone;
      });
      return;
    }

    if (idMedia === 'ALL') {
      setListChecked((_prev: any) => {
        const clone = structuredClone(_prev);
        if (isAction === false) {
          delete clone[idKey];
        } else {
          clone[idKey] = findKeyword?.medias?.map((item: any) => item?.id);
        }
        return clone;
      });
      return;
    }

    setListChecked((_prev: any) => {
      const clone = structuredClone(_prev);
      if (isAction === false) {
        if (clone?.[idKey]?.length === 1) {
          delete clone[idKey];
        } else {
          clone[idKey] = clone?.[idKey]?.filter((item: any) => item !== idMedia) || [];
        }
        return clone;
      }

      clone[idKey] = [...(clone?.[idKey] || []), idMedia];

      return clone;
    });
  }

  const handleChecks = (idKey: string, arrMediaDropdown: any[] = [], isAction: boolean = true,) => {
    const arrIdMediaDropdown = arrMediaDropdown?.map((m: any) => m?.id);
    setListChecked((_prev: any) => {
      const clone = structuredClone(_prev);
      if (isAction === false) {
        if (clone?.[idKey]) {
          const cloneIdKey = clone?.[idKey]?.filter((m: number) => !arrIdMediaDropdown?.includes(m));
          clone[idKey] = cloneIdKey;
          if (cloneIdKey?.length === 0) {
            delete clone[idKey];
          }
        }
        return clone;
      }
      const cloneIdKey = Array.from(new Set((clone?.[idKey] || []).concat(arrIdMediaDropdown) || []));
      clone[idKey] = cloneIdKey;
      if (cloneIdKey?.length === 0) {
        delete clone[idKey];
      }
      return clone;
    });
  }

  const handleEditKeyword = (key: string, value: string) => {
    setListKeywordEdit((prev: any) => {
      const clone = structuredClone(prev);
      clone[key] = value;
      return clone;
    })
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const campaigns = values?.campaign?.reduce((arr: any, item: any) => {
        if (item?.sections?.length > 0) {
          const medias = item?.sections?.map((m: any) => ({
            keyword: m?.keyword,
            keyword_replace: m?.keyword_replace || null,
            partner_id: m?.media?.value,
            partner_code_id: Number(m?.section_code?.value),
            schedule: m?.schedule?.value,
            schedule_at: m?.schedule_date || null,
            publishing_schedule: m?.publishing_schedule?.value,
            publishing_schedule_at: m?.publishing_schedule_date || null,
          }))
          return [...arr, ...medias];
        }
        return arr;
      }, []);
      const response: any = await postCampaignGroups({ campaigns });
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleRefetching && handleRefetching()
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const sectionSchema = Yup.lazy((item) => {
    return Yup.object().shape({
      keyword: Yup.string().nullable(),
      keyword_replace: Yup.string().nullable(),
      schedule: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).required(`${t("Schedule is required")}`),
      schedule_date: Yup.string().when('schedule.value', {
        is: (schedule: any) => schedule === 'one_time',
        then: (schedule: any) => Yup.string().required(`${t("Schedule date is required")}`),
      }),
      publishing_schedule: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).required(`${t("Publishing Schedule is required")}`),
      publishing_schedule_date: Yup.string().when('publishing_schedule.value', {
        is: (schedule: any) => schedule === 'one_time',
        then: (schedule: any) => Yup.string().required(`${t("Publishing Schedule date is required")}`),
      }),
      media: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).nullable(),
      section_code: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).nullable(),
    })
  });

  const validationSchema = Yup.object({
    action_type: Yup.string().nullable(),
    campaign: Yup.array().of(
      Yup.object({
        keyword: Yup.string().nullable(),
        keyword_replace: Yup.string().nullable(),
        sections: Yup.array().of(sectionSchema).nullable(),
      })
    )
  });

  const formik = useFormik({
    initialValues: {
      action_type: ACTION_TYPE_CAMPAIGN.POST,
      keyword: '',
      keyword_replace: '',
      campaign: [
        {
          sections: [
            {
              keyword: '',
              keyword_replace: '',
              schedule: { label: "", value: "" },
              publishing_schedule: { label: "", value: "" },
              schedule_date: '',
              publishing_schedule_date: '',
              media: { label: "", value: "" },
              section_code: { label: "", value: "" },
            }
          ],
        }
      ]
    },
    validationSchema,
    onSubmit: handleShowConfirmCreateCampaign,
  });

  //@ts-ignore
  const initialValueActionType = formik?.values?.action_type || ACTION_TYPE_CAMPAIGN.POST;

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  const onPostClick = () => {
    formik.setFieldValue('action_type', ACTION_TYPE_CAMPAIGN.POST);
    formik.submitForm();
  };

  const handlePreviewTemplate = async ({ index, keyword = '' }: { index: number, keyword: string }) => {
    try {
      setIsPreviewLoading((_prev) => index);
      const data = {
        category_type: CATEGORY_TYPE,
        title_template_id: null,
        body_template_id: null,
        keyword_ids: [],
        keywords: [keyword]
      };
      const response: any = await previewNews(data);
      if (response?.data) {
        setPreviewTemplate(
          (_prev) => response?.data || { title: "", content: "" }
        );
        setIsPreviewLoading((_prev) => null);
        setIsOpenPreview((_prev) => true);
      } else {
        setIsPreviewLoading((_prev) => null);
        toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsPreviewLoading((_prev) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // Begin:: update status keyword
  const [itemUpdate, setItemUpdate] = useState<{ keywords: string[], is_active: number, type: number } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdateStatus = (item: { keywords: string[], is_active: number, type: number } | null) => {
    setItemUpdate(item)
  };
  const handleActionUpdateStatus = (is_active: number) => {
    const keywords: string[] = Object.keys(listChecked) || [];
    handleShowConfirmUpdateStatus({ keywords: keywords || [], is_active: is_active, type: Number(query?.type) });
  }
  const handleOnConfirmUpdate = async () => {
    try {
      if (!itemUpdate) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await updateStatusFlowedKeywordAnalysis(itemUpdate);
      setIsLoadingUpdate((_prev) => false);
      if (response?.data) {
        setItemUpdate((_prev) => null);
        setListChecked((_prev: any) => ({}));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        setListMediaKeyword((prev: any) => {
          return (prev || [])?.map((item: any) => {
            if ((itemUpdate?.keywords)?.includes(item?.keyword) && item?.type === itemUpdate?.type) {
              return ({ ...item, is_active: itemUpdate?.is_active });
            }
            return item;
          })
        });
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // End::update status keyword
  // Begin Step 1
  const handleSubmitStep1 = async () => {
    try {
      formik.resetForm();
      const listCam = Object.entries(listChecked)?.reduce((arr: any, [key, items]: any, index: number) => {
        if (items) {
          const sections = items?.map((item: any) => {
            const media: any = listMediaAllow?.find((m) => (String(m?.id) === String(item)));
            const section_code = media?.partner_codes?.[0] || {};
            return ({
              keyword: key,
              keyword_replace: listKeywordEdit?.[key] || "",
              schedule: SCHEDULE_OPTIONS_LANG[0],
              publishing_schedule: SCHEDULE_OPTIONS_LANG[0],
              media: {
                label: media?.name,
                value: media?.id,
              },
              section_code: {
                label: section_code?.section_title,
                value: section_code?.id,
                section_code: section_code?.section_code,
                reporter: section_code?.reporter,
                reporter_id: section_code?.reporter_id,
              },
            })
          })
          const obCampaign = {
            id: String(+ new Date() + index),
            keyword: key,
            keyword_replace: listKeywordEdit?.[key] || "",
            action_type: ACTION_TYPE_CAMPAIGN.SAVE,
            sections: sections
          };
          return [...arr, obCampaign];
        } else {
          return arr;
        }
      }, []);
      formik.setFieldValue('campaign', listCam);
      setStepAction((pre) => 2);
    } catch (error: any) {
      setIsLoading((_prev) => false);
      return error;
    }
  };

  const lengthKeyWordSelected = Object.keys(listChecked)?.length || 0;

  const lengthMediaSelected = useMemo(() => {
    return Object.entries(listChecked)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0);
  }, [JSON.stringify(listChecked)]);


  const handleScrollToTop = () => {
    tableRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleScroll = (e: any) => {
    setIsScrollBottom((prev: any) => e?.target?.scrollTop > 10)
  };


  // Type condition:{ limitPerPage: limit on per page,  totalRender: total create news for All media type Number, where: {[w-(media id type Number)]: total create news of this media type Number, }}
  const handleFreshNextPage = async (condition: any = { totalRender: 0, where: null }, checkedDefault: any = {}, listKeywordEditDefault: any = {}, pageNext: number) => {
    try {
      let clone = structuredClone(checkedDefault);
      let cloneCondition = structuredClone(condition);
      let cloneListKeywordEdit = structuredClone(listKeywordEditDefault);
      let result: any = [clone, cloneListKeywordEdit];
      const response: any = await getFlowedKeywordAnalysisNormal({ ...query, page: pageNext });
      if (response?.code === 200) {
        const keywords = response?.data?.list;
        const keys = keywords?.map((item: any, index: number) => {
          if (!!item?.keyword_replace && cloneListKeywordEdit?.[item?.keyword] === undefined) {
            cloneListKeywordEdit[item?.keyword] = item?.keyword_replace;
          }
          return (
            {
              id: item?.keyword,
              keyword: item?.keyword,
              keyword_replace: item?.keyword_replace,
              daily_search_volume: item?.daily_search_volume,
              total_search_volume: item?.total_search_volume,
              pv_range_a: item?.is_compare_a ? item?.compare_pv_period_a : (item.pv_period_a || 0),
              pv_range_b: item?.is_compare_b ? item?.compare_pv_period_b : (item.pv_period_b || 0),
              page_sequence: item?.page_sequence,
              type: item?.type,
              is_active: item?.is_active,
              medias: (item?.sequence_of_domains || [])?.map((domain: any) => {
                const findMedia = listMediaAllow?.find((m: any) => String(m?.website) === String(domain?.domain));
                return ({
                  id: findMedia?.id,
                  name: findMedia?.name || domain?.domain,
                  website: domain?.domain,
                  sequence: domain?.sequence,
                  page_sequence: domain?.page_sequence,
                  lastUpdated: domain?.lastUpdated,
                  lastUpdatedLabel: domain?.lastUpdatedLabel,
                });
              }),
            }
          );
        });
        clone = keys?.reduce((ob: any, key: any) => {
          if (ob?.length < cloneCondition?.totalRender) {
            if (cloneCondition?.where !== null) {
              const mediasChose: number[] = [];
              const cloneWhere = structuredClone(cloneCondition?.where || {});
              const medias = key?.medias || []; // List of media where the keyword has previously created news articles
              for (const where of Object.entries(cloneWhere)) {
                if ((where as [string, number])[1] > 0 && mediasChose?.length === 0) {
                  const findMedia = medias?.find((m: any) => (m?.id === Number((where[0])?.split('-')[1]))) || null; // Check the media if it is in the media list that has news articles for the current keyword
                  if (!!findMedia && isMoreThanAWeekOld(findMedia?.lastUpdated || findMedia?.lastUpdatedLabel)) {
                    mediasChose.push(Number((where[0])?.split('-')[1]));
                    cloneCondition.where[where[0]] = cloneCondition.where[where[0]] - 1;
                  } else if (medias?.length === 0) {
                    mediasChose.push(Number((where[0])?.split('-')[1]));
                    cloneCondition.where[where[0]] = cloneCondition.where[where[0]] - 1;
                  }
                }
              }
              if (mediasChose?.length > 0) {
                return ({ length: ob?.length + Number(mediasChose?.length), ob: { ...ob?.ob, [key?.id]: mediasChose } });
              }
            }
            return ob;
          }
          return ob;
        }, clone);

        if (clone?.length < cloneCondition?.totalRender && pageNext < Math.ceil(Number(response?.data?.pagination?.total) / Number(response?.data?.pagination?.limit)) && pageNext < LIMIT_MAX_CALL_PAGE) {
          result = await handleFreshNextPage(cloneCondition, clone, cloneListKeywordEdit, pageNext + 1);
        } else {
          result = [clone, cloneListKeywordEdit];
        }
      }
      return result;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (query) {
      const freshData = async () => {
        try {
          setIsLoading((_prev) => true);
          let condition: any = null;
          let cloneListKeywordEdit = structuredClone(listKeywordEdit);
          const [resPublished, resKeyword]: any = await Promise.all([firstLoadPageRef?.current ? mediaPublishedNewsToday() : null, getFlowedKeywordAnalysisNormal({ ...query })]);
          if (resPublished?.code === 200) {
            const conditionNormal = resPublished?.data?.filter((e: any) => e?.type === 'api')?.reduce((ob: any, value: any) => {
              return ({ totalRender: ob?.totalRender + Number(value?.total_keyword_latest || 0), where: { ...ob?.where, [`w-${value?.id}`]: value?.total_keyword_latest || 0 } });
            }, { totalRender: 0, where: null });
            condition = { ...conditionNormal, where: sortObjectDescending(conditionNormal?.where) };
            setStatusEachMedia((prev: any) => {
              const clone = structuredClone(prev);
              resPublished?.data?.filter((e: any) => e?.type === 'api')?.map((item: any) => {
                clone[`m-${item?.id}`] = {
                  ...clone[`m-${item?.id}`],
                  id: item?.id,
                  name: item?.name,
                  website: item?.website,
                  type: item?.type,
                  number_news_registered: item?.total_published_news,
                  number_news_create: item?.total_keyword_latest,
                };
                return item;
              });
              return clone;
            });
          }

          if (resKeyword?.code === 200) {
            setKeywordAnalysis((prev: any) => resKeyword?.data || {});
            const keywords = resKeyword?.data?.list;
            const keys = keywords?.map((item: any, index: number) => {
              if (!!item?.keyword_replace && cloneListKeywordEdit?.[item?.keyword] === undefined) {
                cloneListKeywordEdit[item?.keyword] = item?.keyword_replace;
              }
              return (
                {
                  id: item?.keyword,
                  keyword: item?.keyword,
                  keyword_replace: item?.keyword_replace,
                  daily_search_volume: item?.daily_search_volume,
                  total_search_volume: item?.total_search_volume,
                  pv_range_a: item?.is_compare_a ? item?.compare_pv_period_a : (item.pv_period_a || 0),
                  pv_range_b: item?.is_compare_b ? item?.compare_pv_period_b : (item.pv_period_b || 0),
                  page_sequence: item?.page_sequence,
                  type: item?.type,
                  is_active: item?.is_active,
                  medias: (item?.sequence_of_domains || [])?.map((domain: any) => {
                    const findMedia = listMediaAllow?.find((m: any) => String(m?.website) === String(domain?.domain));
                    return ({
                      id: findMedia?.id,
                      name: findMedia?.name || domain?.domain,
                      website: domain?.domain,
                      sequence: domain?.sequence,
                      page_sequence: item?.page_sequence,
                      lastUpdated: domain?.lastUpdated,
                      lastUpdatedLabel: domain?.lastUpdatedLabel,
                    });
                  }),
                }
              )
            });
            setListMediaKeyword((prev) => keys || []);
            if (firstLoadPageRef?.current) {
              firstLoadPageRef.current = false;
              let checkedDefault = keys?.reduce((ob: any, key: any) => {
                if (ob?.length < condition?.totalRender) {
                  if (condition?.where !== null) {
                    const mediasChose: number[] = [];
                    const cloneWhere = structuredClone(condition?.where || {});
                    const medias = key?.medias || []; // List of media where the keyword has previously created news articles
                    for (const where of Object.entries(cloneWhere)) {
                      if ((where as [string, number])[1] > 0 && mediasChose?.length === 0) {
                        const findMedia = medias?.find((m: any) => (m?.id === Number((where[0])?.split('-')[1]))) || null; // Check the media if it is in the media list that has news articles for the current keyword
                        if (!!findMedia && isMoreThanAWeekOld(findMedia?.lastUpdated || findMedia?.lastUpdatedLabel)) {
                          mediasChose.push(Number((where[0])?.split('-')[1]));
                          condition.where[where[0]] = condition.where[where[0]] - 1;
                        } else if (medias?.length === 0) {
                          mediasChose.push(Number((where[0])?.split('-')[1]));
                          condition.where[where[0]] = condition.where[where[0]] - 1;
                        }
                      }
                    }
                    if (mediasChose?.length > 0) {
                      return ({ length: ob?.length + Number(mediasChose?.length), ob: { ...ob?.ob, [key?.id]: mediasChose } });
                    }
                  }
                  return ob;
                }
                return ob;
              }, { length: 0, ob: {} });
              // Begin:: If get full select keyword
              if (checkedDefault?.length < condition?.totalRender && query?.page < Math.ceil(Number(resKeyword?.data?.pagination?.total) / Number(resKeyword?.data?.pagination?.limit))) {
                [checkedDefault, cloneListKeywordEdit] = await handleFreshNextPage(condition, checkedDefault, cloneListKeywordEdit, query?.page + 1);
              }
              // End:: If get full select keyword
              setListChecked((prev: any) => checkedDefault?.ob);
              setListKeywordEdit((_prev: any) => cloneListKeywordEdit);
            }
            setIsLoading((_prev) => false);
          } else {
            setIsLoading((_prev) => false);
            toast(`${resKeyword}`, CONFIG_OPTION_TOAST_ERROR);
          }
        } catch (error: any) {
          setIsLoading((_prev) => false);
          toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
          return error;
        }
      };
      freshData();
    }
  }, [JSON.stringify(query), maxNumberAutoSelectKeywordOnPage]);

  const handleChangePage = useCallback((page: any) => {
    setQuery((prev: any) => ({ ...prev, page: page + 1 }))
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    firstLoadPageRef.current = true;
    setQuery((_prev: any) => {
      return ({ ..._prev, page: 1, ...sortBy });
    });
  }, []);

  const formatSort = (key: string) => {
    return ({
      sort_by: key,
      order_by: (query?.order_by === 'DESC' && query?.sort_by === key) ? 'ASC' : 'DESC'
    })
  }
  const getValueSelectMedia = (key: string) => {
    let val = listMediaAllow?.reduce((arr: any, ob: any) => {
      if (listChecked?.[key]?.includes(ob?.id)) {
        return [...arr, { value: `${ob?.id}`, label: ob?.name, website: ob?.website }];
      }
      return arr;
    }, []);
    return val;
  };

  const onChangeSelectMedia = (key: string, event: any) => {
    setListChecked((_prev: any) => {
      const clone = structuredClone(_prev);
      clone[key] = event?.map((item: any) => Number(item?.value));
      return clone;
    });
  }

  const getValueSelectMedias = (key: string, medias: any) => {
    let val = listMediaAllow?.reduce((arr: any, ob: any) => {
      if (listChecked?.[key]?.includes(ob?.id) && !(medias?.find((m: any) => m?.id === ob?.id))) {
        return [...arr, { value: `${ob?.id}`, label: ob?.name, website: ob?.website }];
      }
      return arr;
    }, []);
    return val;
  };

  const onChangeSelectMedias = (key: string, event: any, medias: any) => {
    setListChecked((_prev: any) => {
      const clone = structuredClone(_prev);
      const valueDefault = medias?.reduce((arr: number[], m: any) => ((clone[key] || [])?.includes(m?.id) ? [...arr, m?.id] : arr), []);
      clone[key] = valueDefault?.concat(event?.map((item: any) => Number(item?.value)));
      return clone;
    });
  }

  const [isRenderNewsCreateLoading, setIsRenderNewsCreateLoading] = useState<boolean>(false);

  const handleChangeNumberMediaSelect = (mediaId: number, numberNewsToCreate: number) => {
    setStatusEachMedia((prev: any) => {
      let clone = structuredClone(prev);
      clone[`m-${mediaId}`].number_news_create = numberNewsToCreate || 0;
      return clone;
    });
  }

  function sortObjectDescending(obj: any) {
    const sortedEntries = Object.entries(obj).sort((a: any, b: any) => b[1] - a[1]);
    const sortedObject = Object.fromEntries(sortedEntries);
    return sortedObject;
  }

  const handleRenderNewsToCreateByNumberOfMedia = async () => {
    try {
      firstLoadPageRef.current = false;
      setIsRenderNewsCreateLoading((_prev) => true);
      let cloneListKeywordEdit = structuredClone(listKeywordEdit);
      let checkedDefault = { length: 0, ob: {} };
      const condition: any = Object.values(statusEachMedia)?.reduce((ob: any, value: any) => {
        return ({ totalRender: ob?.totalRender + Number(value?.number_news_create || 0), where: { ...ob?.where, [`w-${value?.id}`]: value?.number_news_create || 0 } });
      }, { totalRender: 0, where: null });
      [checkedDefault, cloneListKeywordEdit] = await handleFreshNextPage({ ...condition, where: sortObjectDescending(condition?.where) }, checkedDefault, cloneListKeywordEdit, 1);
      // End:: If get full select keyword
      setListChecked((prev: any) => checkedDefault?.ob);
      setListKeywordEdit((_prev: any) => cloneListKeywordEdit);
      setIsRenderNewsCreateLoading((_prev) => false);
    } catch (error: any) {
      setIsRenderNewsCreateLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  function resetNumberNewsCreate(obj: any) {
    for (let key in obj) {
      if (obj[key].hasOwnProperty('number_news_create')) {
        obj[key].number_news_create = 0;
      }
    }
    return obj;
  }

  function getNumberOfNewsToBeGenerated(mediaId: number | string | undefined = undefined) {
    return Object.entries(listChecked)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (mediaId === undefined ? (value || [])?.length : (value || [])?.includes(mediaId)));
    }, 0);
  }

  useEffect(() => {
    if (listChecked) {
      setStatusEachMedia((prev: any) => {
        const clone = resetNumberNewsCreate(structuredClone(prev));
        const statusEachMediaNew = Object.entries(listChecked)?.reduce((ob: any, [key, items]: any) => {
          for (const media of items) {
            if (ob[`m-${media}`]) {
              ob[`m-${media}`]['number_news_create'] = Number(ob?.[`m-${media}`]?.number_news_create || 0) + 1;
            }
          }
          return ob;
        }, clone);
        return statusEachMediaNew;
      });
    }
  }, [JSON.stringify(listChecked)]);


  const [firstThird, secondThird, thirdThird]: any = useMemo(() => {
    const clone = structuredClone(Object.entries(statusEachMedia));
    const totalItems = clone.length;

    const firstThird: any[] = [];
    const secondThird: any[] = [];
    const thirdThird: any[] = [];

    for (let i = 0; i < totalItems; i++) {
      if (i % 3 === 0) {
        firstThird.push(clone[i]);
      } else if (i % 3 === 1) {
        secondThird.push(clone[i]);
      } else {
        thirdThird.push(clone[i]);
      }
    }

    return [firstThird, secondThird, thirdThird];
  }, [JSON.stringify(statusEachMedia)]);

  return (
    <React.Fragment>
      <Row>
        <Col sm={12} lg={12} style={{ position: 'sticky', top: '-20px', zIndex: 9, }}>
          <div className="row g-3" >
            <div className="step-arrow-nav mb-4 px-0" >
              <ul role="tablist" className="nav-pills custom-nav nav-justified nav">
                <li className="nav-item">
                  <a href="#" id="steparrow-gen-info-tab" className={`${stepAction === 1 ? 'active done' : 'done'} nav-link`}
                    onClick={() => setStepAction((pre) => 1)}>
                    {t('Select Media')}
                    <span className="ms-1 badge bg-success">{lengthMediaSelected}</span>
                  </a>
                </li>
                <li className="nav-item"><a href="#" id="steparrow-gen-info-tab" className={`${stepAction === 2 ? 'active' : ([2, 3].includes(stepAction) ? 'done' : '')} nav-link`}
                  onClick={() => { (lengthMediaSelected) > 0 && handleSubmitStep1() }}
                >{t('Button Create Campaign')}
                </a>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* Begin:: Step 1 - Create Category */}
        <div id="scrollableDiv" ref={tableRef} className={`color-scrollbar-os ${stepAction === 1 ? '' : 'd-none'}`} style={{ minHeight: '600px', height: 'calc(100dvh - 280px)', margin: 0, overflowY: 'auto' }}
          onScroll={handleScroll}
        >
          <Row className="mb-4">
            <Col sm={12} lg={12} >
              <div className="table-responsive table-scroll-body-wrapper mb-4" style={{
                position: 'sticky',
                top: '0px',
                backgroundColor: 'var(--vz-modal-bg)',
                zIndex: 9,
                boxShadow: isScrollBottom ? 'transparent 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, transparent 0px 1px 3px 0px' : ''
              }}>
                <h6 className="text-primary text-center text-uppercase">{t('Status and plan each media')}</h6>
                <Table className="caption-top table-bordered table-hover table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colSpan={2} className="text-center bold align-middle" style={{ width: '25%' }}>
                        {t('Media')}
                        <span className="tag-style text-capitalize m-0 fs-10 ms-1">
                          Api
                        </span>
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '90px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('Recent <br/>published') }} />
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '90px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('Generated <br/>news') }} />
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '110px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('News to be <br/>generated') }} />
                      </th>
                      <th scope="col" colSpan={2} className="text-center bold align-middle" style={{ width: '25%' }}>
                        {t('Media')}
                        <span className="tag-style text-capitalize m-0 fs-10 ms-1">
                          Api
                        </span>
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '90px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('Recent <br/>published') }} />
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '90px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('Generated <br/>news') }} />
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '110px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('News to be <br/>generated') }} />
                      </th>
                      <th scope="col" colSpan={2} className="text-center bold align-middle" style={{ width: '25%' }}>
                        {t('Media')}
                        <span className="tag-style text-capitalize m-0 fs-10 ms-1">
                          Api
                        </span>
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '90px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('Recent <br/>published') }} />
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '90px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('Generated <br/>news') }} />
                      </th>
                      <th scope="col" className="text-center bold align-middle" style={{ width: '110px' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('News to be <br/>generated') }} />
                      </th>
                    </tr>
                  </thead>
                  <tbody className={isShowBodyTable === false ? 'd-none' : ''}>
                    {firstThird?.map(([key, item]: any, index: number) => (
                      <tr key={`${item?.id}-${index}`}>
                        <>
                          <td className="text-center text-muted align-middle py-0 bg-light-subtle" style={{ width: '30px' }}>
                            {((index + 1) * 3) - 2}
                          </td>
                          <td className="text-start align-middle py-0">
                            {item && (<div className="cursor-pointer" key={item?.id}>
                              <div className="fs-14">
                                {item?.name || ''}
                              </div>
                              <Link className='text-normal fs-13' to={(String(item?.website).includes('https://') || String(item?.website).includes('http://')) ? item?.website : `https://${item?.website}`} target="_blank">
                                {item?.website}
                              </Link>
                            </div>)}
                          </td>
                          <td className="text-center align-middle py-0">
                            {item && formatNumberWithCommas(item?.number_news_registered)}
                          </td>
                          <td className="text-center align-middle py-0">
                            {item && formatNumberWithCommas(getNumberOfNewsToBeGenerated(item?.id))}
                          </td>
                          <td className="text-center align-middle py-0">
                            {item && (<Input
                              type="text"
                              className="form-control text-center py-0 my-1"
                              placeholder={`${t('Number of news to create')}...`}
                              value={item?.number_news_create || 0}
                              onChange={(e) => {
                                const input = e.target.value;
                                const onlyNumbers = input.replace(/\D/g, '');
                                handleChangeNumberMediaSelect(item?.id, Number(onlyNumbers) > MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA ? MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA : Number(onlyNumbers));
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleRenderNewsToCreateByNumberOfMedia();
                                }
                              }}
                            />)}
                          </td>
                        </>
                        <>
                          <td className="text-center text-muted align-middle py-0 bg-light-subtle" style={{ width: '30px' }}>
                            {((index + 1) * 3) - 1}
                          </td>
                          <td className="text-start align-middle py-0">
                            {secondThird?.[index] && (<div className="cursor-pointer" key={item?.id}>
                              <div className="fs-14">
                                {secondThird?.[index]?.[1]?.name || ''}
                              </div>
                              <Link className='text-normal fs-13' to={(String(secondThird?.[index]?.[1]?.website).includes('https://') || String(secondThird?.[index]?.[1]?.website).includes('http://')) ? secondThird?.[index]?.[1]?.website : `https://${secondThird?.[index]?.[1]?.website}`} target="_blank">
                                {secondThird?.[index]?.[1]?.website}
                              </Link>
                            </div>)}
                          </td>
                          <td className="text-center align-middle py-0">
                            {secondThird?.[index] && formatNumberWithCommas(secondThird?.[index]?.[1]?.number_news_registered)}
                          </td>
                          <td className="text-center align-middle py-0">
                            {secondThird?.[index] && formatNumberWithCommas(getNumberOfNewsToBeGenerated(secondThird?.[index]?.[1]?.id))}
                          </td>
                          <td className="text-center align-middle py-0">
                            {secondThird?.[index] && (<Input
                              type="text"
                              className="form-control text-center py-0 my-1"
                              placeholder={`${t('Number of news to create')}...`}
                              value={secondThird?.[index]?.[1]?.number_news_create || 0}
                              onChange={(e) => {
                                const input = e.target.value;
                                const onlyNumbers = input.replace(/\D/g, '');
                                handleChangeNumberMediaSelect(secondThird?.[index]?.[1]?.id, Number(onlyNumbers) > MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA ? MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA : Number(onlyNumbers));
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleRenderNewsToCreateByNumberOfMedia();
                                }
                              }}
                            />)}
                          </td>
                        </>
                        <>
                          <td className="text-center text-muted align-middle py-0 bg-light-subtle" style={{ width: '30px' }}>
                            {((index + 1) * 3)}
                          </td>
                          <td className="text-start align-middle py-0">
                            {thirdThird?.[index] && (<div className="cursor-pointer" key={item?.id}>
                              <div className="fs-14">
                                {thirdThird?.[index]?.[1]?.name || ''}
                              </div>
                              <Link className='text-normal fs-13' to={(String(thirdThird?.[index]?.[1]?.website).includes('https://') || String(thirdThird?.[index]?.[1]?.website).includes('http://')) ? thirdThird?.[index]?.[1]?.website : `https://${thirdThird?.[index]?.[1]?.website}`} target="_blank">
                                {thirdThird?.[index]?.[1]?.website}
                              </Link>
                            </div>)}
                          </td>
                          <td className="text-center align-middle py-0">
                            {thirdThird?.[index] && formatNumberWithCommas(thirdThird?.[index]?.[1]?.number_news_registered)}
                          </td>
                          <td className="text-center align-middle py-0">
                            {thirdThird?.[index] && formatNumberWithCommas(getNumberOfNewsToBeGenerated(thirdThird?.[index]?.[1]?.id))}
                          </td>
                          <td className="text-center align-middle py-0">
                            {thirdThird?.[index] && (<Input
                              type="text"
                              className="form-control text-center py-0 my-1"
                              placeholder={`${t('Number of news to create')}...`}
                              value={thirdThird?.[index]?.[1]?.number_news_create || 0}
                              onChange={(e) => {
                                const input = e.target.value;
                                const onlyNumbers = input.replace(/\D/g, '');
                                handleChangeNumberMediaSelect(thirdThird?.[index]?.[1]?.id, Number(onlyNumbers) > MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA ? MAX_TOTAL_NEWS_WILL_CREATE_IN_ONE_MEDIA : Number(onlyNumbers));
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleRenderNewsToCreateByNumberOfMedia();
                                }
                              }}
                            />)}
                          </td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="position-relative">
                    <span
                      className="position-absolute cursor-pointer bg-danger text-white px-0"
                      style={{ fontWeight: 900, borderBottomRightRadius: '10px', width: '31px' }}
                      onClick={() => setIsShowBodyTable((_prev) => !_prev)}
                    >
                      <TooltipCustom
                        title={isShowBodyTable ? t('Hide the body information of the table') : t('Display the body information of the table')}
                        className="d-inline-block vertical-align-middle py-1"
                      >
                        {isShowBodyTable ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />}
                      </TooltipCustom>
                    </span>
                    <tr>
                      <td colSpan={6} className="text-center text-danger h6 align-middle">
                        {t('Sum')}
                      </td>
                      <td colSpan={3} className="text-center text-danger h6 align-middle">
                        {t('Recent published')}: {formatNumberWithCommas(totalNewsRegistered)}
                      </td>
                      <td colSpan={3} className="text-center text-danger h6 align-middle">
                        {t('Generated news')}: {formatNumberWithCommas(getNumberOfNewsToBeGenerated())}
                      </td>
                      <td colSpan={3} className="text-center h6 align-middle py-0">
                        <button
                          className="btn btn-soft-primary btn-sm fs-11"
                          color="light"
                          type="button"
                          onClick={() => handleRenderNewsToCreateByNumberOfMedia()}
                          disabled={isRenderNewsCreateLoading}
                        >
                          {(!!isRenderNewsCreateLoading) ? (
                            <Spinner size="sm" style={{ width: '12px', height: '12px', verticalAlign: 'middle', marginBottom: '2px' }}></Spinner>
                          ) : (<i className="ri-restart-line align-bottom"></i>)}
                          <> <span className="text-danger h6 mx-1">{formatNumberWithCommas(totalNewsCreate)}</span> {t('Render news to create')}  </>
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
              <div className="table-responsive overflow-x-visible">
                <h6 className="text-primary text-center text-uppercase">{t('Schedule each keyword')}</h6>
                <div>
                  <div className="text-start text-md-start">
                    <button
                      type="button"
                      className="btn btn-primary me-1"
                      onClick={() => handleActionUpdateStatus(1)}
                      disabled={lengthKeyWordSelected <= 0 || isLoadingUpdate}
                    >
                      {t('Button ON')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-14"
                      onClick={() => handleActionUpdateStatus(0)}
                      disabled={lengthKeyWordSelected <= 0 || isLoadingUpdate}
                      style={{ borderColor: '#d4d4d4' }}
                    >
                      {t('Button OFF')}
                    </button>
                  </div>
                  <div className="text-primary w-100 text-start mb-1">
                    <strong>{formatNumberWithCommas(lengthKeyWordSelected)}</strong> {t('item selected')}.
                  </div>
                </div>
                <Table className="caption-top table-bordered table-nowrap mb-0 ">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colSpan={1} rowSpan={2} className="text-center bold align-middle"></th>
                      <th scope="col" colSpan={1} rowSpan={2} className="text-center bold align-middle">
                        <div className="form-check text-center" >
                          <input className="form-check-input" type="checkbox" name="table" checked={isChecked(query?.page, 'ALL_PAGE')} value={'ALL'} onChange={(e) => handleCheck(query?.page, 'ALL_PAGE', e?.target?.checked)} id={`cell-check-ALL-PAGE`} />
                          <label className="form-check-label" htmlFor={`cell-check-ALL-PAGE`}></label>
                        </div>
                      </th>
                      <th scope="col" colSpan={1} rowSpan={2} className="text-center bold align-middle">
                        {t('Keyword')}  <TooltipCustom
                          title={`${t('Description Keyword that flowed by searching')}`}
                          className="d-inline-block vertical-align-middle ms-1"
                          style={{ transform: 'translateY(2px)' }}
                        >
                          <i className="ri-question-line align-bottom text-secondary" ></i>
                        </TooltipCustom><span className="ms-1 badge bg-success">{lengthKeyWordSelected}</span>
                      </th>
                      <th scope="col" colSpan={1} rowSpan={2} className="text-center bold align-middle">
                        {t('News Keyword')}
                      </th>
                      <th scope="col" colSpan={2} className="text-center bold align-middle">
                        <div className="text-center">
                          {t('Search Volume')}
                        </div>
                      </th>
                      <th scope="col" colSpan={2} className="text-center bold align-middle">
                        <div className="text-center">
                          {t('PV')}
                        </div>
                      </th>
                      <th scope="col" rowSpan={2} className="text-center bold align-middle cursor-pointer"
                        onClick={() => handleChangeSorting(formatSort('page_sequence'))}
                      >
                        <div style={{ minWidth: '100px', whiteSpace: 'pre-wrap' }} className="text-center">
                          {t('Session')}
                          <TooltipCustom
                            title={`${t('Description The news section sequence of keyword on Naver')}`}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(2px)' }}
                          >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                          </TooltipCustom>
                          {query?.sort_by === 'page_sequence' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                            {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                          </span>)}
                        </div>
                      </th>
                      <th scope="col" rowSpan={2} className="text-center bold align-middle cursor-pointer"
                        onClick={() => handleChangeSorting(formatSort('sequence'))}
                      >
                        <div style={{ minWidth: '100px', whiteSpace: 'pre-wrap' }} className="text-center">
                          {t('Sequence')}
                          <TooltipCustom
                            title={`${t('Description The impression sequence of media when search keyword in Naver News tab')}`}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(2px)' }}
                          >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                          </TooltipCustom>
                          {query?.sort_by === 'sequence' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                            {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                          </span>)}
                        </div>
                      </th>
                      <th scope="col" rowSpan={2} colSpan={2} className="text-center bold align-middle">
                        <div>
                          <div className="d-inline-block">
                            {t('Media')}
                            <TooltipCustom
                              title={`${t('Description Exposure rankings are tracked up to the 50th position, and any rankings beyond this are displayed as 0')}`}
                              className="d-inline-block vertical-align-middle ms-1"
                              style={{ transform: 'translateY(2px)' }}
                            >
                              <i className="ri-question-line align-bottom text-secondary" ></i>
                            </TooltipCustom>
                          </div>
                          <span className="ms-1 badge bg-success">{(lengthMediaSelected) || 0}</span>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="text-center bold align-middle cursor-pointer"
                        onClick={() => handleChangeSorting(formatSort('daily_search_volume'))}
                      >
                        <div style={{ minWidth: '80px' }} className="text-center">
                          {t('Daily Trend Index')}
                          <TooltipCustom
                            title={t('Description Daily Trend Index')}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(2px)' }}
                          >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                          </TooltipCustom>
                          {query?.sort_by === 'daily_search_volume' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                            {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                          </span>)}
                        </div>
                      </th>
                      <th scope="col" className="text-center bold align-middle cursor-pointer"
                        onClick={() => handleChangeSorting(formatSort('total_search_volume'))}
                      >
                        <div style={{ minWidth: '80px' }} className="text-center">
                          {t('30 Days Trend Index')}
                          <TooltipCustom
                            title={t('Description 30 Days Trend Index')}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(2px)' }}
                          >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                          </TooltipCustom>
                          {query?.sort_by === 'total_search_volume' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                            {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                          </span>)}
                        </div>
                      </th>
                      <th scope="col" className="text-center bold align-middle cursor-pointer"
                        onClick={() => handleChangeSorting(formatSort('pv_range_a'))}
                      >
                        <div style={{ minWidth: '80px' }} className="text-center">
                          {t('Date Range A Keyword Inflow PV')}
                          <TooltipCustom
                            title={`${t('Description PV of Keyword that flowed in specific period :  from January 1, 2024 ago and 7 day ago default')}`}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(2px)' }}
                          >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                          </TooltipCustom>
                          {query?.sort_by === 'pv_range_a' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                            {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                          </span>)}
                        </div>
                      </th>
                      <th scope="col" className="text-center bold align-middle cursor-pointer"
                        onClick={() => handleChangeSorting(formatSort('pv_range_b'))}
                      >
                        <div style={{ minWidth: '80px' }} className="text-center">
                          {t('Date Range B Keyword Inflow PV')}
                          <TooltipCustom
                            title={`${t('Description PV of Keyword that flowed in specific period : from 2 days ago and 1 day ago default')}`}
                            className="d-inline-block vertical-align-middle ms-1"
                            style={{ transform: 'translateY(2px)' }}
                          >
                            <i className="ri-question-line align-bottom text-secondary" ></i>
                          </TooltipCustom>
                          {query?.sort_by === 'pv_range_b' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
                            {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
                          </span>)}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listMediaKeyword?.length === 0 && (
                      <tr>
                        <td colSpan={10} className="text-center">
                          {t('No Data')}
                        </td>
                      </tr>
                    )}
                    {listMediaKeyword?.map((item: any, index: number) => (
                      <>
                        <tr key={item?.id} style={{ backgroundColor: item?.medias[0] ? 'transparent' : '#eaeaea6e' }}>
                          <td className="align-middle text-center" rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)} style={{ width: '40px' }}>
                            {((query?.page - 1) * (query?.limit)) + (index + 1)}
                          </td>
                          <td className="align-middle text-center" style={{ width: '50px' }} rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            <div className="form-check text-center" >
                              <input className="form-check-input" type="checkbox" name="table" checked={isChecked(item?.id, 'ALL')} value={'ALL'} onChange={(e) => handleCheck(item?.id, 'ALL', e?.target?.checked, item?.medias?.length === 0 ? 'MORE' : '')} id={`cell-check-ALL`} />
                              <label className="form-check-label" htmlFor={`cell-check-ALL`}></label>
                            </div>
                          </td>
                          <td className="align-middle text-center" rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            <div className="d-flex align-items-center" style={{ maxWidth: '160px', overflowWrap: 'anywhere' }}>
                              <div className="form-check form-switch form-switch-sm text-center me-2 pb-1" dir="ltr">
                                <input type="checkbox" className="form-check-input" name="is_runing" id="customSwitchsizelg" checked={Boolean(item?.is_active)}
                                  onChange={(event: any) => {
                                    handleShowConfirmUpdateStatus({ keywords: [item?.keyword], is_active: Number(event.target.checked), type: Number(item?.type) });
                                  }}
                                />
                              </div>
                              <CopyWrapper contentCopy={item?.keyword}>
                                <Link className='text-normal text-wrap ' to={renderUrlSearchKeywordInNaver(item?.keyword)} target="_blank">
                                  {item?.keyword}
                                </Link>
                              </CopyWrapper>
                            </div>
                          </td>
                          <td className="align-middle text-center" rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            <div style={{ maxWidth: '180px', overflowWrap: 'anywhere' }}>
                              <Input
                                type="text"
                                className={`form-control text-center ${((listKeywordEdit?.[item?.keyword] !== undefined && listKeywordEdit?.[item?.keyword] !== item?.keyword) || (item?.keyword_replace !== null)) ? 'text-danger' : ''}`}
                                placeholder={`${t('Keyword')}`}
                                value={listKeywordEdit?.[item?.keyword] ?? item?.keyword}
                                onChange={(e) => {
                                  handleEditKeyword(item?.keyword, e?.target?.value);
                                }}
                              />
                            </div>
                          </td>
                          <td className={`align-middle text-end`} rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            {item?.daily_search_volume}
                          </td>
                          <td className={`align-middle text-end`} rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            {item?.total_search_volume}
                          </td>
                          <td className={`align-middle text-end`} rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            {item?.pv_range_a}
                          </td>
                          <td className={`align-middle text-end`} rowSpan={Number(item?.medias?.length ? item?.medias?.length + 1 : 1)}>
                            {item?.pv_range_b}
                          </td>
                          <>
                            <td className={`align-middle text-center`}>
                              {item?.page_sequence || 'N'}
                            </td>
                            <td className={`align-middle text-center`}>
                              {item?.medias[0]?.sequence || 'N'}
                            </td>
                            <td className={`align-middle text-center`}>
                              {item?.medias[0] ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <span>{item?.medias[0]?.name || ''}</span>
                                  <span className="ms-2" >
                                    <TooltipCustom
                                      title={item?.medias[0]?.lastUpdated || item?.medias[0]?.lastUpdatedLabel}
                                      className="d-block vertical-align-middle text-muted fs-11"
                                      style={{ transform: 'translateY(2px)' }}
                                    >
                                      ({item?.medias[0]?.lastUpdatedLabel})
                                    </TooltipCustom>
                                  </span>
                                </div>) : (
                                <div style={{ width: '240px', margin: 'auto' }}>
                                  <SearchFilterMedia
                                    name={`${item?.keyword}-media`}
                                    id={`${item?.keyword}-media`}
                                    dataList={listMediaAllow?.filter((m: any) => (m?.type === 'api'))}
                                    isMulti={true}
                                    initialValue={getValueSelectMedia(item?.id)}
                                    isClearable={true}
                                    onChangeSelect={(event: any) => {
                                      onChangeSelectMedia(item?.id, event);
                                    }}
                                  />
                                </div>
                              )}
                            </td>
                            <td className="align-middle text-center" style={{ width: '50px' }}>
                              {item?.medias[0] ? (<div className="form-check text-center" >
                                <input className="form-check-input" type="checkbox" name="table" checked={isChecked(item?.id, item?.medias[0]?.id ?? '')} value={item?.medias[0]?.id ?? ''} onChange={(e) => handleCheck(item?.id, item?.medias[0]?.id, e?.target?.checked, item?.medias?.length === 0 ? 'MORE' : '')} id={`cell-check-${item?.medias[0]?.id ?? ''}`} />
                                <label className="form-check-label" htmlFor={`cell-check-${item?.medias[0]?.id ?? ''}`}></label>
                              </div>) : (
                                <div className="form-check text-center" >
                                  <input className="form-check-input" type="checkbox" name="table" checked={getValueSelectMedias(item?.id, item?.medias)?.length > 0} value={item?.id ?? ''} onChange={(e) => handleChecks(item?.id, listMediaAllow?.filter((m) => (!(item?.medias?.find((mm: any) => mm?.id === m?.id)) && m?.type === 'api')), e?.target?.checked)} id={`cell-check-v-${item?.id ?? ''}`} />
                                  <label className="form-check-label" htmlFor={`cell-check-v-${item?.id ?? ''}`}></label>
                                </div>
                              )}
                            </td>
                          </>
                        </tr>
                        {item?.medias?.map((m: any, i: number) => (
                          (i > 0) ? (
                            <tr key={`${item?.id}-${i}`}>
                              <td className={`align-middle text-center`}>
                                {m?.page_sequence || 'N'}
                              </td>
                              <td className={`align-middle text-center`}>
                                {m?.sequence || 'N'}
                              </td>
                              <td className={`align-middle text-center`}>
                                <div className="d-flex justify-content-center align-items-center">
                                  <span>{m?.name}</span>
                                  <span className="ms-2" >
                                    <TooltipCustom
                                      title={m?.lastUpdated || m?.lastUpdatedLabel}
                                      className="d-block vertical-align-middle text-muted fs-11"
                                      style={{ transform: 'translateY(2px)' }}
                                    >
                                      ({m?.lastUpdatedLabel})
                                    </TooltipCustom>
                                  </span>
                                </div>
                              </td>
                              <td className="align-middle text-center">
                                <div className="form-check text-center" >
                                  <input className="form-check-input" type="checkbox" name="table" checked={isChecked(item?.id, m?.id ?? '')} value={m?.id ?? ''} onChange={(e) => handleCheck(item?.id, m?.id, e?.target?.checked)} id={`cell-check-${m?.id ?? ''}`} />
                                  <label className="form-check-label" htmlFor={`cell-check-${m?.id ?? ''}`}></label>
                                </div>
                              </td>
                            </tr>
                          ) : (<></>)
                        ))}
                        {item?.medias?.length > 0 && (
                          <tr key="">
                            <td className={`align-middle text-center`}>
                              {item?.page_sequence || 'N'}
                            </td>
                            <td className={`align-middle text-center`}>
                              N
                            </td>
                            <td className={`align-middle text-center`}>
                              <div style={{ width: '240px' }}>
                                <SearchFilterMedia
                                  name={`${item?.keyword}-media`}
                                  id={`${item?.keyword}-media`}
                                  dataList={listMediaAllow?.filter((m) => (!(item?.medias?.find((mm: any) => mm?.id === m?.id)) && m?.type === 'api'))}
                                  isMulti={true}
                                  initialValue={getValueSelectMedias(item?.id, item?.medias)}
                                  isClearable={true}
                                  onChangeSelect={(event: any) => {
                                    onChangeSelectMedias(item?.id, event, item?.medias);
                                  }}
                                />
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              <div className="form-check text-center" >
                                <input className="form-check-input" type="checkbox" name="table" checked={getValueSelectMedias(item?.id, item?.medias)?.length > 0} value={item?.id ?? ''} onChange={(e) => handleChecks(item?.id, listMediaAllow?.filter((m) => (!(item?.medias?.find((mm: any) => mm?.id === m?.id)) && (m?.type === 'api'))), e?.target?.checked)} id={`cell-check-v-${item?.id ?? ''}`} />
                                <label className="form-check-label" htmlFor={`cell-check-v-${item?.id ?? ''}`}></label>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
        {stepAction === 1 && (<Col md={12} xxl={12} lg={12} className="table-pagination-modal">
          <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
            <div className="infinite-loading-inner"></div>
          </div>
          <div className="d-flex align-items-start gap-3 mt-3">
            <div className="align-items-center g-3 text-center">
              <div className="col-sm-auto">
                <ReactPaginate
                  nextLabel={`${t('next')} >`}
                  onPageChange={(page) => {
                    if (handleChangePage) {
                      handleScrollToTop && handleScrollToTop();
                      handleChangePage(page.selected);
                    }
                  }}
                  forcePage={query.page - 1}
                  pageRangeDisplayed={Number(window?.innerWidth) < 900 ? 2 : 3}
                  marginPagesDisplayed={2}
                  pageCount={Math.ceil(Number(keywordAnalysis?.pagination?.total) / Number(keywordAnalysis?.pagination?.limit))}
                  previousLabel={`< ${t('previous')}`}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link ms-0"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination pagination-separated"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
              <div className="col-sm text-start mt-2">
                <div className="text-muted">
                  {t('Total')}:
                  {" "}
                  <span className="fw-semibold ms-1">{formatNumberWithCommas(keywordAnalysis?.pagination?.total)}</span>
                  {" "}
                  {t('Results')}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-success btn-label right ms-auto nexttab " disabled={(lengthMediaSelected) === 0} onClick={() => handleSubmitStep1()}>
              {isLoading ? <i className="label-icon align-middle"><Spinner size="sm" className='me-2'></Spinner></i> : <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>}
              {t('Next step')}
            </button>
          </div>
        </Col>)}
        {/* End:: Step 1 - Create Category */}
        {/* Begin:: Step 2 - Create Campaign */}
        <form onSubmit={formik.handleSubmit} className={`${stepAction === 2 ? '' : 'd-none'}`}>
          <Row>
            <Col sm={12} md={12} lg={12} className="color-scrollbar-os" style={{ height: 'calc(100dvh - 260px)', margin: 0, overflowY: 'auto' }}>
              {formik.values?.campaign?.map((group: any, iG: number) => (
                <div key={`${group?.id}-${iG}`} className={`row g-3 position-relative mt-2 mb-3 ${(group?.sections || [])?.length === 0 ? 'd-none' : ''}`} style={{ border: '1px solid var(--vz-info)', padding: '10px', borderRadius: '5px' }}>
                  <div className="d-flex align-items-center position-absolute w-auto m-0 bold text-primary" style={{ top: 0, left: '1rem', transform: 'translateY(-50%)', backgroundColor: 'var(--vz-secondary-bg)', fontSize: '16px', fontWeight: 500, }}>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      color="light"
                      type="button"
                      onClick={() => handlePreviewTemplate({ index: group?.id, keyword: group?.keyword_replace || group?.keyword })}
                      style={{
                        width: '18px',
                        height: '18px',
                        padding: '0px',
                        fontSize: '11px',
                      }}
                      disabled={isLoading || !!isPreviewLoading}
                    >
                      {(isPreviewLoading === group?.id) ? (
                        <Spinner size="sm" style={{ width: '12px', height: '12px', verticalAlign: 'middle', marginBottom: '2px' }}></Spinner>
                      ) : (
                        <i className="ri-eye-line align-bottom"></i>
                      )}
                    </button>
                    <div>{group?.keyword_replace || group?.keyword}</div>
                  </div>
                  <div
                    className="d-flex align-items-center position-absolute w-auto m-0 bold text-primary"
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      transform: 'translate(-12px, -50%)',
                      zIndex: 2,
                      backgroundColor: 'var(--vz-secondary-bg)',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}>{iG + 1}</div>
                  {/* Begin::List Section */}
                  <div className="p-4 mt-0">
                    {(formik.values?.campaign?.[iG]?.sections || [])?.map((item: any, i: number) => (
                      <Row key={item?.id}
                        className={`${i === ((formik.values?.campaign?.[iG] as any)?.sections?.length - 1) ? 'mb-0' : 'mb-4'} position-relative`}
                        style={{
                          padding: '10px 0px 5px',
                          borderRadius: '5px',
                          border: '1px dashed var(--vz-info)',
                          backgroundColor: 'var(--vz-light)',
                        }}
                      >
                        <Col sm={6} md={3} className="my-2 order-1 order-md-1">
                          <div>
                            <label className="form-label">
                              {t("Creating Schedule")}
                              <span className="text-danger"> *</span>
                            </label>
                            <ScheduleType
                              name={`campaign[${[iG]}]sections[${i}]schedule`}
                              id={`campaign[${[iG]}]sections[${i}]schedule`}
                              dataList={SCHEDULE_OPTIONS_LANG}
                              placeholder={`${t('Select Creating Schedule')}...`}
                              initialValue={(formik.values?.campaign?.[iG]?.sections?.[i] as any)?.schedule || SCHEDULE_OPTIONS_LANG[0]}
                              onChangeSelect={(event) => {
                                formik.setFieldValue(`campaign[${[iG]}]sections[${i}]schedule`, event);
                                formik.setFieldValue(`campaign[${[iG]}]sections[${i}]publishing_schedule`, event);
                                if (event?.value === 'one_time') {
                                  formik.setFieldValue(`campaign[${[iG]}]sections[${i}]schedule_date`, getDefaultTime());
                                  formik.setFieldValue(`campaign[${[iG]}]sections[${i}]publishing_schedule_date`, getDefaultTime());
                                }
                              }}
                            />
                            {(formik.touched?.campaign?.[iG]?.sections?.[i] as any)?.schedule && ((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.schedule ? (
                              <div className="text-danger mt-2">
                                {((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.schedule}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6} md={3} className="my-2 order-1 order-md-1">
                          <div>
                            <label className="form-label">
                              {t("Publishing Schedule")}
                              <span className="text-danger"> *</span>
                            </label>
                            <ScheduleType
                              name={`campaign[${[iG]}]sections[${i}]publishing_schedule`}
                              id={`campaign[${[iG]}]sections[${i}]publishing_schedule`}
                              dataList={SCHEDULE_OPTIONS_LANG}
                              placeholder={`${t('Select Publishing Schedule')}...`}
                              initialValue={(formik.values?.campaign?.[iG]?.sections?.[i] as any)?.publishing_schedule || SCHEDULE_OPTIONS_LANG[0]}
                              onChangeSelect={(event) => {
                                formik.setFieldValue(`campaign[${[iG]}]sections[${i}]publishing_schedule`, event);
                                if (event?.value === 'one_time') {
                                  formik.setFieldValue(`campaign[${[iG]}]sections[${i}]publishing_schedule_date`, getDefaultTime());
                                }
                              }}
                            />
                            {(formik.touched?.campaign?.[iG]?.sections?.[i] as any)?.publishing_schedule && ((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.publishing_schedule ? (
                              <div className="text-danger mt-2">
                                {(((formik.errors?.campaign?.[iG] as any)?.sections as any)?.[i] as any)?.publishing_schedule}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={12} lg={3} className="my-2 order-3 order-md-2">
                          <div>
                            <label className="form-label">
                              {t("Media")}
                              <span className="text-danger"> *</span>
                            </label>
                            <SearchFilterMedia
                              name={`campaign[${[iG]}]sections[${i}]media`}
                              id={`campaign[${[iG]}]sections[${i}]media`}
                              dataList={listMediaAllow}
                              isMulti={false}
                              disabled={true}
                              initialValue={((formik.values?.campaign?.[iG]?.sections?.[i] as any)?.media as unknown as Option) || undefined}
                              onChangeSelect={(event: any) => {
                                formik.setFieldValue(`campaign[${[iG]}]sections[${i}]media`, event);
                                const valSections = listMediaAllow?.filter((m) => (String(m?.id) === String(event?.value)))[0]?.partner_codes || [];
                                formik.setFieldValue(`campaign[${[iG]}]sections[${i}]section_code`, valSections[0] ? ({
                                  label: valSections[0]?.section_title,
                                  value: String(valSections[0]?.id),
                                }) : null);
                              }}
                            />
                            {(formik.touched?.campaign?.[iG]?.sections?.[i] as any) && ((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.media ? (
                              <div className="text-danger mt-2">{((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.media}</div>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={12} lg={3} className="my-2 order-3 order-md-2">
                          <label className="form-label">{t('Section Title')}</label>
                          <DropdownSectionCodeMediaType
                            name={`campaign[${[iG]}]sections[${i}]section_code`}
                            dataList={listMediaAllow?.filter((m) => (String(m?.id) === String((formik.values?.campaign?.[iG]?.sections?.[i] as any)?.media?.value)))[0]?.partner_codes || []}
                            initialValue={(formik?.values?.campaign?.[iG]?.sections?.[i] as any)?.section_code}
                            onChangeSelect={(event: any) => {
                              formik.setFieldValue(`campaign[${[iG]}]sections[${i}]section_code`, event)
                            }}
                          />
                          {(formik.touched?.campaign?.[iG]?.sections?.[i] as any) && ((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.section_code ? (
                            <div className="text-danger mt-2">{((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.section_code}</div>
                          ) : null}
                        </Col>
                        <Col sm={6} md={3} className="my-2 order-2 order-md-3">
                          {(formik.values?.campaign?.[iG]?.sections?.[i] as any)?.schedule?.value === 'one_time' && (
                            <div className="date-picker-wrapper-custom">
                              <label className="form-label">
                                {t("Creating Date")}
                                <span className="text-danger"> *</span>
                              </label>
                              <DatePicker
                                name={`campaign[${[iG]}]sections[${i}]schedule_date`}
                                id={`campaign[${[iG]}]sections[${i}]schedule_date`}
                                className="form-control search"
                                placeholderText={`${t('Date')}...`}
                                minDate={new Date()}
                                value={(formik.values?.campaign?.[iG]?.sections?.[i] as any)?.schedule_date || undefined}
                                selected={new Date((formik.values?.campaign?.[iG]?.sections?.[i] as any)?.schedule_date) || undefined}
                                dateFormat="yyyy-MM-dd h:mm"
                                showTimeSelect
                                timeIntervals={5}
                                autoComplete="off"
                                timeCaption={t("Time")}
                                timeFormat="HH:mm"
                                isClearable={true}
                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                onChange={(value: any) => {
                                  formik.setFieldValue(`campaign[${[iG]}]sections[${i}]schedule_date`, moment(new Date(value)).format("Y-MM-DD HH:mm"));
                                  formik.setFieldValue(`campaign[${[iG]}]sections[${i}]publishing_schedule_date`, moment(new Date(value)).format("Y-MM-DD HH:mm"))
                                }}
                              />
                              {(formik.touched?.campaign?.[iG]?.sections?.[i] as any) && ((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.schedule_date ? (
                                <div className="text-danger mt-2">
                                  {((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.schedule_date}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Col>
                        <Col sm={6} md={3} className="my-2 order-2 order-md-3">
                          {(formik.values?.campaign?.[iG]?.sections?.[i] as any)?.publishing_schedule?.value === 'one_time' && (
                            <div className="date-picker-wrapper-custom">
                              <label className="form-label">
                                {t("Publishing Date")}
                                <span className="text-danger"> *</span>
                              </label>
                              <DatePicker
                                name={`campaign[${[iG]}]sections[${i}]publishing_schedule_date`}
                                id={`campaign[${[iG]}]sections[${i}]publishing_schedule_date`}
                                className="form-control search"
                                placeholderText={`${t('Date')}...`}
                                minDate={new Date()}
                                value={(formik.values?.campaign?.[iG]?.sections?.[i] as any)?.publishing_schedule_date || undefined}
                                selected={new Date((formik.values?.campaign?.[iG]?.sections?.[i] as any)?.publishing_schedule_date) || undefined}
                                dateFormat="yyyy-MM-dd h:mm"
                                showTimeSelect
                                timeIntervals={5}
                                autoComplete="off"
                                timeCaption={t("Time")}
                                timeFormat="HH:mm"
                                isClearable={true}
                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                onChange={(value: any) => {
                                  formik.setFieldValue(`campaign[${[iG]}]sections[${i}]publishing_schedule_date`, moment(new Date(value)).format("Y-MM-DD HH:mm"))
                                }}
                              />
                              {(formik.touched?.campaign?.[iG]?.sections?.[i] as any) && ((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.publishing_schedule_date ? (
                                <div className="text-danger mt-2">
                                  {((formik.errors?.campaign?.[iG] as any)?.sections?.[i] as any)?.publishing_schedule_date}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </div>
                  {/* End::List Section */}
                </div>
              ))}
            </Col>
            <Col lg={12} className="mt-3">
              <div className="w-100 d-flex align-items-end justify-content-between gap-3">
                <button type="button" className="btn btn-light btn-label previestab" disabled={isLoading || (isPreviewLoading !== null)} onClick={() => setStepAction((pre) => 1)}>
                  <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                  {t('Back to Select Media')}
                </button>
                <button className="btn btn-primary fs-14" color="success" type="button" onClick={onPostClick} disabled={isLoading || (isPreviewLoading !== null)}>
                  {(isLoading && (initialValueActionType === ACTION_TYPE_CAMPAIGN.POST)) ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-send-plane-line align-bottom me-1"></i>}
                  {t('Button Post')}
                </button>
              </div>
            </Col>
          </Row >
        </form >
        {/* End:: Step 2 - Create Campaign */}
        {/* Begin:: Step 3 - Create Campaign */}
        <div className={`${stepAction === 3 ? '' : 'd-none'}`}>
          <Col sm={12} lg={12}>
            <div className="row g-3" style={{ border: '1px solid #f0f0f0', padding: '10px', borderRadius: '5px' }}>
              <div id="pills-experience" className="tab-pane active">
                <div className="text-center">
                  <div className="avatar-md mt-5 mb-4 mx-auto">
                    <div className="avatar-title bg-light text-success display-4 rounded-circle">
                      <i className="ri-checkbox-circle-fill"></i></div>
                  </div>
                  <h5>{t('Well Done !')}</h5>
                  <p className="text-muted">{t('You have Successfully Created Campaign')}</p>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* End:: Step 3 - Create Campaign */}
      </Row >
      {/* Begin::modal */}
      < ToastContainer closeButton={false} limit={1} />
      <Modal isOpen={isOpenPreview} centered={true} size="xl" toggle={onClosePreviewClick} keyboard={true} scrollable={true}>
        <ModalHeader toggle={(isPreviewLoading !== null) ? () => { } : onClosePreviewClick}>
          {t('Template Preview')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col xxl={12} className="ribbon-box right">
                    {!!previewTemplate?.memo?.errors?.length && (
                      <div className="ribbon ribbon-danger round-shape top-0" style={{
                        zIndex: 9,
                        background: 'rgb(255 255 255 / 67%)',
                        color: '#f06548',
                        borderRadius: 0,
                        maxHeight: '30dvh',
                        overflowY: 'auto',
                      }}>
                        <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(previewTemplate?.memo?.errors || [])?.map((mess: any) => (
                          <>{`${mess}`}<br /> </>
                        ))}
                        </span>
                      </div>
                    )}
                    <div className="bg-primary-subtle position-relative"><div className="p-5 card-body"><div className="text-center">
                      <h3 className="fw-semibold">{previewTemplate?.title || ''}</h3>
                    </div>
                    </div>
                      <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1440" height="60" preserveAspectRatio="none" viewBox="0 0 1440 60"><g mask="url(&quot;#SvgjsMask1001&quot;)" fill="none"><path d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z" style={{ fill: 'var(--vz-secondary-bg)' }}></path></g><defs><mask id="SvgjsMask1001"><rect width="1440" height="60" fill="#ffffff"></rect></mask></defs></svg>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div className="clear-css-p-in-box" dangerouslySetInnerHTML={{ __html: previewTemplate?.content || '' }}></div>
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-light fs-14" type="button" color="light" onClick={onClosePreviewClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <ModalConfirm
        textButtonConfirm={t('Button Post')}
        classButtonConfirm='btn-primary'
        classIconButtonConfirm='ri-send-plane-line'
        header={t('Campaign Create')}
        title={t('Are you sure you want to post this?')}
        content={t('After confirming the post action, the data will be created. Do you want to proceed with the confirming action.')}
        isShowIcon={false}
        isOpen={isOpenConfirmCreate}
        onClose={onCloseConfirmCreateClick}
        onConfirm={handleCreateCampaign}
      />
      <ModalConfirm
        textButtonConfirm={t('Button Update Status Keyword')}
        classButtonConfirm='btn-primary'
        classIconButtonConfirm=''
        header={itemUpdate?.is_active ? t('Confirm to Display keyword') : t('Confirm to Hide keyword')}
        title={t('Are you sure you want to update this?')}
        content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
        isShowIcon={false}
        isOpen={!!itemUpdate}
        isLoading={isLoadingUpdate}
        onClose={() => setItemUpdate((prev: any) => null)}
        onConfirm={handleOnConfirmUpdate}
      />
      {/* End::modal */}
    </React.Fragment >
  );
};

export default CampaignFormByFlowedKeywordAnalysis;