import { createSlice } from "@reduxjs/toolkit";
import { getAdsAccount, getAdsAccountHistory, getMedias, getNotifications, getRevenue } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IAdsAccount, IAdsAccountHistory, IMedia, INotification, IRevenue } from "api/types/_media";


export interface IState {
  medias: ResponseData<IMedia[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,


  notifications: ResponseData<INotification[]> & PaginationResponse | null,
  isNotificationLoading: boolean,
  isNotificationSuccess: boolean,

  revenue: ResponseData<IRevenue[]> & PaginationResponse | null,
  isRevenueLoading: boolean,
  isRevenueSuccess: boolean,

  adsAccount: ResponseData<IAdsAccount[]> & PaginationResponse | null,
  isAdsAccountLoading: boolean,
  isAdsAccountSuccess: boolean,


  adsAccountHistory: ResponseData<IAdsAccountHistory[]> & PaginationResponse | null,
  isAdsAccountHistoryLoading: boolean,
  isAdsAccountHistorySuccess: boolean,

  allMedia: ResponseData<IMedia[]> | null,

  error: any,
};

export const initialState: IState = {

  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  notifications: null,
  isNotificationLoading: false,
  isNotificationSuccess: false,

  revenue: null,
  isRevenueLoading: false,
  isRevenueSuccess: false,

  adsAccount: null,
  isAdsAccountLoading: false,
  isAdsAccountSuccess: false,

  adsAccountHistory: null,
  isAdsAccountHistoryLoading: false,
  isAdsAccountHistorySuccess: false,

  allMedia: null,

  error: {},
};

const MediaSlice = createSlice({
  name: "Media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Medias
    builder.addCase(getMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(getMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(getMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

    //get Notifications
    builder.addCase(getNotifications.pending, (state: IState, action: any) => {
      state.isNotificationLoading = true
    });
    builder.addCase(getNotifications.fulfilled, (state: IState, action: any) => {
      state.notifications = action.payload.data;
      state.isNotificationSuccess = true;
      state.isNotificationLoading = false;
    });
    builder.addCase(getNotifications.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNotificationSuccess = false;
      state.isNotificationLoading = false;
    });

    //get Revenue
    builder.addCase(getRevenue.pending, (state: IState, action: any) => {
      state.isRevenueLoading = true
    });
    builder.addCase(getRevenue.fulfilled, (state: IState, action: any) => {
      state.revenue = action.payload.data;
      state.isRevenueSuccess = true;
      state.isRevenueLoading = false;
    });
    builder.addCase(getRevenue.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRevenueSuccess = false;
      state.isRevenueLoading = false;
    });

    //get AdsAccount
    builder.addCase(getAdsAccount.pending, (state: IState, action: any) => {
      state.isAdsAccountLoading = true
    });
    builder.addCase(getAdsAccount.fulfilled, (state: IState, action: any) => {
      state.adsAccount = action.payload.data;
      state.isAdsAccountSuccess = true;
      state.isAdsAccountLoading = false;
    });
    builder.addCase(getAdsAccount.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdsAccountSuccess = false;
      state.isAdsAccountLoading = false;
    });

    //get AdsAccount History
    builder.addCase(getAdsAccountHistory.pending, (state: IState, action: any) => {
      state.isAdsAccountHistoryLoading = true
    });
    builder.addCase(getAdsAccountHistory.fulfilled, (state: IState, action: any) => {
      state.adsAccountHistory = action.payload.data;
      state.isAdsAccountHistorySuccess = true;
      state.isAdsAccountHistoryLoading = false;
    });
    builder.addCase(getAdsAccountHistory.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdsAccountHistorySuccess = false;
      state.isAdsAccountHistoryLoading = false;
    });
  },
});

export default MediaSlice.reducer;