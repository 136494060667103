import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICampaignStatistic, ICategoryStatistic, INewsStatistic } from "api/types/_statistic";
import { getCampaignStatisticsFromLab, getCategoryStatisticsFromLab, getNewsStatisticsFromLab } from "./thunk";


export interface IState {
  newsStatistics: ResponseData<INewsStatistic[]> & PaginationResponse | null,
  isNewsStatisticLoading: boolean,
  isNewsStatisticSuccess: boolean,

  campaignStatistics: ResponseData<ICampaignStatistic[]> & PaginationResponse | null,
  isCampaignStatisticLoading: boolean,
  isCampaignStatisticSuccess: boolean,

  categoryStatistics: ResponseData<ICategoryStatistic[]> & PaginationResponse | null,
  isCategoryStatisticLoading: boolean,
  isCategoryStatisticSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  newsStatistics: null,
  isNewsStatisticLoading: false,
  isNewsStatisticSuccess: false,

  campaignStatistics: null,
  isCampaignStatisticLoading: false,
  isCampaignStatisticSuccess: false,

  categoryStatistics: null,
  isCategoryStatisticLoading: false,
  isCategoryStatisticSuccess: false,

  error: {},
};

const NewsStatisticSlice = createSlice({
  name: "Lab",
  initialState,
  reducers: {
    changeStatusCampaignsLabAction(state: any, action : any) {
      state.campaignStatistics = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get NewsStatistics
    builder.addCase(getNewsStatisticsFromLab.pending, (state: IState, action: any) => {
      state.isNewsStatisticLoading = true
    });
    builder.addCase(getNewsStatisticsFromLab.fulfilled, (state: IState, action: any) => {
      state.newsStatistics = action.payload.data;
      state.isNewsStatisticSuccess = true;
      state.isNewsStatisticLoading = false;
    });
    builder.addCase(getNewsStatisticsFromLab.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNewsStatisticSuccess = false;
      state.isNewsStatisticLoading = false;
    });

    //get CategoryStatistics
    builder.addCase(getCategoryStatisticsFromLab.pending, (state: IState, action: any) => {
      state.isCategoryStatisticLoading = true
    });
    builder.addCase(getCategoryStatisticsFromLab.fulfilled, (state: IState, action: any) => {
      state.categoryStatistics = action.payload.data;
      state.isCategoryStatisticSuccess = true;
      state.isCategoryStatisticLoading = false;
    });
    builder.addCase(getCategoryStatisticsFromLab.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategoryStatisticSuccess = false;
      state.isCategoryStatisticLoading = false;
    });

    //get CampaignStatistics
    builder.addCase(getCampaignStatisticsFromLab.pending, (state: IState, action: any) => {
      state.isCampaignStatisticLoading = true
    });
    builder.addCase(getCampaignStatisticsFromLab.fulfilled, (state: IState, action: any) => {
      state.campaignStatistics = action.payload.data;
      state.isCampaignStatisticSuccess = true;
      state.isCampaignStatisticLoading = false;
    });
    builder.addCase(getCampaignStatisticsFromLab.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCampaignStatisticSuccess = false;
      state.isCampaignStatisticLoading = false;
    });

  },
});

export const {
  changeStatusCampaignsLabAction,
} = NewsStatisticSlice.actions;

export default NewsStatisticSlice.reducer;