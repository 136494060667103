import { createSlice } from "@reduxjs/toolkit";
import { getLottos } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ILotto } from "api/types/_lotto";

export interface IState {
  lottos: ResponseData<ILotto[]> & PaginationResponse | null,
  isLottoLoading: boolean,
  isLottoSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  lottos: null,
  isLottoLoading: false,
  isLottoSuccess: false,

  error: {},
};

const LottoSlice = createSlice({
  name: "Lotto",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Lotto
    builder.addCase(getLottos.pending, (state: IState, action: any) => {
      state.isLottoLoading = true
    });
    builder.addCase(getLottos.fulfilled, (state: IState, action: any) => {
      state.lottos = action.payload.data;
      state.isLottoSuccess = true;
      state.isLottoLoading = false;
    });
    builder.addCase(getLottos.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isLottoSuccess = false;
      state.isLottoLoading = false;
    });
  },
});

export default LottoSlice.reducer;