import { IMedia, IType } from "api/types/_media";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes } from "store/thunks";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import CampaignFormByKeywordAnalyzing from "../../CampaignForm/CampaignFormByKeywordAnalyzing";


const CampaignManual = () => {
    const { t, i18n } = useTranslation();

    const [listType, setListType] = useState<IType[]>([]);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

    const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

    const [listClassifications, setListClassifications] = useState<Option[]>([]);

    const handleCallAllOption = async () => {
        try {
            const [
                resMedia,
                resTemplate,
                resType,
                resCategoriesType,
            ]: any = await Promise.all([
                getAllMedias(),
                getAllTemplates(),
                getAllTypes(),
                getAllCategoriesType(),
            ]);
            setListMedia((_prev) => resMedia?.data || []);
            setListTemplate((_prev) => resTemplate?.data || []);
            setListType((_prev) => resType?.data || []);
            setListCategoriesType(
                (_prev) =>
                    resCategoriesType?.data?.map((item: any) => ({
                        value: item?.type,
                        label: item?.name,
                    })) || []
            );
        } catch (error: any) {
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t("Create Campaign (Manual Keyword)")} - ${t("Campaign")} | NewsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title={t("Create Campaign (Manual Keyword)")}
                        centerTitle={t('Keyword Analyzing News')}
                        pageTitle={t("Campaign")}
                    />
                    <CampaignFormByKeywordAnalyzing
                        typeCampaignForm='MANUAL_KEYWORD'
                        listType={listType}
                        listMedia={listMedia}
                        listTemplate={listTemplate}
                        listCategoriesType={listCategoriesType}
                        listClassifications={listClassifications}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CampaignManual;