import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllMedias, getKeywordInflows as onGetKeywordInflows } from "../../../store/thunks";

import { IMedia } from 'api/types/_media';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import { useRole } from 'components/Hooks/UserHooks';
import CountUp from 'react-countup';
import { TYPE_KEYWORD_OPTIONS, TYPE_NEWS_OPTIONS } from 'helpers/constans';
import DropdownOption from 'components/Common/DropdownOption';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import LabelWrapper from 'components/Common/LabelWrapper';
// import ChartLine from './ChartLine';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'pv';

const KeywordInflowList = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();

  const TYPE_KEYWORD_OPTIONS_LANG = TYPE_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    domain: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    type_keyword: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    is_newshub: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [typeKeywordSearch, setTypeKeywordSearch] = useState<Option | null>(TYPE_KEYWORD_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.type_keyword))[0]);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_newshub))[0]);

  // const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);
  // const [chart, setChart] = useState<{
  //   categories?: string[],
  //   series?: {
  //     name: string,
  //     data: number[],
  //   }[]
  // }>({ categories: [], series: [] });

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const keywordInflowsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywordInflows: state.keywordInflows,
      isKeywordInflowSuccess: state.isKeywordInflowSuccess,
      isKeywordInflowLoading: state.isKeywordInflowLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywordInflows, error, isKeywordInflowLoading } = useSelector(keywordInflowsProperties);

  useEffect(() => {
    dispatch(onGetKeywordInflows(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      title: titleSearch || "",
      keyword: keywordSearch || "",
      type_keyword: typeKeywordSearch?.value ?? '',
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      is_newshub: typeNewsSearch?.value,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      domain: '',
      title: '',
      keyword: '',
      type_keyword: '',
      start_date: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };

    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setTypeKeywordSearch(null);
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setTypeNewsSearch(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Ranking'),
        accessor: "rank",
        filterable: false,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        thWidth: 100,
        description: t('Description PV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end">{cell?.value}</div>
          </>
        ),
      },
      // {
      //   Header: t('PV2'),
      //   accessor: "pv2",
      //   filterable: false,
      //   sortable: false,
      //   thClass: 'text-end',
      //   thWidth: 100,
      //   description: t('Description PV (Estimated)'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end">{cell?.value}</div>
      //     </>
      //   ),
      // },
      {
        Header: t('UV'),
        accessor: "uv",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        thWidth: 100,
        description: t('Description UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end">{cell?.value}</div>
          </>
        ),
      },
      // {
      //   Header: t('Count'),
      //   accessor: "count",
      //   filterable: true,
      //   sortable: true,
      //   thWidth: 150,
      //   Cell: (cell: any) => (
      //     <>
      //       <span>{cell?.value}</span>
      //     </>
      //   ),
      // },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  // const handleRenderChartKeywords = async (params: { start_date: string, end_date: string }) => {
  //   try {
  //     setIsLoadingChart((_prev) => true);
  //     const resChart: any = await getDataChartForKeywords(params);
  //     const data = {
  //       categories: (resChart?.data || [])?.map((item: any) => item?.date),
  //       series: [{
  //         name: t('Keyword Inflow'),
  //         data: (resChart?.data || [])?.map((item: any) => Number(item?.total)),
  //       }]
  //     }
  //     setIsLoadingChart((_prev) => false);
  //     setChart((_prev) => data);
  //   } catch (error: any) {
  //     setIsLoadingChart((_prev) => false);
  //     return error;
  //   }
  // };


  // useEffect(() => {
  //   const params = {
  //     start_date: query?.start_date || '',
  //     end_date: query?.end_date || '',
  //   };
  //   handleRenderChartKeywords(params);
  // }, [query?.start_date, query?.end_date]);


  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Keyword Inflow')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    setDurationResponses([
      {
        name: 'Keyword Inflow',
        time: keywordInflows?.pagination?.duration || 0
      }
    ]);
  }, [keywordInflows?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Keyword Inflow')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_INFLOW_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isKeywordInflowLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={keywordInflows?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-2">

                          <Col sm={12} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={`${t('Media')}`} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          {/* <Col sm={3} lg={3} className='mt-2'>
                          <DropdownOption
                            name=""
                            dataList={TYPE_KEYWORD_OPTIONS_LANG}
                            placeholder={`${t("Type Keyword")}...`}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={typeKeywordSearch || null}
                            onChangeSelect={(e: any) => setTypeKeywordSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Keyword'), value: '' }}
                          />
                        </Col> */}
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={`${t('Type')}`} isShow={!!typeNewsSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={TYPE_NEWS_OPTIONS_LANG}
                                placeholder={`${t("News Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={typeNewsSearch || null}
                                onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All News'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={`${t('Start Date')} - ${t('End Date')}`} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mt-0">
                          <Col sm={12} lg={6} className="mt-2">
                            <LabelWrapper label={`${t('Title Post')}`} isShow={!!titleSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title Post')}...`}
                                value={titleSearch}
                                onChange={(e) => setTitleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={`${t('Keyword')}`} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Keyword')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>

                          <Col sm={12} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isKeywordInflowLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  {/* <ChartLine className="mb-2" title={t('Keyword Inflow')} {...chart} isLoading={isLoadingChart} /> */}
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={keywordInflows?.list?.length ? keywordInflows?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={keywordInflows?.pagination?.total}
                        customPageCount={Math.ceil(Number(keywordInflows?.pagination?.total) / Number(keywordInflows?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isKeywordInflowLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default KeywordInflowList;
