import { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import { useTranslation } from "react-i18next";

import ModalCollapseTag from 'components/Common/CollapseTag/ModalCollapseTag';
import { TYPE_SHOW_MORE_HASHTAG } from 'helpers/constans'

const MAX_HASHTAGS_DEFAULT = 'full'
const ID_SELECTED_DEFAULT = '';

export interface Tag {
  id: string;
  text: string
}
interface HashtagProps {
  name?: string,
  initialValue?: Tag[];
  suggestions?: Tag[];
  placeholder?: string,
  onChangeTag?: (params: Tag[]) => void;
  idSelected?: string,
  maxHashtags?: 'full' | number,
  typeShowMore?: string,
  maxHeight?: string
}

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Hashtag = ({
  name = '',
  initialValue = [],
  suggestions = [],
  placeholder = '',
  onChangeTag,
  idSelected = ID_SELECTED_DEFAULT,
  maxHashtags = MAX_HASHTAGS_DEFAULT,
  typeShowMore = TYPE_SHOW_MORE_HASHTAG.MODAL,
  maxHeight = "30dvh"
}: HashtagProps) => {

  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleDelete = (i: number) => {
    onChangeTag && onChangeTag((initialValue || []).filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: any) => {
    onChangeTag && onChangeTag([...initialValue, tag]);
  };

  const handleDeleteValue = (tag: any) => {
    onChangeTag && onChangeTag([]);
  };

  useEffect(() => {
    const tags = (suggestions || [])?.reduce((arr: Tag[], item: Tag) => {
      if (arr.some(e => e.id === item?.id)) {
        return arr;
      }
      return ([...arr, item]);
    }, (initialValue || []));
    onChangeTag && onChangeTag(tags);
  }, [JSON.stringify(suggestions)]);

  useLayoutEffect(() => {

    const hashtagsSelectedElement = document.querySelector(`.${idSelected}.hashtags_selected`) as HTMLElement;
    const btnShowMoreHashtags = document.querySelector(`.${idSelected}.btn-showmore-hashtags`) as HTMLElement;

    if (typeShowMore === TYPE_SHOW_MORE_HASHTAG.SCROLL && hashtagsSelectedElement) {
      hashtagsSelectedElement.style.maxHeight = maxHeight;
      hashtagsSelectedElement.style.overflow = 'auto';

    } else if (typeShowMore === TYPE_SHOW_MORE_HASHTAG.MODAL && hashtagsSelectedElement) {
      if (btnShowMoreHashtags) {
        btnShowMoreHashtags.remove();
      }

      if (hashtagsSelectedElement && typeof maxHashtags === 'number' && maxHashtags < initialValue.length) {

        const btnShowMoreHashtags = document.createElement('div');
        btnShowMoreHashtags.className = `btn-showmore-hashtags d-inline-block link-primary d-contents fs-12 cursor-pointer ${idSelected}`;

        btnShowMoreHashtags.innerHTML = `
      <div class="d-flex align-items-center">
        <span>${t('More')}</span><i class="ri-arrow-down-s-line fs-16"></i>
      </div>
    `;

        // Add click event handler to the "More" button
        btnShowMoreHashtags.addEventListener('click', toggleModalCollapseTag);

        hashtagsSelectedElement.appendChild(btnShowMoreHashtags);
      }
    } else {
      return;
    }
  }, [t, initialValue, maxHashtags, idSelected, maxHeight, typeShowMore]);

  const toggleModalCollapseTag = () => {
    setIsOpenModal((_prev) => !_prev);
  }

  const getTagsLimitedByMaxHashtags = useCallback(() => {
    if (typeShowMore === TYPE_SHOW_MORE_HASHTAG.MODAL && typeof maxHashtags === 'number') {
      const tagsLimit = initialValue.slice(0, maxHashtags);
      return tagsLimit
    }
    return initialValue;

  }, [initialValue, maxHashtags, typeShowMore])

  const getDataTagsModal = () => {
    const tags = initialValue.map((value) => {
      const { text } = value
      return text
    })
    return tags;
  }

  return (
    <div className='position-relative'>
      <ReactTags
        tags={getTagsLimitedByMaxHashtags() || []}
        suggestions={[]}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        autocomplete={true}
        allowDeleteFromEmptyInput={true}
        allowDragDrop={false}
        placeholder={placeholder}
        classNames={{
          tags: 'hashtags-custom',
          tagInputField: 'form-control',
          selected: `ReactTags__selected hashtags_selected ${idSelected}`
        }}
      />
      {(initialValue?.length > 0) && (<span
        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer"
        onClick={handleDeleteValue}
        style={{
          right: '8px',
          top: 5,
          fontSize: '20px',
          color: 'var(--vz-header-item-sub-color)'
        }}
      ></span>)}
      <ModalCollapseTag isOpen={isOpenModal}
        titleModal={t('Hashtags')}
        scrollable={true}
        onCloseClick={toggleModalCollapseTag}
        keyboard={true}
        tags={getDataTagsModal()} />
    </div>
  );
};

export default Hashtag;
