import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import { addPartnerAdsAccountRevenueRate, deleteAdsAccountRevenueRate, getAdsAccountRevenueRate, updatePartnerAdsAccountRevenueRate } from "../../../../store/thunks";

import { IAdsAccount, IAgency, IMedia } from 'api/types/_media';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { formatNumberWithCommas } from 'helpers/format';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import ModalConfirm from 'components/Common/ModalConfirm';

interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'id';

const RevenueRate = ({ accountId = '', nameAdsAccount = '', handleCloseModal, handleActionRefresh }: any) => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();

  const [query, setQuery] = useState<any>({
    account_id: accountId,
    time_request: 0,
    // sort_by: TYPE_SELECT_DEFAULT,
    // order_by: `DESC`,
    page: 1,
    limit: 30,
  });

  const [listAgency, setListAgency] = useState<IAgency[]>([]);
  const [agencySearch, setAgencySearch] = useState<Option | null>(
    {
      label: listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.name || t('All Agency'),
      value: `${listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.id || ''}`
    }
  );

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.id || ''}`
  });

  const [adsAccountRevenueRate, setAdsAccountRevenueRate] = useState<any>({});

  const [valueRate, setValueRate] = useState<any>({});
  const [idAdsAccountLoading, setIdAdsAccountLoading] = useState<number | null>(null);


  const [valueRateAdd, setValueRateAdd] = useState<any>({});
  const [addAdsAccountRevenueRateLoading, setAddAdsAccountRevenueRateLoading] = useState<boolean>(false);

  const [systemRate, setSystemRate] = useState<string>('');
  const [partnerRate, setPartnerRate] = useState<string>('');
  const [initialValuesDefault, setInitialValuesDefault] = useState<IAdsAccount | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [idConfirmDelete, setIdConfirmDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    if (!!accountId) {
      const freshData = async () => {
        try {
          setIsLoading((_prev) => true);
          const response: any = await getAdsAccountRevenueRate(query);
          setIsLoading((_prev) => false);
          if (response?.code === 200) {
            setAdsAccountRevenueRate((prev: any) => response?.data || {});
            setValueRate((prev: any) => {
              return response?.data?.list?.reduce((ob: any, item: IAdsAccount) => ({
                ...ob,
                [`cell-${item?.id}`]: {
                  partner_revenue_rate: item?.partner_revenue_rate,
                  system_revenue_rate: item?.system_revenue_rate,
                  effective_from: item?.effective_from ? moment(item?.effective_from || "", 'Y-MM-DD').toDate() : '',
                  effective_to: item?.effective_to ? moment(item?.effective_to || "", 'Y-MM-DD').toDate() : '',
                }
              }), {});
            });
          } else {
            toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
          }
        } catch (error: any) {
          setIsLoading((_prev) => false);
          toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
          return error;
        }
      };
      freshData();
    }

  }, [JSON.stringify(query)]);

  const handleShowUpdate = async (item: IAdsAccount) => {
    setInitialValuesDefault(item);
    setSystemRate(`${item?.system_revenue_rate}`);
    setPartnerRate(`${item?.partner_revenue_rate}`);
  };

  const onCloseClick = async () => {
    setInitialValuesDefault(null);
  };

  // Begin::Update Ads Account
  const handleActionUpdateAdsAccount = async (item: IAdsAccount) => {
    if (!(item?.id)) { return; };
    try {
      setIdAdsAccountLoading((_prev: any) => item?.id);
      const response: any = await updatePartnerAdsAccountRevenueRate(item?.id, {
        partner_revenue_rate: Number(valueRate[`cell-${item?.id}`]?.partner_revenue_rate),
        system_revenue_rate: Number(valueRate[`cell-${item?.id}`]?.system_revenue_rate),
        effective_from: valueRate[`cell-${item?.id}`]?.effective_from ? moment(new Date(valueRate[`cell-${item?.id}`]?.effective_from)).format("Y-MM-DD") : '',
        effective_to: valueRate[`cell-${item?.id}`]?.effective_to ? moment(new Date(valueRate[`cell-${item?.id}`]?.effective_to)).format("Y-MM-DD") : '',
      });
      if (response?.code === 200) {
        setIdAdsAccountLoading((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleActionRefresh && handleActionRefresh();
      } else {
        setIdAdsAccountLoading((_prev) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdAdsAccountLoading((_prev) => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionAddAdsAccount = async () => {
    if (!(accountId)) { return; };
    try {
      setAddAdsAccountRevenueRateLoading((_prev: any) => true);
      const response: any = await addPartnerAdsAccountRevenueRate({
        account_id: accountId,
        partner_revenue_rate: Number(valueRateAdd?.partner_revenue_rate),
        system_revenue_rate: Number(valueRateAdd?.system_revenue_rate),
        effective_from: valueRateAdd?.effective_from ? moment(new Date(valueRateAdd?.effective_from)).format("Y-MM-DD") : '',
        effective_to: valueRateAdd?.effective_to ? moment(new Date(valueRateAdd?.effective_to)).format("Y-MM-DD") : '',
      });
      if (response?.code === 200) {
        setQuery((_prev: any) => ({ ..._prev, time_request: + new Date() }));
        setValueRateAdd((prev: any) => ({}));
        setAddAdsAccountRevenueRateLoading((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleActionRefresh && handleActionRefresh();
      } else {
        setAddAdsAccountRevenueRateLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setAddAdsAccountRevenueRateLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionDelete = async () => {
    if (!(idConfirmDelete)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteAdsAccountRevenueRate(idConfirmDelete);
      if (response?.code === 200) {
        setQuery((_prev: any) => ({ ..._prev, time_request: + new Date() }));
        setIsConfirmLoading((_prev) => false);
        setIdConfirmDelete((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdConfirmDelete((_prev) => null);
  }

  const onCloseUpdateAdsAccountClick = () => {
    setInitialValuesDefault((_prev) => null);
  }

  // End::Update Ads Account

  const handleScrollToTop = () => {
    tableRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery((_prev: any) => ({ ..._prev, page: page + 1 }));
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card id="leadsList">
          <CardBody className="pt-3">
            <div id="scrollableDiv" ref={tableRef} className={`color-scrollbar-os`} style={{ height: 'calc(100dvh - 280px)', margin: 0, overflowY: 'auto' }}
            >
              <div className="table-responsive" style={{ minHeight: '450px' }}>
                <Table className="caption-top table-bordered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colSpan={5} className="text-center bold">{nameAdsAccount || ''}</th>
                    </tr>
                    <tr>
                      <th scope="col" className="text-center bold">#</th>
                      <th scope="col" className="text-center bold">{t('System Revenue Rate')}</th>
                      <th scope="col" className="text-center bold">{t('Partner Revenue Rate')}</th>
                      <th scope="col" className="text-center bold">{t('Effective Date')}</th>
                      <th scope="col" className="text-center bold">{t('Button Action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key='000' style={{ backgroundColor: '#eaeaea6e' }}>
                      <td className="align-middle text-center h6 ">
                        <div className="w-100 text-center" >
                          *
                        </div>
                      </td>
                      <td className={`align-middle text-center h6`}>
                        <input
                          className="form-control text-center"
                          id={`cell-000-system_revenue_rate`}
                          name={`cell-000-system_revenue_rate`}
                          value={valueRateAdd?.system_revenue_rate ?? ''}
                          placeholder={`${t('System Revenue Rate')}...`}
                          onChange={(event: any) => {
                            const input = event.target.value;
                            const onlyNumbers = input.replace(/\D/g, '');
                            const s = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                            setValueRateAdd((prev: any) => ({
                              ...prev,
                              partner_revenue_rate: 100 - s,
                              system_revenue_rate: s,
                            }));
                          }}
                        />
                      </td>
                      <td className={`align-middle text-center h6`}>
                        <input
                          className="form-control text-center"
                          id={`cell-0-partner_revenue_rate`}
                          name={`cell-0-partner_revenue_rate`}
                          value={valueRateAdd?.partner_revenue_rate ?? ''}
                          placeholder={`${t('Partner Revenue Rate')}...`}
                          onChange={(event: any) => {
                            const input = event.target.value;
                            const onlyNumbers = input.replace(/\D/g, '');
                            const p = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                            setValueRateAdd((prev: any) => ({
                              ...prev,
                              system_revenue_rate: 100 - p,
                              partner_revenue_rate: p
                            }));
                          }}
                        />
                      </td>
                      <td className="align-middle">
                        <div className="date-picker-wrapper-custom" style={{ width: '100%' }}>
                          <DatePickerCustom
                            placeholder={`${t('Effective from')}  >  ${t('Effective to')}`}
                            startDate={valueRateAdd?.effective_from || null}
                            endDate={valueRateAdd?.effective_to || null}
                            // minDate={new Date()}
                            onChangePicker={(event: any) => {
                              setValueRateAdd((prev: any) => ({
                                ...prev,
                                effective_from: event[0],
                                effective_to: event[1]
                              }));
                            }}
                          />
                        </div>
                      </td>
                      <td className={`align-middle text-center h6 text-danger`} style={{ width: '100px' }}>
                        <Link className={`m-auto btn btn-sm btn-warning edit-item-btn d-flex align-items-center justify-content-center ${(addAdsAccountRevenueRateLoading || (valueRateAdd?.system_revenue_rate === undefined || valueRateAdd?.partner_revenue_rate === undefined || valueRateAdd?.effective_from === null || valueRateAdd?.effective_to === null)) ? 'disabled' : ''}`}
                          to="#"
                          style={{ height: '38px', width: '85px' }}
                          onClick={(e) => { e.preventDefault(); handleActionAddAdsAccount() }}
                        >
                          {(addAdsAccountRevenueRateLoading) ? <Spinner size="sm"></Spinner> : <span>{t('Button Create')}</span>}
                        </Link>
                      </td>
                    </tr>
                    {adsAccountRevenueRate?.list?.length === 0 && (
                      <tr>
                        <td colSpan={5} className="text-center">
                          {t('No Data')}
                        </td>
                      </tr>
                    )}
                    {adsAccountRevenueRate?.list?.map((item: IAdsAccount, index: number) => (
                      <tr key={item?.id} style={item?.is_effective ? { backgroundColor: 'rgba(37, 160, 226, 0.2)' } : {}}>
                        <td className="align-middle text-center h6 position-relative">
                          <div className="w-100 text-center" >
                            {((query?.page - 1) * (query?.limit)) + (index + 1)}
                          </div>
                          {item?.is_effective && <span className="text-primary position-absolute " style={{ top: '10px', left: '10px' }}>{t('Applying')}</span>}
                        </td>
                        <td className={`align-middle text-center h6`}>
                          <input
                            className="form-control text-center"
                            id={`cell-${item?.id}-system_revenue_rate`}
                            name={`cell-${item?.id}-system_revenue_rate`}
                            value={valueRate[`cell-${item?.id}`]?.system_revenue_rate}
                            placeholder={`${t('System Revenue Rate')}...`}
                            onChange={(event: any) => {
                              const input = event.target.value;
                              const onlyNumbers = input.replace(/\D/g, '');
                              const s = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                              setValueRate({
                                ...valueRate,
                                [`cell-${item?.id}`]: {
                                  ...valueRate[`cell-${item?.id}`],
                                  partner_revenue_rate: 100 - s,
                                  system_revenue_rate: s,
                                }
                              });
                            }}
                          />
                        </td>
                        <td className={`align-middle text-center h6`}>
                          <input
                            className="form-control text-center"
                            id={`cell-${item?.id}-partner_revenue_rate`}
                            name={`cell-${item?.id}-partner_revenue_rate`}
                            value={valueRate[`cell-${item?.id}`]?.partner_revenue_rate}
                            placeholder={`${t('Partner Revenue Rate')}...`}
                            onChange={(event: any) => {
                              const input = event.target.value;
                              const onlyNumbers = input.replace(/\D/g, '');
                              const p = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                              setValueRate({
                                ...valueRate,
                                [`cell-${item?.id}`]: {
                                  ...valueRate[`cell-${item?.id}`],
                                  system_revenue_rate: 100 - p,
                                  partner_revenue_rate: p
                                }
                              });
                            }}
                          />
                        </td>
                        <td className="align-middle">
                          <div className="date-picker-wrapper-custom" style={{ width: '100%' }}>
                            <DatePickerCustom
                              placeholder={`${t('Effective from')}  >  ${t('Effective to')}`}
                              startDate={valueRate[`cell-${item?.id}`]?.effective_from || null}
                              endDate={valueRate[`cell-${item?.id}`]?.effective_to || null}
                              // minDate={new Date()}
                              onChangePicker={(event: any) => {
                                setValueRate({
                                  ...valueRate,
                                  [`cell-${item?.id}`]: {
                                    ...valueRate[`cell-${item?.id}`],
                                    effective_from: event[0],
                                    effective_to: event[1]
                                  }
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td className={`align-middle text-center h6 text-danger`} style={{ width: '100px' }}>
                          <Link className={`m-auto btn btn-sm btn-soft-success edit-item-btn d-flex align-items-center justify-content-center ${(idAdsAccountLoading === item?.id) ? 'disabled' : ''}`}
                            to="#"
                            style={{ height: '38px', width: '85px' }}
                            onClick={(e) => { e.preventDefault(); handleActionUpdateAdsAccount(item) }}
                          >
                            {(idAdsAccountLoading === item?.id) ? <Spinner size="sm"></Spinner> : <span>{t('Button Update Ads Account')}</span>}
                          </Link>
                          <Link className={`m-auto btn mt-2 btn-sm btn-soft-danger edit-item-btn d-flex align-items-center justify-content-center ${(idAdsAccountLoading === item?.id) ? 'disabled' : ''}`}
                            to="#"
                            style={{ height: '38px', width: '85px' }}
                            onClick={(e) => { e.preventDefault(); setIdConfirmDelete((prev: any) => (item?.id)) }}
                          >
                            {t('Button Delete')}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <Row>
              <Col md={10} xxl={10} lg={10} className="table-pagination-modal">
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
                <div className="d-flex align-items-start gap-3 mt-3">
                  <div className="align-items-center g-3 text-center">
                    <div className="col-sm-auto">
                      <ReactPaginate
                        nextLabel={`${t('next')} >`}
                        onPageChange={(page) => {
                          if (handleChangePage) {
                            handleScrollToTop && handleScrollToTop();
                            handleChangePage(page.selected);
                          }
                        }}
                        forcePage={query.page - 1}
                        pageRangeDisplayed={Number(window?.innerWidth) < 900 ? 2 : 3}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(Number(adsAccountRevenueRate?.pagination?.total) / Number(adsAccountRevenueRate?.pagination?.limit))}
                        previousLabel={`< ${t('previous')}`}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link ms-0"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination pagination-separated"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                    <div className="col-sm text-start mt-2">
                      <div className="text-muted">
                        {t('Total')}:
                        {" "}
                        <span className="fw-semibold ms-1">{formatNumberWithCommas(adsAccountRevenueRate?.pagination?.total)}</span>
                        {" "}
                        {t('Results')}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2} xxl={2} lg={2} className='mt-4'>
                <div className="hstack gap-2 justify-content-end">
                  <button className="btn btn-soft-secondary fs-14" color="light" type="button" onClick={() => handleCloseModal && handleCloseModal()}>
                    <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                    {t('Button Close')}
                  </button>
                </div>
              </Col>
            </Row>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
      <ModalConfirm
        header={t('Button Delete Revenue Rate')}
        isOpen={!!idConfirmDelete}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmClick}
        onConfirm={handleActionDelete}
      />
    </React.Fragment >
  );
};

export default RevenueRate;
