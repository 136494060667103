import { ICampaign } from 'api/types/_campaign';
import { ICategory } from 'api/types/_category';
import { IMedia, IType } from 'api/types/_media';
import { IMediaPublished, IPreviewNews } from 'api/types/_news';
import { Option } from 'api/types/_public';
import { ITemplate } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import CollapseTag from 'components/Common/CollapseTag';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownOption from 'components/Common/DropdownOption';
import DropdownStatusNews from 'components/Common/DropdownStatusNews';
import EditorCustom from 'components/Common/EditorCustom';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterCampaignByKeyword from 'components/Common/SearchFilterCampaignByKeyword';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { COLORS_NEWS_STATUS, SCHEDULE_OPTIONS, SCHEDULE_WEEKLY_OPTIONS, STATUS_NEWS_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import CampaignFormEdit from 'pages/Campaign/CampaignForm/CampaignFormEdit';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import TableContainer from "../../../../components/Common/TableContainer";
import { copyNews, deleteNews, downloadFileNews, downloadMediaNews, getAllCategoriesStockNormal, getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes, getScriptTrackingNews, mediaPublishedNews, getNewsKeyword as onGetNews, putConfirmNews, putMultiReCreateNews, putMultiSetPublishedAtNews, putNews, putResetNews, putResetStatusNews, sendEmailNews } from "../../../../store/thunks";
import LabelWrapper from 'components/Common/LabelWrapper';
import CopyWrapper from 'components/Common/CopyWrapper';

const typeQuery = {
  'title': StringParam,
  'id': StringParam,
  'partner_news_id': StringParam,
  'publishing_date': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';


registerLocale("en", en);
registerLocale("ko", ko);


const getClassTextMinMaxAgeFromArr = (ob: any = {}, num: number = 0) => {
  const arr = [
    ob?.age_10_ratio,
    ob?.age_20_ratio,
    ob?.age_30_ratio,
    ob?.age_40_ratio,
    ob?.age_50_ratio
  ];
  return (Math.min(...arr) === num ? 'text-primary' : (Math.max(...arr) === num ? 'text-danger h6' : ''));
};

const getClassTextMinMaxGenderFromArr = (ob: any = {}, num: number = 0) => {
  const arr = [
    ob?.male_ratio,
    ob?.female_ratio,
  ];
  return (Math.min(...arr) === num ? 'text-secondary' : (Math.max(...arr) === num ? 'text-danger h6' : ''));
};

const NewsListByKeywordAnalyzing = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const CATEGORY_TYPE_CREATE_BY_FORM_MANUAL = ['keyword_manual', 'keyword_popular', 'keyword_core'];

  const STATUS_NEWS_OPTIONS_LANG = STATUS_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const WEEKLY_OPTIONS = SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({ ...item, type: "weekly", })) || [];
  const DATE_OPTIONS_LANG = WEEKLY_OPTIONS?.map((item: any) => ({ ...item, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
    partner_ids: withDefault(StringParam, ''),
    keyword_ids: withDefault(StringParam, ''),
    campaign_id: withDefault(StringParam, ''),
    category_type: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date || "", 'Y-MM-DD').toDate() : '', query?.end_date ? moment(query?.end_date || "", 'Y-MM-DD').toDate() : '']);
  const [startDate, endDate] = dateSearch;

  const [datePublishedSearch, setDatePublishedSearch] = useState<any[]>([new Date(), new Date()]);
  const [startDatePublished, endDatePublished] = datePublishedSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [noSearch, setNoSearch] = useState<string>(query?.id || "");

  const [newsIdClientSearch, setNewsIdClientSearch] = useState<string>(query?.partner_news_id || "");

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState<boolean>(false);
  const [idLoadingDownloadFile, setIdLoadingDownloadFile] = useState<number | string | null>(null);
  const [idLoadingDownloadZip, setIdLoadingDownloadZip] = useState<number | string | null>(null);
  const [keyLoading, setKeyLoading] = useState<number | null>(null);
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);


  const [keyScriptTrackingLoading, setKeyScriptTrackingLoading] = useState<number | null>(null);
  const [isOpenCopyScriptTracking, setIsOpenCopyScriptTracking] = useState<boolean>(false);
  const [scriptTracking, setScriptTracking] = useState<string>('');

  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [isOpenConfirmSendEmail, setIsOpenConfirmSendEmail] = useState<boolean>(false);

  const [isOpenConfirmReCreate, setIsConfirmReCreate] = useState<boolean>(false);
  const [idsReCreateNews, setIdsReCreateNews] = useState<string[] | number[] | null>(null);

  const [isOpenConfirmNews, setIsConfirmNews] = useState<boolean>(false);

  const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(null);

  const [isOpenConfirmResetStatus, setIsConfirmResetStatus] = useState<boolean>(false);
  const [idsResetStatusNews, setIdsResetStatusNews] = useState<string[] | number[] | null>(null);


  const [isOpenConfirmResetNews, setIsConfirmResetNews] = useState<boolean>(false);
  const [idResetNews, setIdResetNews] = useState<string | number | null>(null);


  const [isShowColumn, setIsShowColumn] = useState<boolean>(true);

  const [campaignSearch, setCampaignSearch] = useState<Option | null>(null);

  const [keywordsSearch, setKeywordsSearch] = useState<Option[]>([]);

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [dateTypeSearch, setDateTypeSearch] = useState<Option | null>(null);

  // Begin::List Option Edit Campaign
  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.id || ''}`
  });

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  const [listCategoriesStock, setListCategoriesStock] = useState<ICategory[]>([]);

  const [campaignEdit, setCampaignEdit] = useState<ICampaign | null>(null);

  const listCategoriesTypeLang = listCategoriesType?.reduce((arr: any, item: any) => {
    if (CATEGORY_TYPE_CREATE_BY_FORM_MANUAL?.includes(item?.value)) {
      return ([...arr, ({
        ...item,
        label: t(`Category_Type_[${item?.value}]_For_Campaign`),
      })]);
    }
    return arr;
  }, []) || [];
  // End::List Option Edit Campaign

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const refTitleNews = useRef<any>(null);
  const refContentNews = useRef<any>(null);
  const [isActionConfirmAndUpdateNews, setIsActionConfirmAndUpdateNews] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.News;

  const NewsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      news: state.newsKeyword,
      isNewsSuccess: state.isNewsKeywordSuccess,
      isNewsLoading: state.isNewsKeywordLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { news, error, isNewsLoading } = useSelector(NewsProperties);


  useEffect(() => {
    dispatch(onGetNews(
      { ...query, ...(query.sort_by && rangeValue.current && ({ [query.sort_by]: rangeValue.current?.value })) }
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      title: keywordSearch || undefined,
      partner_news_id: newsIdClientSearch || undefined,
      id: noSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      partner_ids: mediaSearch?.value || '',
      keyword_ids: (keywordsSearch || [])?.map((item) => item?.value).join(',') || '',
      campaign_id: campaignSearch?.value || '',
      category_type: categoryTypeSearch?.value || '',
      status: statusSearch?.value || '',
      publishing_date: dateTypeSearch?.value ?? '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      id: undefined,
      partner_news_id: undefined,
      sort_by: undefined,
      category_id: undefined,
      partner_ids: undefined,
      keyword_ids: undefined,
      campaign_id: undefined,
      start_date: undefined,
      end_date: undefined,
      status: undefined,
      category_type: undefined,
      publishing_date: undefined,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
    setNoSearch("");
    setNewsIdClientSearch("");
    setDateSearch(["", ""]);
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateTypeSearch({ label: t('All Publishing Date'), value: '' });
    setKeywordsSearch([]);
    setCampaignSearch(null);
    setStatusSearch(null);
    setCategoryTypeSearch(null);
  };

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  function onCloseEditClick() {
    setIsOpenEdit((_prev) => !_prev);
  }

  function onCloseCopyScriptTrackingClick() {
    setIsOpenCopyScriptTracking((_prev) => !_prev);
  }

  const handlePreviewNews = async (item: IPreviewNews) => {
    setPreviewTemplate((_prev) => (item));
    setIsOpenPreview((_prev) => true);
  };

  const handleEditNews = async (item: IPreviewNews | null, isGo: boolean = false) => {
    if (isGo) {
      setIsOpenPreview((_prev) => false);
      setIsOpenEdit((_prev) => true);
    } else {
      setPreviewTemplate((_prev) => item);
      setIsOpenEdit((_prev) => true);
    }
  };


  const handleDownloadNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setIsLoadingDownloadFile((_prev) => true);
        setIdLoadingDownloadFile((_prev) => item?.id);
        const response: any = await downloadFileNews(item?.id);
        if (response?.data) {
          setIsLoadingDownloadFile((_prev) => false);
          setIdLoadingDownloadFile((_prev) => null);
          const link = document.createElement('a');
          link.href = response?.data;
          link.download = 'news';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setIsLoadingDownloadFile((_prev) => false);
          setIdLoadingDownloadFile((_prev) => null);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setIsLoadingDownloadFile((_prev) => false);
        setIdLoadingDownloadFile((_prev) => null);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const handleDownloadZipNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setIsLoadingDownloadFile((_prev) => true);
        setIdLoadingDownloadZip((_prev) => item?.id);
        const response: any = await downloadMediaNews(item?.id);
        if (response?.data) {
          setIsLoadingDownloadFile((_prev) => false);
          setIdLoadingDownloadZip((_prev) => null);
          const link = document.createElement('a');
          link.href = response?.data;
          link.download = 'news';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setIsLoadingDownloadFile((_prev) => false);
          setIdLoadingDownloadZip((_prev) => null);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setIsLoadingDownloadFile((_prev) => false);
        setIdLoadingDownloadZip((_prev) => null);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const handleGetScriptTrackingNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setKeyScriptTrackingLoading((_prev) => Number(item?.id));
        const response: any = await getScriptTrackingNews(item?.id);
        if (response?.data) {
          setKeyScriptTrackingLoading((_prev) => null);
          setScriptTracking((_prev) => response?.data || '');
          setIsOpenCopyScriptTracking((_prev) => true);
        } else {
          setKeyScriptTrackingLoading((_prev) => null);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setKeyScriptTrackingLoading((_prev) => null);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const copyTextToClipboard = async (text: string, type: string = 'HTML') => {
    let plainText: any;
    if (type === 'CONTENT') {
      if (typeof text === 'string') {
        plainText = text?.replace(/<img[^>]*src="([^"]+)"[^>]*>/g, '$1')?.replace(/<[^>]+>/g, '');
      } else {
        plainText = text;
      }
    } else {
      plainText = text;
    }

    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(plainText);
    } else {
      document.execCommand('copy', true, plainText);
    }
    toast(`${t('Copied Successfully')}`, CONFIG_OPTION_TOAST_NORMAL);
  };


  const handleDownloadImageNews = async (item: IPreviewNews | null) => {
    if (item?.image_url) {
      try {
        setKeyLoading((_prev) => Number(item?.id));
        const imageUrl = item?.image_url || '';
        fetch(imageUrl)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setKeyLoading((_prev) => null);
          })
          .catch(error => {
            setKeyLoading((_prev) => null);
            toast(`Error downloading image`, CONFIG_OPTION_TOAST_ERROR);
          });
      } catch (error: any) {
        setKeyLoading((_prev) => null);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const handleUpdateNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setIsLoadingUpdate((_prev) => true);
        const data = {
          title: refTitleNews?.current?.value ?? '',
          content: refContentNews?.current?.getContent() ?? '',
          is_confirm: 0,
        };
        const response: any = await putNews(item?.id, data);
        if (response?.data) {
          dispatch(onGetNews(query));
          setIsLoadingUpdate((_prev) => false);
          toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        } else {
          setIsLoadingUpdate((_prev) => false);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setIsLoadingUpdate((_prev) => false);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }


  // Begin::Re-Create
  const handleReCreateNews = (ids: string[] | number[]) => {
    setIdsReCreateNews((_prev) => (ids));
    setIsConfirmReCreate((_prev) => true);
  };

  const handleActionReCreate = async () => {
    if (idsReCreateNews === null) { return; };
    const ids = idsReCreateNews?.length ? idsReCreateNews : Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])?.map((item: Option) => item?.value);
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await putMultiReCreateNews({ ids });
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirmReCreate((_prev) => false);
        setIdsReCreateNews((_prev) => null);
        setOptionsSelected((_prev: any) => ({}));
        if (response?.data?.errors?.length > 0) {
          response?.data?.errors?.map((err: any) => {
            toast(`ID: ${err?.id} -  ${err?.error_message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return err;
          });
        }
        if (response?.data?.success?.length > 0) {
          toast(`ID:  ${response?.data?.success?.join(' , ')} ${t('has been refreshed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmReCreateClick = () => {
    setPreviewTemplate((_prev) => null);
    setIsConfirmReCreate((_prev) => false);
  }
  // End::Re-Create


  // Begin::Reset-status
  const handleResetStatusNews = (ids: string[] | number[]) => {
    setIdsResetStatusNews((_prev) => (ids));
    setIsConfirmResetStatus((_prev) => true);
  };

  const handleActionResetStatus = async () => {
    if (idsResetStatusNews === null) { return; };
    const ids = idsResetStatusNews?.length ? idsResetStatusNews : Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])?.map((item: Option) => item?.value);
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await putResetStatusNews(ids[0], { status: 1 }); // 1 is Analyzed
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirmResetStatus((_prev) => false);
        setIdsResetStatusNews((_prev) => null);
        setOptionsSelected((_prev: any) => ({}));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmResetStatusClick = () => {
    setPreviewTemplate((_prev) => null);
    setIsConfirmResetStatus((_prev) => false);
  }
  // End::Reset-status


  // Begin::Reset-news
  const handleResetNews = (id: string | number) => {
    setIdResetNews((_prev) => (id));
    setIsConfirmResetNews((_prev) => true);
  };

  const handleActionResetNews = async () => {
    if (idResetNews === null) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await putResetNews(idResetNews, {});
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirmResetNews((_prev) => false);
        setIdResetNews((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmResetClick = () => {
    setPreviewTemplate((_prev) => null);
    setIsConfirmResetNews((_prev) => false);
  }
  // End::Reset-news


  // Begin::Confirm News
  const handleConfirmNews = (isConfirm: boolean = false) => {
    setIsActionConfirmAndUpdateNews((_prev) => isConfirm);
    setIsConfirmNews((_prev) => true);
  };

  const handleActionConfirmNews = async () => {
    if (!(previewTemplate?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const data = {
        title: refTitleNews?.current?.value ?? '',
        content: refContentNews?.current?.getContent() ?? '',
        is_confirm: Number(isActionConfirmAndUpdateNews || false)
      };
      const response: any = isActionConfirmAndUpdateNews ? await putNews(previewTemplate?.id, data) : await putConfirmNews(previewTemplate?.id);
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsOpenPreview((_prev) => false);
        setIsOpenEdit((_prev) => false);
        setIsConfirmNews((_prev) => false);
        setPreviewTemplate((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmNewsClick = () => {
    setIsConfirmNews((_prev) => false);
  }
  // End::Confirm News

  // Begin::Delete News
  const handleConfirmDelete = (item: IPreviewNews) => {
    setPreviewTemplate((_prev) => (item));
    setIsOpenConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(previewTemplate?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteNews(previewTemplate?.id);
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirm((_prev) => false);
        setPreviewTemplate((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setPreviewTemplate((_prev) => null);
    setIsOpenConfirm((_prev) => false);
  }
  // End::Delete News

  // Begin::Send Email News
  const handleConfirmSendEmail = (item: IPreviewNews | null) => {
    setPreviewTemplate((_prev) => (item));
    setIsOpenConfirmSendEmail((_prev) => true);
  };

  const handleActionSendEmail = async () => {
    if (!(previewTemplate?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await sendEmailNews(previewTemplate?.id);
      if (response?.code === 200) {
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmSendEmail((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmSendEmailClick = () => {
    setIsOpenConfirmSendEmail((_prev) => false);
  }
  // End::Send Email News

  // Begin::Copy News
  const [idLoadingCopyNews, setIdLoadingCopyNews] = useState<string | number | null>(null);
  const handleCopyNewsToClipboard = async (item: IPreviewNews | null) => {
    if (!item) { return; };
    try {
      setIdLoadingCopyNews((_prev) => item?.id);
      const response: any = await copyNews(item?.id);
      if (response?.code === 200) {
        setIdLoadingCopyNews((_prev) => null);
        copyTextToClipboard(response?.data?.content || '');
      } else {
        setIdLoadingCopyNews((_prev) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdLoadingCopyNews((_prev) => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End::Copy News

  // Begin::Media Published News
  const [isLoadingMediaPublishedNews, setIsLoadingMediaPublishedNews] = useState<boolean>(false);
  const [dataMediaPublishedNews, setDataMediaPublishedNews] = useState<IMediaPublished[] | null>(null);
  const handleShowMediaPublishedNews = async () => {
    try {
      setIsLoadingMediaPublishedNews((_prev) => true);
      const params = {
        start_date: startDatePublished ? moment(new Date(startDatePublished)).format("Y-MM-DD") : moment(new Date()).format("Y-MM-DD"),
        end_date: endDatePublished ? moment(new Date(endDatePublished)).format("Y-MM-DD") : moment(new Date()).format("Y-MM-DD"),
        type: 'keyword' // get by type keyword 
      };
      const response: any = await mediaPublishedNews(params);
      if (response?.code === 200) {
        setIsLoadingMediaPublishedNews((_prev) => false);
        setDataMediaPublishedNews(response?.data || []);
      } else {
        setIsLoadingMediaPublishedNews((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingMediaPublishedNews((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseMediaPublishedNewsClick = () => {
    setDataMediaPublishedNews((_prev) => null);
  }
  // End::Media Published News

  // Begin::Set Published at
  const [publishedAt, setPublishedAt] = useState<string | undefined>(undefined);
  const [isLoadingSetPublishedAt, setIsLoadingSetPublishedAt] = useState<boolean>(false);

  const handleOnCloseSetPublishedAt = () => {
    setPublishedAt((_prev) => undefined);
  }

  const handleActionSetPublishedAt = async () => {
    if (!publishedAt) { return; };
    const ids = Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])?.map((item: Option) => item?.value);
    try {
      setIsLoadingSetPublishedAt((_prev) => true);
      const data = {
        ids: ids,
        publish_at: publishedAt,
      };
      const response: any = await putMultiSetPublishedAtNews(data);
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsLoadingSetPublishedAt((_prev) => false);
        setPublishedAt((_prev) => undefined);
        setOptionsSelected((_prev: any) => ({}));
        if (response?.data?.errors?.length > 0) {
          response?.data?.errors?.map((err: any) => {
            toast(`ID: ${err?.id} -  ${err?.error_message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return err;
          });
        }
        if (response?.data?.success?.length > 0) {
          toast(`ID:  ${response?.data?.success?.join(' , ')} ${t('has been updated.')}`, CONFIG_OPTION_TOAST_NORMAL);
        }
      } else {
        setIsLoadingSetPublishedAt((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingSetPublishedAt((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End:: Set Publish at

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (news?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.keyword }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === news?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])
  }, [optionsSelected]);

  // End:: handle select table 

  // Begin::Update Campaign
  const onCloseClick = () => {
    setCampaignEdit((_prev) => null);
  }
  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetNews(query));
  }
  //End::Update Campaign

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 50,
        thComponent: () => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
              <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
            </div>
          </>
        ),
      },
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: true,
        thWidth: 70,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '40px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('News ID Client'),
        accessor: "partner_news_id",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        thWidth: 150,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '120px' }} className="text-center">{cell?.value || '-'}</div>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keywords",
        filterable: false,
        sortable: false,
        thWidth: 150,
        thClass: "text-center",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const keywords = (value?.keywords || [])?.map((item: any) => item?.keyword)?.filter((item: any) => !!item);
          return (<div className="text-center" style={{ minWidth: '100px' }}>
            {keywords?.map((k: any) => (<span className='tag-style' key={k}>{k}</span>))}
          </div>);
        }
      },
      {
        Header: t('Trend Index'),
        accessor: "trend_index",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        thWidth: 120,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '100px' }} className="text-center">
              {formatNumberWithCommas(item?.keywords?.[0]?.trend_index || 0)}
            </div>
          );
        },
      },
      {
        Header: t('Search Count'),
        accessor: "search_count",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        thWidth: 120,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '100px' }} className="text-center">
              {item?.campaign?.type === 'keyword_popular' ? formatNumberWithCommas(item?.keywords?.[0]?.search_count || 0) : '-'}
            </div>
          );
        },
      },
      {
        Header: t('News Title'),
        thWidth: 230,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '20px' }}>
              <CopyWrapper contentCopy={item?.title}>
                <Link className='text-normal' to="#"
                  onClick={(e) => { e.preventDefault(); handlePreviewNews(item) }}
                >
                  {item?.title}
                </Link>
              </CopyWrapper>
            </div>
          );
        },
      },
      {
        Header: t('Medias'),
        accessor: "partners",
        filterable: false,
        sortable: false,
        thWidth: 120,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const partners = (value?.partners || [])?.map((item: any) => item?.name)?.filter((item: any) => !!item);
          return (<div style={{ minWidth: '100px' }}>
            <CollapseTag tags={partners} isRank={false} totalShow={3} isShowModal={true} titleModal={t('Medias')} />
          </div>);
        }
      },
      {
        Header: t('Col_Gender'),
        accessor: "gender",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        thWidth: 200,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const keywordOb = item?.keywords?.[0] || {};
          const arrAge = [
            { key: 'male_ratio', label: 'Col_Gender_Male' },
            { key: 'female_ratio', label: 'Col_Gender_FeMale' },
          ];
          return (
            <div style={{ minWidth: '160px' }} className="text-center">
              {arrAge?.map((age: any) => (
                <span className={`${getClassTextMinMaxGenderFromArr(keywordOb, keywordOb[`${age?.key}`])}`}>{t(`${age?.label}`)} {keywordOb[age?.key]}%,{' '}</span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t('Col_Age'),
        accessor: "age",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        thWidth: 350,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const keywordOb = item?.keywords?.[0] || {};
          const arrAge = [
            { key: 'age_10_ratio', label: 'Col_Age_10' },
            { key: 'age_20_ratio', label: 'Col_Age_20' },
            { key: 'age_30_ratio', label: 'Col_Age_30' },
            { key: 'age_40_ratio', label: 'Col_Age_40' },
            { key: 'age_50_ratio', label: 'Col_Age_50' }
          ];
          return (
            <div style={{ minWidth: '340px' }} className="text-center">
              {arrAge?.map((age: any) => (
                <span className={`${getClassTextMinMaxAgeFromArr(keywordOb, keywordOb[`${age?.key}`])}`}>{t(`${age?.label}`)} {keywordOb[age?.key]}%,{' '}</span>
              ))}
            </div>
          );
        },
      },

      {
        Header: t('Category Type'),
        accessor: "category_type",
        filterable: true,
        sortable: false,
        thClass: 'text-center',
        thWidth: 140,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '120px' }} className="text-center">{item?.campaign?.type ? t(`Category_Type_[${item?.campaign?.type}]_For_Campaign`) : ''}</div>
          </>)
        },
      },
      {
        Header: t("Status"),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        thWidth: 120,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          return (
            <div style={{ minWidth: "60px" }} className="text-center">
              <span className={`badge bg-${COLORS_NEWS_STATUS[status]}`}>{`${t(
                `Status_News_${status}`
              )}`}</span>
            </div>
          );
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "edited_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Confirmed at'),
        accessor: "confirmed_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Sent at'),
        accessor: "sent_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Published at'),
        accessor: "publish_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Reserved at'),
        accessor: "reserved_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: "100px" }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 130,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className={`list-inline flex-wrap hstack gap-2 mb-0 justify-content-end`} style={{ width: '110px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.NEWS_RESET_STATUS, userPermissions) && (
                <TooltipCustom
                  title={t('Button Reset Status News')}
                  id={`reset-status-ns-${item?.id}`}
                >
                  <li className="list-inline-item me-0" id={`refresh-ns-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleResetStatusNews([item?.id]) }}
                    >
                      <i className="ri-toggle-line align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}

              {isHavePermissionRole(ROLES_FOR_APP.NEWS_REST_NEWS, userPermissions) && (
                <TooltipCustom
                  title={t('Button Reset News')}
                  id={`reset-status-ns-${item?.id}`}
                >
                  <li className="list-inline-item me-0" id={`refresh-ns-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleResetNews(item?.id) }}
                    >
                      <i className="ri-refresh-line align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole(ROLES_FOR_APP.NEWS_SCRIPT_TRACKING, userPermissions) && (
                <TooltipCustom
                  title={t('Button Get Script Tracking News')}
                  id={`tracking-ns-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`tracking-ns-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleGetScriptTrackingNews(item) }}
                    >
                      {(String(keyScriptTrackingLoading) === String(cell?.row?.id)) ? <Spinner size="sm"></Spinner> : <i className="ri-code-fill align-bottom"></i>}
                    </Link>
                  </li>
                </TooltipCustom>
              )}

              {isHavePermissionRole(ROLES_FOR_APP.NEWS_SEND_EMAIL, userPermissions) && (
                <TooltipCustom
                  title={t('Button Send Email')}
                  id={`download-ns-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`download-ns-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmSendEmail(item) }}
                    >
                      <i className="ri-mail-send-line align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole(ROLES_FOR_APP.NEWS_PREVIEW, userPermissions) && (
                <TooltipCustom
                  title={t('Button Copy News to Clipboard')}
                  id={`copy-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`copy-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleCopyNewsToClipboard(item) }}
                    >
                      {idLoadingCopyNews === item?.id ? <Spinner size="sm" style={{ width: '13px', height: '13px', verticalAlign: 'middle' }}></Spinner> : <i className="ri-file-copy-2-line align-bottom"></i>}
                    </Link>
                  </li>
                </TooltipCustom>
              )}

              {isHavePermissionRole(ROLES_FOR_APP.NEWS_DOWNLOAD_WORD, userPermissions) && (
                <TooltipCustom
                  title={t('Button Downloading News As Word Format')}
                  id={`download-ns-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`download-ns-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); if (!idLoadingDownloadZip) { handleDownloadZipNews(item) } }}
                    >
                      {idLoadingDownloadZip === item?.id ? <Spinner size="sm" style={{ width: '13px', height: '13px', verticalAlign: 'middle' }}></Spinner> : <i className="ri-download-2-fill align-bottom"></i>}
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole([ROLES_FOR_APP.NEWS_PREVIEW, ROLES_FOR_APP.NEWS_SET_CONFIRM], userPermissions) && (
                <TooltipCustom
                  title={t('Button Preview News')}
                  id={`preview-ns-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`preview-ns-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handlePreviewNews(item) }}
                    >
                      <i className="ri-eye-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole([ROLES_FOR_APP.NEWS_UPDATE, ROLES_FOR_APP.NEWS_SET_CONFIRM], userPermissions) && (
                <TooltipCustom
                  title={t('Button Update News')}
                  id={`edit-ns-${item?.id}`}
                >
                  <li className="list-inline-item me-0"
                    id={`edit-ns-${item?.id}`}
                  >
                    <Link className="btn btn-sm btn-soft-secondary  edit-item-btn" to="#"

                      onClick={(e) => { e.preventDefault(); handleEditNews(item) }}
                    >
                      <i className="ri-pencil-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole(ROLES_FOR_APP.NEWS_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete News')}
                  id={`delete-ns-${item?.id}`}
                >
                  <li className="list-inline-item me-0" id={`delete-ns-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, keyLoading, keyScriptTrackingLoading, userPermissions, idLoadingDownloadFile, idLoadingDownloadZip, idLoadingCopyNews, JSON.stringify(optionsSelected), JSON.stringify(news?.list)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }


  const handleChangePickerPublished = (values: any[] = []) => {
    setDatePublishedSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);



  const handleCallAllOption = async () => {
    try {
      const [
        resMedia,
        resTemplate,
        resType,
        resCategoriesType,
        resCategoriesStock,
        resCategoriesTrendIndex,
      ]: any = await Promise.all([
        getAllMedias(),
        getAllTemplates(),
        getAllTypes(),
        getAllCategoriesType(),
        getAllCategoriesStockNormal(),
        getAllCategoriesTrendIndexNormal(),
      ]);

      setListTemplate((_prev) => resTemplate?.data || []);
      setListType((_prev) => resType?.data || []);
      setListCategoriesType(
        (_prev) =>
          resCategoriesType?.data?.map((item: any) => ({
            value: item?.type,
            label: item?.name,
          })) || []
      );
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
      const list = ([{ name: t('All Media'), id: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.id || ''}`
      }));
      setListCategoriesStock((_prev) => resCategoriesStock?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);


  useEffect(() => {
    if (statusSearch?.value) {
      setStatusSearch((_prev: any) =>
        STATUS_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === statusSearch?.value)[0]
      );
    }
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    if (dateTypeSearch?.value) {
      setDateTypeSearch((_prev: any) => DATE_OPTIONS_LANG?.filter((item) => (dateTypeSearch?.value === item?.value))[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('News')} - ${t('Campaign')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News')} centerTitle={t('Keyword Analyzing News')} pageTitle={t('Campaign')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.NEWS_LIST, userPermissions) && (
              <>
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-top mb-2 mt-2">
                        <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    <CountUp
                                      start={0}
                                      end={news?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <Row className="g-4 align-items-center  mb-sm-0 mb-md-2 mt-2">
                            <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                              <LabelWrapper label={t('News No')} isShow={!!noSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t('News No')}...`}
                                  value={noSearch}
                                  onChange={(e) => setNoSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={4} lg={3} className='mt-3 mt-md-2'>
                              <LabelWrapper label={t('Campaign')} isShow={!!campaignSearch?.value}>
                                <SearchFilterCampaignByKeyword
                                  limitShow={20}
                                  initialValue={campaignSearch}
                                  isClearable={true}
                                  onChangeSelect={(event: any) => {
                                    setCampaignSearch((_prev: any) => event);
                                  }}
                                  placeholder={`${t('Search or Select Campaign')}`}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={4} lg={3} className='mt-3 mt-md-2'>
                              <LabelWrapper label={t('Category Type')} isShow={!!categoryTypeSearch?.value}>
                                <DropdownOption
                                  name="category-type"
                                  dataList={listCategoriesTypeLang || []}
                                  placeholder={`${t("Category Type")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={categoryTypeSearch || null}
                                  onChangeSelect={(e: any) => setCategoryTypeSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Category Type'), value: '' }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={4} lg={3} className='mt-3 mt-md-2'>
                              <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                <DropdownStatusNews
                                  name="status"
                                  dataList={STATUS_NEWS_OPTIONS_LANG || []}
                                  placeholder={`${t("Status")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={statusSearch || null}
                                  onChangeSelect={(e: any) => setStatusSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Status'), value: '' }}
                                />
                              </LabelWrapper>
                            </Col>
                          </Row>
                          <Row className="g-4 align-items-center mt-0">
                            <Col sm={12} md={3} lg={3} className='mt-3 mt-md-2'>
                              <LabelWrapper label={t('News ID Client')} isShow={!!newsIdClientSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t('News ID Client')}...`}
                                  value={newsIdClientSearch}
                                  onChange={(e) => setNewsIdClientSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={4} lg={3} className='mt-3 mt-md-2'>
                              <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                <SearchFilterMedia
                                  name="medias"
                                  dataList={listMedia}
                                  isMulti={false}
                                  isClearable={false}
                                  initialValue={mediaSearch || null}
                                  onChangeSelect={(event: any) => {
                                    setMediaSearch((_prev) => event);
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={4} lg={3} className='mt-3 mt-md-2'>
                              <DropdownOption
                                name="date"
                                dataList={DATE_OPTIONS_LANG || []}
                                placeholder={`${t("Date")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={dateTypeSearch || null}
                                onChangeSelect={(e: any) => setDateTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Publishing Date'), value: '' }}
                                labelTop={t('Publishing Date')}
                              />
                            </Col>
                            <Col sm={12} md={4} lg={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                              <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                <DatePickerCustom
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  onChangePicker={handleChangePicker}
                                />
                              </LabelWrapper>
                            </Col>
                          </Row>
                          <Row className="g-4 align-items-center mb-2  mt-2 mt-sm-0 mt-md-2">
                            <Col sm={12} md={12} lg={12} className='mt-3  mt-md-2 order-1 order-md-1'>
                              <LabelWrapper label={t('News Title')} isShow={!!keywordSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t('News Title')}...`}
                                  value={keywordSearch}
                                  onChange={(e) => setKeywordSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0 mt-2">
                          <Col sm={12} md={6} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-end  mt-3 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isNewsLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                          <Col sm={12} md={6} lg={6} className="hstack gap-1 justify-content-end mt-3 mt-md-2">
                            <div>
                              {isHavePermissionRole(ROLES_FOR_APP.NEWS_LIST, userPermissions) && (
                                <button
                                  type="button"
                                  className="btn btn-primary me-3 mb-2 mb-sm-0"
                                  onClick={(e) => { e.preventDefault(); handleShowMediaPublishedNews() }}
                                  disabled={isLoadingMediaPublishedNews}
                                >
                                  {isLoadingMediaPublishedNews ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-share-circle-line align-bottom me-1"></i>}
                                  {t('Button Media Published News')}
                                </button>
                              )}
                              {isHavePermissionRole(ROLES_FOR_APP.NEWS_REFRESH, userPermissions) && (
                                <button
                                  type="button"
                                  className="btn btn-success mb-2 me-3 mb-sm-0"
                                  onClick={(e) => { e.preventDefault(); handleReCreateNews([]) }}
                                  disabled={isConfirmLoading || countRowSelected <= 0}
                                >
                                  {countRowSelected > 0 && (
                                    <span className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                      style={{ transform: 'translate(0%, -70%)' }}
                                    >
                                      {formatNumberWithCommas(countRowSelected)}
                                      <span className="visually-hidden">total keywords selected</span>
                                    </span>)}
                                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                  {t('Button Refresh News')}
                                </button>
                              )}
                              {isHavePermissionRole(ROLES_FOR_APP.NEWS_UPDATE, userPermissions) && (<button
                                type="button"
                                className="btn btn-primary "
                                onClick={(e) => { e.preventDefault(); setPublishedAt((_prev) => moment(new Date()).format("yyyy-MM-DD HH:mm")) }}
                                disabled={isLoadingSetPublishedAt || countRowSelected <= 0}
                              >
                                {countRowSelected > 0 && (
                                  <span className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                    style={{ transform: 'translate(0%, -70%)' }}
                                  >
                                    {formatNumberWithCommas(countRowSelected)}
                                    <span className="visually-hidden">total keywords selected</span>
                                  </span>)}
                                <i className="ri-calendar-todo-line align-bottom me-1"></i>{" "}
                                {t('Button Set Published At')}
                              </button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle sticky-table-news-keyword"
                          theadClass="table-light"
                          columns={columns}
                          data={news?.list?.length ? news?.list : []}
                          customPageSize={query.limit}
                          customPageCount={Math.ceil(Number(news?.pagination?.total) / Number(news?.pagination?.limit))}
                          customPageIndex={query.page - 1}
                          totalRecords={news?.pagination?.total}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isNewsLoading}
                        />
                      </div>
                      <ToastContainer closeButton={true} limit={100} />
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
      <Modal isOpen={isOpenPreview} centered={true} size="xl" scrollable={true} toggle={onClosePreviewClick} keyboard={true} >
        <ModalHeader toggle={onClosePreviewClick}>
          {t('Preview')}
        </ModalHeader>
        <ModalBody >
          <div className="row g-4">
            <Col md={12}>
              <div >
                <div className="row g-3">
                  <div style={{ height: 'calc(100dvh - 215px)', margin: 0, overflowY: 'auto' }} className="color-scrollbar-os">
                    <Col xxl={12} className="ribbon-box right mt-0">
                      {!!previewTemplate?.memo?.errors?.length && (
                        <div className="ribbon ribbon-danger round-shape top-0" style={{
                          zIndex: 9,
                          background: 'rgb(255 255 255 / 67%)',
                          color: '#f06548',
                          borderRadius: 0,
                          maxHeight: '30dvh',
                          overflowY: 'auto',
                        }}>
                          <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(previewTemplate?.memo?.errors || [])?.map((mess: any, index: number) => (
                            <span key={index}>{`${mess}`}<br /> </span>
                          ))}
                          </span>
                        </div>
                      )}
                      <div className="bg-primary-subtle position-relative"><div className="p-3 pt-4 card-body mb-2"><div className="text-center">
                        <h3 className="fw-semibold">{previewTemplate?.title || ''}</h3>
                        <p className="mb-0 text-muted">{t('Created at')}: {previewTemplate?.created_at || ''} </p>
                      </div>
                      </div>
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <div className="clear-css-p-in-box" dangerouslySetInnerHTML={{ __html: previewTemplate?.content || '' }}></div>
                    </Col>
                  </div>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-between flex-column flex-xl-row">
                      <div>
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_DOWNLOAD_FILE, userPermissions) && (
                          <button className="btn btn-soft-success fs-14 me-2 mb-2 mb-lg-0" type="button" color="light" disabled={isLoadingDownloadFile} onClick={() => handleDownloadZipNews(previewTemplate)}>
                            {isLoadingDownloadFile ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-download-2-fill align-bottom me-1"></i>}
                            {t('Button Downloading News As Word Format')}
                          </button>)}
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_PREVIEW, userPermissions) && (
                          <button className="btn btn-soft-info fs-14 mb-2 mb-lg-0" type="button" color="light" disabled={!!idLoadingCopyNews} onClick={(e) => { e.preventDefault(); handleCopyNewsToClipboard(previewTemplate) }}>
                            {!!idLoadingCopyNews ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-file-copy-2-line align-bottom me-1"></i>}
                            {t('Button Copy to Clipboard')}
                          </button>
                        )}
                      </div>
                      <div>
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_SEND_EMAIL, userPermissions) && (
                          <button className="btn btn-danger fs-14 me-2 mb-2 mb-lg-0" type="button" color="light" onClick={(e) => { e.preventDefault(); handleConfirmSendEmail(previewTemplate) }}>
                            <i className="ri-mail-send-line align-bottom me-1"></i>
                            {t('Button Send Email')}
                          </button>
                        )}
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_SET_CONFIRM, userPermissions) && [0, 1, 5].includes(Number(previewTemplate?.status)) && (
                          <button className="btn btn-success fs-14 me-2 mb-2 mb-lg-0" type="button" color="light" onClick={(e) => { e.preventDefault(); handleConfirmNews(false) }}>
                            <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                            {t('Button Set Confirmed News')}
                          </button>
                        )}
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_UPDATE, userPermissions) && (
                          <button className="btn btn-primary fs-14 me-2 mb-2 mb-lg-0" type="button" color="light" onClick={(e) => { e.preventDefault(); handleEditNews(previewTemplate, true) }}>
                            <i className="ri-pencil-line align-bottom me-1"></i>
                            {t('Button Go Update News')}
                          </button>
                        )}
                        <button className="btn btn-soft-secondary fs-14 mb-2 mb-lg-0" type="button" color="light" onClick={onClosePreviewClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={isOpenEdit} centered={true} size="xl" scrollable={false} toggle={onCloseEditClick} keyboard={true}>
        <ModalHeader toggle={onCloseEditClick}>
          {t('Button Update News')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col lg={12}>
                    <div className="w-100" >
                      <textarea className="fs-3 w-100 fw-semibold text-center bg-none p-2"
                        style={{
                          // height: '60px',
                          borderBottom: 'none',
                          boxShadow: "0 2px 2px -2px rgba(34,47,62,.1), 0 8px 8px -4px rgba(34,47,62,.07)",
                          padding: '4px 0',
                          border: '1px solid #eee',
                          borderRadius: '10px',
                        }}
                        defaultValue={previewTemplate?.title}
                        rows={1}
                        ref={refTitleNews} />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className='d-flex justify-content-start flex-wrap gap-0 pb-2'
                      style={{
                        border: '1px solid #eee',
                        borderRadius: '10px',
                        boxShadow: "0 2px 2px -2px rgba(34,47,62,.1), 0 8px 8px -4px rgba(34,47,62,.07)",
                      }}>
                      {/* <div className="w-100 p-2">글쓰기도구</div> */}
                      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer w-50 p-2 btn-hover-blue' onClick={() => { refContentNews.current?.ui?.registry.getAll()?.buttons?.image?.onAction() }}>
                        <button className="btn btn-icon "  >
                          <span dangerouslySetInnerHTML={{ __html: refContentNews.current?.ui?.registry.getAll()?.icons?.image || `<svg width="24" height="24"><path d="m5 15.7 3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7ZM5 18V19h3l2.8-2.9-2-2L5 17.9Zm14-3-2.5-2.4-6.4 6.5H19v-4ZM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1Zm6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill-rule="nonzero"></path></svg>` }}></span>
                        </button>
                        <div>{t('Edit_image')}</div>
                      </div>
                      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer w-50 p-2 btn-hover-blue' onClick={() => { refContentNews.current?.ui?.registry.getAll()?.buttons?.media?.onAction() }} >
                        <button className="btn btn-icon " >
                          <span dangerouslySetInnerHTML={{ __html: refContentNews.current?.ui?.registry.getAll()?.icons?.embed || `<svg width="24" height="24"><path d="M4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1Zm1 2v14h14V5H5Zm4.8 2.6 5.6 4a.5.5 0 0 1 0 .8l-5.6 4A.5.5 0 0 1 9 16V8a.5.5 0 0 1 .8-.4Z" fill-rule="nonzero"></path></svg>` }}></span>
                        </button>
                        <div>{t('Edit_video')}</div>
                      </div>
                      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer w-50 p-2 btn-hover-blue' onClick={() => { refContentNews.current?.ui?.registry.getAll()?.buttons?.blockquote?.onAction() }}>
                        <button className="btn btn-icon "  >
                          <span dangerouslySetInnerHTML={{ __html: refContentNews.current?.ui?.registry.getAll()?.icons?.quote || `<svg width="24" height="24"><path d="M7.5 17h.9c.4 0 .7-.2.9-.6L11 13V8c0-.6-.4-1-1-1H6a1 1 0 0 0-1 1v4c0 .6.4 1 1 1h2l-1.3 2.7a1 1 0 0 0 .8 1.3Zm8 0h.9c.4 0 .7-.2.9-.6L19 13V8c0-.6-.4-1-1-1h-4a1 1 0 0 0-1 1v4c0 .6.4 1 1 1h2l-1.3 2.7a1 1 0 0 0 .8 1.3Z" fill-rule="nonzero"></path></svg>` }}></span>
                        </button>
                        <div>{t('Edit_blockquote')}</div>
                      </div>
                      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer w-50 p-2 btn-hover-blue' onClick={() => { refContentNews.current?.ui?.registry.getAll()?.buttons?.link?.onAction() }}>
                        <button className="btn btn-icon "  >
                          <span dangerouslySetInnerHTML={{ __html: refContentNews.current?.ui?.registry.getAll()?.icons?.link || `<svg width="24" height="24"><path d="M6.2 12.3a1 1 0 0 1 1.4 1.4l-2 2a2 2 0 1 0 2.6 2.8l4.8-4.8a1 1 0 0 0 0-1.4 1 1 0 1 1 1.4-1.3 2.9 2.9 0 0 1 0 4L9.6 20a3.9 3.9 0 0 1-5.5-5.5l2-2Zm11.6-.6a1 1 0 0 1-1.4-1.4l2-2a2 2 0 1 0-2.6-2.8L11 10.3a1 1 0 0 0 0 1.4A1 1 0 1 1 9.6 13a2.9 2.9 0 0 1 0-4L14.4 4a3.9 3.9 0 0 1 5.5 5.5l-2 2Z" fill-rule="nonzero"></path></svg>` }}></span>
                        </button>
                        <div>{t('Edit_link')}</div>
                      </div>
                      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer w-50 p-2 btn-hover-blue' onClick={() => { refContentNews.current?.ui?.registry.getAll()?.buttons?.print?.onAction() }}>
                        <button className="btn btn-icon "  >
                          <span dangerouslySetInnerHTML={{ __html: refContentNews.current?.ui?.registry.getAll()?.icons?.print || `<svg width="24" height="24"><path d="M18 8H6a3 3 0 0 0-3 3v6h2v3h14v-3h2v-6a3 3 0 0 0-3-3Zm-1 10H7v-4h10v4Zm.5-5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5Zm.5-8H6v2h12V5Z" fill-rule="nonzero"></path></svg>` }}></span>
                        </button>
                        <div>{t('Edit_print')}</div>
                      </div>
                      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer w-50 p-2 btn-hover-blue' onClick={() => { refContentNews.current?.ui?.registry.getAll()?.buttons?.charmap?.onAction() }}>
                        <button className="btn btn-icon "  >
                          <span dangerouslySetInnerHTML={{ __html: refContentNews.current?.ui?.registry.getAll()?.icons?.['insert-character'] || `<svg width="24" height="24"><path d="M15 18h4l1-2v4h-6v-3.3l1.4-1a6 6 0 0 0 1.8-2.9 6.3 6.3 0 0 0-.1-4.1 5.8 5.8 0 0 0-3-3.2c-.6-.3-1.3-.5-2.1-.5a5.1 5.1 0 0 0-3.9 1.8 6.3 6.3 0 0 0-1.3 6 6.2 6.2 0 0 0 1.8 3l1.4.9V20H4v-4l1 2h4v-.5l-2-1L5.4 15A6.5 6.5 0 0 1 4 11c0-1 .2-1.9.6-2.7A7 7 0 0 1 6.3 6C7.1 5.4 8 5 9 4.5c1-.3 2-.5 3.1-.5a8.8 8.8 0 0 1 5.7 2 7 7 0 0 1 1.7 2.3 6 6 0 0 1 .2 4.8c-.2.7-.6 1.3-1 1.9a7.6 7.6 0 0 1-3.6 2.5v.5Z" fill-rule="evenodd"></path></svg>` }}></span>
                        </button>
                        <div>{t('Edit_character')}</div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={9} style={{ minHeight: 'calc(100dvh - 270px)', }}>
                    <EditorCustom
                      initialValue={previewTemplate?.content || ''}
                      ref={refContentNews}
                    />
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-between flex-column flex-lg-row">
                      <div>
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_SEND_EMAIL, userPermissions) && (
                          <button className="btn btn-soft-danger fs-14 me-2" type="button" color="light" onClick={(e) => { e.preventDefault(); handleConfirmSendEmail(previewTemplate) }}>
                            <i className="ri-mail-send-line align-bottom me-1"></i>
                            {t('Button Send Email')}
                          </button>
                        )}
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_SET_CONFIRM, userPermissions) && [0, 1, 5].includes(Number(previewTemplate?.status)) && (
                          <button className="btn btn-soft-success fs-14 me-2" type="button" color="light" onClick={(e) => { e.preventDefault(); handleConfirmNews(true) }}>
                            <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                            {t('Button Set Confirmed News')}
                          </button>
                        )}
                      </div>
                      <div>
                        {isHavePermissionRole(ROLES_FOR_APP.NEWS_UPDATE, userPermissions) && (
                          <button className="btn btn-primary fs-14 me-2" type="button" color="light" disabled={isLoadingUpdate} onClick={() => handleUpdateNews(previewTemplate)}>
                            {isLoadingUpdate ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-login-circle-line align-bottom ms-2 me-1"></i>}
                            {t('Button Update News')}
                          </button>)}
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseEditClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={isOpenCopyScriptTracking} centered={true} size="xl" scrollable={true} toggle={onCloseCopyScriptTrackingClick} keyboard={true}>
        <ModalHeader toggle={onCloseCopyScriptTrackingClick}>
          {t('Script Tracking News')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col xxl={12} className="m-0">
                    <div className='my-3'>
                      <div className="p-4" style={{ color: 'var(--vz-success)', backgroundColor: 'var(--vz-dark)' }}>{scriptTracking || ''}</div>
                    </div>
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      {isHavePermissionRole(ROLES_FOR_APP.NEWS_SCRIPT_TRACKING, userPermissions) && (
                        <button className="btn btn-primary fs-14" type="button" color="light" onClick={() => copyTextToClipboard(scriptTracking || '')}>
                          <i className="ri-file-copy-line align-bottom me-1"></i>
                          {t('Button Copy Script Tracking')}
                        </button>)}
                      <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseCopyScriptTrackingClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={!!publishedAt} centered={true} size="md" keyboard={true}>
        <ModalHeader toggle={isLoadingSetPublishedAt ? () => { } : handleOnCloseSetPublishedAt}>
          {t("Button Set Published At")}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col sm={12}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label w-100">{t("Published at")} <span className="text-danger"> *</span></label>
                  <div className="date-picker-wrapper-custom">
                    <DatePicker
                      className="form-control search"
                      placeholderText={`${t('Published at')}...`}
                      isClearable={true}
                      showTimeSelect
                      dateFormat="yyyy-MM-DD HH:mm"
                      autoComplete="off"
                      timeCaption={t("Time")}
                      timeIntervals={1}
                      timeFormat="HH:mm"
                      minDate={new Date()}
                      locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                      value={publishedAt}
                      name={`published_at`}
                      onChange={(value: Date | null) => {
                        setPublishedAt((_prev) => (value ? moment(new Date(value)).format("yyyy-MM-DD HH:mm") : ""))
                      }
                      }
                    />
                  </div>
                </Col>
                <div className="mt-4 text-center">
                  <div className="hstack gap-2 justify-content-end">
                    {isHavePermissionRole(ROLES_FOR_APP.NEWS_UPDATE, userPermissions) && (<button
                      className={`btn btn-primary fs-14`}
                      type="button"
                      onClick={handleActionSetPublishedAt}
                      color="success"
                      disabled={isLoadingSetPublishedAt}
                    >
                      {isLoadingSetPublishedAt ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-login-circle-line align-bottom me-1"></i>}
                      {t("Button Set Published At")}
                    </button>)}
                    <button
                      className="btn btn-soft-secondary fs-14"
                      type="button"
                      color="light"
                      onClick={handleOnCloseSetPublishedAt}
                      disabled={isLoadingSetPublishedAt}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={dataMediaPublishedNews !== null} centered={true} size="lg" scrollable={false} toggle={onCloseMediaPublishedNewsClick} keyboard={true}>
        <ModalHeader toggle={onCloseMediaPublishedNewsClick}>
          {t('Button Media Published News')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col sm={12} md={6} lg={4} className="mt-2 date-picker-wrapper-custom">
                    <DatePickerCustom
                      startDate={startDatePublished || null}
                      endDate={endDatePublished || null}
                      onChangePicker={handleChangePickerPublished}
                    />

                  </Col>
                  <Col sm={12} md={6} lg={4} className="mt-2">
                    <button
                      type="button"
                      className="btn btn-primary me-3"
                      onClick={(e) => { e.preventDefault(); handleShowMediaPublishedNews() }}
                      disabled={isLoadingMediaPublishedNews}
                    >
                      {isLoadingMediaPublishedNews ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-filter-3-line align-bottom me-1"></i>}
                      {t('Button Search')}
                    </button>
                  </Col>
                  <Col lg={12}>
                    <div className="w-100 mb-2">
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">{t('Media')}</th>
                              <th scope="col">{t('Website')}</th>
                              <th scope="col" className="text-end">{t('Published News (NewsHub)')}</th>
                              <th scope="col" className="text-end">{t('Published News (Media)')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataMediaPublishedNews?.map((item: IMediaPublished) => (
                              <tr>
                                <td className="fw-medium">
                                  <div className="cursor-pointer">
                                    {item?.name || ''}
                                  </div>
                                </td>
                                <td>
                                  <Link className='text-normal' to={(String(item?.website || '').includes('https://') || String(item?.website || '').includes('http://')) ? item?.website || '' : `https://${item?.website || ''}`} target="_blank">
                                    {item?.website || ''}
                                  </Link>
                                </td>
                                <td className="text-end">{formatNumberWithCommas(item?.total_published_news || 0)}</td>
                                <td className="text-end">{formatNumberWithCommas(item?.total_published_news_by_log || 0)}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot className="table-light">
                            <tr>
                              <td className="fw-medium" colSpan={2}>{t('Sum')}</td>
                              <td className="fw-medium text-end text-danger"><strong>{formatNumberWithCommas((dataMediaPublishedNews || [])?.reduce((total: number, item: IMediaPublished) => (total + (item?.total_published_news || 0)), 0))}</strong> </td>
                              <td className="fw-medium text-end text-danger"><strong>{formatNumberWithCommas((dataMediaPublishedNews || [])?.reduce((total: number, item: IMediaPublished) => (total + (item?.total_published_news_by_log || 0)), 0))}</strong> </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={!!campaignEdit?.id}
        centered={true}
        size="xl"
        scrollable={true}
      >
        <ModalHeader toggle={onCloseClick}>
          {t("Button Update Campaign")} {(' - ' + campaignEdit?.name || '')}
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: "calc(100dvh - 200px)" }}>
            <CampaignFormEdit
              isModal={true}
              id={String(campaignEdit?.id || '')}
              listType={listType}
              listMedia={listMedia}
              listTemplate={listTemplate}
              listCategoriesType={listCategoriesType}
              listCategoriesNaver={[]}
              listCategoriesStock={listCategoriesStock}
              listCategoriesTrendIndex={listCategoriesTrendIndex}
              triggerRefresh={triggerRefresh}
            />
          </div>
        </ModalBody>
      </Modal>

      <ModalConfirm
        header={t('Button Delete News')}
        isOpen={isOpenConfirm}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmClick}
        onConfirm={handleActionDelete}
      />

      <ModalConfirm
        textButtonConfirm={t('Button Refresh News')}
        classButtonConfirm='btn-soft-primary'
        classIconButtonConfirm='ri-refresh-line'
        header={t('Button Refresh News')}
        title={t('Do you want to refresh content of news!')}
        content={t('After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action.')}
        isShowIcon={false}
        isOpen={isOpenConfirmReCreate}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmReCreateClick}
        onConfirm={handleActionReCreate}
      />

      <ModalConfirm
        textButtonConfirm={t('Button Reset Status News')}
        classButtonConfirm='btn-soft-primary'
        classIconButtonConfirm='ri-refresh-line'
        header={t('Button Reset Status News')}
        title={t('Do you want to reset status of news!')}
        content={t("After confirming the reset status action, the data will be change to 'analyzed' status and cannot be recovered. Do you want to proceed with the reset status action.")}
        isShowIcon={false}
        isOpen={isOpenConfirmResetStatus}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmResetStatusClick}
        onConfirm={handleActionResetStatus}
      />

      <ModalConfirm
        textButtonConfirm={t('Button Reset News')}
        classButtonConfirm='btn-soft-primary'
        classIconButtonConfirm='ri-refresh-line'
        header={t('Button Reset News')}
        title={t('Do you want to reset of news!')}
        content={t("After confirming the reset action, the data will be change cannot be recovered. Do you want to proceed with the reset action.")}
        isShowIcon={false}
        isOpen={isOpenConfirmResetNews}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmResetClick}
        onConfirm={handleActionResetNews}
      />

      <ModalConfirm
        textButtonConfirm={t('Button Set Confirmed News')}
        classButtonConfirm='btn-soft-success'
        classIconButtonConfirm='ri-checkbox-circle-line'
        header={t('Button Set Confirmed News')}
        title={t('Do you want to confirm content of news!')}
        content={t('After confirming the action, the status will be updated and cannot be recovered. Do you want to proceed with the confirming action.')}
        isShowIcon={false}
        isOpen={isOpenConfirmNews}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmNewsClick}
        onConfirm={handleActionConfirmNews}
      />

      <ModalConfirm
        textButtonConfirm={t('Button Send Email')}
        classButtonConfirm='btn-soft-danger'
        classIconButtonConfirm='ri-mail-send-line'
        header={t('Button Send Email')}
        title={t('Do you want to send email with content of news !')}
        content={t('After confirming the send action, the data will be send and cannot be recovered. Do you want to proceed with the send action.')}
        isShowIcon={false}
        isOpen={isOpenConfirmSendEmail}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmSendEmailClick}
        onConfirm={handleActionSendEmail}
      />
    </React.Fragment >
  );
};

export default NewsListByKeywordAnalyzing;