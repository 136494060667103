import { Col, ListGroupItem, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

interface IModalCollapseTag {
    id?: string,
    isOpen: boolean,
    onCloseClick: React.MouseEventHandler<any>,
    titleModal?: string,
    size?: string,
    scrollable: boolean,
    keyboard: boolean,
    tags?: string[] | { keyword: string, rank: string | number }[],
}

const ModalCollapseTag = (props: IModalCollapseTag) => {
    const { t } = useTranslation();

    const { id = "firstmodal", isOpen, titleModal = t("Keywords"), onCloseClick, size = "xl", scrollable, keyboard, tags } = props;

    return (
        <Modal
            isOpen={isOpen}
            id={id}
            centered
            size={size}
            scrollable={scrollable}
            toggle={onCloseClick}
            keyboard={keyboard}
        >
            <ModalHeader toggle={onCloseClick}>
                {titleModal}
            </ModalHeader>
            <ModalBody className="text-center">
                <div className="row g-4 w-100">
                    <Col md={12} className="mt-2">
                        <div className="d-flex flex-row flex-wrap">
                            {tags?.map((item: any, index: number) => (
                                <ListGroupItem
                                    key={index}
                                    tag="label"
                                    className="px-4 py-3 text-start item-keyword-suggestion"
                                >
                                    <span className="text-muted">{index + 1}.</span> {item}
                                </ListGroupItem>
                            ))}
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <button
                                className="btn btn-light fs-14 me-2"
                                type="button"
                                color="light"
                                onClick={onCloseClick}
                            >
                                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                {t("Button Close")}
                            </button>
                        </div>
                    </Col>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalCollapseTag
