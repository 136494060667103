import { IMedia } from 'api/types/_media';
import CollapseTag from 'components/Common/CollapseTag';
import DropdownOption from 'components/Common/DropdownOption';
import InputsRange from 'components/Common/InputsRange';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllCategoriesType, getAllMedias, getExportCategoryStatistics, getCategoryStatistics as onGetCategoryStatistics } from "../../../store/thunks";
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { CONFIG_OPTION_TOAST_ERROR } from 'common/toast';
import LabelWrapper from 'components/Common/LabelWrapper';

interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'start_news_count': StringParam,
  'end_news_count': StringParam,
  'start_pv_per_news': StringParam,
  'end_pv_per_news': StringParam,
}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'pv_per_news';

const CategoryStatisticList = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    domain: withDefault(StringParam, ''),
    name: withDefault(StringParam, ''),
    // title: withDefault(StringParam, ''),
    type: withDefault(StringParam, ''),
    is_export: withDefault(NumberParam, 0),
    // start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    // end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const listCategoriesTypeLang = listCategoriesType?.map((item: any) => ({ ...item, label: t(`Category_Type_[${item?.value}]_For_Campaign`) }));


  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  // News from to range
  const [newsFromRangeSearch, setNewsFromRangeSearch] = useState<string>(query?.start_news_count || "");
  const [newsToRangeSearch, setNewsToRangeSearch] = useState<string>(query?.end_news_count || "");

  // PV Per News from to range
  const [pvpnFromRangeSearch, setPVPNFromRangeSearch] = useState<string>(query?.start_pv_per_news || "");
  const [pvpnToRangeSearch, setPVPNToRangeSearch] = useState<string>(query?.end_pv_per_news || "");


  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const categoryStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      categoryStatistics: state.categoryStatistics,
      isCategoryStatisticSuccess: state.isCategoryStatisticSuccess,
      isCategoryStatisticLoading: state.isCategoryStatisticLoading,
      error: state.error,
    })
  );

  // // Inside your component
  const { categoryStatistics, error, isCategoryStatisticLoading } = useSelector(categoryStatisticsProperties);

  useEffect(() => {
    dispatch(onGetCategoryStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      // title: titleSearch || "",
      name: keywordSearch || "",
      type: categoryTypeSearch?.value || '',
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      // start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      // end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      start_news_count: newsFromRangeSearch || '',
      end_news_count: newsToRangeSearch || '',
      start_pv_per_news: pvpnFromRangeSearch || '',
      end_pv_per_news: pvpnToRangeSearch || '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      domain: '',
      title: '',
      name: '',
      type: undefined,
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      start_news_count: undefined,
      end_news_count: undefined,
      start_pv_per_news: undefined,
      end_pv_per_news: undefined,
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setCategoryTypeSearch(null);
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);

    setNewsFromRangeSearch("");
    setNewsToRangeSearch("");
    setPVPNFromRangeSearch("");
    setPVPNToRangeSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Category"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            categoryName.push(category?.name);
          }
          categoryName.push(value?.name);
          return (
            <div className="d-flex" style={{ minWidth: '150px' }}>
              <CollapseTag className={!!value?.is_deleted ? 'row-bg-secondary' : ''} tags={[categoryName.join(` > `)]} isRank={false} isInlineBlock={true} totalShow={2} isBackground={false} isShowModal={true} titleModal={t('Categories')} />
              {!!value?.is_deleted && <span className="text-danger ms-1">  ({t('Deleted')})</span>}
            </div>);
        },
      },
      {
        Header: t('Category Type'),
        accessor: "category_type",
        filterable: true,
        sortable: false,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '130px' }} className="text-center">{item?.categories?.[0]?.type ? t(`Category_Type_[${item?.categories?.[0]?.type}]_For_Campaign`) : ''}</div>
          </>)
        },
      },
      {
        Header: t('Total Campaign Count'),
        accessor: "campaigns",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description Total Category'),
        Cell: (cell: any) => (
          <>
            <div className="text-end" style={{ minWidth: '100px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Total News Count'),
        accessor: "news",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Description Total News'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('PV per News'),
        accessor: "pv_per_news",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Description PV Per News'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end me-3" style={{ minWidth: '120px' }}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('PV Per News (Min)'),
        accessor: "min_pv_per_news",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description PV Per News (Min)'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '150px' }}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('PV Per News (Max)'),
        accessor: "max_pv_per_news",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description PV Per News (Max)'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '150px' }}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Medias'),
        accessor: "domains",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            {(cell?.value || [])?.map((dm: any) => (
              <div className="cursor-pointer mb-2" key={dm} style={{ minWidth: '150px' }}>
                {dm && (<> <span>
                  {listMedia?.filter((item: any) => String(item?.website) === String(dm))[0]?.name || ''}
                </span>
                  <br />
                  <Link className='text-normal' to={(String(dm).includes('https://') || String(dm).includes('http://')) ? dm : `https://${dm}`} target="_blank">
                    {dm}
                  </Link>
                </>
                )}
              </div>
            ))}
          </>)
        },
      },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language, listCategoriesTypeLang, userPermissions, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resMedia, resCategoriesType]: any = await Promise.all([getAllMedias(), getAllCategoriesType()]);

      const listCateType = resCategoriesType?.data?.map((item: any) => ({ value: item?.type, label: item?.name })) || []
      const valCateType = listCateType?.filter((item: any) => item?.value === query?.type)[0];
      setListCategoriesType((_prev) => listCateType);
      setCategoryTypeSearch((prev) => (valCateType ? { ...valCateType, label: t(`Category_Type_[${valCateType?.value}]_For_Campaign`) } : null));

      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }));
    } catch (error: any) {
      return error;
    }
  };

  const handleExportCategory = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await getExportCategoryStatistics({ ...query, is_export: 1 });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'campaign';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Category Statistics')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  useEffect(() => {
    setDurationResponses([
      {
        name: 'Category Statistics',
        time: categoryStatistics?.pagination?.duration || 0
      }
    ]);
  }, [categoryStatistics?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Category Statistics')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_STATISTICS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList" className="ribbon-box right">
                  <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per 10 mins')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      <div className="d-flex flex-column flex-md-row align-items-start align-items-sm-end justify-content-between mt-3 mt-sm-0">
                        <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isCategoryStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={categoryStatistics?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_STATISTICS_LIST, userPermissions) && (
                          <div className="w-100 d-block d-md-none mt-3 mt-md-0 text-start text-md-end ">
                            <button
                              type="button"
                              className="btn btn-soft-primary"
                              onClick={(e) => { e.preventDefault(); handleExportCategory() }}
                              style={{ width: '170px' }}
                            >
                              {isLoadingExportFile ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-download-2-fill align-bottom me-2"></i>}
                              {t('Button Export Category Statistics')}
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mb-md-0 mt-2">
                          <Col sm={12} md={4} lg={4} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Category')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Category')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={2} lg={2} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Category Type')} isShow={!!categoryTypeSearch?.value}>
                              <DropdownOption
                                name="category-type"
                                dataList={listCategoriesTypeLang || []}
                                placeholder={`${t("Category Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={categoryTypeSearch || null}
                                onChangeSelect={(e: any) => setCategoryTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Category Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>

                          <Col sm={12} md={2} lg={2} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} md={4} lg={4} className="mt-2 mt-md-0 mt-md-2">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={newsFromRangeSearch}
                              valueTo={newsToRangeSearch}
                              placeholderFrom={`${t('News From Range')}...`}
                              placeholderTo={`${t('News To Range')}...`}
                              labelFrom={`${t('News From Range')}`}
                              labelTo={`${t('News To Range')}`}
                              onChangeFrom={(val) => setNewsFromRangeSearch(val)}
                              onChangeTo={(val) => setNewsToRangeSearch(val)}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} md={4} lg={4} className="mt-3 mt-md-2">
                            <InputsRange
                              nameFrom="from"
                              nameTo="to"
                              valueFrom={pvpnFromRangeSearch}
                              valueTo={pvpnToRangeSearch}
                              placeholderFrom={`${t('PV Per News From Range')}...`}
                              placeholderTo={`${t('PV Per News To Range')}...`}
                              labelFrom={`${t('PV Per News From Range')}`}
                              labelTo={`${t('PV Per News To Range')}`}
                              onChangeFrom={(val) => setPVPNFromRangeSearch(val)}
                              onChangeTo={(val) => setPVPNToRangeSearch(val)}
                              onKeyDownFrom={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                              onKeyDownTo={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} md={4} lg={4} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isCategoryStatisticLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                            {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_STATISTICS_LIST, userPermissions) && (
                              <button
                                type="button"
                                className="btn btn-soft-success d-none d-md-block"
                                onClick={(e) => { e.preventDefault(); handleExportCategory() }}
                                style={{ width: '190px' }}
                              >
                                {isLoadingExportFile ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-download-2-fill align-bottom me-2"></i>}
                                {t('Button Export Category Statistics')}
                              </button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={categoryStatistics?.list?.length ? categoryStatistics?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={categoryStatistics?.pagination?.total}
                        customPageCount={Math.ceil(Number(categoryStatistics?.pagination?.total) / Number(categoryStatistics?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isCategoryStatisticLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoryStatisticList;
