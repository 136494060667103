import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllMedias, getKeywordStatistics as onGetKeywordStatistics } from "../../../store/thunks";

import DatePickerCustom from 'components/Common/DatePickerCustom';
import { IMedia } from 'api/types/_media';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import { useRole } from 'components/Hooks/UserHooks';
import CountUp from 'react-countup';
import CollapseTag from 'components/Common/CollapseTag';
import { TYPE_NEWS_OPTIONS } from 'helpers/constans';
import DropdownOption from 'components/Common/DropdownOption';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import LabelWrapper from 'components/Common/LabelWrapper';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'date';

const KeywordStatisticList = () => {

  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();
  const { setDurationResponses } = useDurationResponses();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    domain: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    is_newshub: withDefault(StringParam, ''),
    ...typeQuery
  });

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_newshub))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const keywordStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywordStatistics: state.keywordStatistics,
      isKeywordStatisticSuccess: state.isKeywordStatisticSuccess,
      isKeywordStatisticLoading: state.isKeywordStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywordStatistics, error, isKeywordStatisticLoading } = useSelector(keywordStatisticsProperties);

  useEffect(() => {
    dispatch(onGetKeywordStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      title: titleSearch || "",
      keyword: keywordSearch || "",
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date(),
      is_newshub: typeNewsSearch?.value ?? '',
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      domain: '',
      title: '',
      keyword: '',
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setTypeNewsSearch(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: "date",
        filterable: false,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <div style={{ minWidth: '100px' }}>
            <span>{cell?.value}</span>
          </div>
        ),
      },
      {
        Header: t('Keyword PV'),
        accessor: "total_keyword",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description Keyword PV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end" style={{ minWidth: '130px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Keyword UV'),
        accessor: "unique_keyword",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description Keyword UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end" style={{ minWidth: '130px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Accumulated Keyword  UV'),
        accessor: "accumulated_keyword",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description Accumulated Keyword  UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end" style={{ minWidth: '130px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('New Keyword  UV'),
        accessor: "new_keyword",
        filterable: false,
        sortable: false,
        thClass: 'text-end',
        description: t('Description New Keyword  UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end" style={{ minWidth: '140px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('New Keyword Statistics'),
        accessor: "keywords",
        filterable: false,
        sortable: false,
        thWidth: 450,
        thClass: 'text-start ps-4',
        description: t('Description New Keyword Statistics'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (<div className="ms-3" style={{ minWidth: '300px' }}><CollapseTag tags={item?.list_keywords || []} isRank={false} totalShow={3} isShowModal={true} titleModal={t('New Keyword Statistics')} /></div>);
        }
      },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));

      if (typeNewsSearch) {
        setTypeNewsSearch((_prev: any) =>
          TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Keyword Statistics')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  useEffect(() => {
    setDurationResponses([
      {
        name: 'Keyword Statistics',
        time: keywordStatistics?.pagination?.duration || 0
      }
    ]);
  }, [keywordStatistics?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Keyword Statistics')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      {/* <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary order-1 order-md-0 border-0" style={{ width: '200px' }}>
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                <CountUp
                                  start={0}
                                  end={keywordStatistics?.length || 0}
                                  duration={1}
                                />
                              </span></h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card> */}
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-2">

                          <Col sm={12} lg={3} className="mt-2">
                            <LabelWrapper label={`${t('Media')}`} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={`${t('Date')}`} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>

                        </Row>
                        <Row className="g-4 align-items-center mt-0">
                          <Col sm={12} lg={3} className='mt-2'>
                            <LabelWrapper label={`${t('Type')}`} isShow={!!typeNewsSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={TYPE_NEWS_OPTIONS_LANG}
                                placeholder={`${t("News Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={typeNewsSearch || null}
                                onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All News'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={`${t('Title Post')}`} isShow={!!titleSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title Post')}...`}
                                value={titleSearch}
                                onChange={(e) => setTitleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-start mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isKeywordStatisticLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle mb-0"
                        theadClass="table-light"
                        columns={columns}
                        data={keywordStatistics?.length ? keywordStatistics : []}
                        customPageSize={keywordStatistics?.length || 0}
                        customPageIndex={0}
                        totalRecords={keywordStatistics?.length || 0}
                        customPageCount={1}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isKeywordStatisticLoading}
                        isShowPagination={false}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default KeywordStatisticList;
