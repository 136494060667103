import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface ScheduleTypeProps {
  name?: string,
  id?: string,
  initialValue?: Option;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean,
}

const ScheduleType = ({
  name = '',
  id = '',
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
}: ScheduleTypeProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item) => ({ label: item?.label, value: item?.value })) || [] as Option[];
    setOptions((_prev: any) => result);
    if (initialValue) {
      const val = result?.find((e: any) => e.value === String(initialValue?.value));
      setOnChangeOption(val);
    } else {
      setOnChangeOption(result[0]);
    }
  }, [JSON.stringify(dataList)]);

  return (
    <React.Fragment>
      <Select
        id={id}
        options={options}
        value={initialValue}
        name={name}
        isDisabled={disabled}
        autocomplete={false}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ''}</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        className="select-schedule"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default ScheduleType;