import { IMediaCode } from "api/types/_media";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
  section_code?: string;
  reporter?: string;
  reporter_id?: string;
}

interface DropdownSectionCodeMediaTypeProps {
  name?: string,
  dataList?: IMediaCode[],
  initialValue?: Option | undefined;
  onChangeSelect?: (params: Option) => void;
}

const DropdownSectionCodeMediaType = ({
  name = '',
  initialValue,
  dataList = [],
  onChangeSelect,
}: DropdownSectionCodeMediaTypeProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item) => ({
      label: item?.section_title,
      value: String(item?.id),
      section_code: item?.section_code,
      reporter: item?.reporter,
      reporter_id: item?.reporter_id,
    })) || [] as Option[];
    const val = result?.filter((e: any) => e.value === String(initialValue?.value))[0] || null;
    setOptions((_prev: any) => result);
    setOnChangeOption(val);
  }, [JSON.stringify(dataList), JSON.stringify(initialValue)]);

  return (
    <React.Fragment>
      <Select
        options={options}
        isMulti={false}
        isClearable={true}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{t('Select Section')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">{option?.label}
            <div className="op ms-2">
              {`${option?.section_code ? '' + option?.section_code : ''}`} {`${option?.reporter ? ' / ' + option?.reporter : ''}`} {`${option?.reporter_id ? ' / ' + option?.reporter_id : ''}`}
            </div>
          </div>)}
        className="search-filter-section-code"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default DropdownSectionCodeMediaType;
