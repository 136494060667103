import { IBrand, IModel } from "api/types/_car";
import { ICategoryStock } from "api/types/_stock";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownCategoryMultiSub from "components/Common/DropdownCategoryMultiSub";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableCarKeyword from "components/Common/TableCarKeyword";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { typeSearchCar } from "helpers/constans";
import { formatNumber } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import * as Yup from "yup";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {
  getAllBrands,
  getAllCarType,
  getAllCategoriesCarNormal,
  getAllModels,
  importCarsByNormal,
  getCars as onGetCars,
} from "../../../store/thunks";
import CountUp from "react-countup";
interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  sale_total: StringParam,
  manufacture: StringParam,
  used: StringParam,
  export: StringParam,
  name: StringParam,
  keyword: StringParam,
};

const TYPE_SELECT_DEFAULT: string = "sale";
const OPTION_NULL: Option | null = null;
registerLocale("en", en);
registerLocale("ko", ko);

const CarKeyword = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const TYPE_SHOW_OPTIONS_LANG = [{ value: 'brand', label: t('Brand') }, { value: 'model', label: t('Model') }];

  const STOCK_STATUS_OPTIONS_LANG = [{ value: '1', label: t('In stock') }, { value: '0', label: t('Out of stock') }];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    date_version: withDefault(
      StringParam,
      moment(new Date()).subtract(1, "months").format("Y-MM")
    ),
    brand_id: NumberParam,
    car_type_id: NumberParam,
    series_id: NumberParam,
    category_id: NumberParam,
    car_keyword_type: withDefault(StringParam, ''),
    stock_status: withDefault(StringParam, '1'),
    ...typeQuery,
  });

  const typeSearchLang = typeSearchCar?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const [typeSelect, setTypeSelect] = useState(
    typeSearchLang?.find((e: any) => e.value === query.sort_by)
  );
  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [newKeyword, setNewKeyword] = useState<any>({});

  const [initialValueKeyword, setInitialValueKeyword] = useState<string>("");
  const [isOpenSearching, setIsOpenSearching] = useState<boolean>(false);

  const [allBrands, setAllBrands] = useState<IBrand[]>([]);
  const [allCarType, setAllCarType] = useState<any[]>([]);

  const [allModels, setAllModels] = useState<IModel[]>([]);

  const [keyword, setKeyword] = useState(query?.keyword || "");

  const [name, setName] = useState(query?.name || "");

  const [listCategoriesStock, setListCategoriesStock] = useState<
    ICategoryStock[]
  >([]);

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<string>(
    query?.date_version || ""
  );
  const [brandSelect, setBrandSelect] = useState<Option | null>(OPTION_NULL);

  const [carTypeSelect, setCarTypeSelect] = useState<Option | null>(OPTION_NULL);

  const [modelSelect, setModelSelect] = useState<Option | null>(OPTION_NULL);

  const [typeShowSelect, setTypeShowSelect] = useState<Option | null>(TYPE_SHOW_OPTIONS_LANG?.find((item) => item?.value === query?.car_keyword_type) || { label: t('All Type'), value: '' });

  const [stockStatusSelect, setStockStatusSelect] = useState<Option | null>(STOCK_STATUS_OPTIONS_LANG?.find((item) => item?.value === query?.stock_status) || { label: t('All Status'), value: '' });

  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");

  const [show, setShow] = useState(false);

  const validationSchema = Yup.object({
    file: Yup.mixed()
      .required(`${t("Please select a file")}`)
      .test("fileFormat", `${t("Unsupported file format")}`, (value: any) => {
        if (value) {
          return (
            value.type === "application/vnd.ms-excel" ||
            value.type === "text/csv" ||
            value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
        return false;
      }),
    date_version: Yup.string().required(`${t("Date version is required")}`),
  });

  const [isImportCarLoading, setIsImportCarLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setIsImportCarLoading((_prev) => true);
      const response: any = await importCarsByNormal(values);
      if (response?.data) {
        toast(
          `${t("Import data process successful")}...`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        setShow((_prev) => false);
        resetData();
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }

      setIsImportCarLoading((_prev) => false);
    } catch (error) {
      setIsImportCarLoading((_prev) => false);
      return error;
    }
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      date_version: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  function onCloseClick() {
    setShow((_prev) => !_prev);
    // formik.setFieldValue('file', null);
    // formik.setFieldValue('date_version', '');
    formik.setErrors({});
  }

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => {
    return state.Car;
  };

  const carProperties = createSelector(selectLayoutState, (state) => ({
    cars: state.cars,
    isCarSuccess: state.isCarSuccess,
    isCarLoading: state.isCarLoading,
    error: state.error,
  }));

  // Inside your component
  const { cars, error, isCarLoading } = useSelector(carProperties);

  const brandOptions: Option[] = useMemo(() => {
    const list = allBrands?.map((item: IBrand) => ({
      value: String(item?.id),
      label: item?.name,
    }));
    return list;
  }, [allBrands]);

  const carTypeOptions: Option[] = useMemo(() => {
    const list = allCarType?.map((item: any) => ({
      value: String(item?.id),
      label: item?.name,
    }));
    return list;
  }, [allCarType]);

  const searchData = () => {
    const rangeQuery =
      fromRangeSearch && toRangeSearch
        ? `gt:${fromRangeSearch}-lt:${toRangeSearch}`
        : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      brand_id: brandSelect?.value,
      car_type_id: carTypeSelect?.value,
      series_id: modelSelect?.value,
      car_keyword_type: typeShowSelect?.value,
      stock_status: stockStatusSelect?.value,
      date_version: dateSearch,
      time_request: +new Date(),
      category_id: categorySearch?.value,
      name: name,
      keyword: keyword,
    });
  };
  const resetData = () => {
    setQuery(
      {
        name: '',
        keyword: '',
        stock_status: '1',
        sort_by: undefined,
        [typeSelect?.value]: undefined,
        category_id: undefined,
        time_request: +new Date(),
        date_version: moment(new Date()).subtract(1, "months").format("Y-MM"),
      },
      "push"
    );
    setTypeSelect(
      typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT)
    );
    setFromRangeSearch("");
    setToRangeSearch("");
    setDateSearch(moment(new Date()).subtract(1, "months").format("Y-MM"));
    setBrandSelect((prev) => OPTION_NULL);
    setModelSelect((prev) => OPTION_NULL);
    setCategorySearch(null);
    setKeyword("");
    setName("");
    setCarTypeSelect((prev) => OPTION_NULL);
    setTypeShowSelect((prev) => OPTION_NULL);
    setStockStatusSelect((prev) => STOCK_STATUS_OPTIONS_LANG[0]);
  };


  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) =>
      value ? moment(new Date(value)).format("Y-MM") : ""
    );
  };

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resModels, resBrands, resCategoriesStock, resCarType]: any =
        await Promise.all([
          getAllModels(),
          getAllBrands(),
          getAllCategoriesCarNormal({ type: "car" }),
          getAllCarType(),
        ]);

      setListCategoriesStock((_prev) => resCategoriesStock?.data || []);
      setAllModels((_prev) => resModels?.data?.list || []);
      setAllBrands((_prev) => resBrands?.data?.list || []);
      setAllCarType((_prev) => resCarType?.data?.list || []);
      if (query?.brand_id) {
        const optionBrand = resBrands?.data?.list?.filter(
          (item: any) => String(item?.id) === String(query?.brand_id || "")
        )[0];
        setBrandSelect((_prev) =>
          optionBrand
            ? { label: optionBrand?.name, value: String(optionBrand?.id) }
            : OPTION_NULL
        );
      }
    } catch (error: any) {
      return error;
    }
  };

  const handleSearchingKeyword = () => {
    setIsOpenSearching((prev) => true);
  };

  const triggerListData = () => {
    dispatch(
      onGetCars(
        query.sort_by && rangeValue.current
          ? { ...query, [query.sort_by]: rangeValue.current?.value }
          : query
      )
    );
  };
  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    dispatch(
      onGetCars(
        query.sort_by && rangeValue.current
          ? { ...query, [query.sort_by]: rangeValue.current?.value }
          : query
      )
    );
  }, [dispatch, query]);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.find((e: any) => e.value === typeSelect?.value)
      );
    }
    if (!brandSelect?.value) {
      setBrandSelect((prev) => OPTION_NULL);
    }
    if (!modelSelect?.value) {
      setModelSelect((prev) => OPTION_NULL);
    }
    if (!carTypeSelect?.value) {
      setCarTypeSelect((prev) => OPTION_NULL);
    }
    if (!typeShowSelect?.value) {
      setTypeShowSelect((prev) => OPTION_NULL);
    }
    if (!stockStatusSelect?.value) {
      setStockStatusSelect((prev) => OPTION_NULL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Keyword")} - ${t("Car Keyword")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Car Keyword")} pageTitle={t("Keyword")} />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.CAR_KEYWORD_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-2">
                        <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isCarLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={cars?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100 mt-2">
                          <Row className="g-2 g-lg-4 align-items-center mb-3">
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Type")}
                                isShow={true}
                              >
                                <DropdownOption
                                  name="car_keyword_type"
                                  dataList={TYPE_SHOW_OPTIONS_LANG || []}
                                  placeholder={`${t("Type")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={typeShowSelect || null}
                                  onChangeSelect={(e: any) => setTypeShowSelect(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Type"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Brand")}
                                isShow={!!brandSelect?.value}
                              >
                                <DropdownOption
                                  name="brand"
                                  dataList={brandOptions || []}
                                  placeholder={`${t("Brand")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={brandSelect || null}
                                  onChangeSelect={(e: any) => setBrandSelect(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Brand"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Car Type")}
                                isShow={!!carTypeSelect?.value}
                              >
                                <DropdownOption
                                  name="car_type"
                                  dataList={carTypeOptions || []}
                                  placeholder={`${t("Car Type")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={carTypeSelect || null}
                                  onChangeSelect={(e: any) => setCarTypeSelect(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Car Type"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              lg={3}
                              className="date-picker-wrapper-custom"
                            >
                              <LabelWrapper
                                label={t("Date Car")}
                                isShow={!!dateSearch}
                              >
                                <DatePicker
                                  className="form-control search"
                                  placeholderText={`${t("Date Car")}...`}
                                  value={dateSearch || ""}
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  showFourColumnMonthYearPicker
                                  dateFormat="yyyy-MM"
                                  isClearable={false}
                                  locale={i18n?.language === "ko" ? "ko" : "en"}
                                  onChange={handleChangePicker}
                                  selected={new Date(dateSearch) || undefined}
                                />
                              </LabelWrapper>
                            </Col>
                          </Row>
                          <Row className="g-2 g-lg-4 align-items-center mb-3">
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Stock Status")}
                                isShow={true}
                              >
                                <DropdownOption
                                  name="stock_status"
                                  dataList={STOCK_STATUS_OPTIONS_LANG || []}
                                  placeholder={`${t("Stock Status")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={stockStatusSelect || null}
                                  onChangeSelect={(e: any) => setStockStatusSelect(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Status"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Category")}
                                isShow={!!categorySearch?.value}
                              >
                                <DropdownCategoryMultiSub
                                  dataList={listCategoriesStock}
                                  initialValue={categorySearch || undefined}
                                  onChangeCategory={(event: any) => {
                                    setCategorySearch(event);
                                  }}
                                  isClearable={true}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Keyword")}
                                isShow={!!keyword}
                              >
                                <div>
                                  <Input
                                    type="text"
                                    className="form-control search"
                                    placeholder={`${t("Keyword")}...`}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                  />
                                </div>
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} lg={3}>
                              <LabelWrapper
                                label={t("Name")}
                                isShow={!!name}
                              >
                                <div>
                                  <Input
                                    type="text"
                                    className="form-control search"
                                    placeholder={`${t("Name")}...`}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              lg={12}
                              className="hstack gap-1 justify-content-sm-center justify-content-md-end"
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary me-1"
                                  onClick={searchData}
                                  disabled={isCarLoading}
                                >
                                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                                  {t("Button Search")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary fs-14"
                                  onClick={resetData}
                                >
                                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                  {t("Button Reset")}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableCarKeyword
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          data={cars?.list?.length ? cars?.list : []}
                          customPageSize={cars?.list?.length}
                          customPageIndex={query.page - 1}
                          totalRecords={cars?.pagination?.total}
                          customPageCount={Math.ceil(
                            Number(cars?.pagination?.total) /
                            Number(cars?.pagination?.limit)
                          )}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{
                            sort_by: query.sort_by,
                            order_by: query.order_by,
                          }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isCarLoading}
                          isShowPagination={true}
                          triggerListData={triggerListData}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>

        <Modal isOpen={show} centered={true}>
          <ModalHeader
            toggle={isImportCarLoading ? () => { } : onCloseClick}
          ></ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <div>
                    <label htmlFor="file" className="form-label">
                      {t("File")}
                      <span className="text-danger"> *</span>
                    </label>
                    <Input
                      type="file"
                      id="file"
                      name="file"
                      accept=".csv, .excel, .sheet, .xlsx"
                      style={{ display: "none" }}
                      placeholder={`${t("File")}...`}
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          "file",
                          event?.currentTarget?.files[0]
                        );
                      }}
                    />
                    <div className="form-icon right">
                      <label
                        className="form-control form-control-icon"
                        htmlFor="file"
                        id="file-name"
                        style={{ color: "#8d8f9d" }}
                      >
                        {
                          //@ts-ignore
                          formik?.values?.file?.name || `${t("Choose File")}...`
                        }
                        <i className=" ri-attachment-line"></i>
                      </label>
                    </div>
                    {formik.touched.file && formik.errors.file ? (
                      <div className="text-danger mt-2">
                        {formik.errors.file}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col xxl={12}>
                  <div>
                    <label htmlFor="date_version" className="form-label">
                      {t("Date Version")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="date-picker-wrapper-custom">
                      <DatePicker
                        className="form-control search"
                        placeholderText={`${t("Date Version")}...`}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showFourColumnMonthYearPicker
                        dateFormat="yyyy-MM"
                        isClearable={true}
                        value={formik.values.date_version}
                        locale={i18n?.language === "ko" ? "ko" : "en"}
                        onChange={(value: Date | null) =>
                          formik.setFieldValue(
                            "date_version",
                            value ? moment(new Date(value)).format("Y-MM") : ""
                          )
                        }
                      />
                    </div>

                    {formik.touched.date_version &&
                      formik.errors.date_version ? (
                      <div className="text-danger mt-2">
                        {formik.errors.date_version}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      className="btn btn-secondary fs-14"
                      color="light"
                      onClick={onCloseClick}
                      disabled={isImportCarLoading}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                    <button
                      className="btn btn-primary fs-14"
                      color="success"
                      type="submit"
                      disabled={isImportCarLoading}
                    >
                      {isImportCarLoading ? (
                        <Spinner size="sm" className="me-2"></Spinner>
                      ) : (
                        <i className="ri-file-download-line align-bottom me-1"></i>
                      )}
                      {t("Button Import")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CarKeyword;
