import { createAsyncThunk } from "@reduxjs/toolkit";
import labApi from 'api/labApi';
import { formatQueryParams } from "helpers/format";
import 'react-toastify/dist/ReactToastify.css';
import { changeStatusCampaignsLabAction } from "./reducer";

export const getNewsStatisticsFromLab= createAsyncThunk("Lab/NewsStatistics" , async (params: any = {}) => {
  try{
    const response = await labApi.newsStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getCampaignStatisticsFromLab= createAsyncThunk("Lab/Campaigns" , async (params: any = {}) => {
  try{
    const response = await labApi.campaignStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getCategoryStatisticsFromLab= createAsyncThunk("Lab/Categories" , async (params: any = {}) => {
  try{
    const response = await labApi.categoryStatistics(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const changeStatusCampaignsStatisticsFromLab= (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusCampaignsLabAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};