import { ICurrency } from 'api/types/_currency';
import { Option } from 'api/types/_public';
import BreadCrumb from 'components/Common/BreadCrumb';
import FilterCurrencies from 'components/Common/FilterCurruncies';
import TableContainer from 'components/Common/TableContainer';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getAllCurrencies, getExchangeRates as onGetExchangeRates } from "../../../store/thunks";
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import LabelWrapper from 'components/Common/LabelWrapper';

const typeQuery = {
  'cur_unit': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'version_id';
registerLocale('en', en);
registerLocale('ko', ko);

const TODAY_DATE_DEFAULT = `${moment().format("Y-MM-DD")}`;

const ExchangeRateKeyword = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    date_version: withDefault(StringParam, TODAY_DATE_DEFAULT),
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<string>(query?.date_version || TODAY_DATE_DEFAULT);

  const [currencySearch, setCurrencySearch] = useState<Option | null>(null);
  const [listCurrencies, setListCurrencies] = useState<ICurrency[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.ExchangeRate;

  const ExchangeRateProperties = createSelector(
    selectLayoutState,
    (state) => ({
      exchangeRates: state.exchangeRates,
      isExchangeRatesSuccess: state.isExchangeRatesSuccess,
      isExchangeRatesLoading: state.isExchangeRatesLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { exchangeRates, isExchangeRatesLoading } = useSelector(ExchangeRateProperties);

  useEffect(() => {
    dispatch(onGetExchangeRates(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      cur_unit: currencySearch?.value ?? undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      date_version: dateSearch,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      cur_unit: undefined,
      time_request: + new Date()
    }, "push")
    setCurrencySearch(null);
    setDateSearch(TODAY_DATE_DEFAULT);
  };


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('version_date'),
        accessor: "version_date",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ width: '90px' }}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Country'),
        accessor: "currency",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }}>{cell?.value?.country}</div>
          </>
        ),
      },
      {
        Header: t('Currency Name'),
        accessor: "cur_nm",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const currency = value?.currency;
          return (<>
            <div style={{ minWidth: '70px' }}>{currency?.name}</div>
          </>);
        },
      },
      {
        Header: t('cur_unit'),
        accessor: "cur_unit",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '70px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('deal_bas_r'),
        accessor: "deal_bas_r",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '90px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('bkpr'),
        accessor: "bkpr",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '90px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('kftc_bkpr'),
        accessor: "kftc_bkpr",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '160px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('ttb'),
        accessor: "ttb",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '150px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('tts'),
        accessor: "tts",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '150px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('ten_dd_efee_r'),
        accessor: "ten_dd_efee_r",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('yy_efee_r'),
        accessor: "yy_efee_r",
        filterable: true,
        sortable: true,
        thClass: "text-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-3" style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resCurrencies]: any = await Promise.all([getAllCurrencies()]);
      setListCurrencies((_prev) => resCurrencies?.data || []);
      // Begin::set value default for form filter
      const cur_unit = String(query?.cur_unit || '');
      const optionCurUnit = (resCurrencies?.data || [])?.filter((item: any) => String(item?.code || '') === cur_unit)[0];
      setCurrencySearch((_prev) => optionCurUnit ? ({ value: String(optionCurUnit?.code), label: optionCurUnit?.code }) : null);
      // End::set value default for form filter
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!currencySearch?.value) {
      setCurrencySearch((prev) => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Exchange Rate')} - ${t('Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Exchange Rate')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.EXCHANGE_RATE_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={6} lg={3} className="date-picker-wrapper-custom mt-2 mb-2 mb-sm-0">
                        <LabelWrapper label={t('version_date')} isShow={!!dateSearch}>
                          <DatePicker
                            className="form-control search"
                            placeholderText={`${t('version_date')}...`}
                            value={dateSearch || undefined}
                            selected={new Date(dateSearch) || undefined}
                            dateFormat="yyyy-MM-dd"
                            isClearable={false}
                            maxDate={new Date()}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} lg={3} className='mt-2 '>
                        <LabelWrapper label={t('cur_unit')} isShow={!!currencySearch?.value}>
                          <FilterCurrencies
                            dataList={listCurrencies}
                            initialValue={currencySearch}
                            onChangeSelect={(event: any) => {
                              setCurrencySearch((_prev) => event);
                            }}
                            isHasOptionAll={true}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isExchangeRatesLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={exchangeRates?.list?.length ? exchangeRates?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(Number(exchangeRates?.pagination?.total) / Number(exchangeRates?.pagination?.limit))}
                        customPageIndex={query.page - 1}
                        totalRecords={exchangeRates?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isExchangeRatesLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExchangeRateKeyword;
