import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, CardHeader, Col, Container, Input, Row, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getCategories as onGetCategories, } from "../../../store/thunks";

const typeQuery = {
  'keyword_count': StringParam,
}

const NaverCategory = () => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    name: StringParam,
    ...typeQuery
  })
  const [keyword, setKeyword] = useState("");

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Naver;
  const naverCategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      categories: state.categories,
      isCategorySuccess: state.isCategorySuccess,
      isCategoryLoading: state.isCategoryLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { categories, error, isCategoryLoading } = useSelector(naverCategoryProperties);

  useEffect(() => {
    dispatch(onGetCategories(query));
  }, [dispatch, query]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "category_id",
        sortable: false,
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;

          const names = value?.group_categories.map((item: any) => item.name);
          const commaSeparatedString = names ? names.join(' > ') : "";
          let name = value.name;
          if (commaSeparatedString !== "") {
            name = commaSeparatedString + ` > ` + value.name;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: name }} />
          );
        }
      },
      {
        Header: t('Total Keyword'),
        accessor: "keyword_count",
        filterable: false,
        sortable: false,
        thClass: 'text-start',
        thWidth: 130,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? 100}</span>
          </>
        ),
      },
    ],
    [i18n?.language]
  );

  // Search
  const handleSearchCategory = () => {
    setQuery({ name: keyword, page: 1, })
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const resetData = () => {
    setQuery({
      name: undefined,
      time_request: + new Date()
    }, "push")
    setKeyword("");
  };

  useEffect(() => {
    document.title = `${t('Keyword')} - ${t('Naver Category')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid>
          <BreadCrumb title={t('Naver Category')} pageTitle={t('Keyword')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={8} lg={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('Search by name')}...`}
                          value={keyword}
                          onChange={e => setKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchCategory();
                            }
                          }}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col sm={4} className="hstack gap-1">
                      <button type="button" className="btn btn-primary" disabled={isCategoryLoading} onClick={handleSearchCategory}>
                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                        {t('Button Search')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary fs-14"
                        onClick={resetData}
                      >
                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                        {t('Button Reset')}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={categories?.list?.length ? categories?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={categories?.pagination?.total}
                      customPageCount={Math.ceil(categories?.pagination?.total / categories?.pagination?.limit)}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      handleChangePage={handleChangePage}
                      isLoading={isCategoryLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default NaverCategory;