import { IMedia, INotification } from 'api/types/_media';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteNotificationMedia, getAllMedias, getNotifications as onGetNotifications, postNotificationMedia, putNotificationMedia } from "../../../store/thunks";

import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';

import EditorCustom from 'components/Common/EditorCustom';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import EmojiPicker from 'emoji-picker-react';
import CountUp from 'react-countup';
import "./Notification.scss";
import LabelWrapper from 'components/Common/LabelWrapper';
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

const NotificationList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    // sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    title: withDefault(StringParam, ''),
    domain_ids: withDefault(StringParam, ''),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<INotification | null>(null);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [emojiPickerTitle, setEmojiPickerTitle] = useState<boolean>(false);
  const [emojiTitle, setEmojiTitle] = useState<string>("");
  const [emojiContent, setEmojiContent] = useState<string>("");
  const emojiPickerContainerTitleRef = useRef<HTMLDivElement>(null);
  const emojiPickerContainerContentRef = useRef<HTMLDivElement>(null);

  const refContentNews = useRef<any>(null);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Media;

  const NotificationProperties = createSelector(
    selectLayoutState,
    (state) => ({
      notifications: state.notifications,
      isNotificationSuccess: state.isNotificationSuccess,
      isNotificationLoading: state.isNotificationLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { notifications, error, isNotificationLoading } = useSelector(NotificationProperties);

  useEffect(() => {
    dispatch(onGetNotifications(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      title: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      domain_ids: undefined,
      sort_by: undefined,
      time_request: + new Date()
    }, "push")
    setKeywordSearch((_prev) => "");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Title'),
        accessor: "title",
        filterable: true,
        sortable: false,
        thClass: "text-start",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div
              className="cursor-pointer text-start"
              onClick={() => handleEdit(item)}
              dangerouslySetInnerHTML={{
                __html: cell?.value || "",
              }}
            ></div>
          </>)
        },
      },
      {
        Header: t('Medias'),
        accessor: "partner_ids",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            {(cell?.value || [])?.map((dm: any) => {
              const media = listMedia?.filter((item: any) => String(item?.id) === String(dm))[0];
              return (
                <div className="cursor-pointer mb-2" key={media?.id}>
                  {media && (<> <span>
                    {media?.name || ''}
                  </span>
                    <br />
                    <Link className='text-normal' to={(String(media?.website).includes('https://') || String(media?.website).includes('http://')) ? media?.website : `https://${media?.website}`} target="_blank">
                      {media?.website}
                    </Link>
                  </>
                  )}
                </div>)
            })}
          </>)
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end">
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end">
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 130,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0 text-center" style={{ width: '120px' }}>
              <TooltipCustom
                title={t('Button Detail Notification')}
                id={`detail-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`detail-md-${item?.id}`}>
                  <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); handleEdit(item, true) }}
                  >
                    <i className="ri-eye-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>
              {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_UPDATE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Update Notification')}
                  id={`update-md-${item?.id}`}
                >
                  <li className="list-inline-item" id={`update-md-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleEdit(item) }}
                    >
                      <i className="ri-pencil-fill align-bottom "></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete Notification')}
                  id={`delete-md-${item?.id}`}
                >
                  <li className="list-inline-item" id={`delete-md-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, JSON.stringify(listMedia)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Form

  function onCloseClick() {
    setIsOpen((_prev) => !_prev);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  function onCreateClick() {
    setInitialValuesDefault((_prev) => null);
    handleResetForm();
    setIsOpen((_prev) => true);
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        title: values?.title ?? '',
        content: refContentNews?.current?.getContent() ?? '',
        partner_ids: values?.medias?.map((item: any) => item?.value) || [],
      };
      const response: any = !!initialValuesDefault?.id ? await putNotificationMedia(initialValuesDefault?.id, data) : await postNotificationMedia(data);
      if ((response?.code === 200) || response?.data) {
        dispatch(onGetNotifications(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(`${t("Title is required")}`),
    content: Yup.string().nullable(),
    medias: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(`${t('Please provide a label')}`),
        value: Yup.string().required(`${t('Please provide a value')}`),
      })
    ).required(`${t('Medias is required')}`).min(1, `${t('At least 1 media is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
      medias: null,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEdit = (item: INotification, isPreview: boolean = false) => {
    setInitialValuesDefault((_prev) => ({ ...item, isPreview }));
    setIsOpen((_prev) => true);
    const VMedias = listMedia?.reduce((arr: any[], dia: any) => {
      if (item?.partner_ids?.includes(String(dia?.id))) {
        return arr.concat({ label: dia?.name, value: String(dia?.id || ''), website: dia?.website || '' });
      }
      return arr;
    }, []) || [] as Option[];
    formik.setFieldValue('title', item?.title);
    formik.setFieldValue('content', item?.content);
    formik.setFieldValue('medias', VMedias);
  }

  const handleResetForm = () => {
    formik.setFieldValue('title', '');
    formik.setFieldValue('content', '');
    formik.setFieldValue('medias', null);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  };

  const handleCallAllOption = async () => {
    try {
      const [resMedia]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
    } catch (error: any) {
      return error;
    }
  };

  //@ts-ignore
  const initialValueSections = formik?.values?.sections || [];

  //@ts-ignore
  const initialValueMedias = (formik?.values?.medias as Option[]) || [];

  const onEmojiTitleClick = (event: any, emojiObject: any) => {
    setEmojiTitle(event.emoji)
  };


  // Begin::Delete
  const handleConfirmDelete = (item: INotification) => {
    setInitialValuesDefault((_prev: any) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteNotificationMedia(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetNotifications(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }

  // End::Delete


  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        emojiPickerContainerTitleRef.current &&
        !emojiPickerContainerTitleRef.current.contains(event.target)
      ) {
        setEmojiPickerTitle(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerContainerTitleRef]);


  useEffect(() => {
    if (emojiTitle) {
      formik.setFieldValue("title", formik.values?.title + emojiTitle)
      setEmojiTitle('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiTitle])

  useEffect(() => {
    if (emojiContent) {
      formik.setFieldValue("content", formik.values?.content + emojiContent)
      setEmojiContent('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiContent])

  useEffect(() => {
    document.title = `${t('Notification')} - ${t('Media')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Notification')} pageTitle={t('Media')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0 d-none d-md-block" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isNotificationLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={notifications?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className='mt-2'>
                          {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_CREATE, userPermissions) && (
                            <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => onCreateClick()}
                              >
                                <i className="ri-upload-2-line align-bottom me-1"></i>{" "}
                                {t('Button Create Notification')}
                              </button>
                            </Col>)
                          }
                        </Row>
                        <Row className="g-4 align-items-center mb-0 mt-2">
                          <Col sm={12} md={3} lg={3} className='mt-2'>
                            <LabelWrapper label={t('Title')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={9} lg={9} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isNotificationLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                            {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_CREATE, userPermissions) && (
                              <button
                                type="button"
                                className="btn btn-success d-none d-md-block"
                                onClick={() => onCreateClick()}
                              >
                                <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                {t('Button Create Notification')}
                              </button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={notifications?.list?.length ? notifications?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={notifications?.pagination?.total}
                        customPageCount={Math.ceil(Number(notifications?.pagination?.total) / Number(notifications?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isNotificationLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <ModalConfirm
          header={t('Button Delete Notification')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal isOpen={isOpen} centered={true} size="xl" scrollable={true} toggle={onCloseClick} keyboard={true} >
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>{!!initialValuesDefault?.id ? (initialValuesDefault?.isPreview ? t('Button Detail Notification') : t('Button Update Notification')) : t('Button Create Notification')}</ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <div>
                    <label className="form-label">
                      {t("Media")}
                      <span className="text-danger"> *</span>
                    </label>
                    <SearchFilterMedia
                      name="medias"
                      dataList={listMedia}
                      isMulti={true}
                      isClearable={true}
                      initialValue={initialValueMedias || []}
                      onChangeSelect={(event: any) => {
                        if (event && event?.filter((e: any) => e.value === '')?.length) {
                          const result = listMedia?.reduce((arr: any[], item: any) => {
                            if (item?.id) {
                              return arr.concat({ label: item?.name, value: String(item?.id || ''), website: item?.website || '' });
                            }
                            return arr;
                          }, []) || [] as Option[];
                          formik.setFieldValue("medias", result);
                        } else {
                          formik.setFieldValue("medias", event);
                        }
                      }}
                    />
                    {formik.touched.medias && formik.errors.medias ? (
                      <div className="text-danger mt-2">
                        {formik.errors.medias}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col sm={12} md={12}>
                  <div>
                    <label className="form-label">
                      {t("Title")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="position-relative">
                      <Input
                        type="text"
                        onChange={(e: any) => formik.setFieldValue("title", e.target.value)}
                        placeholder={`${t('Title')}...`}
                        value={formik.values?.title}
                      />
                      <div className="col-auto position-absolute top-0 end-0">
                        <div className="chat-input-links me-2">
                          <div className="links-list-item">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none emoji-btn"
                              id="emoji-btn"
                              onClick={() => {
                                setEmojiPickerTitle(!emojiPickerTitle)
                              }}
                            >
                              <i className="bx bx-smile align-middle"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {emojiPickerTitle && <div ref={emojiPickerContainerTitleRef} > <EmojiPicker onEmojiClick={onEmojiTitleClick} width={350} height={382} /> </div>}
                    </div>
                    {formik.touched.title && formik.errors.title ? (
                      <div className="text-danger mt-2">
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col sm={12} md={12}>
                  <label className="form-label">{t("Content")} <span className="text-danger"> *</span></label>
                  <EditorCustom
                    initialValue={formik.values?.content || ''}
                    toolbar="blocks | aligncenter alignjustify alignleft alignnone alignright| forecolor  backcolor blockquote link charmap | image media | bold italic strikethrough underline | table"
                    ref={refContentNews}
                  />
                </Col>
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    {!(initialValuesDefault?.isPreview) && (<button className="btn btn-success fs-14" color="success" type="submit" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : (!!(initialValuesDefault?.id) ? <i className="ri-login-circle-line align-bottom me-1"></i> : <i className="ri-add-fill align-bottom me-1"></i>)}
                      {!!(initialValuesDefault?.id) ? t('Button Update Notification') : t('Button Create Notification')}
                    </button>)}
                    <button className="btn btn-soft-secondary fs-14" color="light" type="button" onClick={onCloseClick} disabled={isLoading}>
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t('Button Close')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default NotificationList;
