import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategoryStock, IStock, IStockCategory, IStockStatistics } from "./types/_stock";
const api = new APIClient();

const path = '/stocks';
const userApi = {
  stocks(params: any):Promise<AxiosResponse<ResponseData<IStockStatistics[]> & PaginationResponse, any>> {
    const url = `stock-statistics`
    return api.get(url, params)
  },
  allStocks(params: any = {}):Promise<AxiosResponse<ResponseData<IStock[]>, any>> {
    const url = `masters/stocks`
    return api.get(url, params)
  },
  categories(params: any = {}):Promise<AxiosResponse<ResponseData<IStockCategory[]> & PaginationResponse, any>> {
    const url = `categories`
    return api.get(url, {...params, type: 'stock',})
  },
  searchCategories(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<ICategoryStock[]>, any>> {
    const url = `masters/categories`;
    return api.get(url, params);
  },
}
export default userApi
