import { ICategory } from "api/types/_category";
import { IMedia, IType } from "api/types/_media";
import { IPreviewNews } from "api/types/_news";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CategoryType from "components/Common/CategoryType";
import DropdownCategoryKeyword from "components/Common/DropdownCategoryKeyword";
import DropdownOption from "components/Common/DropdownOption";
import DropdownSectionCodeMediaType from "components/Common/DropdownSectionCodeMedia";
import Hashtag from "components/Common/Hashtag";
import KeywordInput from "components/Common/KeywordInput";
import ModalConfirm from "components/Common/ModalConfirm";
import ScheduleType from "components/Common/ScheduleType";
import SearchFilterKeyword from "components/Common/SearchFilterKeyword";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import SearchTemplate from "components/Common/SearchTemplate";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import { ACTION_TYPE_CAMPAIGN, SCHEDULE_MONTHLY_OPTIONS, SCHEDULE_OPTIONS, SCHEDULE_WEEKLY_OPTIONS, TYPE_SHOW_MORE_HASHTAG } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { getTrendIndexKeywordsByNormal, postCampaignMultiMedia, postCategory, postCategoryKeywords, previewNews } from "store/thunks";
import * as Yup from 'yup';

export interface Tag {
  id: string;
  text: string;
}
interface Props {
  listKeyword?: Option[],
  listType?: IType[],
  listMedia?: IMedia[],
  listTemplate?: ITemplate[],
  listCategoriesType?: Option[],
  listClassifications?: Option[],
  listCategoriesNaver?: ICategory[],
  listCategoriesStock?: ICategory[],
  listCategoriesTrendIndex?: ICategory[],
  handleRefetchCategoriesTrendIndex?: () => void,
  handleClearSelectedKeywords?: () => void,
}

registerLocale('en', en);
registerLocale('ko', ko);

function removeParentheses(text: string) {
  // Use regular expressions to remove content within ()
  return text.replace(/\([^)]*\)/g, '').trim();
}

const CampaignFormMultiByKeyword = ({
  listKeyword = [],
  listType = [],
  listMedia = [],
  listTemplate = [],
  listCategoriesType = [],
  listClassifications = [],
  listCategoriesNaver = [],
  listCategoriesStock = [],
  listCategoriesTrendIndex = [],
  handleRefetchCategoriesTrendIndex,
  handleClearSelectedKeywords,


}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const OPTIONS_MUST_HAVE_DATE = ['weekly', 'monthly'];

  const OPTIONS_MUST_HAVE_PUBLISHING = ["daily", "weekly", "monthly"];

  const OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD = ["trend_index", "stock"];

  const CATEGORY_TYPE_CREATE_BY_FORM_NORMAL = ['trend_index', 'exchange_rate', 'car', 'movie', 'stock'];

  const MONTHLY_OPTIONS = SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({ ...item, type: 'monthly' })) || [];
  const WEEKLY_OPTIONS = SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({ ...item, type: 'weekly' })) || [];
  const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map((item: any) => ({ ...item, label: t(item?.label) }));


  const OPTION_CATEGORY_ROOT = { name: t('---Root---'), id: '', category_id: '', keyword_count: 0, depth: null };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

  const [isPreviewLoading, setIsPreviewLoading] = useState<number | null>(null);

  const [isOpenCreateCategory, setIsOpenCreateCategory] = useState<boolean>(false);

  const [isCreateCategoryLoading, setIsCreateCategoryLoading] = useState<boolean>(false);

  const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(null);

  const [stepAction, setStepAction] = useState<number>(1);

  const [totalCategorySubShow, setTotalCategorySubShow] = useState<number>(0);

  const listCategoriesTypeLang = listCategoriesType?.reduce((arr: any, item: any) => {
    if (CATEGORY_TYPE_CREATE_BY_FORM_NORMAL?.includes(item?.value)) {
      return ([...arr, ({
        ...item,
        label: t(`Category_Type_[${item?.value}]_For_Campaign`),
      })]);
    }
    return arr;
  }, []) || [];

  // Begin::Classification options
  const listClassificationsLang = listClassifications?.map((item: any) => ({
    ...item,
    label: t(`${item?.value}`),
  }))
  // End::Classification options

  const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);


  const handleShowConfirmCreateCampaign = () => {
    if (formik?.values?.action_type === ACTION_TYPE_CAMPAIGN.SAVE) {
      handleSubmit(formik.values);
    } else {
      setIsConfirmCreate((_prev) => true);
    }
  };

  const handleCreateCampaign = () => {
    setIsConfirmCreate((_prev) => false);
    handleSubmit(formik.values);
  }

  const onCloseConfirmCreateClick = () => {
    setIsConfirmCreate((_prev) => false);
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        schedule: values?.schedule?.value || "now",
        publishing_schedule: values?.publishing_schedule?.value || "now",
        category_id: values?.category?.value ? String(values?.category?.value) : null,
        classification: values?.classification?.value || null,
        type: values?.category_type?.value,
        keywords: [], // values?.keywords?.map((item: Option) => item?.value) ||
        tag_related_news: (values?.tags || [])?.map((item: Tag) => item?.id).join(",") || "",
        action_type: values?.action_type || ACTION_TYPE_CAMPAIGN.POST,
        multiple: true,
        partners: (values?.sections || [])?.reduce((arr: any, item: any) => {
          if (item?.id) {
            return [...arr, {
              partner_id: item?.media?.value ?? "",
              partner_code_id: item?.section_code?.value || '',
              title_template_id: item?.title_template_id?.value ?? "",
              body_template_id: item?.body_template_id?.value ?? "",
              is_random_chart: item?.is_random_chart || false,
              date: OPTIONS_MUST_HAVE_DATE.includes(values?.schedule?.value) ? item?.date?.value || "" : "",
              time: values?.schedule?.value === "now" ? "" : item?.time || "",
              publishing_date: OPTIONS_MUST_HAVE_DATE.includes(values?.publishing_schedule?.value) ? item?.publishing_date?.value || "" : "",
              publishing_time: values?.publishing_schedule?.value === "now" ? "" : item?.publishing_time || "",
            }];
          }
          return arr;
        }, []) || [],
      };
      const response: any = await postCampaignMultiMedia(data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        setStepAction((pre) => 3);
        handleClearSelectedKeywords && handleClearSelectedKeywords();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const sectionSchema = Yup.lazy((item) => {
    return Yup.object().shape({
      media: Yup.object().test('validate media', `${t('Media is required')}`, function (value) {
        return !!value;
      }).required(`${t('Media is required')}`),
      section_code: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).nullable(),
      date: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).when('schedule', {
        is: (schedule: any) => (OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value)),
        then: (schema) => Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).required(`${t('Day is required')}`),
      }),
      time: Yup.string().when(['schedule', 'category_type'], {
        is: (schedule: any, category_type: any) => {
          return (item?.id !== undefined && (formik.values?.schedule as any)?.value !== 'now');
        },
        then: (schema) => Yup.string().required(`${t('Time is required')}`).when('category_type', {
          is: (cateType: any) => {
            return (formik.values?.category_type as any)?.value === 'exchange_rate';
          },
          then: (schema) => Yup.string().required(`${t('Time is required')}`).test('is-later-than-9-30', `${t('You can start writing articles from 9:30 AM!')}`, function (value) {
            if (!value) return true;
            const [hours, minutes] = value.split(':').map(Number);
            const timeInMinutes = hours * 60 + minutes;
            const nineThirtyInMinutes = 9 * 60 + 30;
            return (timeInMinutes >= nineThirtyInMinutes);
          }),
        }),
      }),
      publishing_date: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }).when('publishing_schedule', {
        is: (publishing_schedule: any) => (OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value)),
        then: (schema) => Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }).required(`${t('Day is required')}`),
      }),
      publishing_time: Yup.string().when(['publishing_schedule', 'category_type'], {
        is: (publishing_schedule: any, category_type: any) => {
          return (item?.id !== undefined && (formik.values?.publishing_schedule as any)?.value !== 'now');
        },
        then: (schema) => Yup.string().required(`${t('Time is required')}`).when('category_type', {
          is: (cateType: any) => {
            return (formik.values?.category_type as any)?.value === 'exchange_rate';
          },
          then: (schema) => Yup.string().required(`${t('Time is required')}`).test('is-later-than-9-30', `${t('You can start writing articles from 9:30 AM!')}`, function (value) {
            if (!value) return true;
            const [hours, minutes] = value.split(':').map(Number);
            const timeInMinutes = hours * 60 + minutes;
            const nineThirtyInMinutes = 9 * 60 + 30;
            return (timeInMinutes >= nineThirtyInMinutes);
          }),
        }),
      }),
      title_template_id: Yup.object().test('validate title', `${t('News Title Template is required')}`, function (value) {
        return !!value;
      }).required(`${t('News Title Template is required')}`),
      body_template_id: Yup.object().test('validate body', `${t('News Content Template is required')}`, function (value) {
        return !!value;
      }).required(`${t('News Content Template is required')}`),
      is_random_chart: Yup.boolean().nullable()
    })
  });


  const validationSchema = Yup.object({
    schedule: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t("Schedule is required")}`),
    publishing_schedule: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t("Publishing Schedule is required")}`),
    sections: Yup.array().of(sectionSchema).min(1, `${t("At least 1 media is required")}`),
    category_type: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("Category Type is required")}`),
    category: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .when("category_type", {
        is: (cateType: any) => !!(OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(cateType?.value)),
        then: (schema) =>
          Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .required(`${t("Category is required")}`),
        otherwise: (schema) =>
          Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable(),
      }),
    classification: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).nullable(),
    keywords: Yup.array().when("category_type", {
      is: (cateType: any) => !!(cateType?.value === "default"),
      then: (schema) =>
        Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string(),
              value: Yup.string(),
            })
          )
          .min(1, `${t("At least 1 keyword is required")}`)
          .max(10, `${t("Up to 10 keywords are allowed")}`)
          .required("At least 1 keyword is required"),
      otherwise: (schema) => Yup.array().nullable(),
    }),
    tags: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          text: Yup.string(),
        })
      )
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      schedule: null,
      publishing_schedule: null,
      sections: [],
      category_type: null,
      classification: null,
      category: null,
      keywords: null,
      tags: "",
      action_type: "",
    },
    validationSchema,
    onSubmit: handleShowConfirmCreateCampaign,
  });

  //@ts-ignore
  const initialValueCategoryType = formik?.values?.category_type as Option || listCategoriesTypeLang[0];

  //@ts-ignore
  const initialValueCategory = formik?.values?.category as Option;

  //@ts-ignore
  const initialValueClassification = formik?.values?.classification as Option;

  //@ts-ignore
  const initialValueKeywords = formik?.values?.keywords as Option[] || [];

  //@ts-ignore
  const initialValueSections = (formik?.values?.sections as Option[]) || [];

  //@ts-ignore
  const initialValueTags = formik?.values?.tags as Tag[] || [];

  //@ts-ignore
  const initialValueTagsGetFromKeywords = (initialValueKeywords || [])?.map((item) => ({ id: item?.label, text: item?.label })) as Tag[] || [];

  //@ts-ignore
  const initialValueActionType = formik?.values?.action_type || ACTION_TYPE_CAMPAIGN.POST;

  const isDisabledPreview = (!initialValueCategory?.value && OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value))

  const LIST_SECTION: any = useMemo(() => {
    if (initialValueSections?.length === 0) {
      formik.setFieldValue(`sections`, [
        {
          id: String(+ new Date()),
          date: undefined,
          time: '09:00',
          publishing_date: undefined,
          publishing_time: '09:00',
          title_template_id: undefined,
          body_template_id: undefined,
          is_random_chart: false,
          section_code: undefined,
        },
      ]);
    }
    return initialValueSections;
  }, [initialValueSections]);

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  function onCloseCreateCategoryClick() {
    setIsOpenCreateCategory((_prev) => !_prev);
  }

  const onSaveClick = () => {
    formik.setFieldValue('action_type', ACTION_TYPE_CAMPAIGN.SAVE);
    formik.submitForm();
  };

  const onPostClick = () => {
    formik.setFieldValue('action_type', ACTION_TYPE_CAMPAIGN.POST);
    formik.submitForm();
  };

  const handlePreviewTemplate = async ({ index, is_random_chart, title_template_id, body_template_id }: { index: number, is_random_chart: boolean, title_template_id: string | number, body_template_id: string | number }) => {
    try {
      setIsPreviewLoading((_prev) => index);
      const data = {
        category_type: initialValueCategoryType?.value,
        category_id: initialValueCategory?.value ?? null,
        is_random_chart,
        title_template_id,
        body_template_id,
        keyword_ids: [],
      };
      const response: any = await previewNews(data);
      if (response?.data) {
        setPreviewTemplate(
          (_prev) => response?.data || { title: "", content: "" }
        );
        setIsPreviewLoading((_prev) => null);
        setIsOpenPreview((_prev) => true);
      } else {
        setIsPreviewLoading((_prev) => null);
        toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsPreviewLoading((_prev) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleRemoveSection = (index: number, i: number) => {
    const sections = formik?.values?.sections || [];
    formik.setFieldValue(`sections`, sections?.filter((item: any, idx: number) => idx !== i));
  };

  const handleAddSection = (index: number) => {
    const sections = formik?.values?.sections || [];
    formik.setFieldValue(`sections`, [...sections, {
      id: String(+ new Date()),
      date: undefined,
      time: '09:00',
      publishing_date: DATE_OPTIONS_LANG?.filter((e: any) => e.type === (formik.values?.publishing_schedule as any)?.value)[0],
      publishing_time: '09:00',
      title_template_id: undefined,
      body_template_id: undefined,
      is_random_chart: false,
      section_code: undefined,
    },]);
  };

  // Begin Step 1
  const handleSubmitStep1 = async (values: any) => {
    try {
      setIsLoading((_prev) => true);

      const [keywords, manual_keywords] = values?.step_1_keywords?.reduce(([arr1, arr2]: [string[], string[]], item: any) => {
        return ((item?.isPicked || item?.isFixed) ? [[...arr1, item?.value], arr2] : [arr1, [...arr2, item?.value]]);
      }, [[], []]) || [[], []];

      const data = {
        category_id: String(values?.step_1_category?.value) ?? null,
        keywords: keywords,
        manual_keywords: manual_keywords,
        source: "naver"
      };
      const response: any = await postCategoryKeywords(data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        handleRefetchCategoriesTrendIndex && handleRefetchCategoriesTrendIndex();
        setStepAction((pre) => 2);
        const label = removeParentheses(values?.step_1_category?.label) + ` (${response?.data?.keyword_count || 0})`;
        formik.setFieldValue('category', ({ label: label, value: response?.data?.category_id }));
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchemaStep1 = Yup.object({
    step_1_category: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Category is required')}`),
    step_1_keywords: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ).min(1, `${t('At least 1 keyword is required')}`).required('At least 1 keyword is required')
  });

  const formikStep1 = useFormik({
    initialValues: {
      step_1_category: null,
      step_1_keywords: null,
    },
    validationSchema: validationSchemaStep1,
    onSubmit: handleSubmitStep1,
  });

  //@ts-ignore
  const initialValueStep1Category = formikStep1?.values?.step_1_category as Option;

  //@ts-ignore
  const initialValueStep1Keywords = formikStep1?.values?.step_1_keywords as Option[] || listKeyword?.map((item) => ({ label: item?.label, value: item?.label, isPicked: true })) || [];

  const handleGetListKeyWordsByCategory = async (cateId: string | number) => {
    try {
      const data = {
        page: 1,
        limit: 1200,
        category_id: cateId,
      };
      const response: any = await getTrendIndexKeywordsByNormal(data);
      if (response?.data) {
        const result = response?.data?.list?.map((item: any) => ({
          label: item?.keyword,
          value: item?.keyword,
          categories: item?.categories,
          isFixed: true
        })) || [];
        const filterValueOld = initialValueStep1Keywords?.filter((item: any) => !(item?.isFixed)) || [];
        formikStep1.setFieldValue('step_1_keywords', [...result, ...filterValueOld]);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  useEffect(() => {
    formikStep1.setFieldValue('step_1_keywords', listKeyword?.map((item) => ({ label: item?.label, value: item?.label, isPicked: true })) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Begin::Modal Create Campaign
  const handleSubmitModal = async (values: any) => {
    try {
      setIsCreateCategoryLoading((_prev) => true);
      let data = {};
      let label = '';
      if (initialValueModalCategory?.depth === null) {
        data = {
          parent_id: values?.parent_id?.value || null,
          name: values?.sub1 ?? '',
          sub: (values?.sub2 ? {
            name: values?.sub2 ?? '',
            sub: (values?.sub3 ? ({
              name: values?.sub3 ?? '',
            }) : {})
          } : {})
        };
      }
      if (initialValueModalCategory?.depth === 0) {
        data = {
          parent_id: values?.parent_id?.value || '',
          name: values?.sub2 ?? '',
          sub: (values?.sub3 ? {
            name: values?.sub3 ?? '',
            sub: {}
          } : {})
        };
      }
      if (initialValueModalCategory?.depth === 1) {
        data = {
          parent_id: values?.parent_id?.value || '',
          name: values?.sub3 ?? '',
          sub: {}
        };
      }
      // Begin:: Format label option category new
      if (values?.parent_id?.value) {
        label = removeParentheses(values?.parent_id?.label || '');
      }
      if (values?.sub1) {
        label = values?.sub2 ? values?.sub1 : (values?.sub1 + ' (0)');
      }
      if (values?.sub2) {
        label = [label, values?.sub3 ? values?.sub2 : (values?.sub2 + ' (0)')].join(' > ');
      }
      if (values?.sub3) {
        label = [label, (values?.sub3 + ' (0)')].join(' > ');
      }
      // End:: Format label option category new
      setIsCreateCategoryLoading((_prev) => false);
      const response: any = await postCategory(data);
      if (response?.data) {
        setIsCreateCategoryLoading((_prev) => false);
        handleRefetchCategoriesTrendIndex && handleRefetchCategoriesTrendIndex();
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        setIsOpenCreateCategory((_prev) => false);
        formikStep1.setFieldValue('step_1_category', ({ label: label || response?.data?.name, value: response?.data?.category_id }));
        const filterValueOld = initialValueStep1Keywords?.filter((item: any) => !(item?.isFixed)) || [];
        formikStep1.setFieldValue('step_1_keywords', filterValueOld);
      } else {
        setIsCreateCategoryLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsCreateCategoryLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const validationSchemaModal = Yup.object({
    parent_id: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Category is required')}`),
    sub_1: Yup.string(),
    sub_2: Yup.string(), //.required(`${t('Category is required')}`)
    sub_3: Yup.string(),
  });

  const formikModal = useFormik({
    initialValues: {
      parent_id: null,
      sub_1: '',
      sub_2: '',
      sub_3: '',
    },
    validationSchema: validationSchemaModal,
    onSubmit: handleSubmitModal,
  });

  const handleOpenCreateCategory = () => {
    formikModal.setFieldValue('sub1', '');
    formikModal.setFieldValue('sub2', '');
    formikModal.setFieldValue('sub3', '');
    setIsOpenCreateCategory((prev) => true);
  }

  //@ts-ignore
  const initialValueModalCategory = formikModal?.values?.parent_id as any;

  //@ts-ignore
  const initialValueModalSub1 = formikModal?.values?.sub1;

  //@ts-ignore
  const initialValueModalSub2 = formikModal?.values?.sub2;

  //@ts-ignore
  const initialValueModalSub3 = formikModal?.values?.sub3;


  return (
    <React.Fragment>
      <Row>
        <Col sm={12} lg={12}>
          <div className="row g-3">
            <div className="step-arrow-nav mb-4 px-0">
              <ul role="tablist" className="nav-pills custom-nav nav-justified nav">
                <li className="nav-item"><a href="#" id="steparrow-gen-info-tab" className={`${stepAction === 1 ? 'active done' : 'done'} nav-link`}>{t('Step Select Category')}</a></li>
                <li className="nav-item"><a href="#" id="steparrow-gen-info-tab" className={`${stepAction === 2 ? 'active' : ([2, 3].includes(stepAction) ? 'done' : '')} nav-link`}>{t('Button Create Campaign')}</a></li>
                <li className="nav-item"><a href="#" id="steparrow-gen-info-tab" className={`${stepAction === 3 ? 'active' : ''} nav-link`}>{t('Button Finish')}</a></li>
              </ul>
            </div>
          </div>
        </Col>
        {/* Begin:: Step 1 - Create Category */}
        <form onSubmit={formikStep1.handleSubmit} className={`${stepAction === 1 ? '' : 'd-none'}`}>
          <Row className="mb-4">
            <Col sm={12} lg={12}>
              <div className="row g-3" style={{ border: '1px solid #f0f0f0', padding: '10px', borderRadius: '5px' }}>
                <Col md={6} xxl={4} lg={4}>
                  <div className="d-flex align-items-end">
                    <div className="w-100">
                      <label className="form-label">{t('Add keywords to the category at the specified position')}<span className="text-danger"> *</span></label>
                      <DropdownCategoryKeyword
                        name="step_1_category"
                        categoryType={'trend_index'}
                        dataListNaver={[]}
                        dataListStock={[]}
                        dataListTrendIndex={listCategoriesTrendIndex}
                        initialValue={initialValueStep1Category ?? undefined}
                        // disabled={initialValueCategoryType?.value !== 'trend_index'}
                        onChangeCategory={(event) => {
                          formikStep1.setFieldValue('step_1_category', event);
                          formikModal.setFieldValue('parent_id', event);
                          setTotalCategorySubShow((prev) => Number(event?.depth || 0) + (event?.depth === null ? 1 : 2));
                          handleGetListKeyWordsByCategory && handleGetListKeyWordsByCategory(event?.value);
                        }}
                      />
                    </div>
                    <div className="w-auto ms-2">
                      <button type="button" className="btn btn-success fs-14" onClick={handleOpenCreateCategory}>
                        <i className="ri-add-fill align-bottom "></i>
                      </button>
                    </div>
                  </div>
                  {formikStep1.touched.step_1_category && formikStep1.errors.step_1_category ? (
                    <div className="text-danger mt-2">{formikStep1.errors.step_1_category}</div>
                  ) : null}
                </Col>
                <Col md={6} xxl={8} lg={8}>
                  <div>
                    <label className="form-label">{t('Keywords')}<span className="text-danger"> *</span></label>
                    <span className="ms-2"> [ {t('Total Keywords')}: <strong className="text-success">{formatNumberWithCommas(initialValueStep1Keywords?.length || 0)}</strong> ]</span>
                    <KeywordInput
                      name="step_1_keywords"
                      initialValue={initialValueStep1Keywords || []}
                      placeholder={`${t('Keywords')}...`}
                      type='keywords'
                      onChangeTag={(event: any) => {
                        formikStep1.setFieldValue('step_1_keywords', event);
                      }}
                    />
                    {formikStep1.touched.step_1_keywords && formikStep1.errors.step_1_keywords ? (
                      <div className="text-danger mt-2">{formikStep1.errors.step_1_keywords}</div>
                    ) : null}
                  </div>
                </Col>
                <Col md={12} xxl={12} lg={12}>
                  <div className="d-flex align-items-start gap-3 mt-3">
                    <button type="submit" className="btn btn-success btn-label right ms-auto nexttab nexttab">
                      {isLoading ? <i className="label-icon align-middle"><Spinner size="sm" className='me-2'></Spinner></i> : <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>}
                      {t('Next step')}
                    </button>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </form>
        {/* End:: Step 1 - Create Category */}
        {/* Begin:: Step 2 - Create Campaign */}
        <form onSubmit={formik.handleSubmit} className={`${stepAction === 2 ? '' : 'd-none'}`}>
          <Row>
            <Col sm={12} md={7} lg={7}>
              <div className="row g-3" style={{ border: '1px solid #f0f0f0', padding: '10px', borderRadius: '5px' }}>
                <Col sm={6} md={4} className="d-flex justify-content-between align-items-end">
                  <div style={{ width: '100%' }}>
                    <label className="form-label">{t('Category Type')}<span className="text-danger"> *</span></label>
                    <CategoryType name="category_type"
                      dataList={listCategoriesTypeLang}
                      disabled={true}
                      placeholder={`${t('Select Category Type')}...`}
                      initialValue={initialValueCategoryType ?? undefined} onChangeSelect={(event) => {
                        formik.setFieldValue('category_type', event);
                        formik.setFieldValue('category', null);
                        formik.setFieldValue('keywords', null);
                        formik.setFieldValue('tags', null);
                        formik.setFieldValue("classification", null);
                      }} />
                    {formik.touched.category_type && formik.errors.category_type ? (
                      <div className="text-danger mt-2">{formik.errors.category_type}</div>
                    ) : null}
                  </div>
                </Col>
                <Col sm={3} md={4}>
                  <div>
                    <label className="form-label">
                      {t("Creating Schedule")}
                      <span className="text-danger"> *</span>
                    </label>
                    <ScheduleType
                      name="schedule"
                      id="schedule"
                      dataList={SCHEDULE_OPTIONS_LANG}
                      placeholder={`${t('Select Creating Schedule')}...`}
                      initialValue={formik.values?.schedule || SCHEDULE_OPTIONS_LANG[0]}
                      onChangeSelect={(event) => {
                        formik.setFieldValue('schedule', event);
                        formik.setFieldValue('publishing_schedule', event);
                        ((formik.values?.sections as any) || [])?.map((item: any, index: number) => {
                          const date = DATE_OPTIONS_LANG?.filter((e: any) => e.type === event?.value)[0];
                          formik.setFieldValue(`sections[${index}]date`, date);
                          formik.setFieldValue(`sections[${index}]publishing_date`, date);
                          return item;
                        })
                      }}
                    />
                    {formik.touched.schedule && formik.errors.schedule ? (
                      <div className="text-danger mt-2">
                        {formik.errors.schedule}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col sm={3} md={4}>
                  <div>
                    <label className="form-label">
                      {t("Publishing Schedule")}
                      <span className="text-danger"> *</span>
                    </label>
                    <ScheduleType
                      name="publishing_schedule"
                      id="publishing_schedule"
                      dataList={SCHEDULE_OPTIONS_LANG}
                      placeholder={`${t('Select Publishing Schedule')}...`}
                      initialValue={formik.values?.publishing_schedule || SCHEDULE_OPTIONS_LANG[0]}
                      onChangeSelect={(event) => {
                        formik.setFieldValue('publishing_schedule', event);
                        ((formik.values?.sections as any) || [])?.map((item: any, index: number) => {
                          const date = DATE_OPTIONS_LANG?.filter((e: any) => e.type === event?.value)[0];
                          formik.setFieldValue(`sections[${index}]publishing_date`, date);
                          return item;
                        })
                      }}
                    />
                    {formik.touched.publishing_schedule && formik.errors.publishing_schedule ? (
                      <div className="text-danger mt-2">
                        {formik.errors.publishing_schedule}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <label className="form-label required">{t('Category')}
                      {OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value) && <span className="text-danger"> *</span>}
                    </label>
                    <DropdownCategoryKeyword name="category"
                      categoryType={initialValueCategoryType?.value}
                      dataListNaver={listCategoriesNaver}
                      dataListTrendIndex={listCategoriesTrendIndex}
                      initialValue={initialValueCategory ?? undefined}
                      disabled={initialValueCategoryType?.value !== 'trend_index'}
                      onChangeCategory={(event) => {
                        formik.setFieldValue('category', event);
                        formik.setFieldValue('tags', null);
                      }}
                    />
                    {formik.touched.category && formik.errors.category ? (
                      <div className="text-danger mt-2">{formik.errors.category}</div>
                    ) : null}
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <label className="form-label">
                      {t("Classification")}{" "}
                    </label>
                    <DropdownOption
                      name="classification"
                      dataList={listClassifications?.filter((op: any) => op?.type === (initialValueCategoryType as any)?.value) || []}
                      placeholder={`${t("Select Classification")}...`}
                      className="search-filter-classification-type"
                      classNamePrefix="name-prefix"
                      initialValue={initialValueClassification ?? undefined}
                      onChangeSelect={(e: any) => formik.setFieldValue("classification", e)}
                      isHasOptionAll={true}
                      optionAll={{ label: t('----- None Classification -----'), value: '' }}
                    />
                    {formik.touched.classification && formik.errors.classification ? (
                      <div className="text-danger mt-2">
                        {formik.errors.classification}
                      </div>
                    ) : null}
                  </div>
                </Col>

                {/* Begin::List Section */}
                <div className="p-4 mt-0">
                  {(LIST_SECTION || [])?.map((item: any, i: number) => (
                    <Row key={item?.id}
                      className="mb-4 position-relative"
                      style={{
                        padding: '5px 0px',
                        borderRadius: '5px',
                        border: '1px dashed var(--vz-info)',
                        backgroundColor: 'var(--vz-light)',
                      }}
                    >
                      <div className="position-absolute w-auto end-0 top-0"
                        style={{
                          transform: 'translateY(-50%)',
                          padding: '5px 10px',
                          zIndex: 9,
                          fontWeight: 500
                        }}
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          color="light"
                          type="button"
                          onClick={() => handlePreviewTemplate({
                            index: i,
                            is_random_chart: (formik?.values?.sections?.[i] as any)?.is_random_chart,
                            title_template_id: (formik?.values?.sections?.[i] as any)?.title_template_id?.value,
                            body_template_id: (formik?.values?.sections?.[i] as any)?.body_template_id?.value
                          })}
                          disabled={
                            (isPreviewLoading !== null) ||
                            isLoading ||
                            isDisabledPreview ||
                            !(formik?.values?.sections?.[i] as any)?.title_template_id?.value ||
                            !(formik?.values?.sections?.[i] as any)?.body_template_id?.value
                          }
                        >
                          {(isPreviewLoading === i) ? (
                            <Spinner size="sm" style={{ width: '13px', height: '13px' }}></Spinner>
                          ) : (
                            <i className="ri-eye-fill align-bottom"></i>
                          )}
                        </button>
                        {(i > 0) && (<button
                          className="btn btn-sm btn-danger edit-item-btn ms-2"
                          type="button"
                          disabled={(isPreviewLoading !== null) || isLoading}
                          onClick={(e) => { e.preventDefault(); handleRemoveSection(item?.value, i) }}
                        >
                          <i className="ri-close-circle-fill align-bottom "></i>
                        </button>)}
                        {((formik.values?.sections as any)?.length === (i + 1)) && (<button
                          className="btn btn-sm btn-success edit-item-btn ms-2"
                          type="button"
                          disabled={(isPreviewLoading !== null) || isLoading}
                          onClick={(e) => { e.preventDefault(); handleAddSection(item?.value) }}
                        >
                          <i className="ri-add-circle-fill align-bottom "></i>
                        </button>)}
                      </div>
                      <Col sm={12} lg={6} className="mt-3">
                        <div>
                          <label className="form-label">
                            {t("Media")}
                            <span className="text-danger"> *</span>
                          </label>
                          <SearchFilterMedia
                            name={`sections[${i}]media`}
                            id={`sections[${i}]media`}
                            dataList={listMedia}
                            isMulti={false}
                            initialValue={((formik.values?.sections?.[i] as any)?.media as unknown as Option) || undefined}
                            onChangeSelect={(event: any) => {
                              formik.setFieldValue(`sections[${i}]media`, event);
                              const valSections = listMedia?.filter((m) => (String(m?.id) === String(event?.value)))[0]?.partner_codes || [];
                              formik.setFieldValue(`sections[${i}]section_code`, valSections[0] ? ({
                                label: valSections[0]?.section_title,
                                value: String(valSections[0]?.id),
                              }) : null);
                            }}
                          />
                          {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.media ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.media}</div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12} lg={6} className="mt-3">
                        <label className="form-label">{t('Section Title')}</label>
                        <DropdownSectionCodeMediaType
                          name={`sections[${i}]section_code`}
                          dataList={listMedia?.filter((m) => (String(m?.id) === String((formik.values?.sections?.[i] as any)?.media?.value)))[0]?.partner_codes || []}
                          initialValue={(formik?.values?.sections?.[i] as any)?.section_code}
                          onChangeSelect={(event: any) => {
                            formik.setFieldValue(`sections[${i}]section_code`, event)
                          }}
                        />
                        {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.section_code ? (
                          <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.section_code}</div>
                        ) : null}
                      </Col>

                      {OPTIONS_MUST_HAVE_PUBLISHING?.includes((formik.values?.schedule as any)?.value) && (
                        <>
                          <Col sm={6} md={6} lg={6} className="mt-3">
                            <div>
                              <label className="form-label">
                                {t("Creating Date")}{" "}
                                {OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value) && <span className="text-danger"> *</span>}
                              </label>
                              <Select
                                options={DATE_OPTIONS_LANG?.filter((op) => op?.type === (formik.values?.schedule as any)?.value) || []}
                                value={
                                  !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value)
                                    ? null
                                    : (formik.values?.sections?.[i] as any)?.date
                                }
                                name={`sections[${i}]date`}
                                id={`sections[${i}]date`}
                                autocomplete={false}
                                isDisabled={
                                  !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value)
                                }
                                placeholder={`${t("Date")}...`}
                                className="select-schedule"
                                classNamePrefix="name-prefix"
                                onChange={(event: any) => {
                                  formik.setFieldValue(`sections[${i}]date`, event);
                                  formik.setFieldValue(`sections[${i}]publishing_date`, event);
                                }}
                              ></Select>
                              {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.date ? (
                                <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.date}</div>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mt-3">
                            <div>
                              <label className="form-label">
                                {t("Creating Time")}{" "}
                                {(formik.values?.schedule as any)?.value !== "now" && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className="date-picker-wrapper-custom">
                                <DatePicker
                                  className="form-control search"
                                  placeholderText={`${t("Select Time")}...`}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  disabled={((formik.values?.schedule as any)?.value === "now")}
                                  // timeFormat="p"
                                  timeIntervals={5}
                                  autoComplete="off"
                                  // dateFormat="Pp"
                                  isClearable={true}
                                  timeCaption={t("Time")}
                                  timeFormat="HH:mm"
                                  // disabled
                                  value={
                                    (formik.values?.schedule as any)?.value === "now" ? "" : (formik?.values?.sections?.[i] as any)?.time
                                  }
                                  locale={i18n?.language === "ko" ? "ko" : "en"}
                                  name={`sections[${i}]time`}
                                  // Y-MM-DD
                                  onChange={(value: Date | null) => {
                                    formik.setFieldValue(`sections[${i}]time`, value ? moment(new Date(value)).format("HH:mm") : "");
                                    formik.setFieldValue(`sections[${i}]publishing_time`, value ? moment(new Date(value)).format("HH:mm") : "");
                                  }}
                                />
                              </div>
                              {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.time ? (
                                <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.time}</div>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )}
                      {/* Begin::Publishing */}
                      {OPTIONS_MUST_HAVE_PUBLISHING?.includes((formik.values?.publishing_schedule as any)?.value) && (
                        <>
                          <Col sm={6} md={6} lg={6} className="mt-3">
                            <div>
                              <label className="form-label">
                                {t("Publishing Date")}{" "}
                                {OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value) && <span className="text-danger"> *</span>}
                              </label>
                              <Select
                                options={DATE_OPTIONS_LANG?.filter((op) => op?.type === (formik.values?.publishing_schedule as any)?.value) || []}
                                value={
                                  !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value)
                                    ? null
                                    : (formik.values?.sections?.[i] as any)?.publishing_date
                                }
                                name={`sections[${i}]publishing_date`}
                                id={`sections[${i}]publishing_date`}
                                autocomplete={false}
                                isDisabled={
                                  !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value)
                                }
                                placeholder={`${t("Select Publishing Date")}...`}
                                className="select-schedule"
                                classNamePrefix="name-prefix"
                                onChange={(event: any) => {
                                  formik.setFieldValue(`sections[${i}]publishing_date`, event)
                                }}
                              ></Select>
                              {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.publishing_date ? (
                                <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.publishing_date}</div>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mt-3">
                            <div>
                              <label className="form-label">
                                {t("Publishing Time")}{" "}
                                {(formik.values?.publishing_schedule as any)?.value !== "now" && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className="date-picker-wrapper-custom">
                                <DatePicker
                                  className="form-control search"
                                  placeholderText={`${t("Select Publishing Time")}...`}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  disabled={((formik.values?.publishing_schedule as any)?.value === "now")}
                                  // timeFormat="p"
                                  timeIntervals={5}
                                  autoComplete="off"
                                  // dateFormat="Pp"
                                  isClearable={true}
                                  timeCaption={t("Time")}
                                  timeFormat="HH:mm"
                                  // disabled
                                  value={
                                    (formik.values?.publishing_schedule as any)?.value === "now" ? "" : (formik?.values?.sections?.[i] as any)?.publishing_time
                                  }
                                  locale={i18n?.language === "ko" ? "ko" : "en"}
                                  name={`sections[${i}]publishing_time`}
                                  // Y-MM-DD
                                  onChange={(value: Date | null) =>
                                    formik.setFieldValue(
                                      `sections[${i}]publishing_time`,
                                      value
                                        ? moment(new Date(value)).format(
                                          "HH:mm"
                                        )
                                        : ""
                                    )
                                  }
                                />
                              </div>
                              {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.publishing_time ? (
                                <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.publishing_time}</div>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )}
                      {/* End::Publishing */}
                      <Col sm={12} lg={12} className="d-flex justify-content-between align-items-end mt-3">
                        <div style={{ width: "100%" }}>
                          <label className="form-label">
                            {t("Title Template")}
                            <span className="text-danger"> *</span>
                          </label>
                          <SearchTemplate
                            name={`sections[${i}]title_template_id`}
                            templates={listTemplate}
                            type="title"
                            filterByCategoryType={
                              initialValueCategoryType?.value
                            }
                            filterBySchedule={(formik.values?.schedule as any)?.value}
                            placeholder={`${t("Select Title Template")}...`}
                            initialValue={(formik?.values?.sections?.[i] as any)?.title_template_id}
                            onChangeSelect={(event) => {
                              formik.setFieldValue(
                                `sections[${i}]title_template_id`,
                                event
                              );
                            }}
                          />
                          {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.title_template_id ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.title_template_id}</div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12} lg={12} className="d-flex justify-content-between align-items-end mt-3 mb-3">
                        <div style={{ width: "100%" }}>
                          <label className="form-label">
                            {t("Content Template")}
                            <span className="text-danger"> *</span>
                          </label>
                          <SearchTemplate
                            name={`sections[${i}]body_template_id`}
                            templates={listTemplate}
                            type="body"
                            filterByCategoryType={
                              initialValueCategoryType?.value
                            }
                            filterBySchedule={(formik.values?.schedule as any)?.value}
                            placeholder={`${t(
                              "Select Content Template"
                            )}...`}
                            initialValue={(formik?.values?.sections?.[i] as any)?.body_template_id}
                            onChangeSelect={(event) => {
                              formik.setFieldValue(
                                `sections[${i}]body_template_id`,
                                event
                              );
                            }}
                          />
                          {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.body_template_id ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.body_template_id}</div>
                          ) : null}
                          <div className=" w-100 form-check mt-2">
                            <Input
                              className="form-check-input"
                              name={`sections[${i}]is_random_chart`}
                              checked={(formik?.values?.sections?.[i] as any)?.is_random_chart}
                              type="checkbox"
                              id={`sections[${i}]is_random_chart`}
                              onChange={(event: any) => formik.setFieldValue(`sections[${i}]is_random_chart`, Boolean(event?.target.checked))}
                            />
                            <Label className="form-check-label text-muted" style={{ fontSize: '12px' }} htmlFor={`sections[${i}]is_random_chart`}>{t('Randomly apply infographic charts')}</Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
                {/* End::List Section */}

              </div>
            </Col>
            <Col sm={0} md={5} lg={5}>
              <div className="row g-3" style={{ border: '1px solid #f0f0f0', padding: '10px', borderRadius: '5px' }}>
                <Col xxl={12}>
                  <div>
                    <label className="form-label">{t('Keywords')}
                      {OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value) && <span className="text-danger"> *</span>}
                    </label>
                    <SearchFilterKeyword
                      disabled={true}
                      name="keywords" isGetListOptionDefault={false}
                      initialValue={initialValueKeywords || []}
                      categoryType={initialValueCategoryType?.value || 'default'}
                      filterByCategory={String(initialValueCategory?.value || '')}
                      onChangeSelect={(event) => {
                        formik.setFieldValue('keywords', event);
                      }}
                      maxKeyword={20}
                    />
                    {formik.touched.keywords && formik.errors.keywords ? (
                      <div className="text-danger mt-2">{formik.errors.keywords}</div>
                    ) : null}
                  </div>
                </Col>
                <Col xxl={12}>
                  <div>
                    <label className="form-label">{t('Hashtags')}</label>
                    <Hashtag
                      name="tags" placeholder={`${t('Hashtags')}...`}
                      suggestions={initialValueTagsGetFromKeywords || []}
                      initialValue={initialValueTags || []}
                      onChangeTag={(event) => {
                        formik.setFieldValue('tags', event);
                      }}
                      idSelected="hashtags-CampaignFormMulti"
                      maxHashtags={20}
                      typeShowMore={TYPE_SHOW_MORE_HASHTAG.SCROLL}
                      maxHeight="150px"
                    />
                    {formik.touched.tags && formik.errors.tags ? (
                      <div className="text-danger mt-2">{formik.errors.tags}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-sm-center justify-content-md-end">
                    <button className="btn btn-success fs-14" color="light" type="button" onClick={onSaveClick} disabled={isLoading || (isPreviewLoading !== null)} >
                      {(isLoading && (initialValueActionType === ACTION_TYPE_CAMPAIGN.SAVE)) ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-login-circle-line align-bottom me-1"></i>}
                      {t('Button Save')}
                    </button>
                    <button className="btn btn-primary fs-14" color="success" type="button" onClick={onPostClick} disabled={isLoading || (isPreviewLoading !== null)}>
                      {(isLoading && (initialValueActionType === ACTION_TYPE_CAMPAIGN.POST)) ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-send-plane-line align-bottom me-1"></i>}
                      {t('Button Post')}
                    </button>
                  </div>
                </Col>
              </div >
            </Col >
          </Row >
        </form >
        {/* End:: Step 2 - Create Campaign */}
        {/* Begin:: Step 3 - Create Campaign */}
        <div className={`${stepAction === 3 ? '' : 'd-none'}`}>
          <Col sm={12} lg={12}>
            <div className="row g-3" style={{ border: '1px solid #f0f0f0', padding: '10px', borderRadius: '5px' }}>
              <div id="pills-experience" className="tab-pane active">
                <div className="text-center">
                  <div className="avatar-md mt-5 mb-4 mx-auto">
                    <div className="avatar-title bg-light text-success display-4 rounded-circle">
                      <i className="ri-checkbox-circle-fill"></i></div>
                  </div>
                  <h5>{t('Well Done !')}</h5>
                  <p className="text-muted">{t('You have Successfully Created Campaign')}</p>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* End:: Step 3 - Create Campaign */}
      </Row >
      {/* Begin::modal */}
      < ToastContainer closeButton={false} limit={1} />
      <Modal isOpen={isOpenPreview} centered={true} size="xl" toggle={onClosePreviewClick} keyboard={true} scrollable={true}>
        <ModalHeader toggle={(isPreviewLoading !== null) ? () => { } : onClosePreviewClick}>
          {t('Template Preview')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col xxl={12} className="ribbon-box right">
                    {!!previewTemplate?.memo?.errors?.length && (
                      <div className="ribbon ribbon-danger round-shape top-0" style={{
                        zIndex: 9,
                        background: 'rgb(255 255 255 / 67%)',
                        color: '#f06548',
                        borderRadius: 0,
                        maxHeight: '30dvh',
                        overflowY: 'auto',
                      }}>
                        <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(previewTemplate?.memo?.errors || [])?.map((mess: any) => (
                          <>{`${mess}`}<br /> </>
                        ))}
                        </span>
                      </div>
                    )}
                    <div className="bg-primary-subtle position-relative"><div className="p-5 card-body"><div className="text-center">
                      <h3 className="fw-semibold">{previewTemplate?.title || ''}</h3>
                    </div>
                    </div>
                      <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1440" height="60" preserveAspectRatio="none" viewBox="0 0 1440 60"><g mask="url(&quot;#SvgjsMask1001&quot;)" fill="none"><path d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z" style={{ fill: 'var(--vz-secondary-bg)' }}></path></g><defs><mask id="SvgjsMask1001"><rect width="1440" height="60" fill="#ffffff"></rect></mask></defs></svg>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div className="clear-css-p-in-box" dangerouslySetInnerHTML={{ __html: previewTemplate?.content || '' }}></div>
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-light fs-14" type="button" color="light" onClick={onClosePreviewClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenCreateCategory} centered={true} size="md" toggle={onCloseCreateCategoryClick} keyboard={true}>
        <ModalHeader toggle={isCreateCategoryLoading ? () => { } : onCloseCreateCategoryClick}>
          {t('Button Create Category')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <form onSubmit={formikModal.handleSubmit}>
                <div className="row g-3">
                  <div className="mt-4" style={{ border: '1px solid #f0f0f0', padding: '10px', borderRadius: '5px' }}>
                    <div className="d-flex align-items-end">
                      <div className="w-100 mb-1">
                        <label className="form-label">{t('Category parent')}  <span className="text-danger"> *</span></label>
                        <DropdownCategoryKeyword
                          name="parent_id"
                          categoryType={'trend_index'}
                          dataListNaver={[]}
                          dataListTrendIndex={[OPTION_CATEGORY_ROOT, ...listCategoriesTrendIndex]}
                          initialValue={initialValueModalCategory ?? undefined}
                          placeholder={`${t('Category parent')}...`}
                          onChangeCategory={(event) => {
                            formikModal.setFieldValue('parent_id', event);
                            formikModal.setFieldValue('sub1', '');
                            formikModal.setFieldValue('sub2', '');
                            formikModal.setFieldValue('sub3', '');
                            setTotalCategorySubShow((prev) => Number(event?.depth || 0) + (event?.depth === null ? 1 : 2));
                          }}
                        />
                        {formikModal.touched.parent_id && formikModal.errors.parent_id ? (
                          <div className="text-danger mt-2">{formikModal.errors.parent_id}</div>
                        ) : null}
                      </div>
                    </div>
                    {([null]?.includes(initialValueModalCategory?.depth) && totalCategorySubShow <= 3) && (<div className="d-flex align-items-start mb-1">
                      <div className="w-100">
                        <Input
                          type="text"
                          id="sub_1"
                          name="sub_1"
                          autocomplete={false}
                          value={String(initialValueModalSub1 ?? '')}
                          placeholder={`${t('Category Name Depth 1')}...`}
                          onChange={(event: any) => formikModal.setFieldValue('sub1', event?.target?.value || '')}
                        />
                        {formikModal.touched.sub_1 && formikModal.errors.sub_1 ? (
                          <div className="text-danger mt-2">{formikModal.errors.sub_1}</div>
                        ) : null}
                      </div>
                      <div className="w-auto ms-2">
                        <button type="button" className="btn btn-primary fs-14" disabled={(totalCategorySubShow === 2)} onClick={() => setTotalCategorySubShow((prev) => 2)}>
                          {<i className="ri-add-fill align-bottom "></i>}
                        </button>
                      </div>
                    </div>)}
                    {([null, 0]?.includes(initialValueModalCategory?.depth) && totalCategorySubShow >= 2) && (<div className="ms-4 d-flex align-items-start mb-1">
                      <div className="w-100">
                        <Input
                          type="text"
                          id="sub_2"
                          name="sub_2"
                          autocomplete={false}
                          value={String(initialValueModalSub2 ?? '')}
                          placeholder={`${t('Category Name Depth 2')}...`}
                          onChange={(event: any) => formikModal.setFieldValue('sub2', event?.target?.value || '')}
                        />
                        {formikModal.touched.sub_2 && formikModal.errors.sub_2 ? (
                          <div className="text-danger mt-2">{formikModal.errors.sub_2}</div>
                        ) : null}
                      </div>
                      <div className="w-auto ms-2 d-flex">
                        {(totalCategorySubShow >= 2) && <button
                          type="button"
                          className="btn btn-danger fs-14"
                          disabled={initialValueModalCategory?.depth === 0}
                          onClick={() => setTotalCategorySubShow((prev) => 1)}>
                          <i className="ri-indeterminate-circle-line align-bottom "></i>
                        </button>}
                        {(totalCategorySubShow <= 2) && <button type="button" className="btn btn-primary fs-14 ms-2" onClick={() => setTotalCategorySubShow((prev) => 3)}>
                          {<i className="ri-add-fill align-bottom "></i>}
                        </button>}
                      </div>
                    </div>)}
                    {([null, 0, 1]?.includes(initialValueModalCategory?.depth) && totalCategorySubShow >= 3) && (<div className="ps-4 ms-4 d-flex align-items-start mb-1">
                      <div className="w-100">
                        <Input
                          type="text"
                          id="sub_3"
                          name="sub_3"
                          autocomplete={false}
                          value={String(initialValueModalSub3 ?? '')}
                          placeholder={`${t('Category Name Depth 3')}...`}
                          onChange={(event: any) => formikModal.setFieldValue('sub3', event?.target?.value || '')}
                        />
                        {formikModal.touched.sub_3 && formikModal.errors.sub_3 ? (
                          <div className="text-danger mt-2">{formikModal.errors.sub_3}</div>
                        ) : null}
                      </div>
                      <div className="w-auto ms-2">
                        <button
                          type="button"
                          disabled={initialValueModalCategory?.depth === 1}
                          className="btn btn-danger fs-14"
                          onClick={() => setTotalCategorySubShow((prev) => 2)}>
                          <i className="ri-indeterminate-circle-line align-bottom "></i>
                        </button>
                      </div>
                    </div>)}
                    <div className="d-flex justify-content-end mt-2">
                      <button className="btn btn-light fs-14 me-2" type="button" color="light" onClick={onCloseCreateCategoryClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                      <button
                        type="submit"
                        disabled={isCreateCategoryLoading || initialValueModalCategory?.depth === 2 || (
                          (([null]?.includes(initialValueModalCategory?.depth) && totalCategorySubShow <= 3) && String(initialValueModalSub1 || '').trim() === '') ||
                          (([null, 0]?.includes(initialValueModalCategory?.depth) && totalCategorySubShow >= 2) && String(initialValueModalSub2 || '').trim() === '') ||
                          (([null, 0, 1]?.includes(initialValueModalCategory?.depth) && totalCategorySubShow >= 3) && String(initialValueModalSub3 || '').trim() === '')
                        )}
                        className="btn btn-success d-none d-md-block"
                      >
                        {isCreateCategoryLoading ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-add-fill align-bottom me-1"></i>}
                        {t('Button Create Category')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <ModalConfirm
        textButtonConfirm={t('Button Post')}
        classButtonConfirm='btn-primary'
        classIconButtonConfirm='ri-send-plane-line'
        header={t('Campaign Create')}
        title={t('Are you sure you want to post this?')}
        content={t('After confirming the post action, the data will be created. Do you want to proceed with the confirming action.')}
        isShowIcon={false}
        isOpen={isOpenConfirmCreate}
        onClose={onCloseConfirmCreateClick}
        onConfirm={handleCreateCampaign}
      />
      {/* End::modal */}
    </React.Fragment >
  );
};

export default CampaignFormMultiByKeyword;