import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import { typeSearchMovie, } from "helpers/constans";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { importMoviesByNormal, getMovies as onGetMovies } from "../../../store/thunks";
import { useRole } from "components/Hooks/UserHooks";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'sales': StringParam,
  'audience': StringParam,
  'screen': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'audience';
registerLocale('en', en);
registerLocale('ko', ko);

function getPreviousDay(date = new Date()) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return previous;
}

const PREVIOUS_DATE_DEFAULT = `${moment(getPreviousDay()).format("Y-MM-DD")}`;

const MovieKeyword = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    date_version: withDefault(StringParam, PREVIOUS_DATE_DEFAULT),
    title: StringParam,
    ...typeQuery
  });

  const typeSearchLang = typeSearchMovie?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const [typeSelect, setTypeSelect] = useState(typeSearchLang?.find((e: any) => e.value === query.sort_by));

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<string>(query?.date_version || PREVIOUS_DATE_DEFAULT);
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");

  const [show, setShow] = useState(false);

  const [isImportMovieLoading, setIsImportMovieLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setIsImportMovieLoading((_prev) => true);
      const response: any = await importMoviesByNormal(values);
      if (response?.data) {
        toast(`${t('Import data process successful')}...`, CONFIG_OPTION_TOAST_NORMAL);
        setShow((_prev) => false);
        resetData();
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
      setIsImportMovieLoading((_prev) => false);
    } catch (error) {
      setIsImportMovieLoading((_prev) => false);
      return error;
    }
  };

  const validationSchema = Yup.object({
    file: Yup.mixed().required(`${t('Please select a file')}`).test('fileFormat', `${t('Unsupported file format')}`, (value: any) => {
      if (value) {
        return (
          value.type === 'application/vnd.ms-excel' ||
          value.type === 'text/csv' ||
          value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      }
      return false;
    }),
    date_version: Yup.string().required(`${t('Date version is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      file: null,
      date_version: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  function onCloseClick() {
    setShow((_prev) => !_prev);
    formik.setErrors({});
  }

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Movie;

  const movieProperties = createSelector(
    selectLayoutState,
    (state) => ({
      movies: state.movies,
      isMovieSuccess: state.isMovieSuccess,
      isMovieLoading: state.isMovieLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { movies, error, isMovieLoading } = useSelector(movieProperties);

  useEffect(() => {
    dispatch(onGetMovies(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    const rangeQuery = (fromRangeSearch && toRangeSearch) ? `gt:${fromRangeSearch}-lt:${toRangeSearch}` : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      title: keywordSearch || undefined,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      date_version: dateSearch,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      [typeSelect?.value]: undefined,
      category_id: undefined,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
    setTypeSelect(typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT));
    setFromRangeSearch("");
    setToRangeSearch("");
    setDateSearch(PREVIOUS_DATE_DEFAULT);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date Movie'),
        accessor: "date_version",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Naver Search'),
        accessor: "naver_search_monthly",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Audience'),
        accessor: "audience",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Title Movie'),
        accessor: "title",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <span>{cell?.value}</span>
            </>
          )
        },
      },
      {
        Header: t('Open Date'),
        accessor: "open_date",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Sales'),
        accessor: "sales",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Sales Rate'),
        accessor: "sales_rate",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Screen'),
        accessor: "screen",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Representative Nationality'),
        accessor: "representative_nationality",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Nationality'),
        accessor: "nationality",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.find((e: any) => e.value === typeSelect?.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Keyword')} - ${t('Movie Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Movie Keyword')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.MOVIE_KEYWORD_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => setShow(true)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          {t('Button Import')}
                        </button>
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mb-2">
                      <Col sm={4} lg={4} className="date-picker-wrapper-custom">
                        <DatePicker
                          className="form-control search"
                          placeholderText={`${t('Date Movie')}...`}
                          value={dateSearch || ''}
                          dateFormat="yyyy-MM-dd"
                          isClearable={true}
                          maxDate={getPreviousDay()}
                          locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                          onChange={handleChangePicker}
                        />
                      </Col>
                      <Col sm={8} lg={4}>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('Title Movie')}...`}
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center">
                      {/* type search */}
                      <Col sm={4} lg={4}>
                        <Select
                          options={typeSearchLang}
                          value={typeSelect}
                          onChange={(e: any) => setTypeSelect(e)}
                          placeholder={<div>{`${t('Search by field')}...`}</div>}
                          loadingMessage={() => (<div>{t('Loading')}...</div>)}
                          noOptionsMessage={() => (<div>{t('No Options')}</div>)}
                        />
                      </Col>
                      <Col sm={4} lg={2}>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('From Range')}...`}
                          value={fromRangeSearch}
                          onChange={(e) => setFromRangeSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                      <Col sm={4} lg={2}>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('To Range')}...`}
                          value={toRangeSearch}
                          onChange={(e) => setToRangeSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                      <Col sm={12} lg={4} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isMovieLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                        {isHavePermissionRole(ROLES_FOR_APP.MOVIE_KEYWORD_IMPORT, userPermissions) && (
                          <button
                            type="button"
                            className="btn btn-success d-none d-md-block"
                            onClick={() => setShow(true)}
                          >
                            <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                            {t('Button Import')}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={movies?.list?.length ? movies?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={movies?.pagination?.total}
                        customPageCount={Math.ceil(Number(movies?.pagination?.total) / Number(movies?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isMovieLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={show} centered={true}>
          <ModalHeader toggle={isImportMovieLoading ? () => { } : onCloseClick}></ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <div>
                    <label htmlFor="file" className="form-label">{t('File')}<span className="text-danger"> *</span></label>
                    <Input
                      type="file"
                      id="file"
                      name="file"
                      accept=".csv, .excel, .sheet, .xlsx"
                      style={{ display: 'none' }}
                      placeholder={`${t('File')}...`}
                      onChange={(event: any) => {
                        formik.setFieldValue('file', event?.currentTarget?.files[0]);
                      }}
                    />
                    <div className="form-icon right">
                      <label
                        className="form-control form-control-icon"
                        htmlFor="file"
                        id="file-name"
                        style={{ color: '#8d8f9d' }}
                      >
                        {
                          //@ts-ignore
                          formik?.values?.file?.name || `${t('Choose File')}...`
                        }
                        <i className=" ri-attachment-line"></i>
                      </label>
                    </div>
                    {formik.touched.file && formik.errors.file ? (
                      <div className="text-danger mt-2">{formik.errors.file}</div>
                    ) : null}
                  </div>
                </Col>
                <Col xxl={12}>
                  <div>
                    <label htmlFor="date_version" className="form-label">{t('Year Version')}<span className="text-danger"> *</span></label>
                    <div className="date-picker-wrapper-custom">
                      <DatePicker
                        className="form-control search"
                        placeholderText={`${t('Date Movie')}...`}
                        value={formik.values.date_version}
                        dateFormat="yyyy-MM-dd"
                        isClearable={true}
                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                        onChange={(value: Date | null) => formik.setFieldValue('date_version', value ? moment(new Date(value)).format("Y-MM-DD") : '')}
                      />
                    </div>

                    {formik.touched.date_version && formik.errors.date_version ? (
                      <div className="text-danger mt-2">{formik.errors.date_version}</div>
                    ) : null}
                  </div>
                </Col>
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button className="btn btn-secondary fs-14" color="light" onClick={onCloseClick} disabled={isImportMovieLoading}>
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t('Button Close')}
                    </button>
                    <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isImportMovieLoading}>
                      {isImportMovieLoading ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-file-download-line align-bottom me-1"></i>}
                      {t('Button Import')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default MovieKeyword;
