import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICar, IModel, IBrand, ICategoryCar } from "./types/_car";
import { PaginationResponse, ResponseApi, ResponseData } from "./types/_public";
const api = new APIClient();

const path = "/cars";
const carApi = {
  models(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IModel[]> & PaginationResponse, any>> {
    const url = `${path}/series`;
    return api.get(url, params);
  },
  allModels(params: any = {}) {
    const url = `${path}/series/all`;
    return api.get(url, params);
  },
  brands(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IBrand[]> & PaginationResponse, any>> {
    const url = `${path}/brands`;
    return api.get(url, params);
  },
  allBrands(params: any = {}) {
    const url = `${path}/brands/all`;
    return api.get(url, params);
  },

  allCarType(params: any = {}) {
    const url = `${path}/categories/all`;
    return api.get(url, params);
  },
  categories(
    params: any = {}
  ): Promise<
    AxiosResponse<ResponseData<ICategoryCar[]> & PaginationResponse, any>
  > {
    const url = `/categories`;
    return api.get(url, params);
  },
  cars(
    params: any
  ): Promise<AxiosResponse<ResponseData<ICar[]> & PaginationResponse, any>> {
    const url = `${path}/models`;
    return api.get(url, params);
  },
  searchCategories(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<ICategoryCar[]>, any>> {
    const url = `masters/categories`;
    return api.get(url, params);
  },
  importCars(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<ICar[]> & PaginationResponse, any>> {
    const formData = new FormData();
    formData.append("date_version", dataForm?.date_version || "");
    formData.append("files", dataForm?.file ? (dataForm?.file as File) : "");
    const url = `${path}/import`;
    return api.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ms-blob-type": "BlockBlob",
      },
    });
  },

  updateCarKeyword(id: any, dataForm: any): Promise<AxiosResponse<any, any>> {
    const url = `/keywords/${id}`;
    return api.put(url, dataForm);
  },
};
export default carApi;
