import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import mediaApi from "api/mediaApi";
import { formatQueryParams } from "helpers/format";

export const getMedias = createAsyncThunk(
  "Medias",
  async (params: any = {}) => {
    try {
      const response = await mediaApi.medias(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getNotifications = createAsyncThunk(
  "Notifications",
  async (params: any = {}) => {
    try {
      const response = await mediaApi.notifications(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getRevenue = createAsyncThunk(
  "Revenue",
  async (params: any = {}) => {
    try {
      const response = await mediaApi.revenue(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAdsAccount = createAsyncThunk(
  "AdsAccount",
  async (params: any = {}) => {
    try {
      const response = await mediaApi.adsAccount(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAdsAccountHistory = createAsyncThunk(
  "AdsAccountHistory",
  async (params: any = {}) => {
    try {
      const response = await mediaApi.adsAccountHistory(
        formatQueryParams(params)
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllMedias = async (params: any = {}) => {
  try {
    const response = await mediaApi.allMedias(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postMedia = async (data: any = {}) => {
  try {
    const response = await mediaApi.postMedia(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putMedia = async (id: string | number, data: any = {}) => {
  try {
    const response = await mediaApi.putMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getConfigMediaById = async (id: any) => {
  try {
    const response = await mediaApi.getConfig(id);
    return response;
  } catch (error) {
    return error;
  }
};

export const postNotificationMedia = async (data: any = {}) => {
  try {
    const response = await mediaApi.postNotificationMedia(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putNotificationMedia = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await mediaApi.putNotificationMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAccountMedia = async (id: string | number, data: any = {}) => {
  try {
    const response = await mediaApi.putAccountMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateConfigMedia = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await mediaApi.updateConfig(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteNotificationMedia = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await mediaApi.deleteNotificationMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMedia = async (id: string | number, data: any = {}) => {
  try {
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllTypes = async (params: any = {}) => {
  try {
    const response = await mediaApi.allTypes(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const refreshKey = async (id: string | number, params: any = {}) => {
  try {
    const response = await mediaApi.refreshKey(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllAdsAgency = async (params: any = {}) => {
  try {
    const response = await mediaApi.allAdsAgency(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const addPartnerAdsAccountRevenueRate = async (data: any = {}) => {
  try {
    const response = await mediaApi.postPartnerAdsAccountRevenueRate(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePartnerAdsAccountRevenueRate = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await mediaApi.putPartnerAdsAccountRevenueRate(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAdsAccountNormal = async (params: any = {}) => {
  try {
    const response = await mediaApi.adsAccountNormal(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAdsAccountRevenueRate = async (params: any = {}) => {
  try {
    const response = await mediaApi.adsAccountRevenueRate(
      formatQueryParams(params)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAdsAccountRevenueRate = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await mediaApi.deleteAdsAccountRevenueRate(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
