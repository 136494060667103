import { ICampaignGroup } from 'api/types/_campaign';
import { IMedia, IType } from 'api/types/_media';
import { Option } from 'api/types/_public';
import { ITemplate } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import CollapseTag from 'components/Common/CollapseTag';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownOption from 'components/Common/DropdownOption';
import DropdownStatusCampaign from 'components/Common/DropdownStatusCampaign';
import HashtagInput from 'components/Common/HashtagInput';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { COLORS_STATUS_CAMPAIGN_GROUP, SCHEDULE_MONTHLY_OPTIONS, SCHEDULE_OPTIONS, SCHEDULE_WEEKLY_OPTIONS, STATUS_CAMPAIGN_GROUP, STATUS_CAMPAIGN_GROUP_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import CampaignFormByKeywordAnalyzing from 'pages/Campaign/CampaignForm/CampaignFormByKeywordAnalyzing';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { ROUTES } from 'routes/allRoutes';
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import TableContainer from "../../../../components/Common/TableContainer";
import { deleteCampaignGroup, exportFileCampaign, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes, getCampaignGroups as onGetCampaignGroups, putCampaignGroupMultiMedia } from "../../../../store/thunks";
import LabelWrapper from 'components/Common/LabelWrapper';

const typeQuery = {
  'sales': StringParam,
  'audience': StringParam,
  'screen': StringParam,
  'status': StringParam,
  'schedule': StringParam,
  'date': StringParam,
  'publishing_schedule': StringParam,
  'publishing_date': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'audience';


const CampaignListByKeywordAnalyzing = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    // sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    // order_by: withDefault(StringParam, `DESC`),
    name: withDefault(StringParam, ''),
    id: withDefault(StringParam, ''),
    type: withDefault(StringParam, ''),
    keyword_ids: withDefault(StringParam, ''),
    hashtags: withDefault(StringParam, ''),
    partner_ids: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
    ...typeQuery
  });

  const CATEGORY_TYPE_CREATE_BY_FORM_MANUAL = ['keyword_manual', 'keyword_popular', 'keyword_core'];

  const SCHEDULE_OPTIONS_LANG = [...SCHEDULE_OPTIONS, { value: 'one_time', label: 'One Time' }]?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_CAMPAIGN_GROUP_OPTIONS_LANG = STATUS_CAMPAIGN_GROUP_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const MONTHLY_OPTIONS = SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({ ...item, type: "monthly", })) || [];
  const WEEKLY_OPTIONS = SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({ ...item, type: "weekly", })) || [];
  const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map((item: any) => ({ ...item, label: t(item?.label) }));

  const rangeValue = useRef<any>();
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<ICampaignGroup | null>(null);

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [isOpenConfirmUpdateStatusCampaign, setIsConfirmUpdateStatusCampaign] = useState<boolean>(false);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date || "", 'Y-MM-DD').toDate() : '', query?.end_date ? moment(query?.end_date || "", 'Y-MM-DD').toDate() : '']);

  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");

  const [noSearch, setNoSearch] = useState<string>(query?.id || "");

  const [campaignEdit, setCampaignEdit] = useState<ICampaignGroup & { isCopy?: boolean } | null>(null);

  const TYPE_CAMPAIGN_FORM = campaignEdit?.type === 'keyword_manual' ? 'MANUAL_KEYWORD' : 'POPULAR_KEYWORD';

  const TITLE_CREATE = (TYPE_CAMPAIGN_FORM === 'MANUAL_KEYWORD') ? t('Create Campaign (Manual Keyword)') : t('Create Campaign (Popular Keyword)');

  const TITLE_UPDATE = (TYPE_CAMPAIGN_FORM === 'MANUAL_KEYWORD') ? t('Update Campaign (Manual Keyword)') : t('Update Campaign (Popular Keyword)');

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [scheduleTypeSearch, setScheduleTypeSearch] = useState<Option | null>(SCHEDULE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.schedule))[0]);

  const [dateTypeSearch, setDateTypeSearch] = useState<Option | null>(DATE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.date))[0]);;

  const [schedulePublishingTypeSearch, setSchedulePublishingTypeSearch] = useState<Option | null>(SCHEDULE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.publishing_schedule))[0]);

  const [datePublishingTypeSearch, setDatePublishingTypeSearch] = useState<Option | null>(DATE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.publishing_date))[0]);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_CAMPAIGN_GROUP_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);

  const [keywordsSearch, setKeywordsSearch] = useState<Option[]>([]);

  const [hashtagsSearch, setHashtagsSearch] = useState<Option[]>([]);

  // Begin::List Option Edit Campaign
  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listClassifications, setListClassifications] = useState<Option[]>([]);

  const listCategoriesTypeLang = listCategoriesType?.reduce((arr: any, item: any) => {
    if (CATEGORY_TYPE_CREATE_BY_FORM_MANUAL?.includes(item?.value)) {
      return ([...arr, ({
        ...item,
        label: t(`Category_Type_[${item?.value}]_For_Campaign`),
      })]);
    }
    return arr;
  }, []) || [];
  // End::List Option Edit Campaign

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.id || ''}`
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Campaign;

  const campaignGroupProperties = createSelector(
    selectLayoutState,
    (state) => ({
      campaignGroups: state.campaignGroups,
      isCampaignGroupSuccess: state.isCampaignGroupSuccess,
      isCampaignGroupLoading: state.isCampaignGroupLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { campaignGroups, isCampaignGroupLoading } = useSelector(campaignGroupProperties);

  useEffect(() => {
    dispatch(onGetCampaignGroups(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      id: noSearch || undefined,
      name: keywordSearch || undefined,
      // sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      type: categoryTypeSearch?.value || '',
      status: statusSearch?.value || '',
      schedule: scheduleTypeSearch?.value || '',
      date: dateTypeSearch?.value ?? '',
      publishing_schedule: schedulePublishingTypeSearch?.value || '',
      publishing_date: datePublishingTypeSearch?.value ?? '',
      keyword_ids: (keywordsSearch || [])?.map((item) => item?.value).join(',') || '',
      hashtags: (hashtagsSearch || [])?.map((item) => item?.value).join(',') || '',
      partner_ids: mediaSearch?.value || '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      id: undefined,
      name: undefined,
      // sort_by: undefined,
      type: undefined,
      status: undefined,
      schedule: undefined,
      date: undefined,
      publishing_schedule: undefined,
      publishing_date: undefined,
      keyword_ids: undefined,
      hashtags: undefined,
      partner_ids: undefined,
      start_date: undefined,
      end_date: undefined,
      time_request: + new Date()
    }, "push");
    setNoSearch("");
    setKeywordSearch("");
    setDateSearch(["", ""]);
    setCategoryTypeSearch(null);
    setScheduleTypeSearch(null);
    setDateTypeSearch({ label: t('All Publishing Date'), value: '' });
    setSchedulePublishingTypeSearch(null);
    setDatePublishingTypeSearch({ label: t('All Publishing Date'), value: '' });
    setStatusSearch(null);
    setKeywordsSearch([]);
    setHashtagsSearch([]);
    setMediaSearch({ label: t('All Media'), value: '' });
  };

  const handleExportCampaign = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await exportFileCampaign();
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'campaign';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "name",
        filterable: false,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const keywords = [cell?.value];
          return (<div style={{ minWidth: '100px' }}><CollapseTag totalShow={10} tags={keywords} isShowModal={true} titleModal={t('Keywords')} /></div>);
        }
      },
      {
        Header: t('Category Type'),
        accessor: "type",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '130px' }} className="text-center">{cell?.value ? t(`Category_Type_[${cell?.value}]_For_Campaign`) : ''}</div>
          </>
        ),
      },
      {
        Header: t('Media'),
        accessor: "partners",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const partners = value?.partners;
          let partnerName: string = "";
          for (let item of partners) {
            partnerName += `<span class='tag-style'>${item.name} </span>`;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: partnerName }} style={{ minWidth: '150px' }} />
          );
        }
      },
      {
        Header: t('Creating Schedule'),
        accessor: "schedule",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          let day = item?.date || '';
          let time = item?.time || '';
          if (item?.schedule === 'weekly') {
            day = SCHEDULE_WEEKLY_OPTIONS?.filter((it: any) => String(it?.value) === String(item?.date))[0]?.label || '';
          } else if (item?.schedule === 'monthly') {
            day = SCHEDULE_MONTHLY_OPTIONS?.filter((it: any) => String(it?.value) === String(item?.date))[0]?.label || '';
          }
          const classDay = t(`${day}`) === t('Saturday') ? 'text-primary' : (t(`${day}`) === t('Sunday') ? 'text-danger' : '');
          return (
            <>
              {item?.type === 'keyword_manual' ? (
                <div style={{ minWidth: i18n?.language === 'en' ? '200px' : '150px' }} className="text-center">
                  {SCHEDULE_OPTIONS_LANG?.filter((op: any) => (String(op?.value) === String(item?.schedule)))[0]?.label || ''}
                  {day ? <> - <span className={classDay}>{t(`${day}`)} </span></> : day}
                  {time ? (' - ' + time + '') : ''}
                </div>) : (<div style={{ minWidth: i18n?.language === 'en' ? '200px' : '150px' }} className="text-center">-</div>)
              }
            </>
          );
        },
      },
      {
        Header: t('Publishing Schedule'),
        accessor: "publishing_schedule",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          let day = item?.publishing_date || '';
          let time = item?.publishing_time || '';
          if (item?.publishing_schedule === 'weekly') {
            day = SCHEDULE_WEEKLY_OPTIONS?.filter((it: any) => String(it?.value) === String(item?.publishing_date))[0]?.label || '';
          } else if (item?.publishing_schedule === 'monthly') {
            day = SCHEDULE_MONTHLY_OPTIONS?.filter((it: any) => String(it?.value) === String(item?.publishing_date))[0]?.label || '';
          }
          const classDay = t(`${day}`) === t('Saturday') ? 'text-primary' : (t(`${day}`) === t('Sunday') ? 'text-danger' : '');
          return (
            <>
              <div style={{ minWidth: i18n?.language === 'en' ? '200px' : '150px' }} className="text-center">
                {SCHEDULE_OPTIONS_LANG?.filter((op: any) => (String(op?.value) === String(item?.publishing_schedule)))[0]?.label || ''}
                {day ? <> - <span className={classDay}>{t(`${day}`)} </span></> : day}
                {time ? (' - ' + time + '') : ''}
              </div>
            </>
          );
        },
      },
      {
        Header: t('Schedule at'),
        accessor: "schedule_at",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              {cell?.value ? <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span> : '-'}
            </div>
          )
        },
      },
      {
        Header: t('Publishing schedule at'),
        accessor: "publishing_schedule_at",
        filterable: true,
        sortable: false,
        thWidth: 180,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              {cell?.value ? <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span> : '-'}
            </div>
          )
        },
      },
      {
        Header: t('Status'),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          const color = COLORS_STATUS_CAMPAIGN_GROUP[Number(status)];
          return (<div style={{ minWidth: '60px' }} className="text-center"><span className={`badge bg-${color}`}>{`${t(`Status_Campaign_Group_${status}`)}`}</span></div>);
        }
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0 w-100 justify-content-end">
              {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_UPDATE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Update Status Campaign')}
                  id={`reset-status-ns-${item?.id}`}
                >
                  <li className="list-inline-item me-0" id={`refresh-ns-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleUpdateStatusCampaign(item) }}
                    >
                      <i className={`${item?.status === STATUS_CAMPAIGN_GROUP.IN_PROGRESS ? 'ri-toggle-fill' : 'ri-toggle-line'} align-bottom`}></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_UPDATE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Update Campaign')}
                  id={`update-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`update-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCampaignEdit((prev) => item)
                      }}>
                      <i className="ri-pencil-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole([ROLES_FOR_APP.CAMPAIGN_COPY, ROLES_FOR_APP.CAMPAIGN_CREATE], userPermissions) && (
                <TooltipCustom
                  title={t('Button Copy Campaign')}
                  id={`copy-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`copy-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); setCampaignEdit((prev) => ({ ...item, isCopy: true })) }}
                    >
                      <i className="ri-file-copy-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
              {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete Campaign')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: ICampaignGroup) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCampaignGroup(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetCampaignGroups(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const handleActionUpdateStatusCampaign = async () => {
    try {
      if (initialValuesDefault?.id) {
        setIsConfirmLoading((_prev) => true);
        const response: any = await putCampaignGroupMultiMedia(initialValuesDefault?.id || '',
          {
            status: initialValuesDefault?.status === STATUS_CAMPAIGN_GROUP.IN_PROGRESS ? STATUS_CAMPAIGN_GROUP.STOP : STATUS_CAMPAIGN_GROUP.IN_PROGRESS
          });
        if (response?.code === 200) {
          dispatch(onGetCampaignGroups(query));
          setIsConfirmLoading((_prev) => false);
          setIsConfirmUpdateStatusCampaign((_prev) => false);
          toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        } else {
          setIsConfirmLoading((_prev) => false);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleUpdateStatusCampaign = (item: ICampaignGroup) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirmUpdateStatusCampaign((_prev) => true);
  }

  const onCloseConfirmUpdateStatusCampaignClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirmUpdateStatusCampaign((_prev) => false);
  }
  // End::Reset-status


  // Begin::Update Campaign
  const onCloseClick = () => {
    setCampaignEdit((_prev) => null);
  }

  //End::Update Campaign

  const handleCallAllOption = async () => {
    try {
      const [
        resMedia,
        resTemplate,
        resType,
        resCategoriesType,

      ]: any = await Promise.all([
        getAllMedias(),
        getAllTemplates(),
        getAllTypes(),
        getAllCategoriesType(),
      ]);
      setListTemplate((_prev) => resTemplate?.data || []);
      setListType((_prev) => resType?.data || []);
      setListCategoriesType(
        (_prev) =>
          resCategoriesType?.data?.map((item: any) => ({
            value: item?.type,
            label: item?.name,
          })) || []
      );
      // Begin::Classification options
      setListClassifications((_prev) => resCategoriesType?.data?.reduce((arr: any, item: any) => {
        const sub: any = (item?.classifications || [])?.map((it: any) => ({ label: it, value: it, type: item?.type }));
        return arr.concat(sub);
      }, []));
      // End::Classification options

      const list = ([{ name: t('All Media'), id: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.id || '') === String(query?.partner_ids || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetCampaignGroups(query));
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    if (scheduleTypeSearch?.value) {
      setScheduleTypeSearch((_prev: any) => SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === scheduleTypeSearch?.value)[0]);
    }


    setDateTypeSearch((_prev: any) => DATE_OPTIONS_LANG?.filter((item) => ((item?.type === scheduleTypeSearch?.value) && (dateTypeSearch?.value === item?.value)))[0]);


    if (schedulePublishingTypeSearch?.value) {
      setSchedulePublishingTypeSearch((_prev: any) => SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === schedulePublishingTypeSearch?.value)[0]);
    }


    setDatePublishingTypeSearch((_prev: any) => DATE_OPTIONS_LANG?.filter((item) => ((item?.type === schedulePublishingTypeSearch?.value) && (datePublishingTypeSearch?.value === item?.value)))[0]);


    if (statusSearch?.value) {
      setStatusSearch((_prev: any) => STATUS_CAMPAIGN_GROUP_OPTIONS_LANG?.filter((e: any) => e.value === statusSearch?.value)[0]);
    }

    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Campaign')} - ${t('Campaign')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Campaign')} centerTitle={t('Keyword Analyzing News')} pageTitle={t('Campaign')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_LIST, userPermissions) && (
              <>
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <Row>
                        <Col sm={12} className="text-end  mb-2 mb-md-0 d-block d-xxl-none">
                          <div className="d-flex flex g-4 align-items-start align-items-md-start mb-2 mt-2">
                            <Card className="card-animate mb-0 me-0 me-md-4 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                              <CardBody>
                                <div className="d-flex align-items-center ">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-primary text-truncate mb-0 text-start">{t('Total')}</p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                      <span className="counter-value text-primary">
                                        <CountUp
                                          start={0}
                                          end={campaignGroups?.pagination?.total || 0}
                                          duration={1}
                                        />
                                      </span></h4>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="w-100 d-flex flex-column align-items-end">
                              {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions) && (
                                <button
                                  type="button"
                                  className="btn btn-success ms-2 mt-0"
                                  onClick={() => navigate(`${ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL}`)}
                                  style={{ width: '170px' }}
                                >
                                  <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                  {t('Button Create Campaign')}
                                </button>)}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-2">
                        <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-xxl-block" style={{ width: '200px' }}>
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    <CountUp
                                      start={0}
                                      end={campaignGroups?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <div className="align-items-center ">
                            <Row className="g-4 align-items-center mt-0 mb-2">
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t('Campaign No')} isShow={!!noSearch}>
                                  <Input
                                    type="text"
                                    className="form-control search"
                                    placeholder={`${t('Campaign No')}...`}
                                    value={noSearch}
                                    onChange={(e) => setNoSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        searchData();
                                      }
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t('Category Type')} isShow={!!categoryTypeSearch?.value}>
                                  <DropdownOption
                                    name="category-type"
                                    dataList={listCategoriesTypeLang || []}
                                    placeholder={`${t("Category Type")}...`}
                                    className="search-filter-category-type"
                                    classNamePrefix="name-prefix"
                                    initialValue={categoryTypeSearch || null}
                                    onChangeSelect={(e: any) => setCategoryTypeSearch(e)}
                                    isHasOptionAll={true}
                                    optionAll={{ label: t('All Category Type'), value: '' }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <DropdownOption
                                  name="schedule"
                                  dataList={SCHEDULE_OPTIONS_LANG || []}
                                  placeholder={`${t("Creating Schedule")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={scheduleTypeSearch || null}
                                  onChangeSelect={(e: any) => setScheduleTypeSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Creating Schedule'), value: '' }}
                                  labelTop={t('Creating Schedule')}
                                />
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <DropdownOption
                                  name="date"
                                  dataList={DATE_OPTIONS_LANG?.filter((item) => item?.type === scheduleTypeSearch?.value) || [] || []}
                                  placeholder={`${t("Date")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={dateTypeSearch || null}
                                  disabled={['', 'now', 'daily', 'one_time']?.includes(scheduleTypeSearch?.value || '')}
                                  onChangeSelect={(e: any) => setDateTypeSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Creating Date'), value: '' }}
                                  labelTop={t('Creating Date')}
                                />
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                  <DropdownStatusCampaign
                                    name="status"
                                    dataList={STATUS_CAMPAIGN_GROUP_OPTIONS_LANG || []}
                                    placeholder={`${t("Status")}...`}
                                    className="search-filter-category-type"
                                    classNamePrefix="name-prefix"
                                    initialValue={statusSearch || null}
                                    onChangeSelect={(e: any) => setStatusSearch(e)}
                                    isHasOptionAll={true}
                                    optionAll={{ label: t('All Status'), value: '' }}
                                    colors={COLORS_STATUS_CAMPAIGN_GROUP}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-sm-4 mt-md-2 text-end d-none d-xxl-block">
                                {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions) && (
                                  <div className="w-100 d-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-success d-none d-xxl-block"
                                      onClick={() => navigate(`${ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL}`)}
                                      style={{ minWidth: '170px' }}
                                    >
                                      <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                      {t('Button Create Campaign')}
                                    </button>
                                  </div>
                                )}
                                {/* {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_DOWNLOAD, userPermissions) && (
                                  <button
                                    type="button"
                                    className="btn btn-soft-primary "
                                    onClick={(e) => { e.preventDefault(); handleExportCampaign() }}
                                    style={{ minWidth: '170px' }}
                                  >
                                    {isLoadingExportFile ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-download-2-fill align-bottom me-2"></i>}
                                    {t('Button Export Campaign')}
                                  </button>
                                )} */}
                              </Col>
                            </Row>
                            <Row className="g-4 align-items-center mt-0">
                              <Col sm={12} md={6} xl={6} xxl={4} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                                  <Input
                                    type="text"
                                    className="form-control search"
                                    placeholder={`${t('Keyword')}...`}
                                    value={keywordSearch}
                                    onChange={(e) => setKeywordSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        searchData();
                                      }
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <DropdownOption
                                  name="schedule_publishing"
                                  dataList={SCHEDULE_OPTIONS_LANG || []}
                                  placeholder={`${t("Publishing Schedule")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={schedulePublishingTypeSearch || null}
                                  onChangeSelect={(e: any) => setSchedulePublishingTypeSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Publishing Schedule'), value: '' }}
                                  labelTop={t('Publishing Schedule')}
                                />
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                <DropdownOption
                                  name="date_publishing"
                                  dataList={DATE_OPTIONS_LANG?.filter((item) => item?.type === schedulePublishingTypeSearch?.value) || [] || []}
                                  placeholder={`${t("Date")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={datePublishingTypeSearch || null}
                                  disabled={['', 'now', 'daily', 'one_time']?.includes(schedulePublishingTypeSearch?.value || '')}
                                  onChangeSelect={(e: any) => setDatePublishingTypeSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Publishing Date'), value: '' }}
                                  labelTop={t('Publishing Date')}
                                />
                              </Col>
                              <Col sm={12} md={6} xl={3} xxl={2} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                  <DatePickerCustom
                                    startDate={startDate || null}
                                    endDate={endDate || null}
                                    onChangePicker={handleChangePicker}
                                  />
                                </LabelWrapper>
                              </Col>
                              {/* <Col sm={6} md={6} xl={3} xxl={2} className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-sm-4 mt-md-2 text-end d-none d-xxl-block">
                                {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions) && (
                                  <div className="w-100 d-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-success d-none d-xxl-block"
                                      onClick={() => navigate(`${ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL}`)}
                                      style={{ minWidth: '170px' }}
                                    >
                                      <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                      {t('Button Create Campaign')}
                                    </button>
                                  </div>
                                )}
                              </Col> */}
                              <Col sm={12} md={6} xl={4} xxl={4} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t('Hashtags')} isShow={hashtagsSearch?.length > 0}>
                                  <HashtagInput initialValue={hashtagsSearch || []} placeholder={`${t('Hashtags')}...`} onChangeTag={(event) => {
                                    setHashtagsSearch((_prev: any) => event);
                                  }} />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={6} xl={4} xxl={4} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                  <SearchFilterMedia
                                    name="medias"
                                    dataList={listMedia}
                                    isMulti={false}
                                    isClearable={false}
                                    initialValue={mediaSearch || null}
                                    onChangeSelect={(event: any) => {
                                      setMediaSearch((_prev) => event);
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={4} xl={4} xxl={4} className="hstack gap-1 justify-content-sm-center justify-content-md-start justify-content-xxl-start mt-sm-4 mt-md-3">
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-primary me-1"
                                    onClick={searchData}
                                    disabled={isCampaignGroupLoading}
                                  >
                                    <i className="ri-search-line align-bottom me-1"></i>{" "}
                                    {t('Button Search')}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary fs-14"
                                    onClick={resetData}
                                  >
                                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                    {t('Button Reset')}
                                  </button>
                                </div>

                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          columns={columns}
                          data={campaignGroups?.list?.length ? campaignGroups?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={campaignGroups?.pagination?.total}
                          customPageCount={Math.ceil(Number(campaignGroups?.pagination?.total) / Number(campaignGroups?.pagination?.limit))}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isCampaignGroupLoading}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={!!campaignEdit?.id}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(campaignEdit?.isCopy) ? TITLE_CREATE : TITLE_UPDATE}</>  <>{!(campaignEdit?.isCopy) && (' - ' + campaignEdit?.name || '')}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <CampaignFormByKeywordAnalyzing
                isModal={true}
                id={String(campaignEdit?.id || '')}
                isCopy={!!(campaignEdit?.isCopy)}
                typeCampaignForm={TYPE_CAMPAIGN_FORM}
                listType={listType}
                listMedia={listMedia?.filter((item) => !!item?.id)}
                listTemplate={listTemplate}
                listCategoriesType={listCategoriesType}
                listClassifications={listClassifications}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Campaign')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <ModalConfirm
          textButtonConfirm={t('Button Update Status Campaign')}
          classButtonConfirm='btn-soft-primary'
          classIconButtonConfirm='ri-refresh-line'
          header={t('Button Update Status Campaign')}
          title={t('Do you want to update status of campaign!')}
          content={<span>{t(`After confirming the update status action, the data will be change to`)}<span className={`text-${initialValuesDefault?.status === STATUS_CAMPAIGN_GROUP.IN_PROGRESS ? 'danger' : 'success'} h6`}> {t(`Status_Campaign_Group_${initialValuesDefault?.status === STATUS_CAMPAIGN_GROUP.IN_PROGRESS ? STATUS_CAMPAIGN_GROUP.STOP : STATUS_CAMPAIGN_GROUP.IN_PROGRESS}`)} </span> {t('status. Do you want to proceed with the update status action.')}</span>}
          isShowIcon={false}
          isOpen={isOpenConfirmUpdateStatusCampaign}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmUpdateStatusCampaignClick}
          onConfirm={handleActionUpdateStatusCampaign}
        />
      </div>
    </React.Fragment >
  );
};

export default CampaignListByKeywordAnalyzing;
