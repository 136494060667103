import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import sportApi from 'api/sportApi';
import { formatQueryParams } from "helpers/format";
import { changeStatusActiveSportsAction } from "./reducer";

export const getSports = createAsyncThunk("Sports" , async (params: any = {}) => {
  try{
    const response = await sportApi.sports(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getGames = createAsyncThunk("Games" , async (params: any = {}) => {
  try{
    const response = await sportApi.games(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getCategoriesSport = createAsyncThunk("Sports/getCategoriesSport" , async (params: any = {}) => {
  try{
    const response = await sportApi.categories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
})

export const getSportKeywordsByNormal = async (params: any = {}) => {
  try {
    const response = await sportApi.sports(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCategoriesSportNormal = async (
  params: any = { type: "sport" }
) => {
  try {
    const response = await sportApi.searchCategories(params);
    return response;
  } catch (error) {
    return error;
  }
};


export const getAllSports = async (params: any = {}) => {
  try{
    const response = await sportApi.allSports(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const putStatusActiveSportTeam = async (id: string | number ,  data: any = {}) => {
  try{
    const response = await sportApi.putStatusActiveSportTeam(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const changeStatusActiveSports = (preloader : any) => async (dispatch : any) => {
  try {
      dispatch(changeStatusActiveSportsAction(preloader));
  } catch (error) {
      // console.log(error);
  }
};