import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { useTranslation } from 'react-i18next';
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { ROUTES } from 'routes/allRoutes';

const SearchOptionPages = () => {
    const { t } = useTranslation();
    const { userPermissions } = useRole();
    const [value, setValue] = useState("");
    const onChangeData = (value: any) => {
        setValue(value);
    };

    const menuItems: any = [
        {
            label: t('Dashboards'),
            link: ROUTES.DASHBOARD,
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        },
        // {
        //     label: t('Naver Category'),
        //     link: ROUTES.NAVER_CATEGORY,
        //     icon: <i className="ri-donut-chart-fill align-middle fs-18 text-muted me-2"></i>,
        // },
        {
            label: t('Naver Keyword'),
            link: ROUTES.NAVER_KEYWORD,
            icon: <i className="ri-donut-chart-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NAVER_KEYWORD_LIST, userPermissions),
        },
        // {
        //     label: t('Car Category'),
        //     link: ROUTES.CAR_CATEGORY,
        //     icon: <i className="ri-car-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.CAR_CATEGORY_LIST, userPermissions),
        // },
        {
            label: t('Car Keyword'),
            link: ROUTES.CAR_KEYWORD,
            icon: <i className="ri-car-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAR_KEYWORD_LIST, userPermissions),
        },
        // {
        //     label: t('Movie Category'),
        //     link: ROUTES.MOVIE_CATEGORY,
        //     icon: <i className="ri-movie-2-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.MOVIE_CATEGORY_LIST, userPermissions),
        // },
        // {
        //     label: t('Movie Keyword'),
        //     link: ROUTES.MOVIE_KEYWORD,
        //     icon: <i className="ri-movie-2-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.MOVIE_KEYWORD_LIST, userPermissions),
        // },
        // {
        //     label: t('Exchange Rate Category'),
        //     link: ROUTES.EXCHANGE_RATE_CATEGORY,
        //     icon: <i className="ri-exchange-funds-fill align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.EXCHANGE_RATE_CATEGORY_LIST, userPermissions),
        // },
        {
            label: t('Exchange Rate'),
            link: ROUTES.EXCHANGE_RATE,
            icon: <i className="ri-exchange-funds-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.EXCHANGE_RATE_LIST, userPermissions),
        },
        {
            label: t('Coin'),
            link: ROUTES.COIN,
            icon: <i className="ri-coins-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.COIN_LIST, userPermissions),
        },
        {
            label: t('Stock Category'),
            link: ROUTES.STOCK_CATEGORY,
            icon: <i className="ri-line-chart-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.STOCK_CATEGORY_LIST, userPermissions),
        },
        {
            label: t('Stock'),
            link: ROUTES.STOCK,
            icon: <i className="ri-line-chart-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.STOCK_KEYWORD_LIST, userPermissions),
        },
        {
            label: t('Sport Category'),
            link: ROUTES.SPORT_CATEGORY,
            icon: <i className=" ri-football-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.SPORT_CATEGORY_LIST, userPermissions),
        },
        // {
        //     label: t('Sport'),
        //     link: ROUTES.SPORT,
        //     icon: <i className=" ri-football-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.SPORT_TEAM_LIST, userPermissions),
        // },
        {
            label: t('Sports Schedule'),
            link: ROUTES.SPORT_SCHEDULE,
            icon: <i className="ri-calendar-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.SPORT_SCHEDULE_LIST, userPermissions),
        },
        {
            label: t('Lotto Result'),
            link: ROUTES.LOTTO_SCHEDULE,
            icon: <i className="ri-number-8 align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.LOTTO_SCHEDULE_LIST, userPermissions),
        },
        {
            label: t('Trend Index Category'),
            link: ROUTES.TREND_INDEX_CATEGORY,
            icon: <i className="ri-blaze-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_CATEGORY_LIST, userPermissions),
        },
        {
            label: t('Trend Index'),
            link: ROUTES.TREND_INDEX,
            icon: <i className="ri-blaze-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_LIST, userPermissions),
        },
        {
            label: t('Media'),
            link: ROUTES.MEDIA,
            icon: <i className="ri-building-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LIST, userPermissions),
        },
        {
            label: t('Notification'),
            link: ROUTES.NOTIFICATION,
            icon: <i className="ri-notification-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_LIST, userPermissions),
        },
        // {
        //     label: t('Settlement'),
        //     link: ROUTES.SETTLEMENT,
        //     icon: <i className="ri-donut-chart-fill align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.SETTLEMENT_LIST, userPermissions),
        // },
        {
            label: t('Create Campaign'),
            link: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions),
        },

        {
            label: t('Create Campaign Sport'),
            link: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_SPORT,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_SPORT, userPermissions),
        },
        {
            label: t('Create Campaign Lotto'),
            link: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_LOTTO,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_LOTTO, userPermissions),
        },
        {
            label: t('Campaign'),
            link: ROUTES.CAMPAIGN_TREND_RANKING_LIST_CAMPAIGN,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_LIST, userPermissions),
        },
        {
            label: t('News'),
            link: ROUTES.CAMPAIGN_TREND_RANKING_LIST_NEWS,
            icon: <i className=" ri-newspaper-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_LIST, userPermissions),
        },
        {
            label: t('Create Campaign (Popular Keyword)'),
            link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_POPULAR,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions),
        },
        {
            label: t('Create Campaign (Manual Keyword)'),
            link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions),
        },
        {
            label: t('Create Campaign (Core Keyword)'),
            link: ROUTES.CAMPAIGN_FLOWED_KEYWORD_CREATE_CAMPAIGN,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions),
        },
        {
            label: t('Campaign'),
            link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_LIST_CAMPAIGN,
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_LIST, userPermissions),
        },
        {
            label: t('News'),
            link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_LIST_NEWS,
            icon: <i className=" ri-newspaper-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_LIST, userPermissions),
        },
        {
            label: t('Template News Title'),
            link: ROUTES.TEMPLATE_TITLE,
            icon: <i className="ri-layout-left-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_TITLE_LIST, userPermissions),
        },
        {
            label: t('Template News Content'),
            link: ROUTES.TEMPLATE_CONTENT,
            icon: <i className="ri-layout-right-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.TEMPLATE_BODY_LIST, userPermissions),
        },
        {
            label: t('Info-Graphic'),
            link: ROUTES.TEMPLATE_INFO_GRAPHIC,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.INFO_GRAPHIC_LIST, userPermissions),
        },
        {
            label: t('Image'),
            link: ROUTES.TEMPLATE_IMAGE,
            icon: <i className="ri-gallery-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.INFO_GRAPHIC_LIST, userPermissions),
        },
        // {
        //     label: t('Variable Definition'),
        //     link: ROUTES.TEMPLATE_VARIABLE_DEFINITION,
        //     icon: <i className="ri-at-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.VARIABLE_DEFINITION_LIST, userPermissions),
        // },
        {
            label: t('Media Log'),
            link: ROUTES.STATISTICS_MEDIA_LOG,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LOG_LIST, userPermissions),
        },
        {
            label: t('Media Revenue Log'),
            link: ROUTES.STATISTICS_REVENUE_LOG,
            icon: <i className="ri-creative-commons-nc-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LIST, userPermissions),
        },
        {
            label: t('Media Statistics'),
            link: ROUTES.STATISTICS_MEDIA_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_STATISTICS_LIST, userPermissions),
        },
        // {
        //     label: t('News Log'),
        //     link: "/statistics/news-log",
        //     icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>
        // },
        {
            label: t('News Statistics'),
            link: ROUTES.STATISTICS_NEWS_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Visitor Statistics'),
            link: ROUTES.STATISTICS_VISITOR_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.VISITOR_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Keyword Inflow'),
            link: ROUTES.STATISTICS_KEYWORD_INFLOW,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_INFLOW_LIST, userPermissions),
        },
        {
            label: t('Keyword Statistics'),
            link: ROUTES.STATISTICS_KEYWORD_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Keyword Analysis'),
            link: ROUTES.STATISTICS_KEYWORD_ANALYSIS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST, userPermissions),
        },
        {
            label: t('Core Keyword'),
            link: ROUTES.STATISTICS_FLOWED_KEYWORD_ANALYSIS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST, userPermissions),
        },
        {
            label: t('Campaign Statistics'),
            link: ROUTES.STATISTICS_CAMPAIGN_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Category Statistics'),
            link: ROUTES.STATISTICS_CATEGORY_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Revenue Statistics'),
            link: ROUTES.STATISTICS_REVENUE_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Media Revenue Statistics'),
            link: ROUTES.STATISTICS_MEDIA_REVENUE_STATISTICS,
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('User'),
            link: ROUTES.USER_LIST,
            icon: <i className="ri-group-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.USER_LIST, userPermissions),
        },
        {
            label: t('Profile'),
            link: ROUTES.USER_PROFILE,
            icon: <i className="ri-user-6-line align-middle fs-18 text-muted me-2"></i>,
            isShow: true,
        },
        {
            label: t('Role'),
            link: ROUTES.USER_ROLE,
            icon: <i className="ri-user-star-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions),
        },
        {
            label: t('News Statistics (Lab)'),
            link: ROUTES.LAB_NEWS_STATISTICS,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Campaign Statistics (Lab)'),
            link: ROUTES.LAB_CAMPAIGN_STATISTICS,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Category Statistics (Lab)'),
            link: ROUTES.LAB_CATEGORY_STATISTICS,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Configuration'),
            link: ROUTES.CONFIGURATION,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CONFIGURATION_UPDATE, userPermissions),
        },
        {
            label: t('Admin Activity Logs'),
            link: ROUTES.ADMIN_ACTIVITY_LOGS,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions),
        },
        {
            label: t('Media Ranking'),
            link: ROUTES.STATISTICS_MEDIA_RANKING,
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_RANKING_LIST, userPermissions),
        },
    ];

    useEffect(() => {
        var searchOptions: any = document.getElementById("search-close-options");
        var dropdown: any = document.getElementById("search-dropdown");
        var searchInput: any = document.getElementById("search-options");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
        });

        document.body.addEventListener("click", function (e: any) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });
    }, []);

    const checkIsIncludeText = (text: string, search: string) => {
        return String(text).toUpperCase().includes(String(search).toUpperCase());
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-sm-block">
                <div className="position-relative">
                    <Input type="text" className="form-control" placeholder={`${t('Button Search')}...`}
                        id="search-options"
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                            onChangeData(e.target.value);
                        }} />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar style={{ height: "320px" }}>

                        <div className="dropdown-header mt-2">
                            <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('Pages')}</h6>
                        </div>

                        {menuItems?.map((item: any, index: number) => (
                            <Link key={index} to={item?.link} className={`dropdown-item notify-item ${(!!item?.isShow && checkIsIncludeText(item?.label || '', value || '')) ? '' : 'd-none'}`}>
                                {item?.icon}
                                <span className="">{item?.label}</span>
                            </Link>
                        ))}
                    </SimpleBar>
                </div>
            </form>
        </React.Fragment>
    );
};

export default SearchOptionPages;