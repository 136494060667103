import { IMedia } from 'api/types/_media';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { detailNews, getAllMedias, getNewsLogsByNormal } from "../../../store/thunks";


import { IPreviewNews } from 'api/types/_news';
import { INewsLog } from 'api/types/_statistic';
import { CONFIG_OPTION_TOAST_ERROR } from 'common/toast';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { BOT_OPTIONS, DEVICE_OPTIONS, PLATFORM_LOG_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import CountUp from "react-countup";
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'createdAt';


const NewsLogList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const PLATFORM_LOG_OPTIONS_LANG = [{ label: t('All Platform'), value: '' }].concat(PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || []);

  const DEVICE_OPTIONS_LANG = [{ label: t('All Device'), value: '' }].concat(DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || []);

  const BOT_OPTIONS_LANG = [{ label: t('All Type Bot'), value: '' }].concat(BOT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || []);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    media_id: withDefault(StringParam, ''),
    news_title: withDefault(StringParam, ''),
    id: withDefault(StringParam, ''),
    ip: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 7))).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    platform: withDefault(StringParam, ''),
    device: withDefault(StringParam, ''),
    bot: withDefault(StringParam, ''),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [idSearch, setIdSearch] = useState<string>(query?.id || "");
  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [newsTitleSearch, setNewsTitleSearch] = useState<string>(query?.news_title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
  });

  const [platformSearch, setPlatformSearch] = useState<Option | null>(PLATFORM_LOG_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.platform))[0] || PLATFORM_LOG_OPTIONS_LANG[0]);

  const [deviceSearch, setDeviceSearch] = useState<Option | null>(DEVICE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.device))[0] || DEVICE_OPTIONS_LANG[0]);

  const [botSearch, setBotSearch] = useState<Option | null>(BOT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.bot))[0] || BOT_OPTIONS_LANG[0]);

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);
  const [previewNews, setPreviewNews] = useState<IPreviewNews | null>(null);

  const [previewNewsLog, setPreviewNewsLog] = useState<INewsLog | null>(null);

  // Inside your component

  const [newsLogs, setNewsLogs] = useState<{ list: INewsLog[], total: number, total_uv: number }>({ list: [], total: 0, total_uv: 0 });
  const [isNewsLogLoading, setIsNewsLogLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    try {
      setIsNewsLogLoading((prev) => true);
      const res: any = await getNewsLogsByNormal(query);
      setNewsLogs((prev: any) => ({
        list: (prev?.list || []).concat(res?.data?.list || []),
        total: res?.data?.pagination?.total || 0,
        total_uv: res?.data?.total_uv || 0
      }));
      setIsNewsLogLoading((prev) => false);
    } catch (error: any) {
      setIsNewsLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1
    });
  }

  useEffect(() => {
    handleQueryData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      news_title: newsTitleSearch || "",
      id: idSearch || "",
      ip: ipSearch || "",
      keyword: keywordSearch || "",
      media_id: mediaSearch?.value || '',
      platform: platformSearch?.value || '',
      device: deviceSearch?.value || '',
      bot: botSearch?.value ?? '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNewsLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      news_title: '',
      id: '',
      ip: '',
      keyword: '',
      media_id: '',
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: moment(new Date(new Date().setDate(new Date().getDate() - 7))).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      platform: '',
      device: '',
      bot: '',
      page: 1,
      time_request: + new Date()
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNewsLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0
      }));
    }
    setQuery({ ...queryNew }, "push")
    setIdSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setNewsTitleSearch((_prev) => "");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setPlatformSearch(PLATFORM_LOG_OPTIONS_LANG[0]);
    setDeviceSearch(DEVICE_OPTIONS_LANG[0]);
    setBotSearch(BOT_OPTIONS_LANG[0]);
  };

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  const handlePreviewNews = async (idNews: string | number) => {
    try {
      const resNews: any = await detailNews(String(idNews));
      if (resNews?.data) {
        setPreviewNews((_prev) => resNews?.data || []);
        setIsOpenPreview((_prev) => true);
      } else {
        toast(`${resNews}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  function onClosePreviewNewsLogClick() {
    setPreviewNewsLog((_prev) => null);
  }

  const handlePreviewNewsLog = async (newsLog: INewsLog | null = null) => {
    try {
      setPreviewNewsLog((_prev) => newsLog);
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const formatDate = (strDate: string = '') => {
    return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('.')[0]}`;
  }
  // Column
  const columns = useMemo(
    () => [
      // {
      //   Header: t('Id'),
      //   accessor: "id",
      //   filterable: true,
      //   sortable: false,
      //   Cell: (cell: any) => (
      //     <>
      //       <span>{cell?.value}</span>
      //     </>
      //   ),
      // },
      {
        Header: t('News Title'),
        accessor: "news_title",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ maxWidth: '30dvw' }}>
              <Link className='text-normal' to={item?.domain_url} target="_blank">
                {item?.news_title}
              </Link>
            </div>
          </>)
        },
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        thWidth: 230,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer">{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Media'),
        accessor: "media_name",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer">
              {cell?.value && (<> <span>{cell?.value}</span> <br />
                <span>({t('ID')}: {item?.media_id})</span></>)}
            </div>
          </>)
        },
      },
      // {
      //   Header: t('WEB_bot'),
      //   accessor: "bot",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 60,
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (<>
      //       <div>{cell?.value}</div>
      //     </>)
      //   },
      // },
      {
        Header: t('Platform'),
        accessor: "platform",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <span>{PLATFORM_LOG_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Device'),
        accessor: "device",
        filterable: true,
        sortable: false,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <span>{DEVICE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('OS'),
        accessor: "os",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Browser'),
        accessor: "browser",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('IP'),
        accessor: "ip",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => {
          return (
            <>
              <span><span>{String(cell?.value || '').split('T')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split('T')[1]).split('.')[0] || ''}</span> </span>
            </>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <TooltipCustom
                title={t('Detail News Log')}
                id={`detail-log-${item?.id}`}
              >
                <li className=" list-inline-item me-0" id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); handlePreviewNewsLog(item) }}
                  >
                    <i className="ri-eye-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
      }));
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (platformSearch) {
      setPlatformSearch((_prev: any) =>
        PLATFORM_LOG_OPTIONS_LANG?.filter((e: any) => e.value === platformSearch?.value)[0]
      );
    }
    if (deviceSearch) {
      setDeviceSearch((_prev: any) =>
        DEVICE_OPTIONS_LANG?.filter((e: any) => e.value === deviceSearch?.value)[0]
      );
    }

    if (botSearch) {
      setBotSearch((_prev: any) =>
        BOT_OPTIONS_LANG?.filter((e: any) => e.value === botSearch?.value)[0]
      );
    }

    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('News Log')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News Log')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.NEWS_STATISTICS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isNewsLogLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={newsLogs?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          {/* <Col sm={12} lg={2} className='mt-2'>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('ID')}...`}
                              value={idSearch}
                              onChange={(e) => setIdSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col> */}
                          <Col sm={12} md={2} lg={2} className='mt-2'>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('News Title')}...`}
                              value={newsTitleSearch}
                              onChange={(e) => setNewsTitleSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} lg={2} className='mt-2'>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Keyword')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>

                          <Col sm={12} lg={2} className='mt-2'>
                            <Select
                              options={DEVICE_OPTIONS_LANG}
                              value={deviceSearch || null}
                              onChange={(e: any) => setDeviceSearch(e)}
                              placeholder={<div>{`${t('Device')}...`}</div>}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                            />
                          </Col>
                          <Col sm={12} md={3} lg={3} className="mt-2 date-picker-wrapper-custom">
                            <DatePickerCustom
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                            />
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">

                          <Col sm={12} lg={2} className='mt-2'>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('IP')}...`}
                              value={ipSearch}
                              onChange={(e) => setIpSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} lg={2} className='mt-2'>
                            <Select
                              options={BOT_OPTIONS_LANG}
                              value={botSearch || null}
                              onChange={(e: any) => setBotSearch(e)}
                              placeholder={<div>{`${t('WEB_bot')}...`}</div>}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                            />
                          </Col>
                          <Col sm={12} lg={2} className='mt-2'>
                            <Select
                              options={PLATFORM_LOG_OPTIONS_LANG}
                              value={platformSearch || null}
                              onChange={(e: any) => setPlatformSearch(e)}
                              placeholder={<div>{`${t('Platform')}...`}</div>}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                            />
                          </Col>
                          <Col sm={12} lg={3} className='mt-2'>
                            <div>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm={12} md={3} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isNewsLogLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                  </CardHeader>
                  <CardBody className="pt-0">
                    <div>
                      <InfiniteScroll
                        dataLength={newsLogs?.list?.length || 0}
                        next={handleLoadMore}
                        scrollableTarget='scrollableDiv'
                        hasMore={newsLogs && newsLogs?.list?.length < newsLogs?.total ? true : false}
                        loader={''} // loader={<LoadingListNotify />}
                        scrollThreshold={'50%'}
                      >
                        <div className="mx-3 my-4">
                          <TableContainer
                            className="custom-header-css"
                            divClass="table-card"
                            tableClass="align-middle"
                            theadClass="table-light"
                            columns={columns}
                            data={newsLogs?.list?.length ? newsLogs?.list : []}
                            customPageSize={query.limit}
                            customPageIndex={query.page - 1}
                            totalRecords={newsLogs?.total}
                            customPageCount={1}
                            handleChangePage={handleChangePage}
                            manualSorting={true}
                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                            handleChangeSorting={handleChangeSorting}
                            isLoading={isNewsLogLoading}
                            isShowPagination={false}
                          />
                        </div>
                      </InfiniteScroll>
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={isOpenPreview} centered={true} size="xl" scrollable={true} toggle={onClosePreviewClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewClick}>
            {t('Preview')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="bg-primary-subtle position-relative"><div className="p-3 pt-4 card-body"><div className="text-center">
                        <h3 className="fw-semibold">{previewNews?.title || ''}</h3>
                        <p className="mb-0 text-muted">{t('Created at')}: {previewNews?.created_at || ''} </p>
                      </div>
                      </div>
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <div dangerouslySetInnerHTML={{ __html: previewNews?.content || '' }}></div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={!!previewNewsLog} centered={true} size="xl" scrollable={true} toggle={onClosePreviewNewsLogClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewNewsLogClick}>
            {t('Detail News Log')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">{t('ID')}</th>
                                <td>{previewNewsLog?.id}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('News ID')}</th>
                                <td>
                                  <Link className='text-normal' to="#" onClick={(e) => { e.preventDefault(); handlePreviewNews(previewNewsLog?.news_id || '') }}>
                                    {previewNewsLog?.news_id}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('News Title')}</th>
                                <td>
                                  <Link className='text-normal' to="#" onClick={(e) => { e.preventDefault(); handlePreviewNews(previewNewsLog?.news_id || '') }}>
                                    {previewNewsLog?.news_title}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Keyword')}</th>
                                <td>{previewNewsLog?.keyword}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Media')}</th>
                                <td>{previewNewsLog?.media_name}  ({t('ID')}: {previewNewsLog?.media_id})</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Device')}</th>
                                <td>{DEVICE_OPTIONS_LANG?.filter((item) => (!!previewNewsLog?.device && String(item?.value) === String(previewNewsLog?.device)))[0]?.label || previewNewsLog?.device}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Platform')}</th>
                                <td>{PLATFORM_LOG_OPTIONS_LANG?.filter((item) => (!!previewNewsLog?.platform && String(item?.value) === String(previewNewsLog?.platform)))[0]?.label || previewNewsLog?.platform}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('OS')}</th>
                                <td>{previewNewsLog?.os}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('IP')}</th>
                                <td>{previewNewsLog?.ip}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Browser')}</th>
                                <td>{previewNewsLog?.browser}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Agent')}</th>
                                <td>{previewNewsLog?.agent}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Reference Link')}</th>
                                <td>
                                  <Link className='text-normal' to={previewNewsLog?.reference_link || ''} target="_blank">
                                    {previewNewsLog?.reference_link}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('WEB_bot')}</th>
                                <td>
                                  {BOT_OPTIONS_LANG?.filter((item) => (!!previewNewsLog?.bot && String(item?.value) === String(previewNewsLog?.bot)))[0]?.label || previewNewsLog?.bot}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Domain')}</th>
                                <td>
                                  {previewNewsLog?.domain}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Domain Url')}</th>
                                <td>
                                  <Link className='text-normal' to={previewNewsLog?.domain_url || ''} target="_blank">
                                    {previewNewsLog?.domain_url}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Created at')}</th>
                                <td>{formatDate(previewNewsLog?.createdAt)}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Updated at')}</th>
                                <td>{formatDate(previewNewsLog?.updatedAt)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewNewsLogClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >
      </div >
    </React.Fragment >
  );
};

export default NewsLogList;