import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import keywordApi from 'api/keywordApi';
import { formatQueryParams } from "helpers/format";

export const getSearchKeywords = createAsyncThunk("SearchKeywords" , async (params: any = {}) => {
  try{
    const response = await keywordApi.keywords(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getSearchKeyword =  async(params: any = {}) => {
  try{
    const response = await keywordApi.searchKeyword(params)
    return response;
  }catch (error) {
    return error;
  }
};