import { IAgency, IMedia } from "api/types/_media";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface SearchAgencyProps {
  name?: string,
  dataList?: IAgency[],
  initialValue?: Option[] | Option | null | undefined;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
  isMulti?: boolean,
  isClearable?: boolean,
  id?: string,
  disabled?: boolean
}

const SearchAgency = ({
  name = '',
  initialValue = [],
  dataList = [],
  onChangeSelect,
  placeholder,
  isMulti = true,
  isClearable = false,
  id = '',
  disabled = false
}: SearchAgencyProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item) => ({ label: item?.name, value: String(item?.id || ''), logo: item?.logo_url || '' })) || [] as Option[];
    setOptions((_prev: any) => result);
  }, [JSON.stringify(dataList)]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        name={name}
        isClearable={isClearable}
        value={initialValue}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{isMulti ? t('Select Agencies') : t('Select Agency')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">{option?.label}
            {/* <div className="op ms-2">
              {`${option?.website ? option?.website : ''}`}
            </div> */}
          </div>)}
        className="search-filter-media"
        classNamePrefix="name-prefix"
        id={id}
      />
    </React.Fragment>
  );
};

export default SearchAgency;
