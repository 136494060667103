import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import stockApi from 'api/stockApi';
import { formatQueryParams } from "helpers/format";

export const getStocks = createAsyncThunk("Stocks" , async (params: any = {}) => {
  try{
    const response = await stockApi.stocks(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getCategoriesStock = createAsyncThunk("Stocks/getCategoriesStock" , async (params: any = {}) => {
  try{
    const response = await stockApi.categories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
})

export const getStockKeywordsByNormal = async (params: any = {}) => {
  try {
    const response = await stockApi.stocks(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCategoriesStockNormal = async (
  params: any = { type: "stock" }
) => {
  try {
    const response = await stockApi.searchCategories(params);
    return response;
  } catch (error) {
    return error;
  }
};


export const getAllStocks = async (params: any = {}) => {
  try{
    const response = await stockApi.allStocks(params)
    return response;
  }catch (error) {
    return error;
  }
}