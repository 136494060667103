import { createSlice } from "@reduxjs/toolkit";
import { getTrendIndexes, getCategoriesTrendIndex, getRelatedKeywords } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICategoryTrendIndex, ITrendIndex } from "api/types/_trendIndex";

export interface IState {
  categories: ResponseData<ICategoryTrendIndex[]> & PaginationResponse | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,


  trendIndexes: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isTrendIndexLoading: boolean,
  isTrendIndexSuccess: boolean,

  relatedKeywords: ResponseData<ITrendIndex[]> & PaginationResponse | null,
  isRelatedKeywordsLoading: boolean,
  isRelatedKeywordsSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,

  trendIndexes: null,
  isTrendIndexLoading: false,
  isTrendIndexSuccess: false,

  relatedKeywords: null,
  isRelatedKeywordsLoading: false,
  isRelatedKeywordsSuccess: false,

  error: {},
};

const TrendIndexSlice = createSlice({
  name: "TrendIndex",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    // get categories
    builder.addCase(getCategoriesTrendIndex.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategoriesTrendIndex.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategoriesTrendIndex.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });

    //get trendIndexes
    builder.addCase(getTrendIndexes.pending, (state: IState, action: any) => {
      state.isTrendIndexLoading = true
    });
    builder.addCase(getTrendIndexes.fulfilled, (state: IState, action: any) => {
      state.trendIndexes = action.payload.data;
      state.isTrendIndexSuccess = true;
      state.isTrendIndexLoading = false;
    });
    builder.addCase(getTrendIndexes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isTrendIndexSuccess = false;
      state.isTrendIndexLoading = false;
    });


    //get relatedKeywords
    builder.addCase(getRelatedKeywords.pending, (state: IState, action: any) => {
      state.isRelatedKeywordsLoading = true
    });
    builder.addCase(getRelatedKeywords.fulfilled, (state: IState, action: any) => {
      state.relatedKeywords = action.payload.data;
      state.isRelatedKeywordsSuccess = true;
      state.isRelatedKeywordsLoading = false;
    });
    builder.addCase(getRelatedKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRelatedKeywordsSuccess = false;
      state.isRelatedKeywordsLoading = false;
    });
  },
});

export default TrendIndexSlice.reducer;