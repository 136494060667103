import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IUser } from "./types/_user";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IKeywordSearch } from "./types/_keyword";
const api = new APIClient();

const path = '/keywords';
const userApi = {
  keywords(params: any):Promise<AxiosResponse<ResponseData<IKeywordSearch[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  searchKeyword(params: any = {}) : Promise<AxiosResponse<ResponseData<IKeywordSearch>, any>>  {
    const url = `${path}/search`;
    return api.get(url, params)
  },
}
export default userApi
