import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { Row, Table } from "reactstrap";

import { IMedia } from "api/types/_media";
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";

interface TableMediaRankingProps {
  medias?: IMedia[],
  className?: any;
  summary?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string, order_by: string };
  handleChangeSorting?: (sortBy: { sort_by: string | undefined | null, order_by: string | undefined | null }) => void,
  manualSorting?: boolean,
  isLoading?: boolean,
  isShowPagination?: boolean,
  isShowLoadingBottom?: boolean,
  isShowColumn?: boolean,
  classBackgroundWhenActiveSort?: string,
  isScrollToTop?: boolean,
}

function createArray(len: number = 15) {
  var array = [];

  for (var i = 0; i < len; i++) {
    array.push(i);
  }

  return array;
}

function checkLengthArrHas15Item(arr: any) {
  if (arr?.length < 15) {
    const arrPush = createArray(15 - arr?.length);
    return arr.concat(arrPush);
  }
  return arr
};


const COLOR_A = 'rgba(37, 160, 226, 0.1)';
const COLOR_AA = 'rgba(37, 160, 226, 0.2)';
const COLOR_B = 'rgba(240, 101, 72, 0.1)';
const COLOR_BB = 'rgba(240, 101, 72, 0.2)';
const COLOR_C = '#fffcf4';
const COLOR_CC = '#fffaed';
const COLOR_D = 'rgba(243, 246, 249, 0.1)';
const COLOR_DD = 'rgba(243, 246, 249, 0.4)';
const COLOR_E = 'rgba(243, 246, 249, 0.5)';
const COLOR_EE = 'rgba(243, 246, 249, 1)';


const COLOR_HEX_A = 'rgba(37, 160, 226, 0.1)';
const COLOR_HEX_AA = 'rgb(212 237 249)';
const COLOR_HEX_B = 'rgba(240, 101, 72, 0.1)';
const COLOR_HEX_BB = 'rgb(252 226 222)';
const COLOR_HEX_C = '#fffcf4';
const COLOR_HEX_CC = '#fffaed';
const COLOR_HEX_D = 'rgba(243, 246, 249, 0.1)';
const COLOR_HEX_DD = 'rgb(250 252 252)';
const COLOR_HEX_E = 'rgba(243, 246, 249, 0.5)';
const COLOR_HEX_EE = 'rgba(243, 246, 249, 1)';

const TableMediaRanking = ({
  medias = [],
  summary,
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = '',
  isScrollToTop = true,
}: TableMediaRankingProps) => {

  const { t } = useTranslation();

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);

  const thRefs = useRef<any>([]); // Fixed Header Table
  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementHeaderFixedRef = useRef<any>(null); // Fixed Header Table
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [rowsShow, setRowsShow] = useState<number[]>([]);

  const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false); // Fixed Header Table

  const { events } = useDraggable(warperTableRef);

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft)
    if (warperTableRef.current && elementFixedRef.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
        elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft; // Fixed Header Table
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }

      // Begin::Fixed Header Table
      const rect = warperTableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top;
      if (distanceFromTop <= 70) {
        elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft;
        setIsShowHeaderTableFixed((prev) => true);
      } else {
        setIsShowHeaderTableFixed((prev) => false);
      }
      // End::Fixed Header Table
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleShowMore = (num: number) => {
    if (rowsShow?.includes(num)) {
      setRowsShow((prev) => prev?.filter((item) => item !== num));
    } else {
      setRowsShow((prev) => [...prev, num]);
    }
  }

  const dataDefault = data?.find((item: any) => item?.data?.length > 0)?.data?.map((e: any) => ({ date: e?.date })) || createArray();


  const formatDate = (date: string) => {
    const num = moment(date).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
    const textNote = moment(new Date(date)).format("MM/DD") + ' (' + t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`) + ')';
    return textNote;
  }

  const getColorByDate = (date: string, index: number, type: string = 'RGB') => {
    const num = moment(date).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
    if (num === 0) {
      return type === 'RGB' ? [COLOR_B, COLOR_BB] : [COLOR_HEX_B, COLOR_HEX_BB];
    }
    if (num === 6) {
      return type === 'RGB' ? [COLOR_A, COLOR_AA] : [COLOR_HEX_A, COLOR_HEX_AA];
    }
    if (index % 2 === 0) {
      return type === 'RGB' ? [COLOR_D, COLOR_DD] : [COLOR_HEX_D, COLOR_HEX_DD];
    }
    return type === 'RGB' ? [COLOR_E, COLOR_EE] : [COLOR_HEX_E, COLOR_HEX_EE];
  }

  const getDataByDate = (data: any, date: string) => {
    return data?.find((item: any) => item?.date === date) || null;
  }

  const generateSortingIndicator = (column: string = '') => {
    return (
      column ? (
        <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
          {(column === sorting?.sort_by && sorting?.order_by === 'ASC') && <i className={`ri-arrow-up-line fs-13 text-primary`} />}
          {(column === sorting?.sort_by && sorting?.order_by === 'DESC') && <i className={`ri-arrow-down-line fs-13 text-primary`} />}
        </span>
      ) : (
        ""
      )
    );
  };

  return (
    <Fragment>
      <div className={`table-custom-pagination ${divClass}`} {...events} ref={warperTableRef} onScroll={handleScroll}>
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ minHeight: '70px' }} >
          <thead className={theadClass} ref={tableRef}>
            <tr className={trClass}>
              <th
                className={` sticky-th-1 text-center cursor-pointer align-middle ${thClass || ''}`}
                style={{ width: '180px', }}
                rowSpan={2}
                ref={el => thRefs.current[0] = el} // Fixed Header Table
              >
                {t('Media')}
              </th>
              <th
                className={`sticky-th-2 text-center cursor-pointer align-middle ${thClass || ''}`}
                style={{ width: '160px', backgroundColor: `${COLOR_CC}` }}
                colSpan={2}
                ref={el => thRefs.current[1] = el} // Fixed Header Table
              >
                {t('Average')}
              </th>
              {dataDefault?.map((a: any, index: number) => (
                <th
                  className={`text-center cursor-pointer ${thClass || ''}`}
                  style={{ width: '160px', backgroundColor: `${getColorByDate(a?.date, index)[1]}` }}
                  colSpan={2}
                  ref={el => thRefs.current[index + 2] = el} // Fixed Header Table
                >
                  {a?.date ? formatDate(a?.date) : ''}
                  {index === 1 ? <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}><i className={`ri-arrow-down-line fs-13 text-primary`} /></span> : <></>}
                </th>
              ))}
            </tr>
            <tr className={trClass}>
              <>
                <th
                  className={`sticky-th-2-1 text-center cursor-pointer ${thClass || ''}`}
                  style={{ width: '80px', backgroundColor: `${COLOR_CC}` }}
                >
                  {t('PV')}
                </th>
                <th
                  className={`sticky-th-2-2 text-center cursor-pointer ${thClass || ''}`}
                  style={{ width: '80px', backgroundColor: `${COLOR_CC}` }}
                >
                  {t('UV')}
                </th>
              </>
              {dataDefault?.map((a: any, index: number) => (
                <>
                  <th
                    className={`text-center cursor-pointer ${thClass || ''}`}
                    style={{ width: '80px', backgroundColor: `${getColorByDate(a?.date, index)[1]}` }}
                  >
                    {t('PV')}
                  </th>
                  <th
                    className={`text-center cursor-pointer ${thClass || ''}`}
                    style={{ width: '80px', backgroundColor: `${getColorByDate(a?.date, index)[1]}` }}
                  >
                    {t('UV')}
                  </th>
                </>
              ))}
            </tr>
            <tr >
              <th colSpan={8} className="py-0 px-0" style={{ borderBottomColor: 'transparent' }}>
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center" role="cell" style={{ backgroundColor: `${COLOR_C}` }}>
                <strong>{t('Sum')}</strong>
              </td>
              <td className="sticky-th-2-1 text-center" role="cell"
                style={{ width: '80px', backgroundColor: `${COLOR_C}` }}
              >

              </td>
              <td className="sticky-th-2-2 text-center" role="cell"
                style={{ width: '80px', backgroundColor: `${COLOR_C}` }}
              >
                <div></div>
              </td>
              {checkLengthArrHas15Item(summary || [])?.map((m: any, i: number) => (
                <>
                  <td className="text-center" role="cell"
                    style={{ width: '80px', backgroundColor: `${getColorByDate(dataDefault[i]?.date, i)[0]}` }}
                  >
                    <div><strong>{m?.total_pv || '0'}</strong></div>
                  </td>
                  <td className="text-center" role="cell"
                    style={{ width: '80px', backgroundColor: `${getColorByDate(dataDefault[i]?.date, i)[0]}` }}
                  >
                    <div><strong>{m?.total_uv || '0'}</strong></div>
                  </td>
                </>
              ))}
            </tr>
            {data.map((item: any, index: number) => (
              <>
                <tr key={item?.domain}>
                  <td className="text-center" role="cell" style={{}}>
                    <MediaComponent domain={item?.domain} medias={medias} />
                  </td>
                  <>
                    <td className="sticky-th-2-1 text-center" role="cell"
                      style={{ width: '80px', backgroundColor: `${COLOR_C}` }}
                    >
                      <div><strong>{item?.avg_pv || '0'}</strong></div>
                    </td>
                    <td className="sticky-th-2-2 text-center" role="cell"
                      style={{ width: '80px', backgroundColor: `${COLOR_C}` }}
                    >
                      <div><strong>{item?.avg_uv || '0'}</strong></div>
                    </td>
                  </>
                  {checkLengthArrHas15Item(item?.data || [])?.map((m: any, i: number) => (
                    <>
                      <td className="text-center" role="cell"
                        style={{ width: '80px', backgroundColor: `${getColorByDate(dataDefault[i]?.date, i)[0]}` }}
                      >
                        <div>{m?.pv || '0'}</div>
                      </td>
                      <td className="text-center" role="cell"
                        style={{ width: '80px', backgroundColor: `${getColorByDate(dataDefault[i]?.date, i)[0]}` }}
                      >
                        <div>{m?.uv || '0'}</div>
                      </td>
                    </>
                  ))}
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Begin::Fixed Header Table */}
      <div
        className={`table-card overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
        style={{
          position: 'fixed',
          top: 70,
          zIndex: 9,
          left: elementWarperTable?.left,
          width: elementWarperTable?.width,
          right: 0,

        }}
        ref={elementHeaderFixedRef}
      >
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'} mb-0`} style={{ height: 'auto', tableLayout: 'fixed' }} >
          <thead className={theadClass} style={{ width: elementTable?.width }}>
            <tr className={trClass}>
              <th
                className={` sticky-th-1 text-center cursor-pointer align-middle ${thClass || ''}`}
                rowSpan={2}
                {...{ width: thRefs.current[0]?.offsetWidth }}
              >
                {t('Media')}
              </th>
              <th
                className={`sticky-th-2 text-center cursor-pointer align-middle ${thClass || ''}`}
                style={{ backgroundColor: `${COLOR_CC}` }}
                colSpan={2}
                {...{ width: thRefs.current[1]?.offsetWidth }}
              >
                {t('Average')}
              </th>
              {dataDefault?.map((a: any, index: number) => (
                <th
                  className={`text-center cursor-pointer ${thClass || ''}`}
                  style={{ backgroundColor: `${getColorByDate(a?.date, index, 'HEX')[1]}` }}
                  colSpan={2}
                  {...{ width: thRefs.current[index + 2]?.offsetWidth }}
                >
                  {a?.date ? formatDate(a?.date) : ''}
                  {index === 1 ? <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}><i className={`ri-arrow-down-line fs-13 text-primary`} /></span> : <></>}
                </th>
              ))}
            </tr>
            <tr className={trClass}>
              <>
                <th
                  className={`sticky-th-2-1 text-center cursor-pointer ${thClass || ''}`}
                  style={{ width: '80px', backgroundColor: `${COLOR_CC}` }}
                >
                  {t('PV')}
                </th>
                <th
                  className={`sticky-th-2-2 text-center cursor-pointer ${thClass || ''}`}
                  style={{ width: '80px', backgroundColor: `${COLOR_CC}` }}
                >
                  {t('UV')}
                </th>
              </>
              {dataDefault?.map((a: any, index: number) => (
                <>
                  <th
                    className={`text-center cursor-pointer ${thClass || ''}`}
                    style={{ width: '80px', backgroundColor: `${getColorByDate(a?.date, index, 'HEX')[1]}` }}
                  >
                    {t('PV')}
                  </th>
                  <th
                    className={`text-center cursor-pointer ${thClass || ''}`}
                    style={{ width: '80px', backgroundColor: `${getColorByDate(a?.date, index, 'HEX')[1]}` }}
                  >
                    {t('UV')}
                  </th>
                </>
              ))}
            </tr>
          </thead>
        </Table>
      </div>
      {/* End::Fixed Header Table */}

      <div
        className="overflow-x-auto color-scrollbar-os"
        style={{
          position: 'fixed',
          zIndex: 999,
          left: elementWarperTable?.left,
          bottom: 0,
          width: elementWarperTable?.width,
          right: 0,
          opacity: isScrollBottom ? 0 : 1,
        }}
        ref={elementFixedRef}
        onScroll={handleScroll}
      >
        <div style={{ width: elementTable?.width, height: '1px' }}></div>
      </div>
      {isShowPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {t('Total')}:
              {" "}
              <span className="fw-semibold ms-1">{formatNumberWithCommas(totalRecords)}</span>
              {" "}
              {t('Results')}
            </div>
          </div>
          <div className="col-sm-auto">
            <ReactPaginate
              nextLabel={`${t('next')} >`}
              onPageChange={(page) => {
                if (handleChangePage) {
                  isScrollToTop && handleScrollToTop();
                  handleChangePage(page.selected);
                }
              }}
              forcePage={+customPageIndex}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={customPageCount}
              previousLabel={`< ${t('previous')}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-separated"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>)}
    </Fragment>
  );
};


TableMediaRanking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableMediaRanking);


const MediaComponent = ({ domain, medias }: any) => {
  const media = medias?.find((item: any) => String(item?.website) === String(domain));
  return (<>
    <div className="cursor-pointer text-start">
      <span>
        {media?.name || ''}
      </span>
      <br />
      <Link className='text-normal' to={(String(domain).includes('https://') || String(domain).includes('http://')) ? domain : `https://${domain}`} target="_blank">
        {domain}
      </Link>
    </div>
  </>);
}