import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllMedias, getNewsStatisticsFromLab as onGetNewsStatistics } from "../../../store/thunks";
import { IMedia } from 'api/types/_media';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import CountUp from 'react-countup';
import CollapseTag from 'components/Common/CollapseTag';
import { TYPE_NEWS_OPTIONS } from 'helpers/constans';
import DropdownOption from 'components/Common/DropdownOption';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useRole } from 'components/Hooks/UserHooks';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import LabelWrapper from 'components/Common/LabelWrapper';
import CopyWrapper from 'components/Common/CopyWrapper';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'pv';
const NewsStatisticList = () => {

  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    domain: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    is_newshub: withDefault(StringParam, '1'),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    start_published_at: withDefault(StringParam, ''),
    end_published_at: withDefault(StringParam, ''),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [publishedAtSearch, setPublishedAtSearch] = useState<any[]>([query?.start_published_at ? moment(query?.start_published_at || "", 'Y-MM-DD').toDate() : '', query?.end_published_at ? moment(query?.end_published_at || "", 'Y-MM-DD').toDate() : '']);
  const [startPublishedAt, endPublishedAt] = publishedAtSearch;

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_newshub))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Lab;

  const NewsStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      newsStatistics: state.newsStatistics,
      isNewsStatisticSuccess: state.isNewsStatisticSuccess,
      isNewsStatisticLoading: state.isNewsStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { newsStatistics, error, isNewsStatisticLoading } = useSelector(NewsStatisticsProperties);

  useEffect(() => {
    dispatch(onGetNewsStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      title: titleSearch || "",
      is_newshub: typeNewsSearch?.value ?? '',
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      start_published_at: startPublishedAt ? moment(new Date(startPublishedAt)).format("Y-MM-DD") : '',
      end_published_at: endPublishedAt ? moment(new Date(endPublishedAt)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      domain: '',
      title: '',
      is_newshub: 1,
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      start_published_at: "",
      end_published_at: "",
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setTypeNewsSearch(null);
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setPublishedAtSearch(["", ""]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Ranking'),
        accessor: "rank",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <div style={{ minWidth: '60px' }}>
            <span>{cell?.value}</span>
          </div>
        ),
      },
      {
        Header: t('Title Post'),
        accessor: "title",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <CopyWrapper contentCopy={item?.title} style={{ minWidth: '150px' }}>
              <Link className='text-normal' to={item?.url} target="_blank">
                {item?.title}
              </Link>
            </CopyWrapper>
          </>)
        },
      },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        thWidth: 150,
        description: t('Description PV'),
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('UV'),
        accessor: "uv",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        thWidth: 150,
        description: t('Description UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer" style={{ minWidth: '100px' }}>
              {cell?.value && (<> <span>
                {/* {cell?.value} */}
                {listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0]?.name || ''}
              </span>
                <br />
                <Link className='text-normal' to={(String(cell?.value).includes('https://') || String(cell?.value).includes('http://')) ? cell?.value : `https://${cell?.value}`} target="_blank">
                  {cell?.value}
                </Link>
              </>
              )}
            </div>
          </>)
        },
      },
      {
        Header: t('News Published at'),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="ms-4 text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePublishedAt = (values: any[] = []) => {
    setPublishedAtSearch((_prev: any) => values);
  }


  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('News Statistics')} - ${t('Lab')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  useEffect(() => {
    setDurationResponses([
      {
        name: 'News Statistics',
        time: newsStatistics?.pagination?.duration || 0
      }
    ]);
  }, [newsStatistics?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News Statistics')} pageTitle={t('Lab')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.NEWS_STATISTICS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList" className="ribbon-box right">
                  <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per 10 mins')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isNewsStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={newsStatistics?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} md={3} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('News Type')} isShow={!!typeNewsSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={TYPE_NEWS_OPTIONS_LANG}
                                placeholder={`${t("News Type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={typeNewsSearch || null}
                                onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All News'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>

                        </Row>
                        <Row className="g-4 align-items-center mt-0">

                          <Col sm={12} md={6} lg={6} className="mt-2 mt-md-2">
                            <LabelWrapper label={t('Title Post')} isShow={!!titleSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title Post')}...`}
                                value={titleSearch}
                                onChange={(e) => setTitleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={`${t('Start Published at')} - ${t('End Published at')}`} isShow={!!startPublishedAt || !!endPublishedAt}>
                              <DatePickerCustom
                                placeholder={`${t('Start Published at')} - ${t('End Published at')}`}
                                startDate={startPublishedAt || null}
                                endDate={endPublishedAt || null}
                                onChangePicker={handleChangePublishedAt}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} md={3} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-start mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isNewsStatisticLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    {/* <ChartLine className="mb-5" title={t('News Statistics')} /> */}
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={newsStatistics?.list?.length ? newsStatistics?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={newsStatistics?.pagination?.total}
                        customPageCount={Math.ceil(Number(newsStatistics?.pagination?.total) / Number(newsStatistics?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isNewsStatisticLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewsStatisticList;
