import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { exportFileCategory, getCategoriesStock as onGetCategoriesStock, } from "../../../store/thunks";
import CollapseTag from "components/Common/CollapseTag";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useRole } from "components/Hooks/UserHooks";
import LabelWrapper from "components/Common/LabelWrapper";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";

const typeQuery = {
  'keyword_count': StringParam,
}

const StockCategory = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const [query, setQuery] = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    name: StringParam,
    ...typeQuery
  })
  const [keyword, setKeyword] = useState("");

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Stock;
  const StockCategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      categories: state.categories,
      isCategorySuccess: state.isCategorySuccess,
      isCategoryLoading: state.isCategoryLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { categories, error, isCategoryLoading } = useSelector(StockCategoryProperties);

  useEffect(() => {
    dispatch(onGetCategoriesStock(query));
  }, [dispatch, query]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        sortable: false,
        thWidth: 150,
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: false,
        sortable: false,
        thWidth: 500,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const names = value?.group_categories.map((item: any) => item.name);
          const commaSeparatedString = names ? names.join(" > ") : "";
          let name = value.name;
          if (commaSeparatedString !== "") {
            name = commaSeparatedString + ` > ` + value.name;
          }
          return <div dangerouslySetInnerHTML={{ __html: name }} />;
        },
      },

      {
        Header: t('Total Stock'),
        accessor: "keyword_count",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '150px' }}>{cell?.value}</div>
          </>
        ),
      },
    ],
    [i18n?.language]
  );

  // Search
  const handleSearchCategory = () => {
    setQuery({ name: keyword, page: 1, })
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const resetData = () => {
    setQuery({
      name: undefined,
      time_request: + new Date()
    }, "push")
    setKeyword("");
  };


  const handleExportCategory = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await exportFileCategory({ type: 'stock' });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data;
        link.download = 'categories';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  useEffect(() => {
    document.title = `${t('Keyword')} - ${t('Stock Category')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid>
          <BreadCrumb title={t('Stock Category')} pageTitle={t('Keyword')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.STOCK_CATEGORY_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <Col sm={12} md={4} lg={3}>
                        <LabelWrapper label={t('Search by name')} isShow={!!keyword}>
                          <div className="search-box">
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Search by name')}...`}
                              value={keyword}
                              onChange={e => setKeyword(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSearchCategory();
                                }
                              }}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={8} lg={9} className="hstack gap-1 flex-column flex-sm-row justify-content-between">
                        <div>
                          <button type="button" className="btn btn-primary" disabled={isCategoryLoading} onClick={handleSearchCategory}>
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14 ms-2"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                        <div className="mt-3 mt-sm-0">
                          <button
                            type="button"
                            className="btn btn-soft-primary "
                            onClick={(e) => { e.preventDefault(); handleExportCategory() }}
                            style={{ width: '170px' }}
                          >
                            {isLoadingExportFile ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-download-2-fill align-bottom me-2"></i>}
                            {t('Button Export Category')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        columns={columns}
                        data={categories?.list?.length ? categories?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={categories?.pagination?.total}
                        customPageCount={Math.ceil(categories?.pagination?.total / categories?.pagination?.limit)}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        handleChangePage={handleChangePage}
                        isLoading={isCategoryLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default StockCategory;