import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllMedias, getWebsiteStatistics as onGetWebsiteStatistics } from "../../../store/thunks";


import DatePickerCustom from 'components/Common/DatePickerCustom';
import CountUp from 'react-countup';
import { IMedia } from 'api/types/_media';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useRole } from 'components/Hooks/UserHooks';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import LabelWrapper from 'components/Common/LabelWrapper';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'pv';

const SiteStatisticList = () => {

  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const { setDurationResponses } = useDurationResponses();

  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    // keyword: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, ""),
    end_date: withDefault(StringParam, ""),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date || "", 'Y-MM-DD').toDate() : '', query?.end_date ? moment(query?.end_date || "", 'Y-MM-DD').toDate() : '']);
  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const websiteStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      websiteStatistics: state.websiteStatistics,
      isWebsiteStatisticSuccess: state.isWebsiteStatisticSuccess,
      isWebsiteStatisticLoading: state.isWebsiteStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { websiteStatistics, error, isWebsiteStatisticLoading } = useSelector(websiteStatisticsProperties);

  useEffect(() => {
    dispatch(onGetWebsiteStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      // keyword: keywordSearch || "",
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      // keyword: "",
      start_date: undefined,
      end_date: undefined,
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setDateSearch(["", ""]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Rank'),
        accessor: "rank",
        filterable: true,
        sortable: false,
        thWidth: 130,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer">
              {cell?.value && (<> <span>
                {/* {cell?.value} */}
                {listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0]?.name || ''}
              </span>
                <br />
                <Link className='text-normal' to={(String(cell?.value).includes('https://') || String(cell?.value).includes('http://')) ? cell?.value : `https://${cell?.value}`} target="_blank">
                  {cell?.value}
                </Link>
              </>
              )}
            </div>
          </>)
        },
      },
      // {
      //   Header: t('Total News Count'),
      //   accessor: "total_news_count",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      // {
      //   Header: t('PV per News'),
      //   accessor: "pv_per_news",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (<>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>)
      //   },
      // },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Description PV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3">{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('UV'),
        accessor: "uv",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        description: t('Description UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3">{cell?.value}</div>
          </>
        ),
      },
      // {
      //   Header: t("Created at"),
      //   accessor: "created_at",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: 130,
      //   thClass: "text-end",
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end me-3">
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
    ],
    [i18n?.language, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // End::Delete

  const handleCallAllOption = async () => {
    try {
      const [resMedia]: any = await Promise.all([getAllMedias()]);

      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
      setListMedia((_prev) => list);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);


  useEffect(() => {
    document.title = `${t('Media Statistics')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    setDurationResponses([
      {
        name: 'Media Statistics',
        time: websiteStatistics?.pagination?.duration || 0
      }
    ]);
  }, [websiteStatistics?.pagination?.duration])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Media Statistics')} pageTitle={t('Statistics')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.MEDIA_STATISTICS_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList" className="ribbon-box right">
                  <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per 10 mins')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isWebsiteStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={websiteStatistics?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        {/* <Row className="g-4 align-items-center mb-2 mt-2">
                        <Col sm={12} md={3} lg={3} className='mt-2'>
                          <div style={{ opacity: 0 }}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Media Name')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row> */}
                        <Row className="g-4 align-items-center mt-0">
                          <Col sm={6} md={3} lg={3} className="date-picker-wrapper-custom">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={6} md={9} lg={9} className="hstack gap-1 justify-content-sm-start justify-content-md-between">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isWebsiteStatisticLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={websiteStatistics?.list?.length ? websiteStatistics?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={websiteStatistics?.pagination?.total}
                        customPageCount={Math.ceil(Number(websiteStatistics?.pagination?.total) / Number(websiteStatistics?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isWebsiteStatisticLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SiteStatisticList;
