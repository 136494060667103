import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllAdsAgency, getAllMedias, getMediaRevenueStatistics as onGetMediaRevenueStatistics } from "../../../store/thunks";

import { IAgency, IMedia } from 'api/types/_media';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import LabelWrapper from 'components/Common/LabelWrapper';
import SearchAgency from 'components/Common/SearchAgency';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import TableRevenue from 'components/Common/TableRevenue';
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import CountUp from 'react-countup';
// import ChartLine from './ChartLine';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'system_revenue';

const MediaRevenueStatistics = () => {
  const { userPermissions } = useRole();
  const { t, i18n } = useTranslation();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 1000),
    partner_id: withDefault(StringParam, ''),
    date_from: withDefault(StringParam, moment(new Date(new Date().setDate(1))).format("Y-MM-DD")),
    date_to: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    agency_id: withDefault(StringParam, ''),
    group_by: withDefault(StringParam, 'partner_id'),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.date_from || "", 'Y-MM-DD').toDate(), moment(query?.date_to || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [listAgency, setListAgency] = useState<IAgency[]>([]);
  const [agencySearch, setAgencySearch] = useState<Option | null>(
    {
      label: listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.name || t('All Agency'),
      value: `${listAgency?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.id || ''}`
    }
  );

  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.id || ''}`
  });

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const MediaRevenueStatisticProperties = createSelector(
    selectLayoutState,
    (state) => ({
      revenue: state.mediaRevenueStatistics,
      isRevenueSuccess: state.isMediaRevenueStatisticSuccess,
      isRevenueLoading: state.isMediaRevenueStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { revenue, error, isRevenueLoading } = useSelector(MediaRevenueStatisticProperties);

  useEffect(() => {
    dispatch(onGetMediaRevenueStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      partner_id: mediaSearch?.value || '',
      date_from: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      date_to: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      agency_id: agencySearch?.value ?? '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      partner_id: '',
      date_from: moment(new Date(new Date().setDate(1))).format("Y-MM-DD"),
      date_to: moment(new Date()).format("Y-MM-DD"),
      agency_id: '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };

    setQuery(queryNew, "push");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.date_from || "", 'Y-MM-DD').toDate(), moment(queryNew?.date_to || "", 'Y-MM-DD').toDate()]);
    setAgencySearch({ label: t('All Agency'), value: '' });
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resMedia, resAgency]: any = await Promise.all([getAllMedias(), getAllAdsAgency()]);
      const listM = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMedia?.data || []);
      const listA = ([{ name: t('All Agency'), id: '' } as any]).concat(resAgency?.data || []);
      setListMedia((_prev) => listM);
      setMediaSearch((_prev) => ({
        label: listM?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.name || t('All Media'),
        value: `${listM?.filter((item) => String(item?.id || '') === String(query?.partner_id || ''))[0]?.id || ''}`
      }));
      setListAgency((_prev) => listA);
      setAgencySearch((_prev) => ({
        label: listA?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.name || t('All Agency'),
        value: `${listA?.filter((item) => String(item?.id || '') === String(query?.agency_id || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (agencySearch) {
      setListAgency((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Agency') })));
      (!agencySearch?.value) && setAgencySearch((prev) => ({ label: t('All Agency'), value: '' }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Media Revenue Statistics')} - ${t('Lab')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Media Revenue Statistics')} pageTitle={t('Lab')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_INFLOW_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isRevenueLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={revenue?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-end mb-2 mb-md-0 mt-2">
                          <Col sm={12} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <SearchFilterMedia
                                name="medias"
                                isMulti={false}
                                // isClearable={true}
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="mt-3 mt-md-2">
                            <LabelWrapper label={t('Agency')} isShow={!!agencySearch}>
                              <SearchAgency
                                name="agency"
                                isMulti={false}
                                dataList={listAgency}
                                initialValue={agencySearch}
                                onChangeSelect={(event: any) => {
                                  setAgencySearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-3 mt-md-2">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                startDate={startDate || null}
                                endDate={endDate || null}
                                showOptions={[
                                  'today', 'yesterday',
                                  'this_week',
                                  'this_month', 'last_month', 'last_3_month', 'last_6_month',
                                ]}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-4 mt-md-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isRevenueLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  {/* <ChartLine className="mb-2" title={t('Keyword Inflow')} {...chart} isLoading={isLoadingChart} /> */}
                  <CardBody className="pt-3">
                    <div>
                      <TableRevenue
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-have-first-row-is-total"
                        theadClass="table-light"
                        summary={revenue?.summary}
                        data={revenue?.list?.length ? revenue?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={revenue?.pagination?.total}
                        customPageCount={Math.ceil(Number(revenue?.pagination?.total) / Number(revenue?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isRevenueLoading}
                        isShowPagination={true}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MediaRevenueStatistics;
