import { createAsyncThunk } from "@reduxjs/toolkit";
import newsApi from "api/newsApi";
import { formatQueryParams } from "helpers/format";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods


export const getNews = createAsyncThunk("news" , async (params: any = {}) => {
  try{
    const response = await newsApi.news(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getNewsKeyword = createAsyncThunk("newsKeywords" , async (params: any = {}) => {
  try{
    const response = await newsApi.newsKeywords(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});



export const previewNews = async (data: any = {}) => {
  try{
    const response = await newsApi.preview(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const detailNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.detailNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const postNews = async (data: any = {}) => {
  try{
    const response = await newsApi.postNews(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putReCreateNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putReCreateNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putMultiReCreateNews = async (data: any = {}) => {
  try{
    const response = await newsApi.putMultiReCreateNews(data);
    return response;
  }catch (error) {
    return error;
  }
};


export const putResetStatusNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putResetStatusNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putMultiResetStatusNews = async (data: any = {}) => {
  try{
    const response = await newsApi.putMultiResetStatusNews(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putResetNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putResetNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const putMultiSetPublishedAtNews = async (data: any = {}) => {
  try{
    const response = await newsApi.putMultiSetPublishedAtNews(data);
    return response;
  }catch (error) {
    return error;
  }
};



export const putConfirmNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putConfirmNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.deleteNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const downloadFileNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.downloadFile(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const downloadImageNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.downloadImageNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const downloadMediaNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.downloadMediaNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getScriptTrackingNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.renderScriptTrackingNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const uploadImage = async (data: any = {}) => {
  try{
    const response = await newsApi.uploadImage(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const sendEmailNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.sendEmailNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const copyNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.copyNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const mediaPublishedNews = async (data: any = {}) => {
  try{
    const response = await newsApi.mediaPublishedNews(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const mediaPublishedNewsToday = async (data: any = {}) => {
  try{
    const response = await newsApi.mediaPublishedNewsToday(data);
    return response;
  }catch (error) {
    return error;
  }
};
