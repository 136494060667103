import { Option } from "api/types/_public";
import { ICategoryTrendIndex, ITrendIndex } from "api/types/_trendIndex";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import BreadCrumb from "components/Common/BreadCrumb";
import DropdownCategoryKeyword from "components/Common/DropdownCategoryKeyword";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { COLORS_STATUS_KEYWORD, STATUS_KEYWORD_OPTIONS, typeSearchTrendIndex } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import * as Yup from "yup";
import {
  deleteMultiplesTrendIndex,
  deleteTrendIndex,
  getAllCategoriesTrendIndexNormal,
  getTrendIndexes as onGetTrendIndexes,
  postCategoryKeywords
} from "../../../store/thunks";
import RelatedKeyword from "../RelatedKeyword";
import DropdownStatusKeyword from "components/Common/DropdownStatusKeyword";
import LabelWrapper from "components/Common/LabelWrapper";

const typeQuery = {
  index_trend: StringParam,
};

const TYPE_SELECT_DEFAULT: string = "index_trend";
registerLocale("en", en);
registerLocale("ko", ko);

function removeParentheses(text: string = "") {
  // Use regular expressions to remove content within ()
  return text.replace(/\([^)]*\)/g, "").trim();
}

function decreaseQuantity(input: string) {
  // Use regex to find and replace the numbers decreased by 1
  return input.replace(/\((\d+)\)/g, (match, p1) => {
    const originalQuantity = Number(p1);
    const newQuantity = originalQuantity - 1;
    return `(${newQuantity})`;
  });
}

interface Props {
  detailCategory?: any | undefined;
  handleRefreshListCategory?: (itemLoading: any) => void;
}



const TrendIndexKeyword = ({
  detailCategory,
  handleRefreshListCategory,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const STATUS_KEYWORD_OPTIONS_LANG = STATUS_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: withDefault(
      NumberParam,
      detailCategory?.value ? Number(detailCategory?.value) : null
    ),
    competition_level: StringParam,
    keyword: StringParam,
    status: StringParam,
    ...typeQuery,
  });

  const typeSearchLang = typeSearchTrendIndex?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );
  const [typeSelect, setTypeSelect] = useState(
    typeSearchLang?.find((e: any) => e.value === query.sort_by)
  );
  const rangeValue = useRef<any>();
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");
  const [statusSearch, setStatusSearch] = useState<Option | null>(null);
  const [categorySelect, setCategorySelect] = useState<Option | null>(
    detailCategory || null
  );

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<
    ICategoryTrendIndex[]
  >([]);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<ICategoryTrendIndex | null>(null);

  const [isUpdateKeywordsLoading, setIsUpdateKeywordsLoading] = useState<boolean>(false);

  const [isKeyLoading, setIsKeyLoading] = useState<number | null>(null);

  const [isFullRowEnterKeywords, setIsFullRowEnterKeywords] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [autocompleteKeywords, setAutocompleteKeywords] = useState<{ category_id: string | number; keywords: string[]; }>({ category_id: "", keywords: [] });

  const [relatedKeyword, setRelatedKeyword] = useState<ITrendIndex | null>(null);


  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const textareaKeyWordsRef = useRef<any>();

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.TrendIndex;

  const TrendIndexProperties = createSelector(selectLayoutState, (state) => ({
    trendIndexes: state.trendIndexes,
    isTrendIndexesSuccess: state.isTrendIndexesSuccess,
    isTrendIndexLoading: state.isTrendIndexLoading,
    error: state.error,
  }));

  // Inside your component
  const { trendIndexes, isTrendIndexLoading } = useSelector(TrendIndexProperties);

  useEffect(() => {
    dispatch(
      onGetTrendIndexes(
        query.sort_by && rangeValue.current
          ? { ...query, [query.sort_by]: rangeValue.current?.value }
          : query
      )
    );
  }, [dispatch, query]);

  const searchData = () => {
    const rangeQuery =
      fromRangeSearch && toRangeSearch
        ? `gt:${fromRangeSearch}-lt:${toRangeSearch}`
        : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      keyword: keywordSearch || undefined,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      category_id: categorySelect?.value,
      status: statusSearch?.value || '',
      time_request: + new Date()
    });
  };

  // Begin::Delete
  const handleConfirmDelete = (item: ICategoryTrendIndex | null = null) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id && optionsSelected?.length === 0) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const ids = (optionsSelected || []).flat()?.map((item: any) => Number(item?.value));
      const dataDelete = { ids: ids?.length > 0 ? ids : [Number(initialValuesDefault?.id)], ...(!!detailCategory?.value ? { category_id: detailCategory?.value } : {}) };
      const response: any = (initialValuesDefault?.id && !detailCategory?.value) ? await deleteTrendIndex(initialValuesDefault?.id) : await deleteMultiplesTrendIndex(dataDelete);
      if (response?.code === 200) {
        dispatch(onGetTrendIndexes(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);

        setOptionsSelected((prev: any) => []);

        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        handleCallAllOption();
        if (!!detailCategory) {
          const op = {
            ...detailCategory,
            keyword_count: Number(detailCategory?.keyword_count) - 1,
            label: `${removeParentheses(String(detailCategory?.label))} (${Number(detailCategory?.keyword_count) - 1
              })`,
          };
          handleRefreshListCategory && handleRefreshListCategory(op);
          setCategorySelect((prev) => op);
        }
        if (
          String(query?.category_id) === String(categorySelect?.value) &&
          categorySelect?.value
        ) {
          setCategorySelect((prev: any) => ({
            ...prev,
            label: `${decreaseQuantity(String(prev?.label))}`,
          }));
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  };
  // End::Delete

  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        sort_by: undefined,
        [typeSelect?.value]: undefined,
        category_id: undefined,
        status: undefined,
        time_request: + new Date()
      },
      "push"
    );
    setKeywordSearch("");
    setTypeSelect(
      typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT)
    );
    setFromRangeSearch("");
    setToRangeSearch("");
    setCategorySelect(null);
    setStatusSearch(null);
  };

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (trendIndexes?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.keyword }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === trendIndexes?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
              <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
            </div>
          </>
        ),
      },
      {
        Header: t("Index Trend"),
        accessor: "index_trend",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }}>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            let subs = [];
            for (let sub of category?.group_categories) {
              subs.push(sub?.name);
            }
            subs.push(category?.name);
            categoryName.push(subs.join(` > `));
          }
          return (
            <div
              dangerouslySetInnerHTML={{ __html: categoryName.join(`<br>`) }}
              style={{ minWidth: '150px' }}
            />
          );
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '100px' }}>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Gender Trend"),
        accessor: "gender_label",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const { naver_trend_male_ratio, naver_trend_female_ratio } = value;
          const num_male = Number(String(naver_trend_male_ratio).replace('%', '').replace(',', ''));
          const num_female = Number(String(naver_trend_female_ratio).replace('%', '').replace(',', ''));
          let nameTop: string = '';
          if (num_male > num_female) {
            nameTop = 'naver_trend_male_ratio';
          } else if (num_male < num_female) {
            nameTop = 'naver_trend_female_ratio';
          }
          return (
            <div style={{ minWidth: '100px' }}>
              <span className={`${nameTop === 'naver_trend_male_ratio' && 'text-danger'}`}>{naver_trend_male_ratio}</span>{' , '}
              <span className={`${nameTop === 'naver_trend_female_ratio' && 'text-danger'}`}>{naver_trend_female_ratio}</span>
            </div>
          );
        },
      },
      {
        Header: t("Age Trend"),
        accessor: "age_label",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const {
            naver_trend_age_10_ratio,
            naver_trend_age_20_ratio,
            naver_trend_age_30_ratio,
            naver_trend_age_40_ratio,
            naver_trend_age_50_ratio,
          } = value;
          const top = [
            naver_trend_age_10_ratio,
            naver_trend_age_20_ratio,
            naver_trend_age_30_ratio,
            naver_trend_age_40_ratio,
            naver_trend_age_50_ratio,
          ].reduce(({ numMax = 0, i = 0 }, item: string, index) => {
            const num = Number(String(item).replace('%', '').replace(',', ''));
            const max = num > numMax ? ({ numMax: num, i: index + 1 }) : ({ numMax, i });
            return max;
          }, {});

          return (
            <div style={{ minWidth: '180px' }}>
              <span className={`${top?.i === 1 && 'text-danger'}`}>{naver_trend_age_10_ratio}</span>{' , '}
              <span className={`${top?.i === 2 && 'text-danger'}`}>{naver_trend_age_20_ratio}</span>{' , '}
              <span className={`${top?.i === 3 && 'text-danger'}`}>{naver_trend_age_30_ratio}</span>{' , '}
              <span className={`${top?.i === 4 && 'text-danger'}`}>{naver_trend_age_40_ratio}</span>{' , '}
              <span className={`${top?.i === 5 && 'text-danger'}`}>{naver_trend_age_50_ratio}</span>
            </div>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "status_name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          const color = COLORS_STATUS_KEYWORD[Number(status)];
          return (
            <div style={{ minWidth: "60px" }}>
              <span className={`badge bg-${color}`}>{`${t(
                `Status_Keyword_${status}`
              )}`}</span>
            </div>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: false,
        sortable: true,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end me-3" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: false,
        sortable: true,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end me-3" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 132,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item" title={t('Button Delete Keyword')}>
                <button type="button" className="btn btn-sm btn-success btn-sm fs-14"
                  disabled={!!isKeyLoading} onClick={() => handleAnalysis(cell?.row?.original?.keyword, cell?.row?.id)}
                >
                  {(String(isKeyLoading) === String(cell?.row?.id)) ? <Spinner size="sm"></Spinner> : <i className="ri-refresh-line align-bottom"></i>}
                </button>
              </li> */}
              {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_UPDATE, userPermissions) && (<TooltipCustom
                title={t("Button Related Keywords")}
                id={`related-idx-kw-${item?.id}`}
              >
                <li
                  className="list-inline-item"
                  id={`related-idx-kw-${item?.id}`}
                >
                  <Link
                    className="btn btn-sm btn-soft-secondary  edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowRelatedKeywords(item);
                    }}
                  >
                    <i className="ri-donut-chart-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>)}
              {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_UPDATE, userPermissions) && (
                <TooltipCustom
                  title={t("Button Autocomplete Keywords")}
                  id={`autocomplete-idx-kw-${item?.id}`}
                >
                  <li
                    className="list-inline-item"
                    id={`autocomplete-idx-kw-${item?.id}`}
                  >
                    <Link
                      className="btn btn-sm btn-soft-secondary  edit-item-btn"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowAutocompleteKeywords(item?.suggestions || []);
                      }}
                    >
                      <i className="ri-voiceprint-line align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>)}
              {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t("Button Delete Keyword")}
                  id={`delete-idx-kw-${item?.id}`}
                >
                  <li
                    className="list-inline-item"
                    id={`delete-idx-kw-${item?.id}`}
                  >
                    <Link
                      className="btn btn-sm btn-soft-secondary  edit-item-btn"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleConfirmDelete(item);
                      }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, isKeyLoading, JSON.stringify(optionsSelected), query?.page, JSON.stringify(trendIndexes?.list), userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resCategoriesTrendIndex]: any = await Promise.all([
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  // Begin::Add New Keyword
  const handleSubmit = async (values: any) => {
    if (!detailCategory?.value) {
      return;
    }
    const keywords = String(values?.keywords || "")
      .split("\n")
      ?.reduce((arr: string[], item: string) => {
        return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
      }, []);
    try {
      setIsUpdateKeywordsLoading((_prev) => true);
      const data = {
        category_id: detailCategory?.value,
        keywords: [],
        manual_keywords: keywords || [],
        source: "chatgpt",
      };
      const response: any = await postCategoryKeywords(data);
      if (response?.data) {
        dispatch(onGetTrendIndexes(query));
        setIsUpdateKeywordsLoading((_prev) => false);
        const op = {
          ...detailCategory,
          keyword_count: response?.data?.keyword_count,
          label: `${removeParentheses(String(detailCategory?.label))} (${response?.data?.keyword_count
            })`,
        };
        handleRefreshListCategory && handleRefreshListCategory(op);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        formik.setFieldValue("keywords", "");
      } else {
        setIsUpdateKeywordsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsUpdateKeywordsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    keywords: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      keywords: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  //@ts-ignore
  const initialValueKeywords = formik?.values?.keywords;

  // End::Add New Keyword


  // Begin::Show Autocomplete Keywords 

  function onCloseClick() {
    setIsOpen((_prev) => !_prev);
  }
  const handleShowAutocompleteKeywords = async (keys = []) => {
    setAutocompleteKeywords((prev) => ({
      category_id: '',
      keywords: keys,
    }));
    setIsOpen((_prev) => true);
  };

  // End::Show Autocomplete Keywords 

  // Begin::Show Related Keywords 

  function onCloseRelatedKeywordClick() {
    setRelatedKeyword((_prev) => null);
  }

  const handleShowRelatedKeywords = async (item: any = null) => {
    setRelatedKeyword((prev) => (item));
  };

  // End::Show Related Keywords 


  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.filter((e: any) => e.value === typeSelect?.value)[0]
      );
    }
    if (statusSearch?.value) {
      setStatusSearch((_prev: any) =>
        STATUS_KEYWORD_OPTIONS_LANG?.filter((e: any) => e.value === statusSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Trend Index")} - ${t("Keyword")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className={`page-content ${!!detailCategory?.value && "p-0"}`}>
        <Container fluid>
          {!detailCategory?.value && (
            <BreadCrumb title={t("Trend Index")} pageTitle={t("Keyword")} />
          )}
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-3 mt-0">
                      <Col
                        md={
                          !!detailCategory?.value && !isFullRowEnterKeywords
                            ? 7
                            : 12
                        }
                      >
                        <div
                          className=""
                          style={
                            !!detailCategory?.value
                              ? {
                                backgroundColor: "var(--vz-topbar-search-bg)",
                                padding: "20px 10px 20px",
                                borderRadius: "5px",
                              }
                              : {}
                          }
                        >
                          <Row className="g-4 align-items-center mb-4 mb-sm-3 mb-md-2">
                            <Col sm={12} md={!!detailCategory?.value ? 6 : 4} className="mt-3 mt-sm-3 mb-0 mb-md-3">
                              <LabelWrapper label={t('Category')} isShow={!!detailCategory?.value || !!categorySelect?.value}>
                                <DropdownCategoryKeyword
                                  categoryType="trend_index"
                                  dataListNaver={[]}
                                  dataListTrendIndex={listCategoriesTrendIndex || []}
                                  initialValue={detailCategory || categorySelect || undefined}
                                  onChangeCategory={(event: any) => {
                                    setCategorySelect(event);
                                  }}
                                  disabled={!!detailCategory?.value}
                                  isClearable={true}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={6} md={!!detailCategory?.value ? 3 : 2} className="mt-3 mt-sm-3 mb-2 mb-md-3">
                              <LabelWrapper label={t('Category')} isShow={!!keywordSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("Search by keyword")}...`}
                                  value={keywordSearch}
                                  onChange={(e) => setKeywordSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={6} md={!!detailCategory?.value ? 3 : 2} className="mt-2 mt-sm-3 mb-2 mb-md-3">
                              <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                <DropdownStatusKeyword
                                  name="status"
                                  dataList={STATUS_KEYWORD_OPTIONS_LANG || []}
                                  placeholder={`${t("Status")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={statusSearch || null}
                                  onChangeSelect={(e: any) => setStatusSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t('All Status'), value: '' }}
                                />
                              </LabelWrapper>
                            </Col>
                          </Row>
                          <Row className="g-4 align-items-center">
                            {/* type search */}
                            <Col sm={6} md={!!detailCategory?.value ? 6 : 4} className="mt-2 mt-sm-3  mb-2 mb-md-3">
                              <LabelWrapper label={t('Type')} isShow={!!typeSelect?.value}>
                                <Select
                                  options={typeSearchLang}
                                  value={typeSelect}
                                  onChange={(e: any) => setTypeSelect(e)}
                                  placeholder={
                                    <div>{`${t("Search by field")}...`}</div>
                                  }
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={3} md={!!detailCategory?.value ? 3 : 2} className="mt-2 mt-sm-3 mb-2 mb-md-3">
                              <LabelWrapper label={t('From Range')} isShow={!!fromRangeSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("From Range")}...`}
                                  value={fromRangeSearch}
                                  onChange={(e) =>
                                    setFromRangeSearch(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={3} md={!!detailCategory?.value ? 3 : 2} className="mt-2 mt-sm-3 mb-2 mb-md-3">
                              <LabelWrapper label={t('To Range')} isShow={!!toRangeSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("To Range")}...`}
                                  value={toRangeSearch}
                                  onChange={(e) => setToRangeSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={!!detailCategory?.value ? 12 : 4}
                              className={`hstack gap-1 mt-3 mb-2 mb-md-3 flex-column flex-sm-row justify-content-sm-${!!detailCategory?.value ? "end" : "center"
                                } justify-content-md-${!!detailCategory?.value ? "end" : "between"
                                }`}
                            >
                              <div className="w-100 mb-4 mb-sm-0 text-center text-sm-start">
                                <button
                                  type="button"
                                  className="btn btn-primary me-1"
                                  onClick={searchData}
                                  disabled={isTrendIndexLoading}
                                >
                                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                                  {t("Button Search")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary fs-14"
                                  onClick={resetData}
                                >
                                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                  {t("Button Reset")}
                                </button>
                              </div>
                              {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_DELETE, userPermissions) && (
                                <div className="w-100 mb-2 mb-sm-0 text-end text-sm-end">
                                  <button
                                    type="button"
                                    className="btn btn-danger fs-14"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleConfirmDelete(null);
                                    }}
                                    disabled={countRowSelected <= 0}
                                  >
                                    <i className="ri-delete-bin-5-line align-bottom me-1"></i>{" "}
                                    {countRowSelected > 0 && (
                                      <span className="position-absolute topbar-badge badge rounded-pill bg-warning"
                                        style={{ transform: 'translate(0%, -70%)' }}
                                      >
                                        {formatNumberWithCommas(countRowSelected)}
                                        <span className="visually-hidden">total keywords selected</span>
                                      </span>)}
                                    {t('Button Delete Keywords')}
                                  </button>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      {!!detailCategory?.value && (
                        <Col lg={isFullRowEnterKeywords ? 12 : 5}>
                          <div
                            className="position-relative"
                            style={{
                              backgroundColor: "var(--vz-topbar-search-bg)",
                              padding: "20px 10px 20px",
                              borderRadius: "5px",
                            }}
                          >
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row g-3">
                                <Col md={12} lg={12}>
                                  <div className="text-start">
                                    <textarea
                                      ref={textareaKeyWordsRef}
                                      rows={3}
                                      id="keywords"
                                      name="keywords"
                                      className="form-control"
                                      autoFocus={true}
                                      autoComplete="off"
                                      style={{ minHeight: "85px" }}
                                      value={initialValueKeywords || ""}
                                      placeholder={`${t(
                                        "Keywords list which is separated by new line"
                                      )}...`}
                                      onChange={(event: any) =>
                                        formik.setFieldValue(
                                          "keywords",
                                          event?.target?.value || ""
                                        )
                                      }
                                    />
                                    {/* {formik.touched.keywords && formik.errors.keywords ? (
                                  <div className="text-danger mt-2">{formik.errors.keywords}</div>
                                ) : null} */}
                                  </div>
                                </Col>
                                <Col md={12} xxl={12} lg={12}>
                                  <div className="d-flex align-items-start gap-3 mt-2">
                                    <button
                                      type="submit"
                                      className="btn btn-success ms-auto "
                                      disabled={
                                        isUpdateKeywordsLoading ||
                                        String(initialValueKeywords).trim() === ""
                                      }
                                    >
                                      {isUpdateKeywordsLoading ? (
                                        <i className="label-icon align-middle">
                                          <Spinner
                                            size="sm"
                                            className="me-2"
                                          ></Spinner>
                                        </i>
                                      ) : (
                                        <i className="ri-login-circle-line align-bottom me-1"></i>
                                      )}
                                      {t("Button Add Keywords")}
                                    </button>
                                  </div>
                                </Col>
                              </div>
                            </form>
                            <button
                              type="button"
                              className="btn btn-ghost-secondary ms-auto btn-sm fs-14 position-absolute"
                              style={{ left: "0px", bottom: "0px" }}
                              onClick={() =>
                                setIsFullRowEnterKeywords((prev) => !prev)
                              }
                            >
                              <i
                                className={`${isFullRowEnterKeywords
                                  ? "ri-picture-in-picture-exit-line"
                                  : "ri-picture-in-picture-2-line"
                                  } align-bottom me-1`}
                                style={{ fontSize: "18px" }}
                              ></i>
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={trendIndexes?.list?.length ? trendIndexes?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(
                          Number(trendIndexes?.pagination?.total) /
                          Number(trendIndexes?.pagination?.limit)
                        )}
                        customPageIndex={query.page - 1}
                        totalRecords={trendIndexes?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isTrendIndexLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={isOpen}
          id="firstmodal"
          centered
          size="lg"
          scrollable={true}
          toggle={onCloseClick}
          keyboard={true}
        >
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
            {t("Button Autocomplete Keywords")}
          </ModalHeader>
          <ModalBody className="text-center">
            <div className="row g-4">
              <Col md={12} className="mt-2">
                <div className="d-flex flex-row flex-wrap">
                  {autocompleteKeywords?.keywords?.map((item, index) => (
                    <ListGroupItem
                      key={index}
                      tag="label"
                      className="px-4 py-3 text-start item-keyword-suggestion"
                    >
                      {/* <Input
                        className="form-check-input me-1"
                        type="checkbox"
                        value={item}
                        checked={isChecked(item)}
                        onChange={() => handleChecked(item)}
                      /> */}
                      {item}
                    </ListGroupItem>
                  ))}
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <button
                    className="btn btn-light fs-14 me-2"
                    type="button"
                    color="light"
                    onClick={onCloseClick}
                  >
                    <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                    {t("Button Close")}
                  </button>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!relatedKeyword?.id}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseRelatedKeywordClick}>
            <>{t("Button Related Keywords")} - {relatedKeyword?.keyword}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <RelatedKeyword
                id={Number(relatedKeyword?.id || '')}
              />
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={!initialValuesDefault?.id ? t("Button Delete Keywords") : t("Button Delete Keyword")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default TrendIndexKeyword;
