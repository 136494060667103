import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ModalCollapseTag from "./ModalCollapseTag";

interface CollapseTagProps {
    tags?: string[] | { keyword: string, rank: string | number }[],
    totalShow?: number,
    isRank?: boolean,
    isInlineBlock?: boolean,
    isShowModal?: boolean,
    titleModal?: string,
    isBackground?: boolean,
    style?: any,
    className?: string
}

const CollapseTag = ({
    tags = [],
    totalShow = 10,
    isRank = false,
    isInlineBlock = false,
    isShowModal = false,
    titleModal = '',
    isBackground = true,
    style = {},
    className = '',
}: CollapseTagProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const listShow = tags?.filter((item, index) => index < totalShow) || [];
    const listHidden = tags?.filter((item, index) => index >= totalShow) || [];
    function onCloseClick() {
        setIsOpenModal((_prev) => !_prev);
    }
    return (
        <React.Fragment>
            {(tags?.length > 0) ? (<div className={`d-flex flex-wrap ${(isRank || isInlineBlock) && 'flex-column'} ${className}`} style={style}>
                {(listShow?.length > 0) && listShow?.map((item: any, index: number) => (<span key={index}><span className={`${isBackground ? 'tag-style' : ''}`}>{isRank ? `${item?.keyword}` : `${item}`}</span>{isRank && (<>: {item?.rank} <br /> </>)}</span>))}
                {isOpen && <React.Fragment>
                    {(listHidden?.length > 0) && listHidden?.map((item: any) => (<span><span className={`${isBackground ? 'tag-style' : ''}`}>{isRank ? `${item?.keyword}` : `${item}`}</span>{isRank && (<>: {item?.rank} <br /> </>)} </span>))}
                </React.Fragment>}
                {(listHidden?.length > 0) && <Link
                    to="#"
                    onClick={(e) => { e.preventDefault(); isShowModal ? onCloseClick() : setIsOpen((prev) => !prev) }}
                    style={{ cursor: "pointer", verticalAlign: 'middle' }}
                    className="link-primary d-contents fs-12" >
                    <div className="d-flex align-items-center">
                        <span>{isOpen ? t('Hidden') : t('More')}</span><i className={`${isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} fs-16`}></i>
                    </div>
                </Link>}
            </div>) : <></>}
            <ModalCollapseTag
                isOpen={isOpenModal}
                scrollable={true}
                onCloseClick={onCloseClick}
                keyboard={true}
                titleModal={titleModal}
                tags={tags}
            />
        </React.Fragment>
    );
};

export default CollapseTag;