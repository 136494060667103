import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICampaign, ICampaignGroup } from "./types/_campaign";
const api = new APIClient();

const path = '/campaigns';
const campaignApi = {
  campaigns(params: any):Promise<AxiosResponse<ResponseData<ICampaign[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  campaign(id:number | string, params: any = {}):Promise<AxiosResponse<ICampaign, any>>{
    const url = `${path}/${id}`
    return api.get(url, params)
  },
  postCampaign(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putCampaign(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  putCampaignStatus(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/status`;
    return api.put(url, dataForm)
  },
  postCampaignMultiMedia(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/multiple`;
    return api.post(url, dataForm)
  },
  putCampaignMultiMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/multiple/${id}`;
    return api.put(url, dataForm)
  },
  deleteCampaign(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  exportFileCampaign(params: any = {}):Promise<AxiosResponse<ICampaign, any>>{
    const url = `exports/campaigns`
    return api.get(url, params)
  },
  campaignGroups(params: any):Promise<AxiosResponse<ResponseData<ICampaignGroup[]> & PaginationResponse, any>> {
    const url = `/campaign-groups`
    return api.get(url, params)
  },
  postCampaignGroups(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `/campaign-groups`;
    return api.post(url, dataForm)
  },
  campaignGroup(id:number | string, params: any = {}):Promise<AxiosResponse<ICampaignGroup, any>>{
    const url = `campaign-groups/${id}`
    return api.get(url, params)
  },
  putCampaignGroupMultiMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `campaign-groups/${id}`;
    return api.put(url, dataForm)
  },
  deleteCampaignGroup(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `campaign-groups/${id}`;
    return api.delete(url, dataForm)
  },
  postCampaignSports(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `campaign-sports`;
    return api.post(url, dataForm)
  },
  getCampaignSports(params: any = {}):Promise<AxiosResponse<ICampaignGroup, any>>{
    const url = `campaign-sports`
    return api.get(url, params)
  },
  deleteTeamInCampaignSport(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `campaign-sports`;
    return api.delete(url, {data: dataForm})
  },
}
export default campaignApi
