import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getSettlements as onGetSettlements, deleteSettlement } from "../../../store/thunks";
import { ISettlement } from 'api/types/_settlement';


import iconDelete from "../../../assets/images/gif/trash-bin.gif";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import ModalConfirm from 'components/Common/ModalConfirm';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'sales': StringParam,
  'audience': StringParam,
  'screen': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'audience';

registerLocale('en', en);
registerLocale('ko', ko);


const SettlementList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    // sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    // order_by: withDefault(StringParam, `DESC`),
    date_version: withDefault(StringParam, ''),
    name: StringParam,
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<ISettlement | null>(null);

  const [dateSearch, setDateSearch] = useState<string>(query?.date || "");
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Settlement;

  const SettlementProperties = createSelector(
    selectLayoutState,
    (state) => ({
      Settlements: state.Settlements,
      isSettlementSuccess: state.isSettlementSuccess,
      isSettlementLoading: state.isSettlementLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { Settlements, error, isSettlementLoading } = useSelector(SettlementProperties);

  useEffect(() => {
    dispatch(onGetSettlements(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    const rangeQuery = (fromRangeSearch && toRangeSearch) ? `gt:${fromRangeSearch}-lt:${toRangeSearch}` : undefined;
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      // sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      date_version: dateSearch,
    });
  };

  const resetData = () => {
    setQuery({
      name: undefined,
      // sort_by: undefined,
      category_id: undefined,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
    setFromRangeSearch("");
    setToRangeSearch("");
    setDateSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Settlement Name'),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      // {
      //   Header: t('Status'),
      //   accessor: "status",
      //   filterable: false,
      //   sortable: false,
      //   Cell: (cell: any) => {
      //     const value: any = cell?.row?.original;
      //     const status = value?.status;
      //     const color = (status === 1) ? 'primary' : (status === 2 ? 'success' : 'danger');
      //     return (<span className={`badge bg-${color}-subtle text-${color} badge-border`}>{`${t(`Status_Settlement_${status}`)}`}</span>);
      //   }
      // },
      {
        Header: t('Keywords'),
        accessor: "keywords",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const keywords = value?.keywords;
          let keywordName: string = "";
          for (let item of keywords) {
            keywordName += `${item.keyword} <br />`;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: keywordName }} />
          );
        }
      },
      {
        Header: t('Hashtags'),
        accessor: "tag_related_news",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const tag_related_news = ((value?.tag_related_news || '').split(',') || []);
          let tagName: string = "";
          for (let item of tag_related_news) {
            tagName += `${item} <br />`;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: tagName }} />
          );
        }
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      // {
      //   Header: t('Button Action'),
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul className="list-inline hstack gap-2 mb-0">
      //         <li className="list-inline-item" title="Edit">
      //           <Link className="edit-item-btn" to={`/Settlement/${item?.id}`}>
      //             <i className="ri-pencil-fill align-bottom text-muted"></i>
      //           </Link>
      //         </li>
      //         <li className="list-inline-item" title={t('Button Delete Settlement')}>
      //           <Link className="edit-item-btn" to="#"
      //             onClick={() => handleConfirmDelete(item)}
      //           >
      //             <i className="ri-delete-bin-3-fill align-bottom text-muted"></i>
      //           </Link>
      //         </li>
      //       </ul>
      //     );
      //   },
      // },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: ISettlement) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteSettlement(initialValuesDefault?.id);
      if (response?.code === 200) {
        resetData();
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  useEffect(() => {
    document.title = `${t('Settlement')} - ${t('Settlement')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Settlement')} pageTitle={t('Media')} />
          <Row>
            <Col lg={12}>
              <div className='text-center mt-5'>
                {t('Under development')}
              </div>
              {/* <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => navigate('/Settlement/create')}
                      >
                        <i className="ri-upload-2-line align-bottom me-1"></i>{" "}
                        {t('Button Create Settlement')}
                      </button>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2">
                    <Col sm={12} lg={4}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('Settlement Name')}...`}
                        value={keywordSearch}
                        onChange={(e) => setKeywordSearch(e.target.value)}
                      />
                    </Col>
                    <Col sm={12} lg={8} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isSettlementLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        onClick={() => navigate('/Settlement/create')}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t('Button Create Settlement')}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={Settlements?.list?.length ? Settlements?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={Settlements?.pagination?.total}
                      customPageCount={Math.ceil(Number(Settlements?.pagination?.total) / Number(Settlements?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isSettlementLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
        <ModalConfirm
          header={t('Button Delete Settlement')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default SettlementList;
