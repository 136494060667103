import { createAsyncThunk } from "@reduxjs/toolkit";
import coinApi from "api/coinApi";
import { formatQueryParams } from "helpers/format";

export const getCoins = createAsyncThunk("coins", async (params: any = {}) => {
    try {
      const response = await coinApi.coins(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllCoins = async (params: any = {}) => {
  try{
    const response = await coinApi.allCoins(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const getAllCategoriesCoinNormal = async (params: any = { type: "coin" }) => {
  try {
    const response = await coinApi.searchCategories(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCoinKeywordsByNormal = async (params: any = {}) => {
  try {
    const response = await coinApi.coins(params);
    return response;
  } catch (error) {
    return error;
  }
};