import { IGraphic } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteGraphic, getAllParameters as onGetAllParameters, getGraphics as onGetGraphics, postGraphic, putGraphic } from "../../../store/thunks";

import ModalConfirm from 'components/Common/ModalConfirm';
import { useRole } from 'components/Hooks/UserHooks';
import { SCHEDULE_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import CountUp from 'react-countup';
import LabelWrapper from 'components/Common/LabelWrapper';

interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'id': StringParam,
  'schedule': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';
registerLocale('en', en);
registerLocale('ko', ko);

const TemplateListInfoGraphic = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.reduce((arr: any, item: any) => {
    return item?.value !== 'now' ? ([...arr, ({ value: item?.value, label: t(item?.label) })]) : arr;
  }, []);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    name: StringParam,
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFullView, setIsFullView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [initialValuesDefault, setInitialValuesDefault] = useState<IGraphic | null>(null);

  const [linkImage, setLinkImage] = useState<string>('');

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => {
    console.log('state', state);
    return state.Template
  };

  const GraphicProperties = createSelector(
    selectLayoutState,
    (state) => ({
      graphics: state.graphics,
      parameters: state.allParameter,
      isGraphicSuccess: state.isGraphicSuccess,
      isGraphicLoading: state.isGraphicLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { graphics, parameters, error, isGraphicLoading } = useSelector(GraphicProperties);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      name: '',
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    }, "push")
    setKeywordSearch("");
  };

  // Form
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        name: values?.name ?? '',
        name_korea: values?.name_korea ?? '',
      };
      const response: any = !!(initialValuesDefault?.id) ? await putGraphic(initialValuesDefault?.id, data) : await postGraphic(data);
      if (response?.data) {
        dispatch(onGetGraphics(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleResetForm();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t('Info-Graphic Name is required')}`),
    name_korea: Yup.string().required(`${t('Info-Graphic Name Korea is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      name_korea: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEditTemplate = (valueDefault: IGraphic) => {
    setInitialValuesDefault((_prev) => valueDefault);
    setIsOpen((_prev) => true);
    formik.setFieldValue('name', valueDefault?.name);
    formik.setFieldValue('name_korea', valueDefault?.name_korea);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleResetForm = () => {
    formik.setFieldValue('name', null);
    formik.setFieldValue('name_korea', null);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleCreateTemplate = () => {
    setInitialValuesDefault((_prev) => null);
    setIsOpen((_prev) => true);
    handleResetForm();
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IGraphic) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteGraphic(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetGraphics(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen((_prev) => false);
    setInitialValuesDefault((_prev) => null);
    setTimeout(() => {
      formik.setFieldValue('name', null);
      formik.setFieldValue('name_korea', null);
    }, 300);
  };


  const onCloseImageClick = (e: any) => {
    setLinkImage((_prev) => '');
  };

  // Effect
  useEffect(() => {
    dispatch(onGetGraphics(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(onGetAllParameters({}));
  }, []);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: true,
        thWidth: 70,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '60px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Image'),
        accessor: "image_url",
        thClass: 'text-center',
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => (
          <>
            <div className="w-100 text-center " style={{ minWidth: '150px' }}>
              <div style={{ width: '90px' }} className="m-auto cursor-pointer" onClick={() => setLinkImage((prev) => cell?.value)}>
                <img src={cell?.value} className="flex-shrink-0 rounded w-100" alt="chart" />
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="limit-text-to-two-line cursor-pointer" style={{ minWidth: '100px' }}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Name Korea'),
        accessor: "name_korea",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="limit-text-to-two-line cursor-pointer" style={{ minWidth: '130px' }}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 200,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 200,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },

    ],
    [i18n?.language]
  );


  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  useEffect(() => {
    document.title = `${t('Info-Graphic')} - ${t('Template')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Info-Graphic')} pageTitle={t('Template')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.INFO_GRAPHIC_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-0 bg-primary-subtle text-primary border-0 d-none d-md-block" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isGraphicLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={graphics?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-0">
                          <Col sm={12} md={4} lg={3}>
                            <LabelWrapper label={t('Schedule')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Name')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isGraphicLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={graphics?.list?.length ? graphics?.list : []}
                        customPageSize={query.limit}
                        customPageCount={Math.ceil(Number(graphics?.pagination?.total) / Number(graphics?.pagination?.limit))}
                        customPageIndex={query.page - 1}
                        totalRecords={graphics?.pagination?.total}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isGraphicLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={isOpen} centered={true} size="md" scrollable={true}>
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
            {t('Info-Graphic')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row g-3">
                    <Col xxl={12}>
                      <label className="form-label">{t('Name')}<span className="text-danger"> *</span></label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik?.values?.name}
                        placeholder={`${t('Name')}...`}
                        onChange={(event: any) => formik.setFieldValue('name', event?.target?.value || '')}
                      />
                      {formik.touched.name && formik.errors.name ? (<div className="text-danger mt-2">{formik.errors.name}</div>) : null}
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">{t('Name Korea')}<span className="text-danger"> *</span></label>
                      <input
                        className="form-control"
                        id="name_korea"
                        name="name_korea"
                        value={formik?.values?.name_korea}
                        placeholder={`${t('Name Korea')}...`}
                        onChange={(event: any) => formik.setFieldValue('name_korea', event?.target?.value || '')}
                      />
                      {formik.touched.name_korea && formik.errors.name_korea ? (<div className="text-danger mt-2">{formik.errors.name_korea}</div>) : null}
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isLoading}>
                          {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : (!!(initialValuesDefault?.id) ? <i className="ri-login-circle-line align-bottom me-1"></i> : <i className="ri-add-fill align-bottom me-1"></i>)}
                          {!!(initialValuesDefault?.id) ? t('Button Update Info-Graphic') : t('Button Create Info-Graphic')}
                        </button>
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseClick} disabled={isLoading}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={!!linkImage} centered={true} size="lg" scrollable={true} toggle={onCloseImageClick} keyboard={true}>
          <ModalBody className="">
            <img src={linkImage || ''} className="flex-shrink-0 rounded w-100" alt="chart" />
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={t('Button Delete Info-Graphic')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default TemplateListInfoGraphic;