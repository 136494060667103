import { createSlice } from "@reduxjs/toolkit";
import { IUserActivityLog } from "api/types/_logging";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getUserActivityLogs } from "./thunk";


export interface IState {
  userActivityLogs: ResponseData<IUserActivityLog[]> & PaginationResponse | null,
  isUserActivityLogLoading: boolean,
  isUserActivityLogSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  userActivityLogs: null,
  isUserActivityLogLoading: false,
  isUserActivityLogSuccess: false,

  error: {},
};

const LoggingSlice = createSlice({
  name: "Logging",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get User Activity Logs
    builder.addCase(getUserActivityLogs.pending, (state: IState, action: any) => {
      state.isUserActivityLogLoading = true
    });
    builder.addCase(getUserActivityLogs.fulfilled, (state: IState, action: any) => {
      state.userActivityLogs = action.payload.data;
      state.isUserActivityLogSuccess = true;
      state.isUserActivityLogLoading = false;
    });
    builder.addCase(getUserActivityLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUserActivityLogSuccess = false;
      state.isUserActivityLogLoading = false;
    });

  },
});


export default LoggingSlice.reducer;