import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { IMedia } from "api/types/_media";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { formatNumber, formatNumberWithCommas } from "helpers/format";
import TingSearchKeyword from "pages/Campaign/KeywordAnalyzingNews/TingSearch";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDraggable } from "react-use-draggable-scroll";
import { updateCarKeyword } from "store/thunks";

interface TableCarKeywordProps {
  medias?: IMedia[];
  className?: any;
  summary?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string; order_by: string };
  handleChangeSorting?: (sortBy: {
    sort_by: string | undefined | null;
    order_by: string | undefined | null;
  }) => void;
  manualSorting?: boolean;
  isLoading?: boolean;
  isShowPagination?: boolean;
  isShowLoadingBottom?: boolean;
  isShowColumn?: boolean;
  classBackgroundWhenActiveSort?: string;
  isScrollToTop?: boolean;
  triggerListData?: () => void;
}

const TableCarKeyword = ({
  medias = [],
  summary,
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = "",
  isScrollToTop = true,
  triggerListData,
}: TableCarKeywordProps) => {
  const { t } = useTranslation();

  const TYPE_SHOW_OPTIONS_LANG = [
    { value: "brand", label: t("Brand") },
    { value: "model", label: t("Model") },
  ];

  const STOCK_STATUS_OPTIONS_LANG = [{ value: '1', label: t('In stock') }, { value: '0', label: t('Out of stock') }];

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);
  const [initialValueKeyword, setInitialValueKeyword] = useState<string>("");
  const [isOpenSearching, setIsOpenSearching] = useState<boolean>(false);
  const [newKeyword, setNewKeyword] = useState<any>({});
  const [isLoadingUpdateKeyword, setIsLoadingUpdateKeyword] = useState<boolean>(false);

  const thRefs = useRef<any>([]); // Fixed Header Table
  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementHeaderFixedRef = useRef<any>(null); // Fixed Header Table
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] = useState<boolean>(false); // Fixed Header Table

  const { events } = useDraggable(warperTableRef);

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft);
    if (warperTableRef.current && elementFixedRef.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
        elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft; // Fixed Header Table
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if (scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }

      // Begin::Fixed Header Table
      const rect = warperTableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top;
      if (distanceFromTop <= 70) {
        elementHeaderFixedRef.current.scrollLeft = elementFixedRef?.current?.scrollLeft;
        setIsShowHeaderTableFixed((prev) => true);
      } else {
        setIsShowHeaderTableFixed((prev) => false);
      }
      // End::Fixed Header Table

    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const generateSortingIndicator = (column: string = "") => {
    return column ? (
      <span
        style={{
          display: "inline-flex",
          justifyContent: "center",
          width: "12px",
          marginLeft: "2px",
          verticalAlign: "middle",
        }}
      >
        {column === sorting?.sort_by && sorting?.order_by === "ASC" && (
          <i className={`ri-arrow-up-line fs-13 text-primary`} />
        )}
        {column === sorting?.sort_by && sorting?.order_by === "DESC" && (
          <i className={`ri-arrow-down-line fs-13 text-primary`} />
        )}
      </span>
    ) : (
      ""
    );
  };
  const handleAddKeywordFromSearching = (str: string) => {
    setIsOpenSearching((prev) => false);
  };

  const handleUpdateKeyword = async (id: any) => {
    setIsLoadingUpdateKeyword((prev) => true);
    try {
      const res: any = await updateCarKeyword(id, {
        keyword: newKeyword?.value,
      });
      if (res?.code === 200) {
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        setNewKeyword({});
        triggerListData && triggerListData();
      } else {
        toast(res?.message || res, CONFIG_OPTION_TOAST_ERROR);
      }
      setIsLoadingUpdateKeyword(false);
    } catch (error: any) {
      toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
      setIsLoadingUpdateKeyword(false);
    }
  };
  return (
    <Fragment>
      <div
        className={`table-custom-pagination ${divClass}`}  {...events} ref={warperTableRef} onScroll={handleScroll}>
        <Table
          hover
          className={`${tableClass} ${Number(scrollLeft || 0) > 0 && "is-scroll"}`}
          style={{ minHeight: "70px" }}
        >
          <thead className={theadClass} ref={tableRef}>
            <tr className={trClass}>
              <th
                className={`text-center cursor-pointer align-middle ${thClass || ""
                  }`}
                style={{ width: 150 }}
                ref={el => thRefs.current[0] = el} // Fixed Header Table
              >
                {t("Date Car")}
              </th>
              <th
                className={`text-start cursor-pointer align-middle text-start`}
                style={{ width: 150 }}
                ref={el => thRefs.current[1] = el} // Fixed Header Table
              >
                {t("Name")}
              </th>
              <th
                className={`text-start cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[2] = el} // Fixed Header Table
              >
                {t("Keyword")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[3] = el} // Fixed Header Table
              >
                {t("Type")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[4] = el} // Fixed Header Table
              >
                {t("Brand")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle`}
                style={{ width: 100 }}
                ref={el => thRefs.current[5] = el} // Fixed Header Table
              >
                {t("Car Type")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle`}
                style={{ width: 100 }}
                ref={el => thRefs.current[6] = el} // Fixed Header Table
              >
                {t("Stock Status")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[7] = el} // Fixed Header Table
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = { sort_by: "sale", order_by: "DESC" };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "sale",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "sale"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Sale")}
                {generateSortingIndicator("sale")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[8] = el} // Fixed Header Table
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = { sort_by: "sale_ratio", order_by: "DESC" };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "sale_ratio",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "sale_ratio"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Sale Ratio")}
                {generateSortingIndicator("sale_ratio")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[9] = el} // Fixed Header Table
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_month_sale_change",
                      order_by: "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_month_sale_change",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "prev_month_sale_change"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Previous Month Sales Change")}
                {generateSortingIndicator("prev_month_sale_change")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                style={{ width: 200 }}
                ref={el => thRefs.current[10] = el} // Fixed Header Table
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_year_sale_change",
                      order_by: "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_year_sale_change",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "prev_year_sale_change"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Previous Year Sales Change")}
                {generateSortingIndicator("prev_year_sale_change")}
              </th>
              <th
                className={`cursor-pointer align-middle text-end`}
                style={{ width: 120 }}
                ref={el => thRefs.current[11] = el} // Fixed Header Table
              >
                {t("Created at")}
              </th>
              <th
                className={`tcursor-pointer align-middle text-end`}
                style={{ width: 120 }}
                ref={el => thRefs.current[12] = el} // Fixed Header Table
              >
                {t("Updated at")}
              </th>
            </tr>
            <tr>
              <th
                colSpan={8}
                className="py-0 px-0"
                style={{ borderBottomColor: "transparent" }}
              >
                <div
                  className={`infinite-loading-outer ${isLoading && "is-show-loading"
                    }`}
                >
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: any, index: number) => (
              <>
                <tr key={item?.domain}>
                  <td className="text-center" role="cell" style={{}}>
                    <div style={{ minWidth: "70px" }}>
                      {item?.date_version ?? "-"}
                    </div>
                  </td>
                  <td className="text-center" role="cell" style={{}}>
                    <div className="d-flex">
                      {(item?.model?.image_url || item?.brand?.image_url) && (
                        <img
                          alt={item?.model?.name || item?.brand?.name || "-"}
                          loading="lazy"
                          className="me-2"
                          src={item?.model?.image_url || item?.brand?.image_url}
                          height={30}
                          width={"auto"}
                        ></img>
                      )}
                      <div
                        className="text-start fw-semibold text-primary"
                        style={{ minWidth: "100px", lineHeight: "30px" }}
                        onClick={() => {
                          setInitialValueKeyword(item?.name);
                          setIsOpenSearching((prev) => true);
                        }}
                      >
                        {item?.model?.name || item?.brand?.name || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="text-center" role="cell" style={{}}>
                    <div
                      className="position-relative"
                      style={{ minWidth: "200px" }}
                    >
                      <Input
                        type="text"
                        className={`form-control text-start ${(newKeyword?.value !== undefined &&
                          newKeyword?.id === item?.id &&
                          item?.keyword !== newKeyword?.value) ||
                          item?.name !== item?.keyword
                          ? "text-danger"
                          : ""
                          }`}
                        placeholder={`${t("Keyword")}`}
                        value={
                          newKeyword?.id === item?.id
                            ? newKeyword?.value
                            : item?.keyword || ""
                        }
                        onChange={(e) => {
                          setNewKeyword({
                            value: e.target.value,
                            id: item?.id,
                          });
                        }}
                      />
                      {newKeyword?.id === item?.id && (
                        <button
                          type="button"
                          className="d-flex justify-content-center position-absolute bottom-0 end-0 btn btn-success text-center"
                          onClick={() => handleUpdateKeyword(item?.keyword_id)}
                          style={{
                            width: "20px",
                            height: "38px",
                          }}
                        >
                          {isLoadingUpdateKeyword ? (
                            <div>
                              <Spinner size="sm"></Spinner>
                            </div>
                          ) : (
                            <i className="ri-login-circle-line mx-auto"></i>
                          )}
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="text-center" style={{ minWidth: "60px" }}>
                      {TYPE_SHOW_OPTIONS_LANG?.find(
                        (type: any) =>
                          type?.value === (item?.model ? "model" : "brand")
                      )?.label || ""}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="text-center" style={{ minWidth: "60px" }}>
                      {item?.brand?.name ?? "-"}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="d-flex gap-1" style={{ minWidth: "100px" }}>
                      {item?.car_types?.map((e: any) => (
                        <div style={{ minWidth: 100 }}>
                          <span className="badge bg-primary pt-1">
                            {e?.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div className="text-center" style={{ minWidth: "90px" }}>
                      {STOCK_STATUS_OPTIONS_LANG?.find((status: any) => status?.value === (item?.sale > 0 ? "1" : "0"))?.label || ""}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div
                      className="text-center text-primary fw-medium"
                      style={{ minWidth: "90px" }}
                    >
                      {formatNumber(item?.sale || 0)}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div
                      className="text-center fw-medium pe-3"
                      style={{ minWidth: "90px" }}
                    >
                      {item?.sale_ratio}%
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div
                      className="text-center fw-medium pe-3"
                      style={{ minWidth: "90px" }}
                    >
                      {Number(item?.prev_month_sale_change) === 0 && (
                        <div className="text-end pe-3">0</div>
                      )}
                      {Number(item?.prev_month_sale_change) < 0 && (
                        <div
                          className={`text-end fw-medium pe-3`}
                          style={{ minWidth: "90px" }}
                        >
                          <div>{formatNumber(item?.prev_month_sale || 0)}</div>
                          <div className="text-primary fs-13">
                            {formatNumber(
                              item?.prev_month_sale_change * -1 || 0
                            )}{" "}
                            <span style={{ fontSize: "9px" }}>{"▼"}</span>
                          </div>
                        </div>
                      )}
                      {Number(item?.prev_month_sale_change) > 0 && (
                        <div
                          className={`text-end fw-medium pe-3 
                     
                   `}
                          style={{ minWidth: "90px" }}
                        >
                          <div>{formatNumber(item?.prev_month_sale || 0)}</div>
                          <div className="text-danger fs-13">
                            {formatNumber(item?.prev_month_sale_change || 0)}{" "}
                            <span style={{ fontSize: "10px" }}>{"▲"}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="text-center" role="cell">
                    <div
                      className="text-center fw-medium pe-3"
                      style={{ minWidth: "90px" }}
                    >
                      {Number(item?.prev_year_sale_change) === 0 && (
                        <div className="text-end pe-3">0</div>
                      )}
                      {Number(item?.prev_year_sale_change) < 0 && (
                        <div
                          className={`text-end fw-medium pe-3`}
                          style={{ minWidth: "90px" }}
                        >
                          <div>{formatNumber(item?.prev_year_sale || 0)}</div>
                          <div className="text-primary fs-13">
                            {formatNumber(
                              item?.prev_year_sale_change * -1 || 0
                            )}{" "}
                            <span style={{ fontSize: "9px" }}>{"▼"}</span>
                          </div>
                        </div>
                      )}
                      {Number(item?.prev_year_sale_change) > 0 && (
                        <div
                          className={`text-end fw-medium pe-3 
                     
                   `}
                          style={{ minWidth: "90px" }}
                        >
                          <div>{formatNumber(item?.prev_year_sale || 0)}</div>
                          <div className="text-danger fs-13">
                            {formatNumber(item?.prev_year_sale_change || 0)}{" "}
                            <span style={{ fontSize: "10px" }}>{"▲"}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      <div
                        className="ms-4 text-end"
                        style={{ minWidth: "100px" }}
                      >
                        <span>
                          <span>
                            {String(item?.created_at)?.split(" ")?.[0] || ""}
                          </span>{" "}
                          <br />{" "}
                          <span className="text-secondary">
                            {String(item?.created_at)?.split(" ")?.[1] || ""}
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div
                        className="ms-4 text-end"
                        style={{ minWidth: "100px" }}
                      >
                        <span>
                          <span>
                            {String(item?.updated_at)?.split(" ")?.[0] || ""}
                          </span>{" "}
                          <br />{" "}
                          <span className="text-secondary">
                            {String(item?.updated_at)?.split(" ")?.[1] || ""}
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Begin::Fixed Header Table */}
      <div
        className={`table-card overflow-x-auto m-0 hidden-scrollbar-os ${isShowHeaderTableFixed ? '' : 'd-none'}`}
        style={{
          position: 'fixed',
          top: 70,
          zIndex: 9,
          left: elementWarperTable?.left,
          width: elementWarperTable?.width,
          right: 0,

        }}
        ref={elementHeaderFixedRef}
      >
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'} mb-0`} style={{ height: 'auto', tableLayout: 'fixed' }} >
          <thead className={theadClass} style={{ width: elementTable?.width }}>
            <tr className={trClass}>
              <th
                className={`text-center cursor-pointer align-middle ${thClass || ""
                  }`}
                {...{ width: thRefs.current[0]?.offsetWidth }}
              >
                {t("Date Car")}
              </th>
              <th
                className={`text-start cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[1]?.offsetWidth }}
              >
                {t("Name")}
              </th>
              <th
                className={`text-start cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[2]?.offsetWidth }}
              >
                {t("Keyword")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[3]?.offsetWidth }}
              >
                {t("Type")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[4]?.offsetWidth }}
              >
                {t("Brand")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle`}
                {...{ width: thRefs.current[5]?.offsetWidth }}
              >
                {t("Car Type")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle`}
                {...{ width: thRefs.current[6]?.offsetWidth }}
              >
                {t("Stock Status")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[7]?.offsetWidth }}
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = { sort_by: "sale", order_by: "DESC" };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "sale",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "sale"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Sale")}
                {generateSortingIndicator("sale")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[8]?.offsetWidth }}
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = { sort_by: "sale_ratio", order_by: "DESC" };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "sale_ratio",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "sale_ratio"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Sale Ratio")}
                {generateSortingIndicator("sale_ratio")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[9]?.offsetWidth }}
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_month_sale_change",
                      order_by: "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_month_sale_change",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "prev_month_sale_change"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Previous Month Sales Change")}
                {generateSortingIndicator("prev_month_sale_change")}
              </th>
              <th
                className={`text-center cursor-pointer align-middle text-start`}
                {...{ width: thRefs.current[10]?.offsetWidth }}
                onClick={(e) => {
                  if (!sorting?.sort_by || !sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_year_sale_change",
                      order_by: "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = {
                      sort_by: "prev_year_sale_change",
                      order_by:
                        sorting?.order_by === "DESC" &&
                          sorting?.sort_by === "prev_year_sale_change"
                          ? "ASC"
                          : "DESC",
                    };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t("Previous Year Sales Change")}
                {generateSortingIndicator("prev_year_sale_change")}
              </th>
              <th
                className={`cursor-pointer align-middle text-end`}
                {...{ width: thRefs.current[11]?.offsetWidth }}
              >
                {t("Created at")}
              </th>
              <th
                className={`tcursor-pointer align-middle text-end`}
                {...{ width: thRefs.current[12]?.offsetWidth }}
              >
                {t("Updated at")}
              </th>
            </tr>
          </thead>
        </Table>
      </div>
      {/* End::Fixed Header Table */}

      <div
        className="overflow-x-auto color-scrollbar-os"
        style={{
          position: "fixed",
          zIndex: 999,
          left: elementWarperTable?.left,
          bottom: 0,
          width: elementWarperTable?.width,
          right: 0,
          opacity: isScrollBottom ? 0 : 1,
        }}
        ref={elementFixedRef}
        onScroll={handleScroll}
      >
        <div style={{ width: elementTable?.width, height: "1px" }}></div>
      </div>
      {isShowPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {t("Total")}:{" "}
              <span className="fw-semibold ms-1">
                {formatNumberWithCommas(totalRecords)}
              </span>{" "}
              {t("Results")}
            </div>
          </div>
          <div className="col-sm-auto">
            <ReactPaginate
              nextLabel={`${t("next")} >`}
              onPageChange={(page) => {
                if (handleChangePage) {
                  isScrollToTop && handleScrollToTop();
                  handleChangePage(page.selected);
                }
              }}
              forcePage={+customPageIndex}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={customPageCount}
              previousLabel={`< ${t("previous")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-separated"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      )}
      <Modal
        isOpen={isOpenSearching}
        centered={true}
        size="xl"
        className="modal-fullscreen-r"
        scrollable={true}
      >
        <ModalHeader toggle={() => setIsOpenSearching((prev) => !prev)}>
          {t("Ting Search")}
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: "calc(100dvh - 200px)" }}>
            <TingSearchKeyword
              isShowButtonSelect={false}
              isOpen={isOpenSearching}
              keywordSearch={initialValueKeyword || ""}
              handleAddKeyword={handleAddKeywordFromSearching}
            />
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer closeButton={false} limit={1} />
    </Fragment>
  );
};

TableCarKeyword.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableCarKeyword);
