import { createSlice } from "@reduxjs/toolkit";
import { getCategoriesStock, getStocks } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICategoryStock, IStockStatistics } from "api/types/_stock";

export interface IState {
  stocks: ResponseData<IStockStatistics[]> & PaginationResponse | null,
  isStocksLoading: boolean,
  isStocksSuccess: boolean,

  categories: ResponseData<ICategoryStock[]> & PaginationResponse | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  stocks: null,
  isStocksLoading: false,
  isStocksSuccess: false,

  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,

  error: {},
};

const StockSlice = createSlice({
  name: "Stock",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Stock
    builder.addCase(getStocks.pending, (state: IState, action: any) => {
      state.isStocksLoading = true
    });
    builder.addCase(getStocks.fulfilled, (state: IState, action: any) => {
      state.stocks = action.payload.data;
      state.isStocksSuccess = true;
      state.isStocksLoading = false;
    });
    builder.addCase(getStocks.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isStocksSuccess = false;
      state.isStocksLoading = false;
    });

    // get categories
    builder.addCase(getCategoriesStock.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategoriesStock.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategoriesStock.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });
  },
});

export default StockSlice.reducer;